'use client';

import React from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useDispatch, useSelector } from '@studygenius/shared/Variables';
import { useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import { Hidden } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountPopovers from '../AccountPopovers';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import { TextField, InputBase, IconButton } from '@mui/material';
import colors from '@studygenius/shared/Colors';
import { MUIStyles } from '@studygenius/shared/Variables';
import Login from '../login/component';
import Signup from './signup/component';
import ForgotPassword from './popups/ForgotPassword';
import { PrimaryWhiteButton, defaultColors, InvisibleButton, PrimaryButton } from '../Components';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslations } from 'next-intl';
export const POPOVER_CONTENT = (props) => ({
  LOGIN: <Login {...props} />,
  SIGNUP: <Signup {...props} />,
  FORGOT_PASSWORD: <ForgotPassword {...props} />,
  FALSE: false,
});

export const menuHeight = '64px';

export const setPopovercontentWithClose = (setPopoverContent) =>
  POPOVER_CONTENT({ close: () => setPopoverContent(false), setPopoverContent });

export default function MenuBar() {
  const router = useRouter();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [PopoverContent, setPopoverContent] = React.useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const [searchQueryInput, setSearchQueryInput] = React.useState('');
  const contentWithClose = setPopovercontentWithClose(setPopoverContent);

  const userEmail = useSelector((state) => state.websocket.userProfile?.email);
  const userName = useSelector((state) => state.websocket.userProfile?.name);
  const userId = useSelector((state) => state.websocket.userProfile?.id);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const t = useTranslations('NavigationBar')

  const navItems = [
    { text: 'Home', link: '/', icon: CottageOutlinedIcon },
  ];

  // State to manage which menus are open
  const [menuAnchors, setMenuAnchors] = React.useState({});

  const setUserProfile = (userProfile) => dispatch(SET_USER_PROFILE(userProfile));

  const signOut = async () => {
    await authentication().signOut();
    setUserProfile(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handlePopoverClose = () => {
    setPopoverContent(false);
  };

  const RenderMobileOption = ({ item, index, bottom }) => {
    const Icon = item.icon;
    const [openSubMenu, setOpenSubMenu] = React.useState(false);

    const handleClick = () => {
      if (item.onClick) {
        item.onClick();
        setMobileOpen(false); // Close the drawer
      } else if (item.subItems) {
        // Toggle the open state of submenu
        setOpenSubMenu(!openSubMenu);
      } else if (item.link) {
        router.push(item.link);
        setMobileOpen(false); // Close the drawer
      }
    };

    return (
      <Box>
        <Box
          sx={{
            borderBottom: `1px solid ${colors.text}`,
            padding: 0,
            paddingLeft: 0,
            display: 'flex',
            alignItems: 'center',
            ...(bottom ? { borderBottom: 'none', borderTop: `1px solid ${colors.text}`, padding: 0 } : {}),
          }}
        >
          <PrimaryButton
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              height: bottom ? '50px' : '70px',
              padding: 2,
              paddingLeft: 1,
              marginLeft: 0,
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            {Icon ? (
              <Icon
                color={colors.white}
                sx={{ color: colors.white, width: '32px', height: '32px', paddingRight: 1 }}
                size={24}
              />
            ) : null}
            <Typography sx={{ ...MUIStyles.nuitoBold, color: colors.white }}>{item.text}</Typography>
            {item.subItems ? (
              openSubMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />
            ) : null}
          </PrimaryButton>
        </Box>
        {item.subItems && openSubMenu && (
          <Box sx={{ pl: 4 }}>
            {item.subItems.map((subItem, subIndex) => (
              <PrimaryButton
                key={subIndex}
                onClick={() => {
                  router.push(subItem.link);
                  setMobileOpen(false); // Close the drawer
                }}
                sx={{
                  paddingLeft: 1,
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography sx={{ ...MUIStyles.nuitoBold, color: colors.white }}>{subItem.text}</Typography>
              </PrimaryButton>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const mobileOptions = !userEmail
    ? [
        {
          onClick: () => setPopoverContent(contentWithClose.LOGIN),
          text: t('LOG_IN'),
          icon: Person2OutlinedIcon,
        },
        {
          onClick: () => setPopoverContent(contentWithClose.SIGNUP),
          text: t('REGISTER'),
          icon: PersonAddAlt1OutlinedIcon,
        },
      ]
    : [
        {
          text: t('ACCOUNT'),
          onClick: () => router.push('account'),
          icon: Person2OutlinedIcon,
        },
      ];

  const RightItems = !userEmail ? (
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '240px', justifyContent: 'end' }}>
      <PrimaryWhiteButton
        variant={'outlined'}
        sx={{ marginLeft: 1, marginRight: 1, height: '42px' }}
        onClick={() => setPopoverContent(contentWithClose.LOGIN)}
      >
       {t('LOG_IN')}

      </PrimaryWhiteButton>
      <PrimaryButton
        onClick={() => setPopoverContent(contentWithClose.SIGNUP)}
        sx={{ height: '42px' }}
      >
        {t('REGISTER')}
      </PrimaryButton>
    </Box>
  ) : (
    <InvisibleButton
      isActive={false} // Adjust if you have logic to determine active state
      onClick={() => router.push('account')}
      sx={{ width: '240px', justifyContent: 'end'}}
    >
      <Typography
        sx={{ ...MUIStyles.latoBoldParagraph, color: colors.text, marginLeft: 1 }}
      >
        {userName}
      </Typography>
      <Avatar sx={{ backgroundColor: colors.main, width: '32px', height: '32px', marginLeft: 1 }}>
        <PersonIcon sx={{ color: colors.white }} />
      </Avatar>
    </InvisibleButton>
  );

  return (
    <AppBar component="nav" sx={{ height: menuHeight, backgroundColor: colors.white }}>
      <Toolbar
        sx={{
          backgroundColor: `${colors.white} !important`,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          height: menuHeight
        }}
      >
        {isMobile && isSearchExpanded ? (
          // When search is expanded on mobile, show back arrow and search bar only
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => setIsSearchExpanded(false)}
              sx={{ mr: 1 }}
            >
              <ArrowBackIcon />
            </IconButton>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (searchQueryInput.trim()) {
                  router.push(
                    `/search?query=${encodeURIComponent(searchQueryInput.trim())}`
                  );
                  setSearchQueryInput(''); // Clear the input after submission
                  setIsSearchExpanded(false); // Collapse the search bar after submission
                }
              }}
              style={{ flexGrow: 1 }} // So that the input fills the remaining space
            >
              <InputBase
                placeholder={t('SEARCH')}
                value={searchQueryInput}
                onChange={(e) => setSearchQueryInput(e.target.value)}
                autoFocus
                endAdornment={
                  <IconButton type="submit" sx={{ p: '5px' }}>
                    <SearchIcon />
                  </IconButton>
                }
                sx={{
                  width: '100%',
                  backgroundColor: '#f1f1f1',
                  borderRadius: '8px',
                  padding: '2px 8px',
                }}
              />
            </form>
          </>
        ) : (
          // Regular toolbar content
          <>
            {/* Left side: menu icon (mobile only) */}
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setMobileOpen(true)}
                sx={{ mr: 2 }}
              >
                <MenuIcon color={colors.main} sx={{ color: colors.main, height: '32px', width: '32px' }} />
              </IconButton>
            )}

            {/* Logo */}
            <Box onClick={() => router.push('/')} sx={{ cursor: 'pointer' }}>
              <Box
                component="img"
                src={'/inline-combo-2.png'}
                onClick={() => router.push('/')}
                sx={{
                  cursor: 'pointer',
                  marginLeft: { xs: 0, md: '40px' },
                  marginBottom: '4px',
                  width: { xs: '200px', sm: '200px', md: '254px' },
                  height: 'auto',
                }}
              />
            </Box>

            {/* Spacer to push content to the right on desktop */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}

            {/* Desktop search bar */}
            {!isMobile && (
              <Box
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2,
                  mt: 0,
                }}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (searchQueryInput.trim()) {
                      router.push(
                        `/search?query=${encodeURIComponent(searchQueryInput.trim())}`
                      );
                      setSearchQueryInput('');
                    }
                  }}
                >
                  <InputBase
                    placeholder={t('SEARCH')}
                    value={searchQueryInput}
                    onChange={(e) => setSearchQueryInput(e.target.value)}
                    endAdornment={
                      <IconButton type="submit" sx={{ p: '5px' }}>
                        <SearchIcon sx={{ height: '32px', width: '32px' }} />
                      </IconButton>
                    }
                    sx={{
                      width: '533px',
                      backgroundColor: '#f1f1f1',
                      borderRadius: '8px',
                      padding: '2px 8px',
                      transition: 'width 0.3s ease-in-out',
                      marginRight: '26px',
                    }}
                  />
                </form>
              </Box>
            )}

            {/* Right side: search icon (mobile only) */}
            {isMobile && (
              <IconButton
                onClick={() => setIsSearchExpanded(true)}
                sx={{
                  color: colors.main,
                }}
              >
                <SearchIcon sx={{ height: '32px', width: '32px' }} />
              </IconButton>
            )}

            {/* RightItems - only show on desktop */}
            {!isMobile && RightItems}
          </>
        )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        sx={{
          height: '0px',
          backgroundColor: colors.main,
          width: '80vw',
          '& .MuiDrawer-paper': {
            backgroundColor: colors.main,
            width: '260px',
          },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        {/* Mobile Menu */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            {navItems.map((item, index) => (
              <RenderMobileOption key={index} item={item} index={index} />
            ))}
          </Box>
          <Box>
            {mobileOptions.map((item, index) => (
              <RenderMobileOption key={index} item={item} index={index} bottom={true} />
            ))}
          </Box>
        </Box>
      </Drawer>

      {/* Remove extra search bar below navigation when searching on mobile */}
      {/* So remove or comment out this section */}
      {/*
      <Box
        sx={{
          position: 'relative',
        }}
      >
        {(isMobile && isSearchExpanded) && (
          // Remove this block since we're rendering the search bar inside the Toolbar now
        )}
      </Box>
      */}

      {/* Account Popovers */}
      <AccountPopovers PopoverContent={PopoverContent} setPopoverContent={setPopoverContent} />
    </AppBar>
  );
}