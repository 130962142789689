import React from 'react';
import Footer from './Footer';
import colors from '@studygenius/shared/Colors';
import { Box } from '@mui/material';
import { MUIStyles } from '@studygenius/shared/Variables';
import SimpleMenu from './SimpleMenu';
import dynamic from 'next/dynamic';
import FullMenu from './[locale]/FullMenu'
import Script from 'next/script';
import ClientMenuSwap from './ClientMenuSwap';
import ClientProviders from './ClientProviders'; // The client component we just created
// const FullMenu = dynamic(() => import('./FullMenu'), { ssr: false }); 


function NoHydration({ children }) {
  console.log('typeof window', typeof window)
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? children : null}
    </div>
  );
}

export function EnhancedComponent(props) {
  const {
    WrappedComponent,
    children,
    topBorder = false,
    sideBorder = false,
    onlyMenu = false,
    show = true,
    containerSx = {},
  } = props;
  let showFullMenu = true

  if (!show) return children;
  console.log('typeof window', typeof window)
  return (
    <div>
      {/* Render the server component Menu */}
      <div id="simple-menu" style={{ display: 'block' }}>
        <SimpleMenu />
      </div>
      <ClientMenuSwap />
      {/* Render FullMenu with inline style to hide by default */}
      <div id="full-menu" style={{ display: 'block' }}>
        <FullMenu />
      </div>

      {/* Inline script to toggle visibility on the client side */}
      <Script id="menu-toggle-script" strategy="afterInteractive">
        {`
          (function() {
            var simpleMenu = document.getElementById('simple-menu');
            var fullMenu = document.getElementById('full-menu');
            if (simpleMenu && fullMenu) {
              simpleMenu.style.display = 'none';
              fullMenu.style.display = 'block';
            }
          })();
        `}
      </Script>

      {/* Wrap client-only components inside ClientProviders */}
      <ClientProviders>
        {/* If WrappedComponent needs to access setPopoverContent, ensure it's a client component */}
        {WrappedComponent && (
          <WrappedComponent {...props} />
        )}
      </ClientProviders>

      <Box
        sx={{
          marginTop: '64px',
          borderTop: topBorder
            ? { xs: `10px solid ${colors.lightGreen}`, md: `18px solid ${colors.lightGreen}` }
            : null,
          borderBottom: null,
          
          borderRight: sideBorder
            ? { xs: `8px solid ${colors.lightGreen}`, md: `16px solid ${colors.lightGreen}` }
            : null,
          borderLeft: sideBorder
            ? { xs: `8px solid ${colors.lightGreen}`, md: `16px solid ${colors.lightGreen}` }
            : null,
          position: 'relative',
          ...containerSx,
        }}
      >
        {children}
      </Box>
      {onlyMenu ? null : <Footer />}
    </div>
  );
}

export default EnhancedComponent;