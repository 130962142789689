'use client'

import { useRouter } from 'next/navigation'
import {Box, TextField, Typography,  Grid} from '@mui/material'
import colors from '@studygenius/shared/Colors'
import { MUIStyles, authentication } from '@studygenius/shared/Variables'
import {OutlinedGreenButton} from '../Components';
import { PrimaryLoadingButton } from '../ClientComponents'
import sigInImage from '../../../public/sign-in.png';
import { getAuth, setPersistence, browserLocalPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import {setPopovercontentWithClose} from '../[locale]/FullMenu';
import { CloseButton } from '../Components'
import { useTranslations } from 'next-intl'



 


export default function LoginUi({ isLoading, value, setValue, signUp, navigation, close , setPopoverContent}) {
  const contentWithClose = setPopovercontentWithClose(setPopoverContent)

    const router = useRouter()
    const t = useTranslations('Login')

    const openForgotPassword = () => setPopoverContent(contentWithClose.FORGOT_PASSWORD)

    const login = async() => {
      // await setPersistence(browserLocalPersistence)

      // await setPersistence(authentication(), browserLocalPersistence)
        const isAuthenticated = await signUp();
        if(isAuthenticated) return close()
    }
            return (
              <Box>
                     <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: {xs: '16px',md: '32px'}, marginY: 2 }}>
      <Typography sx={{...MUIStyles.smallHeader}}>{t('LOGIN')}</Typography>
      <CloseButton onClick={() => setPopoverContent(false)} />
    </Box>
                   <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 2, marginBottom: 3}}>
                    <img src='/sign-in.png' style={{ width: '108px', aspectRatio: 1}}/>
                  </Box>
                  <Box sx={{marginLeft: 2, display: 'flex', flexDirection: 'column', marginRight: 2}}>
                {/* <Typography variant="h5" sx={{...MUIStyles.nuitoHeader}}>Login into StudyGenius
                  </Typography> */}
                  <Box sx={{width: '100%', backgroundColor: colors.text, height: '1px', marginTop: 2, marginBottom: 0}} />
                    <TextField label={t("EMAIL")} placeholder="example@gmail.com" sx={{marginTop: 2}} onChange={(event) => setValue({ ...value, email: event.target.value })} value={value.email}></TextField>
                    <TextField sx={{marginTop: 2}} label={t("PASSWORD")} placeholder={t("ENTER_PASSWORD")} type="password" name={t("PASSWORD")}value={value.password} onChange={(text) => setValue({ ...value, password: event.target.value })} />
                    {!!value.error && <Box><Typography sx={{...MUIStyles.smallParagraph, color: colors.errorText, marginTop: 2, textAlign: 'center'}}>{value.error}</Typography></Box>}

                  <PrimaryLoadingButton loading={isLoading} disabled={!value.email || !value.password} onClick={login} variant={'contained'} sx={{marginTop: 2}}>{t('SIGN_IN')}</PrimaryLoadingButton>
                  <OutlinedGreenButton onClick={openForgotPassword} sx={{border: 'none', width: 'fit-content', paddingTop: 1, paddingBottom: 1, marginTop: 1, marginBottom: 1, paddingLeft: 0}}><Typography sx={{...MUIStyles.mediumParagraph, color: colors.lightGreen, textTransform: 'none'}}>{t('FORGOT_PASSWORD')}</Typography></OutlinedGreenButton>
                  </Box>               
                  </Box>
              );
  }