const translations = {
    userType: {
        question: 'Ertu núna í námi?',
        options: {
            school_student: 'Nemandi í grunnskóla',
            university_student: 'Nemandi í háskóla',
            not_student: 'Ekki nemandi',
        },
    },
    schoolCurriculum: {
        question: 'Hvaða námskrá ert þú að læra eftir?',
        options: {
            ib: 'Alþjóðlegt Baccalaureate (IB)',
            british: 'Breska námskráin (GCSE/A-Levels)',
            american: 'Ameríska námskráin',
            swedish: 'Sænska námskráin',
            australian: 'Ástralska námskráin',
            other: 'Önnur',
        },
    },
    schoolYear: {
        question: 'Í hvaða bekk ertu?',
        optionsByCurriculum: {
            ib: [
                { value: 'year11_ib1', label: 'Ár 11 (IB Ár 1)' },
                { value: 'year12_ib2', label: 'Ár 12 (IB Ár 2)' },
            ],
            british: [
                { value: 'year10_gcse1', label: 'Ár 10 (GCSE Ár 1)' },
                { value: 'year11_gcse2', label: 'Ár 11 (GCSE Ár 2)' },
                { value: 'year12_as', label: 'Ár 12 (AS-Ár)' },
                { value: 'year13_a', label: 'Ár 13 (A-Ár)' },
            ],
            american: [
                { value: 'grade9', label: 'Bekkur 9 (Freshman)' },
                { value: 'grade10', label: 'Bekkur 10 (Sophomore)' },
                { value: 'grade11', label: 'Bekkur 11 (Junior)' },
                { value: 'grade12', label: 'Bekkur 12 (Senior)' },
            ],
            australian: [
                { value: 'year9', label: 'Bekkur 9' },
                { value: 'year10', label: 'Bekkur 10' },
                { value: 'year11', label: 'Bekkur 11' },
                { value: 'year12', label: 'Bekkur 12' },
            ],
            swedish: [
                { value: 'year7', label: 'Bekkur 7' },
                { value: 'year8', label: 'Bekkur 8' },
                { value: 'year9', label: 'Bekkur 9' },
                { value: 'gymnasium1', label: 'Menntaskóli ár 1' },
                { value: 'gymnasium2', label: 'Menntaskóli ár 2' },
                { value: 'gymnasium3', label: 'Menntaskóli ár 3' },
            ],
            other: [
                { value: 'year7', label: 'Bekkur 7' },
                { value: 'year8', label: 'Bekkur 8' },
                { value: 'year9', label: 'Bekkur 9' },
                { value: 'year10', label: 'Bekkur 10' },
                { value: 'year11', label: 'Bekkur 11' },
                { value: 'year12', label: 'Bekkur 12' },
            ],
        },
    },
    schoolSubjects: {
        question: 'Veldu þau fög sem þú ert að læra núna:',
        optionsByCurriculum: {
            ib: [
                { value: 'math_hl', label: 'Stærðfræði HL' },
                { value: 'math_sl', label: 'Stærðfræði SL' },
                { value: 'physics_hl', label: 'Eðlisfræði HL' },
                { value: 'physics_sl', label: 'Eðlisfræði SL' },
                { value: 'chemistry_hl', label: 'Efnafræði HL' },
                { value: 'chemistry_sl', label: 'Efnafræði SL' },
                { value: 'biology_hl', label: 'Líffræði HL' },
                { value: 'biology_sl', label: 'Líffræði SL' },
                { value: 'english_lit_hl', label: 'Enska A Bókmenntir HL' },
                { value: 'english_lit_sl', label: 'Enska A Bókmenntir SL' },
                { value: 'history_hl', label: 'Saga HL' },
                { value: 'history_sl', label: 'Saga SL' },
                { value: 'economics_hl', label: 'Hagfræði HL' },
                { value: 'economics_sl', label: 'Hagfræði SL' },
                { value: 'cs_hl', label: 'Tölvunarfræði HL' },
                { value: 'cs_sl', label: 'Tölvunarfræði SL' },
                { value: 'visual_arts_hl', label: 'Myndlist HL' },
                { value: 'visual_arts_sl', label: 'Myndlist SL' },
                { value: 'spanish_b_hl', label: 'Spænska B HL' },
                { value: 'spanish_b_sl', label: 'Spænska B SL' },
            ],
            british: [
                { value: 'mathematics', label: 'Stærðfræði' },
                { value: 'further_math', label: 'Framhaldsstærðfræði' },
                { value: 'physics', label: 'Eðlisfræði' },
                { value: 'chemistry', label: 'Efnafræði' },
                { value: 'biology', label: 'Líffræði' },
                { value: 'english_lit', label: 'Enskar Bókmenntir' },
                { value: 'english_lang', label: 'Enska Tungumál' },
                { value: 'history', label: 'Saga' },
                { value: 'geography', label: 'Landafræði' },
                { value: 'economics', label: 'Hagfræði' },
                { value: 'cs', label: 'Tölvunarfræði' },
                { value: 'art_design', label: 'List og Hönnun' },
                { value: 'business_studies', label: 'Viðskiptafræði' },
                { value: 'psychology', label: 'Sálfræði' },
                { value: 'sociology', label: 'Félagsfræði' },
                { value: 'law', label: 'Lögfræði' },
                { value: 'french', label: 'Franska' },
                { value: 'spanish', label: 'Spænska' },
                { value: 'german', label: 'Þýska' },
            ],
            american: [
                { value: 'algebra1', label: 'Algebra I' },
                { value: 'geometry', label: 'Rúmfræði' },
                { value: 'algebra2', label: 'Algebra II' },
                { value: 'pre_calculus', label: 'Forsnúningur' },
                { value: 'calculus', label: 'Reiknivísindi' },
                { value: 'ap_calc_ab', label: 'AP Reiknivísindi AB' },
                { value: 'ap_calc_bc', label: 'AP Reiknivísindi BC' },
                { value: 'physics', label: 'Eðlisfræði' },
                { value: 'ap_physics1', label: 'AP Eðlisfræði 1' },
                { value: 'ap_physics2', label: 'AP Eðlisfræði 2' },
                { value: 'chemistry', label: 'Efnafræði' },
                { value: 'ap_chemistry', label: 'AP Efnafræði' },
                { value: 'biology', label: 'Líffræði' },
                { value: 'ap_biology', label: 'AP Líffræði' },
                { value: 'english_lit', label: 'Enskar Bókmenntir' },
                { value: 'ap_english_lit', label: 'AP Enskar Bókmenntir' },
                { value: 'us_history', label: 'Saga Bandaríkjanna' },
                { value: 'ap_us_history', label: 'AP Saga Bandaríkjanna' },
                { value: 'world_history', label: 'Heimsaga' },
                { value: 'ap_world_history', label: 'AP Heimsaga' },
                { value: 'government', label: 'Stjórnvöld' },
                { value: 'economics', label: 'Hagfræði' },
                { value: 'spanish', label: 'Spænska' },
                { value: 'ap_spanish', label: 'AP Spænska Tungumál' },
            ],
            australian: [
                { value: 'math_methods', label: 'Stærðfræðiaðferðir' },
                { value: 'specialist_math', label: 'Sérhæfð stærðfræði' },
                { value: 'general_math', label: 'Almenn Stærðfræði' },
                { value: 'physics', label: 'Eðlisfræði' },
                { value: 'chemistry', label: 'Efnafræði' },
                { value: 'biology', label: 'Líffræði' },
                { value: 'english', label: 'Enska' },
                { value: 'literature', label: 'Bókmenntir' },
                { value: 'modern_history', label: 'Nútímasaga' },
                { value: 'ancient_history', label: 'Fornöld' },
                { value: 'geography', label: 'Landafræði' },
                { value: 'legal_studies', label: 'Lögfræði' },
                { value: 'economics', label: 'Hagfræði' },
                { value: 'business_management', label: 'Viðskiptastjórnun' },
                { value: 'visual_arts', label: 'Myndlist' },
                { value: 'design_tech', label: 'Hönnun og Tækni' },
                { value: 'it', label: 'Upplýsingatækni' },
                { value: 'languages', label: 'Tungumál' },
            ],
            other: [
                { value: 'mathematics', label: 'Stærðfræði' },
                { value: 'adv_math', label: 'Framhaldsstærðfræði' },
                { value: 'physics', label: 'Eðlisfræði' },
                { value: 'chemistry', label: 'Efnafræði' },
                { value: 'biology', label: 'Líffræði' },
                { value: 'english', label: 'Enska' },
                { value: 'history', label: 'Saga' },
                { value: 'geography', label: 'Landafræði' },
                { value: 'economics', label: 'Hagfræði' },
                { value: 'cs', label: 'Tölvunarfræði' },
                { value: 'art_design', label: 'List og Hönnun' },
                { value: 'business_studies', label: 'Viðskiptafræði' },
                { value: 'psychology', label: 'Sálfræði' },
                { value: 'sociology', label: 'Félagsfræði' },
                { value: 'law', label: 'Lögfræði' },
                { value: 'french', label: 'Franska' },
                { value: 'spanish', label: 'Spænska' },
                { value: 'german', label: 'Þýska' },
                { value: 'music', label: 'Tónlist' },
                { value: 'pe', label: 'Líkamsrækt' },
            ],
            swedish: [
                { value: 'mathematics', label: 'Stærðfræði' },
                { value: 'physics', label: 'Eðlisfræði' },
                { value: 'chemistry', label: 'Efnafræði' },
                { value: 'biology', label: 'Líffræði' },
                { value: 'english', label: 'Enska' },
                { value: 'history', label: 'Saga' },
                { value: 'geography', label: 'Landafræði' },
                { value: 'economics', label: 'Hagfræði' },
                { value: 'cs', label: 'Tölvunarfræði' },
                { value: 'art_design', label: 'List og Hönnun' },
                { value: 'business_studies', label: 'Viðskiptafræði' },
                { value: 'psychology', label: 'Sálfræði' },
                { value: 'sociology', label: 'Félagsfræði' },
                { value: 'law', label: 'Lögfræði' },
                { value: 'french', label: 'Franska' },
                { value: 'spanish', label: 'Spænska' },
                { value: 'german', label: 'Þýska' },
                { value: 'music', label: 'Tónlist' },
                { value: 'pe', label: 'Líkamsrækt' },
            ],
        },
        multiple: true,
        dependsOn: { id: 'schoolCurriculum' },
    },
    universityProgram: {
        question: 'Í hvaða prógrammi ertu skráð/ur?',
        options: {
            engineering: 'Verkfræði',
            arts: 'Listir',
            science: 'Vísindi',
            business: 'Viðskiptafræði',
            law: 'Lögfræði',
            medicine: 'Læknisfræði',
            computer_science: 'Tölvunarfræði',
            psychology: 'Sálfræði',
            education: 'Menntun',
            architecture: 'Arkitektúr',
        },
    },
    universityCourses: {
        question: 'Veldu þá áfanga sem þú ert að læra núna:',
        optionsByProgram: {
            engineering: [
                { value: 'calculus1', label: 'Reiknivísindi I' },
                { value: 'calculus2', label: 'Reiknivísindi II' },
                { value: 'linear_algebra', label: 'Línuleg Algebra' },
                { value: 'physics1', label: 'Eðlisfræði I' },
                { value: 'physics2', label: 'Eðlisfræði II' },
                { value: 'chem_for_eng', label: 'Efnafræði fyrir Verkfræðinga' },
                { value: 'statics', label: 'Stöðuleiki' },
                { value: 'dynamics', label: 'Hreyfifræði' },
                { value: 'thermodynamics', label: 'Varmadýnamík' },
                { value: 'materials_science', label: 'Efnisfræði' },
                { value: 'fluid_mechanics', label: 'Vökvamekaník' },
                { value: 'electrical_circuits', label: 'Rafeindahringir' },
                { value: 'eng_design', label: 'Verkfræði hönnun' },
                { value: 'mech_materials', label: 'Efnisvélfræði' },
            ],
            arts: [
                { value: 'intro_literature', label: 'Inngangur að Bókmenntum' },
                { value: 'art_history', label: 'Listasaga' },
                { value: 'philosophy_101', label: 'Heimspeki 101' },
                { value: 'psychology_101', label: 'Sálfræði 101' },
                { value: 'sociology_101', label: 'Félagsfræði 101' },
                { value: 'creative_writing', label: 'Skapandi ritun' },
                { value: 'performing_arts', label: 'Sviðslistir' },
                { value: 'history_west_civ', label: 'Saga vestursins' },
                { value: 'cultural_studies', label: 'Menningarfræði' },
            ],
            science: [
                { value: 'bio1', label: 'Líffræði I' },
                { value: 'bio2', label: 'Líffræði II' },
                { value: 'chem1', label: 'Efnafræði I' },
                { value: 'chem2', label: 'Efnafræði II' },
                { value: 'physics1', label: 'Eðlisfræði I' },
                { value: 'physics2', label: 'Eðlisfræði II' },
                { value: 'earth_science', label: 'Jarðvísindi' },
                { value: 'env_science', label: 'Umhverfisfræði' },
                { value: 'statistics', label: 'Tölfræði' },
                { value: 'genetics', label: 'Erfðafræði' },
                { value: 'organic_chem', label: 'Lífræn efnafræði' },
                { value: 'ecology', label: 'Vistfræði' },
            ],
            business: [
                { value: 'intro_business', label: 'Inngangur að Viðskiptafræði' },
                { value: 'accounting1', label: 'Bókhald I' },
                { value: 'accounting2', label: 'Bókhald II' },
                { value: 'microeconomics', label: 'Reiknivísindi' },
                { value: 'macroeconomics', label: 'Stóra Viđskiptafræði' },
                { value: 'marketing_principles', label: 'Markaðsáætlanagerð' },
                { value: 'business_law', label: 'Viðskiptalög' },
                { value: 'org_behavior', label: 'Hegðun innan Stofnunar' },
                { value: 'finance', label: 'Fjármál' },
                { value: 'operations_mgmt', label: 'Rekstursstjórnun' },
                { value: 'intl_business', label: 'Alþjóðaviðskipti' },
            ],
            law: [
                { value: 'intro_law', label: 'Inngangur að Lögfræði' },
                { value: 'contract_law', label: 'Samningaréttur' },
                { value: 'criminal_law', label: 'Refsiréttur' },
                { value: 'property_law', label: 'Eignaréttur' },
                { value: 'tort_law', label: 'Skaðabótaréttur' },
                { value: 'intl_law', label: 'Alþjóðaréttur' },
                { value: 'constitutional_law', label: 'Stjórnarskrárréttur' },
                { value: 'legal_writing', label: 'Réttarskrif' },
                { value: 'admin_law', label: 'Stjórnsýslulög' },
            ],
            medicine: [
                { value: 'human_anatomy', label: 'Mannslíkamsfræði' },
                { value: 'human_physiology', label: 'Mannslífeðlisfræði' },
                { value: 'biochemistry', label: 'Lífefnafræði' },
                { value: 'medical_ethics', label: 'Læknisfræði siðfræði' },
                { value: 'microbiology', label: 'Örverufræði' },
                { value: 'pathology', label: 'Sjúkdómafræði' },
                { value: 'pharmacology', label: 'Lyfjafræði' },
                { value: 'neuroscience', label: 'Taugavísindi' },
                { value: 'clinical_skills', label: 'Klínísk Hæfni' },
            ],
            computer_science: [
                { value: 'intro_programming', label: 'Inngangur að Forritun' },
                { value: 'oop', label: 'Hluttengin Forritun' },
                { value: 'data_structures', label: 'Gagnastrúktúrar' },
                { value: 'algorithms', label: 'Reiknirit' },
                { value: 'comp_systems', label: 'Tölvukerfi' },
                { value: 'operating_systems', label: 'Stýrikerfi' },
                { value: 'database_systems', label: 'Gagnagrunnskerfi' },
                { value: 'software_eng', label: 'Hugbúnaðarverkfræði' },
                { value: 'ai', label: 'Gervigreind' },
                { value: 'machine_learning', label: 'Véltenging' },
                { value: 'web_dev', label: 'Vefþróun' },
                { value: 'networks_security', label: 'Netkerfi og Öryggi' },
            ],
            psychology: [
                { value: 'intro_psychology', label: 'Inngangur að Sálfræði' },
                { value: 'dev_psychology', label: 'Þroskasálfræði' },
                { value: 'cognitive_psychology', label: 'Hugsunarsálfræði' },
                { value: 'abnormal_psychology', label: 'Önnur Sálfræði' },
                { value: 'social_psychology', label: 'Félagassálfræði' },
                { value: 'neuroscience', label: 'Taugavísindi' },
                { value: 'research_methods', label: 'Rannsóknaraðferðir' },
                { value: 'personality_psych', label: 'Persónuleikasálfræði' },
                { value: 'psych_assessment', label: 'Sálfræðilegt Mat' },
            ],
            education: [
                { value: 'foundations_edu', label: 'Forgangsskilyrði Menntunar' },
                { value: 'edu_psychology', label: 'Menntastigssálfræði' },
                { value: 'curriculum_dev', label: 'Námshorn' },
                { value: 'class_mgmt', label: 'Bekkjastjórnun' },
                { value: 'assessment_eval', label: 'Mat og Matsaðferð' },
                { value: 'special_education', label: 'Sérmenntun' },
                { value: 'teaching_practicum', label: 'Kennsluþjálfun' },
                { value: 'edu_technology', label: 'Menntatækni' },
            ],
            architecture: [
                { value: 'design_studio1', label: 'Hönnunarstofa I' },
                { value: 'design_studio2', label: 'Hönnunarstofa II' },
                { value: 'arch_history', label: 'Arkitektasaga' },
                { value: 'building_tech', label: 'Byggingartækni' },
                { value: 'structures', label: 'Uppbyggingar' },
                { value: 'env_systems', label: 'Umhverfiskerfi' },
                { value: 'digital_design', label: 'Stafræn Hönnun' },
                { value: 'urban_planning', label: 'Borgarskipulag' },
                { value: 'sustainable_design', label: 'Sjálfbær Hönnun' },
            ],
        },
        multiple: true,
        dependsOn: { id: 'universityProgram' },
    },
    hobbyInterests: {
        question: 'Veldu þau áhugamál sem þú hefur:',
        options: [
            {
                category: 'Psychology',
                translatedCategory: 'Sálfræði',
                subOptions: [
                    { value: 'cognitive_psychology', label: 'Hugsunarsálfræði' },
                    { value: 'behavioral_psychology', label: 'Hegðunarsálfræði' },
                    { value: 'developmental_psychology', label: 'Þroskasálfræði' },
                    { value: 'social_psychology', label: 'Félagassálfræði' },
                    { value: 'positive_psychology', label: 'Jákvæð sálfræði' },
                    { value: 'therapy_techniques', label: 'Meðferðaraðferðir' },
                ],
            },
            {
                category: 'Playing Music',
                translatedCategory: 'Spila Tónlist',
                subOptions: [
                    { value: 'guitar_beginner', label: 'Gítarleikur Byrjandi' },
                    { value: 'guitar_intermediate_advanced', label: 'Gítarleikur Meðaltýnn/Framhalds' },
                    { value: 'piano_beginner', label: 'Píanó Byrjandi' },
                    { value: 'piano_intermediate_advanced', label: 'Píanó Meðaltýnn/Framhalds' },
                    { value: 'piano_advanced', label: 'Píanó Framhalds' },
                    { value: 'violin', label: 'Fiðla' },
                    { value: 'drums_beginner', label: 'Trommusláttur Byrjandi' },
                    { value: 'drums_intermediate_advanced', label: 'Trommusláttur Meðaltýnn/Framhalds' },
                    { value: 'brass_instruments', label: 'Koparhljóðfæri (Trompet, Básuna)' },
                    { value: 'woodwind_instruments', label: 'Reykið undurgerð plaströr (Flauta, Klarinet)' },
                    { value: 'music_theory', label: 'Tónfræði' },
                    { value: 'music_production', label: 'Tónlistarsköpun' },
                    { value: 'songwriting_lyrics', label: 'Lagasmíð: Textasmíði' },
                    { value: 'songwriting_melody', label: 'Lagasmíð: Melódíugerð' },
                    { value: 'songwriting_structure', label: 'Lagasmíð: Lagskipulag' },
                    { value: 'songwriting_genres', label: 'Lagasmíð: Stíl (Pop, Rock, Hiphop, etc.)' },
                    { value: 'classical_composition', label: 'Klassísk Tóngerving' },
                    { value: 'contemporary_composition', label: 'Samtímatóning' },
                    { value: 'arranging_music', label: 'Útsamsetning Tónlistar' },
                    { value: 'electronic_composition', label: 'Rafrænt Tónsköpun' },
                ],
            },
            {
                category: 'Languages',
                translatedCategory: 'Tungumál',
                subOptions: [
                    { value: 'spanish_beginner', label: 'Spænska Byrjandi' },
                    { value: 'spanish_intermediate', label: 'Spænska Meðaltýnn' },
                    { value: 'spanish_advanced', label: 'Spænska Framhalds' },
                    { value: 'french_beginner', label: 'Franska Byrjandi' },
                    { value: 'french_intermediate', label: 'Franska Meðaltýnn' },
                    { value: 'french_advanced', label: 'Franska Framhalds' },
                    { value: 'mandarin_beginner', label: 'Mandarin Byrjandi' },
                    { value: 'mandarin_intermediate', label: 'Mandarin Meðaltýnn' },
                    { value: 'mandarin_advanced', label: 'Mandarin Framhalds' },
                    { value: 'german_beginner', label: 'Þýska Byrjandi' },
                    { value: 'german_intermediate', label: 'Þýska Meðaltýnn' },
                    { value: 'german_advanced', label: 'Þýska Framhalds' },
                    { value: 'italian_beginner', label: 'Ítölsku Byrjandi' },
                    { value: 'italian_intermediate', label: 'Ítölsku Meðaltýnn' },
                    { value: 'italian_advanced', label: 'Ítölsku Framhalds' },
                    { value: 'japanese_beginner', label: 'Japanískt Byrjandi' },
                    { value: 'japanese_intermediate', label: 'Japanískt Meðaltýnn' },
                    { value: 'japanese_advanced', label: 'Japanískt Framhalds' },
                    { value: 'russian_beginner', label: 'Rússneska Byrjandi' },
                    { value: 'russian_intermediate', label: 'Rússneska Meðaltýnn' },
                    { value: 'russian_advanced', label: 'Rússneska Framhalds' },
                ],
            },
            {
                category: 'Technology_and_Coding',
                translatedCategory: 'Tækni og Forritun',
                subOptions: [
                    { value: 'frontend_dev', label: 'Framsíðuforritun (HTML, CSS, JavaScript, React)' },
                    { value: 'backend_dev', label: 'Baksíðuforritun (Node.js, Python, Ruby)' },
                    { value: 'fullstack_dev', label: 'Fullur stakkur þróun' },
                    { value: 'ux_design', label: 'UX Hönnun' },
                    { value: 'ui_design', label: 'UI Hönnun' },
                    { value: 'game_design', label: 'Spilahönnun' },
                    { value: 'cybersecurity', label: 'Neteinelti' },
                    { value: 'data_science', label: 'Gagnavísindi' },
                    { value: 'machine_learning', label: 'Véltenging' },
                    { value: 'mobile_app_dev', label: 'Flakkarís App Þróun' },
                    { value: 'cloud_computing', label: 'Skýrekstur' },
                ],
            },
            {
                category: 'Entertainment',
                translatedCategory: 'Skemmtun',
                subOptions: [
                    { value: 'movie_analysis', label: 'Kvikmyndagreining' },
                    { value: 'high_grossing_movies', label: 'Hágengið Kvikmyndir' },
                    { value: 'celebrity_news', label: 'Frægðafréttir' },
                    { value: 'tv_shows', label: 'Sjónvarpsþættir og Ráðstefnur' },
                    { value: 'popular_video_games', label: 'Vinsæl vídeóleikir' },
                    { value: 'comic_books', label: 'Teiknimyndasögur og Grafískar Skáldsögur' },
                ],
            },
            {
                category: 'Self_Growth_Personal_Development',
                translatedCategory: 'Sjálfsþróun/Flokkskennsla',
                subOptions: [
                    { value: 'mindfulness', label: 'Skynrænn leiðbeinandi og Hugleiðsla' },
                    { value: 'time_management', label: 'Tímatækni' },
                    { value: 'productivity_hacks', label: 'Framleiðsluhakk' },
                    { value: 'emotional_intelligence', label: 'Viðhorfsrökfræði' },
                    { value: 'goal_setting', label: 'Markmiðaákvörðun og Vanartrén' },
                    { value: 'public_speaking', label: 'Opin spjallfortíðarverk' },
                    { value: 'networking_skills', label: 'Netkerfisferðir' },
                    { value: 'negotiation_techniques', label: 'Samningstækni' },
                    { value: 'leadership_skills', label: 'Hlíningarhæfileikar' },
                    { value: 'critical_thinking', label: 'Rökfræði' },
                    { value: 'creativity_enhancement', label: 'Skapargerð' },
                ],
            },
            {
                category: 'Politics_Geography',
                translatedCategory: 'Stjórnmál/Landafræði',
                subOptions: [
                    { value: 'political_theories', label: 'Stjórnmálahnutur' },
                    { value: 'global_current_events', label: 'Globalar Raunarviðburðir' },
                    { value: 'environmental_studies', label: 'Umhverfisforskólar' },
                    { value: 'cultural_geography', label: 'Menningarheimar' },
                    { value: 'international_relations', label: 'Alþjóðasambönd' },
                    { value: 'geopolitical_analysis', label: 'Geopolitical Analysis' },
                ],
            },
            {
                category: 'Sports',
                translatedCategory: 'Íþróttir',
                subOptions: [
                    { value: 'gym_techniques', label: 'Líkamsræktaraðferðir' },
                    { value: 'sport_psychology', label: 'Sálfræði í Íþróttir' },
                    { value: 'sport_history', label: 'Íþróttasaga/fest' },
                    { value: 'coaching_techniques', label: 'Þjálfunaraðferðir' },
                    { value: 'performance_analysis', label: 'Frammistöðugreining' },
                ],
            },
            {
                category: 'Creative_Arts',
                translatedCategory: 'Listvirkni',
                subOptions: [
                    { value: 'painting_drawing', label: 'Málverkstækniker/hönnun' },
                    { value: 'photography', label: 'Ljósmyndun fyrir byrjendur' },
                    { value: 'digital_art', label: 'Tölvulist og Myndlist' },
                    { value: 'crafting_diy', label: 'Greinargerð og DIY verkefni' },
                    { value: 'graphic_design', label: 'Ferskingahönnun' },
                    { value: 'creative_writing', label: 'Listu skrift' },
                ],
            },
            {
                category: 'Cooking_Baking',
                translatedCategory: 'Elda/baka',
                subOptions: [
                    { value: 'basic_cooking_skills', label: 'Litabækling' },
                    { value: 'specialty_cuisines', label: 'Sérstæðir Stratfæringar (Ítalskur, Mexíkóskur, Asíska' },
                    { value: 'baking_techniques', label: 'Bakstursskrá' },
                    { value: 'meal_prep_ideas', label: 'Máltíðabók tjárvika' },
                    { value: 'healthy_eating', label: 'Heilsusamleg matvals leið ömí grána' },
                ],
            },
            {
                category: 'Travel',
                translatedCategory: 'Ferðast',
                subOptions: [
                    { value: 'travel_planning', label: 'Ferðabókargerðir' },
                    { value: 'cultural_etiquette', label: 'Tónlistarhugmyndir um grundvallaðumyddling' },
                    { value: 'adventures_abroad', label: 'Ævintýri og reynslur vel log' },
                    { value: 'destination_highlights', label: 'Leskólivani um glæsiluðinni' },
                    { value: 'travel_photography', label: 'Ferðaljósmyndun' },
                ],
            },
            {
                category: 'Health_and_Fitness',
                translatedCategory: 'Heilsa og Líkamsrækt',
                subOptions: [
                    { value: 'nutrition_dieting', label: 'Næringargát' },
                    { value: 'mental_health', label: 'Vitalegt vitnaðagát' },
                    { value: 'yoga_pilates', label: 'Yoga og Pilates' },
                    { value: 'running_marathon', label: 'Hreyfigjálfun' },
                    { value: 'exercise_science', label: 'Æfingarseimdi' },
                ],
            },
            {
                category: 'Science_and_Nature',
                translatedCategory: 'Vísindi og Náttúra',
                subOptions: [
                    { value: 'astronomy_space', label: 'Stjörnufræði og Veturfönn' },
                    { value: 'biology_wildlife', label: 'Fróðleikur um dýralífið' },
                    { value: 'environmental_conservation', label: 'Verndarsvæðing' },
                    { value: 'physics_concepts', label: 'Eðlisfræðibjútur' },
                    { value: 'technology_innovations', label: 'Höfundir links um áhersla mendring' },
                ],
            },
            {
                category: 'History',
                translatedCategory: 'Saga',
                subOptions: [
                    { value: 'ancient_civilizations', label: 'Fornaldarborgar' },
                    { value: 'world_wars', label: 'Alheimsstríðsgluggur' },
                    { value: 'historical_figures', label: 'Eldri mynstrahópa' },
                    { value: 'cultural_history', label: 'Viðvörun um menningaröld' },
                    { value: 'social_movements', label: 'Sturlufélagsærður' },
                    { value: 'history_of_science', label: 'Vísindasaga Tækni' },
                ],
            },
            {
                category: 'Philosophy',
                translatedCategory: 'Heimspeki',
                subOptions: [
                    { value: 'ethics_morality', label: 'Umhyggið' },
                    { value: 'existentialism', label: 'Sanfreyseyðing' },
                    { value: 'eastern_philosophy', label: 'Vestanha Berlingehardga' },
                    { value: 'western_philosophy', label: 'Vestanha Berlingehar' },
                    { value: 'logic_critical_thinking', label: 'Vandamál kosmur og virkijáfngjöf' },
                    { value: 'philosophy_of_mind', label: 'Makkeinspeki' },
                ],
            },
            {
                category: 'Brain_Science',
                translatedCategory: 'Heilavfræði',
                subOptions: [
                    { value: 'neuroscience_basics', label: 'Máltækifrá' },
                    { value: 'neuroplasticity', label: 'Hugfræði orsóknun' },
                    { value: 'memory_techniques', label: 'Gugsæmi lifting' },
                    { value: 'cognitive_enhancement', label: 'Hugfræðigreindu' },
                    { value: 'mental_health_disorders', label: 'Hafa sjúklega vitnaðagátu og meðferðarraunir' },
                    { value: 'psychology_of_learning', label: 'Fróðleikur sálfræði' },
                ],
            },
            {
                category: 'Hobbies',
                translatedCategory: 'Áhugamál',
                subOptions: [
                    { value: 'gardening_basics', label: 'Garðyrkjufræðiskrár fyrir nýliða' },
                    { value: 'writing', label: 'Skrifsmíð' },
                    { value: 'blogging_vlogging', label: 'Bloggaraskólar og Vloggaraskólar' },
                ],
            },
        ],
        multiple: true,
    },
};

export default translations;