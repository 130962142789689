'use client';

import React, { useEffect, useState } from 'react';
import ArticleList from '../../ArticleList';
import SelectedTopics from './SelectedTopics';
import { useSelector } from 'react-redux';
import { fetchUserSelectedTopics } from '@studygenius/shared/Requests';
import EnhancedComponent from '../../EnhancedComponent';
import colors from '@studygenius/shared/Colors';
import { OutlinedPrimaryOpacityButton, PrimaryButton } from '../../Components';
import TopicSelectorModal from '../new-learning-path/content';
import { Typography, IconButton, Button, Box, CircularProgress, Card, CardContent } from '@mui/material';
import { MUIStyles } from '@studygenius/shared/Variables';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import QuizzesCompletedStats from '../../QuizzesCompletedStats';
import { generateArticlesForTopics } from '@studygenius/shared/Requests';
import {useTranslations, useLocale} from 'next-intl';

const Page = () => {
  const userId = useSelector((state) => state.websocket.userProfile?.id);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [isLoadingTopics, setIsLoadingTopics] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [modalOpen, setModalOpen] = useState(false);
  const locale = useLocale()

  const minRequiredWidth = 921; // 288 + 633 (min widths of left and middle columns)
  const showSelectedTopics = windowWidth >= minRequiredWidth;
  const t = useTranslations('HomePage');


  // Fetch user's selected topics when component mounts or userId changes
  useEffect(() => {
    const loadUserSelectedTopics = async () => {
      setIsLoadingTopics(true);
      try {
        if (userId) {
          const topics = await fetchUserSelectedTopics(userId);
          setSelectedTopics(topics);
          if(!topics.length || topics.length === 0) {
            setModalOpen(true)
          }
          setIsLoadingTopics(false);
        } else {
          setSelectedTopics([]);
          // setModalOpen(true)

        }
      } catch (error) {
        console.error('Error fetching user topics:', error);
        // setModalOpen(true)

        setSelectedTopics([]);
        setIsLoadingTopics(false);
      } finally {
       
      }
    };

    loadUserSelectedTopics();
  }, [userId]);

  // // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      // Initial set
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleReselect = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const handleTopicsChanged = (newSelectedTopics) => {
    setSelectedTopics(newSelectedTopics);
    setModalOpen(false);
  };

  const createFacts = (language = 'en') => {
    generateArticlesForTopics(selectedTopics, language)
  }

  if(!selectedTopics.length  && isLoadingTopics) return  <EnhancedComponent><Box sx={{display: 'flex', width: '100%', height: '100%', minHeight: '80vh', justifyContent: 'center', alignItems: 'center'}}><CircularProgress size={48} /></Box></EnhancedComponent>

  return (
    <EnhancedComponent>
      
     {!showSelectedTopics && (
      <>
        <div style={{ textAlign: 'center', padding: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{...MUIStyles.mediumParagraph, opacity: 0.8}}>You have {selectedTopics.length} topics selected</Typography>
          <IconButton onClick={handleReselect} style={{ backgroundColor: colors.main, color: 'white', borderRadius: '4px' }}>
        <FilterAltIcon />
      </IconButton>
          {modalOpen && (
            <TopicSelectorModal
              open={modalOpen}
              onClose={handleModalClose}
              onTopicsChanged={handleTopicsChanged}
              userId={userId}
              selectedTopics={selectedTopics}
              
            />
          )}

        </div>
        <Box sx={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
      <QuizzesCompletedStats />
      </Box>
    </>


      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          backgroundColor: colors.newBackground,
        }}
      >
        {/* Left Column: Selected Topics */}
        {showSelectedTopics && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '280px',
              maxWidth: '360px',
              padding: '24px',
              height: '100vh',
              overflowY: 'auto',
              position: 'sticky',
              top: 0,
            }}
          >
            <SelectedTopics
              selectedTopics={selectedTopics}
              isLoadingTopics={isLoadingTopics}
              setSelectedTopics={setSelectedTopics}
              userId={userId}
              locale={locale}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              handleModalOpen={handleReselect}
              onTopicsChanged={handleTopicsChanged}
            />
          </div>
        )}
        {/* Main Content: Article List */}
        <div style={{ width: '600px' }}>
        {/* <Button onClick={() => createFacts('en')}>Generate facts </Button>
        <Button onClick={() => createFacts('sv')}>Generate swedish facts </Button> */}

         {!selectedTopics.length ? <Card sx={{marginTop: {xs: 0, md: '60px'}}}><CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><Typography sx={{...MUIStyles.latoBoldLargeParagraph}}>{t('NO_TOPICS_MESSAGE')}</Typography>
         <Typography sx={{...MUIStyles.latoBoldParagraph, marginTop: '8px', marginBottom: '24px'}}>{t('SELECT_INTEREST')}</Typography>
         <img src={'/No-Results.png'} style={{height: '250px', width: '250px'}} />
         <PrimaryButton onClick={() => setModalOpen(true)} sx={{marginTop: '32px'}}>{t('START')}</PrimaryButton>
         </CardContent>
         </Card> : 
            <ArticleList
            selectedTopics={selectedTopics}
            isLoadingTopics={isLoadingTopics}
            searchQuery={searchQuery} // Pass the search query here
          />}
        </div>
        {showSelectedTopics && (
       <QuizzesCompletedStats />
        )}   
      </div>
    </EnhancedComponent>
  );
};

export default Page;