'use client'

import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { getSelectedTopics, buildOptionTopicCounts, getSelectedTopicObjects } from '../../getSelectedTopics';
import { setSelectedTopics } from '@studygenius/shared/Requests';
import { useSelector } from 'react-redux';
import { PrimaryButton, PrimaryTransparentButton } from '../../Components';
import { useLocale, useTranslations } from 'next-intl';
import swedishTranslation from './swedishTranslations'
import icelandicTranslations from './icelandicTranslations'

const translationsMap = {
  // en: englishTranslations,
  sv: swedishTranslation,
  is: icelandicTranslations
  // 'es': esTranslations,  // Uncomment when Spanish translations are added
};

const hobbyInterestsOptions = [
  {
    category: 'Languages',
    label: 'Languages',
    subOptions: [
      { value: 'spanish_beginner', label: 'Spanish Beginner' },
      { value: 'spanish_intermediate', label: 'Spanish Intermediate' },
      { value: 'spanish_advanced', label: 'Spanish Advanced' },
      { value: 'french_beginner', label: 'French Beginner' },
      { value: 'french_intermediate', label: 'French Intermediate' },
      { value: 'french_advanced', label: 'French Advanced' },
      { value: 'mandarin_beginner', label: 'Mandarin Chinese Beginner' },
      { value: 'mandarin_intermediate', label: 'Mandarin Chinese Intermediate' },
      { value: 'mandarin_advanced', label: 'Mandarin Chinese Advanced' },
      { value: 'german_beginner', label: 'German Beginner' },
      { value: 'german_intermediate', label: 'German Intermediate' },
      { value: 'german_advanced', label: 'German Advanced' },
      { value: 'italian_beginner', label: 'Italian Beginner' },
      { value: 'italian_intermediate', label: 'Italian Intermediate' },
      { value: 'italian_advanced', label: 'Italian Advanced' },
      { value: 'japanese_beginner', label: 'Japanese Beginner' },
      { value: 'japanese_intermediate', label: 'Japanese Intermediate' },
      { value: 'japanese_advanced', label: 'Japanese Advanced' },
      { value: 'russian_beginner', label: 'Russian Beginner' },
      { value: 'russian_intermediate', label: 'Russian Intermediate' },
      { value: 'russian_advanced', label: 'Russian Advanced' },
    ],
  },
  {
    category: 'Playing Music',
    label: 'Playing Music',
    subOptions: [
      { value: 'guitar_beginner', label: 'Guitar Beginner' },
      { value: 'guitar_intermediate_advanced', label: 'Guitar Intermediate/Advanced' },
      { value: 'piano_beginner', label: 'Piano Beginner' },
      { value: 'piano_intermediate_advanced', label: 'Piano Intermediate/Advanced' },
      { value: 'piano_advanced', label: 'Piano Advanced' },
      { value: 'violin', label: 'Violin' },
      { value: 'drums_beginner', label: 'Drums Beginner' },
      { value: 'drums_intermediate_advanced', label: 'Drums Intermediate/Advanced' },
      { value: 'brass_instruments', label: 'Brass Instruments (Trumpet, Trombone)' },
      { value: 'woodwind_instruments', label: 'Woodwind Instruments (Flute, Clarinet)' },
      { value: 'music_theory', label: 'Music Theory' },
      { value: 'music_production', label: 'Music Production' },
      { value: 'songwriting_lyrics', label: 'Songwriting: Lyric Writing' },
      { value: 'songwriting_melody', label: 'Songwriting: Melody Creation' },
      { value: 'songwriting_structure', label: 'Songwriting: Song Structure' },
      { value: 'songwriting_genres', label: 'Songwriting: Genres (Pop, Rock, Hip-Hop, etc.)' },
      { value: 'classical_composition', label: 'Classical Composition' },
      { value: 'contemporary_composition', label: 'Contemporary Composition' },
      { value: 'arranging_music', label: 'Arranging Music' },
      { value: 'electronic_composition', label: 'Electronic Composition' },
    ],
  },
  {
    category: 'Technology_and_Coding',
    label: 'Technology and Coding',
    subOptions: [
      { value: 'frontend_dev', label: 'Front-End Development (HTML, CSS, JavaScript, React)' },
      { value: 'backend_dev', label: 'Back-End Development (Node.js, Python, Ruby)' },
      { value: 'fullstack_dev', label: 'Full-Stack Development' },
      { value: 'ux_design', label: 'UX Design' },
      { value: 'ui_design', label: 'UI Design' },
      { value: 'game_design', label: 'Game Design' },
      { value: 'cybersecurity', label: 'Cybersecurity' },
      { value: 'data_science', label: 'Data Science' },
      { value: 'machine_learning', label: 'Machine Learning' },
      { value: 'mobile_app_dev', label: 'Mobile App Development' },
      { value: 'cloud_computing', label: 'Cloud Computing' },
    ],
  },
  {
    category: 'Entertainment',
    label: 'Entertainment',
    subOptions: [
      { value: 'movie_analysis', label: 'Movie Analysis' },
      { value: 'high_grossing_movies', label: 'High-Grossing Movies' },
      { value: 'celebrity_news', label: 'Celebrity News and Gossip' },
      { value: 'tv_shows', label: 'Television Shows and Series' },
      { value: 'popular_video_games', label: 'Popular Video Games' },
      { value: 'comic_books', label: 'Comic Books and Graphic Novels' },
    ],
  },
  {
    category: 'Self_Growth_Personal_Development',
    label: 'Self-Growth/Personal Development',
    subOptions: [
      { value: 'mindfulness', label: 'Mindfulness and Meditation' },
      { value: 'time_management', label: 'Time Management Techniques' },
      { value: 'productivity_hacks', label: 'Productivity Hacks' },
      { value: 'emotional_intelligence', label: 'Emotional Intelligence' },
      { value: 'goal_setting', label: 'Goal Setting & Habit Formation' },
      { value: 'public_speaking', label: 'Public Speaking' },
      { value: 'networking_skills', label: 'Networking Skills' },
      { value: 'negotiation_techniques', label: 'Negotiation Techniques' },
      { value: 'leadership_skills', label: 'Leadership Skills' },
      { value: 'critical_thinking', label: 'Critical Thinking' },
      { value: 'creativity_enhancement', label: 'Creativity Enhancement' },
    ],
  },
  {
    category: 'Politics_Geography',
    label: 'Politics/Geography',
    subOptions: [
      { value: 'political_theories', label: 'Political Theories' },
      { value: 'global_current_events', label: 'Global Current Events' },
      { value: 'environmental_studies', label: 'Environmental Studies' },
      { value: 'cultural_geography', label: 'Cultural Geography' },
      { value: 'international_relations', label: 'International Relations' },
      { value: 'geopolitical_analysis', label: 'Geopolitical Analysis' },
    ],
  },
  {
    category: 'Sports',
    label: 'Sports',
    subOptions: [
      { value: 'gym_techniques', label: 'Gym Techniques' },
      { value: 'sport_psychology', label: 'Sport Psychology' },
      { value: 'sport_history', label: 'Sport History/Culture' },
      { value: 'coaching_techniques', label: 'Coaching Techniques' },
      { value: 'performance_analysis', label: 'Performance Analysis' },
    ],
  },
  {
    category: 'Creative_Arts',
    label: 'Creative Arts',
    subOptions: [
      { value: 'painting_drawing', label: 'Painting and Drawing Techniques' },
      { value: 'photography', label: 'Photography for Beginners' },
      { value: 'digital_art', label: 'Digital Art and Illustration' },
      { value: 'crafting_diy', label: 'Crafting and DIY Projects' },
      { value: 'graphic_design', label: 'Graphic Design' },
      { value: 'creative_writing', label: 'Creative Writing' },
    ],
  },
  {
    category: 'Cooking_Baking',
    label: 'Cooking/Baking',
    subOptions: [
      { value: 'basic_cooking_skills', label: 'Basic Cooking Skills' },
      { value: 'specialty_cuisines', label: 'Specialty Cuisines (Italian, Mexican, Asian)' },
      { value: 'baking_techniques', label: 'Baking Techniques' },
      { value: 'meal_prep_ideas', label: 'Meal Prep Ideas' },
      { value: 'healthy_eating', label: 'Healthy Eating and Nutrition' },
    ],
  },
  {
    category: 'Travel',
    label: 'Travel',
    subOptions: [
      { value: 'travel_planning', label: 'Travel Planning Tips' },
      { value: 'cultural_etiquette', label: 'Cultural Etiquette Around the World' },
      { value: 'adventures_abroad', label: 'Adventures and Experiences Abroad' },
      { value: 'destination_highlights', label: 'Destination Highlights' },
      { value: 'travel_photography', label: 'Travel Photography' },
    ],
  },
  {
    category: 'Health_and_Fitness',
    label: 'Health and Fitness',
    subOptions: [
      { value: 'nutrition_dieting', label: 'Nutrition and Dieting' },
      { value: 'mental_health', label: 'Mental Health Awareness' },
      { value: 'yoga_pilates', label: 'Yoga and Pilates' },
      { value: 'running_marathon', label: 'Running and Marathon Training' },
      { value: 'exercise_science', label: 'Exercise Science' },
    ],
  },
  {
    category: 'Science_and_Nature',
    label: 'Science and Nature',
    subOptions: [
      { value: 'astronomy_space', label: 'Astronomy and Space Exploration' },
      { value: 'biology_wildlife', label: 'Biology and Wildlife' },
      { value: 'environmental_conservation', label: 'Environmental Conservation' },
      { value: 'physics_concepts', label: 'Physics Concepts' },
      { value: 'technology_innovations', label: 'Technology Innovations' },
    ],
  },
  {
    category: 'History',
    label: 'History',
    subOptions: [
      { value: 'ancient_civilizations', label: 'Ancient Civilizations' },
      { value: 'world_wars', label: 'World Wars Studies' },
      { value: 'historical_figures', label: 'Historical Figures and Their Impact' },
      { value: 'cultural_history', label: 'Cultural History' },
      { value: 'social_movements', label: 'Social Movements' },
      { value: 'history_of_science', label: 'History of Science and Technology' },
    ],
  },
  {
    category: 'Philosophy',
    label: 'Philosophy',
    subOptions: [
      { value: 'ethics_morality', label: 'Ethics and Morality' },
      { value: 'existentialism', label: 'Existentialism' },
      { value: 'eastern_philosophy', label: 'Eastern Philosophy' },
      { value: 'western_philosophy', label: 'Western Philosophy' },
      { value: 'logic_critical_thinking', label: 'Logic and Critical Thinking' },
      { value: 'philosophy_of_mind', label: 'Philosophy of Mind' },
    ],
  },
  {
    category: 'Psychology',
    label: 'Psychology',
    subOptions: [
      { value: 'cognitive_psychology', label: 'Cognitive Psychology' },
      { value: 'behavioral_psychology', label: 'Behavioral Psychology' },
      { value: 'developmental_psychology', label: 'Developmental Psychology' },
      { value: 'social_psychology', label: 'Social Psychology' },
      { value: 'positive_psychology', label: 'Positive Psychology' },
      { value: 'therapy_techniques', label: 'Therapy Techniques' },
    ],
  },
  {
    category: 'Brain_Science',
    label: 'Brain Science',
    subOptions: [
      { value: 'neuroscience_basics', label: 'Neuroscience Basics' },
      { value: 'neuroplasticity', label: 'Neuroplasticity' },
      { value: 'memory_techniques', label: 'Memory Techniques' },
      { value: 'cognitive_enhancement', label: 'Cognitive Enhancement Strategies' },
      { value: 'mental_health_disorders', label: 'Mental Health Disorders and Management' },
      { value: 'psychology_of_learning', label: 'The Psychology of Learning' },
    ],
  },
  {
    category: 'Hobbies',
    label: 'Hobbies',

    subOptions: [
      { value: 'gardening_basics', label: 'Gardening Basics' },
      { value: 'writing', label: 'Writing (Fiction, Non-Fiction, Poetry)' },
      { value: 'blogging_vlogging', label: 'Blogging and Vlogging' },
    ],
  },
];
const questions = [
  // ... other questions ...

  // Updated hobbyInterests question with nested options
  {
    id: 'userType',
    question: 'Are you currently studying?',
    options: [
      { value: 'school_student', label: 'School Student' },
      { value: 'university_student', label: 'University Student' },
      { value: 'not_student', label: 'Not a student' },
    ],
  },
  {
    id: 'schoolCurriculum',
    question: 'Which curriculum are you studying?',
    options: [
      { value: 'british', label: 'British Curriculum (GCSE/A-Levels)' },
      { value: 'american', label: 'American Curriculum' },
    ],
    dependsOn: { id: 'userType', value: 'school_student' },
  },
  {
    id: 'schoolYear',
    question: 'Which year are you in?',
    optionsByCurriculum: {
  
      british: [
        { value: 'year10_gcse1', label: 'Year 10 (GCSE Year 1)' },
        { value: 'year11_gcse2', label: 'Year 11 (GCSE Year 2)' },
        { value: 'year12_as', label: 'Year 12 (AS-Level)' },
        { value: 'year13_a', label: 'Year 13 (A-Level)' },
      ],
      american: [
        { value: 'grade9', label: 'Grade 9 (Freshman)' },
        { value: 'grade10', label: 'Grade 10 (Sophomore)' },
        { value: 'grade11', label: 'Grade 11 (Junior)' },
        { value: 'grade12', label: 'Grade 12 (Senior)' },
      ],
      other: [
        { value: 'year7', label: 'Year 7' },
        { value: 'year8', label: 'Year 8' },
        { value: 'year9', label: 'Year 9' },
        { value: 'year10', label: 'Year 10' },
        { value: 'year11', label: 'Year 11' },
        { value: 'year12', label: 'Year 12' },
      ],
      swedish: [
        { value: 'year7', label: 'Year 7' },
        { value: 'year8', label: 'Year 8' },
        { value: 'year9', label: 'Year 9' },
        { value: 'year10', label: 'Year 10' },
        { value: 'year11', label: 'Year 11' },
        { value: 'year12', label: 'Year 12' },
      ],
    },
    dependsOn: { id: 'schoolCurriculum' },
  },
  {
    id: 'schoolSubjects',
    question: 'Select the subjects you are currently studying:',
    optionsByCurriculum: {
      swedish: [
          { value: 'mathematics', label: 'Matematik' },
          { value: 'physics', label: 'Fysik' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'english', label: 'Engelska' },
          { value: 'history', label: 'Historia' },
          { value: 'geography', label: 'Geografi' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'cs', label: 'Datorvetenskap' },
          { value: 'art_design', label: 'Konst och Design' },
          { value: 'business_studies', label: 'Företagsstudier' },
          { value: 'psychology', label: 'Psykologi' },
          { value: 'sociology', label: 'Sociologi' },
          { value: 'law', label: 'Juridik' },
          { value: 'french', label: 'Franska' },
          { value: 'spanish', label: 'Spanska' },
          { value: 'german', label: 'Tyska' },
          { value: 'music', label: 'Musik' },
          { value: 'pe', label: 'Gymnastik' },
      ],
      british: [
        { value: 'mathematics', label: 'Mathematics' },
        { value: 'further_math', label: 'Further Mathematics' },
        { value: 'physics', label: 'Physics' },
        { value: 'chemistry', label: 'Chemistry' },
        { value: 'biology', label: 'Biology' },
        { value: 'english_lit', label: 'English Literature' },
        { value: 'english_lang', label: 'English Language' },
        { value: 'history', label: 'History' },
        { value: 'geography', label: 'Geography' },
        { value: 'economics', label: 'Economics' },
        { value: 'cs', label: 'Computer Science' },
        { value: 'art_design', label: 'Art and Design' },
        { value: 'business_studies', label: 'Business Studies' },
        { value: 'psychology', label: 'Psychology' },
        { value: 'sociology', label: 'Sociology' },
        { value: 'law', label: 'Law' },
        { value: 'french', label: 'French' },
        { value: 'spanish', label: 'Spanish' },
        { value: 'german', label: 'German' },
      ],
      american: [
        { value: 'algebra1', label: 'Algebra I' },
        { value: 'geometry', label: 'Geometry' },
        { value: 'algebra2', label: 'Algebra II' },
        { value: 'pre_calculus', label: 'Pre-Calculus' },
        { value: 'calculus', label: 'Calculus' },
        { value: 'ap_calc_ab', label: 'AP Calculus AB' },
        { value: 'ap_calc_bc', label: 'AP Calculus BC' },
        { value: 'physics', label: 'Physics' },
        { value: 'ap_physics1', label: 'AP Physics 1' },
        { value: 'ap_physics2', label: 'AP Physics 2' },
        { value: 'chemistry', label: 'Chemistry' },
        { value: 'ap_chemistry', label: 'AP Chemistry' },
        { value: 'biology', label: 'Biology' },
        { value: 'ap_biology', label: 'AP Biology' },
        { value: 'english_lit', label: 'English Literature' },
        { value: 'ap_english_lit', label: 'AP English Literature' },
        { value: 'us_history', label: 'US History' },
        { value: 'ap_us_history', label: 'AP US History' },
        { value: 'world_history', label: 'World History' },
        { value: 'ap_world_history', label: 'AP World History' },
        { value: 'government', label: 'Government' },
        { value: 'economics', label: 'Economics' },
        { value: 'spanish', label: 'Spanish' },
        { value: 'ap_spanish', label: 'AP Spanish Language' },
      ],
    },
    multiple: true,
    dependsOn: { id: 'schoolCurriculum' },
  },
  {
    id: 'universityProgram',
    question: 'Which program are you enrolled in?',
    options: [
      { value: 'engineering', label: 'Engineering' },
      { value: 'arts', label: 'Arts' },
      { value: 'science', label: 'Science' },
      { value: 'business', label: 'Business' },
      { value: 'law', label: 'Law' },
      { value: 'medicine', label: 'Medicine' },
      { value: 'computer_science', label: 'Computer Science' },
      { value: 'psychology', label: 'Psychology' },
      { value: 'education', label: 'Education' },
      { value: 'architecture', label: 'Architecture' },
      // { value: 'other', label: 'Other' },
    ],
    dependsOn: { id: 'userType', value: 'university_student' },
  },
  {
    id: 'universityCourses',
    question: 'Select the courses you are currently studying:',
    optionsByProgram: {
      engineering: [
        { value: 'calculus1', label: 'Calculus I' },
        { value: 'calculus2', label: 'Calculus II' },
        { value: 'linear_algebra', label: 'Linear Algebra' },
        { value: 'physics1', label: 'Physics I' },
        { value: 'physics2', label: 'Physics II' },
        { value: 'chem_for_eng', label: 'Chemistry for Engineers' },
        { value: 'statics', label: 'Statics' },
        { value: 'dynamics', label: 'Dynamics' },
        { value: 'thermodynamics', label: 'Thermodynamics' },
        { value: 'materials_science', label: 'Materials Science' },
        { value: 'fluid_mechanics', label: 'Fluid Mechanics' },
        { value: 'electrical_circuits', label: 'Electrical Circuits' },
        { value: 'eng_design', label: 'Engineering Design' },
        { value: 'mech_materials', label: 'Mechanics of Materials' },
      ],
      arts: [
        { value: 'intro_literature', label: 'Introduction to Literature' },
        { value: 'art_history', label: 'Art History' },
        { value: 'philosophy_101', label: 'Philosophy 101' },
        { value: 'psychology_101', label: 'Psychology 101' },
        { value: 'sociology_101', label: 'Sociology 101' },
        { value: 'creative_writing', label: 'Creative Writing' },
        { value: 'performing_arts', label: 'Performing Arts' },
        { value: 'history_west_civ', label: 'History of Western Civilization' },
        { value: 'cultural_studies', label: 'Cultural Studies' },
      ],
      science: [
        { value: 'bio1', label: 'Biology I' },
        { value: 'bio2', label: 'Biology II' },
        { value: 'chem1', label: 'Chemistry I' },
        { value: 'chem2', label: 'Chemistry II' },
        { value: 'physics1', label: 'Physics I' },
        { value: 'physics2', label: 'Physics II' },
        { value: 'earth_science', label: 'Earth Science' },
        { value: 'env_science', label: 'Environmental Science' },
        { value: 'statistics', label: 'Statistics' },
        { value: 'genetics', label: 'Genetics' },
        { value: 'organic_chem', label: 'Organic Chemistry' },
        { value: 'ecology', label: 'Ecology' },
      ],
      business: [
        { value: 'intro_business', label: 'Introduction to Business' },
        { value: 'accounting1', label: 'Accounting I' },
        { value: 'accounting2', label: 'Accounting II' },
        { value: 'microeconomics', label: 'Microeconomics' },
        { value: 'macroeconomics', label: 'Macroeconomics' },
        { value: 'marketing_principles', label: 'Marketing Principles' },
        { value: 'business_law', label: 'Business Law' },
        { value: 'org_behavior', label: 'Organizational Behavior' },
        { value: 'finance', label: 'Finance' },
        { value: 'operations_mgmt', label: 'Operations Management' },
        { value: 'intl_business', label: 'International Business' },
      ],
      law: [
        { value: 'intro_law', label: 'Introduction to Law' },
        { value: 'contract_law', label: 'Contract Law' },
        { value: 'criminal_law', label: 'Criminal Law' },
        { value: 'property_law', label: 'Property Law' },
        { value: 'tort_law', label: 'Tort Law' },
        { value: 'intl_law', label: 'International Law' },
        { value: 'constitutional_law', label: 'Constitutional Law' },
        { value: 'legal_writing', label: 'Legal Writing' },
        { value: 'admin_law', label: 'Administrative Law' },
      ],
      medicine: [
        { value: 'human_anatomy', label: 'Human Anatomy' },
        { value: 'human_physiology', label: 'Human Physiology' },
        { value: 'biochemistry', label: 'Biochemistry' },
        { value: 'medical_ethics', label: 'Medical Ethics' },
        { value: 'microbiology', label: 'Microbiology' },
        { value: 'pathology', label: 'Pathology' },
        { value: 'pharmacology', label: 'Pharmacology' },
        { value: 'neuroscience', label: 'Neuroscience' },
        { value: 'clinical_skills', label: 'Clinical Skills' },
      ],
      computer_science: [
        { value: 'intro_programming', label: 'Introduction to Programming' },
        { value: 'oop', label: 'Object-Oriented Programming' },
        { value: 'data_structures', label: 'Data Structures' },
        { value: 'algorithms', label: 'Algorithms' },
        { value: 'comp_systems', label: 'Computer Systems' },
        { value: 'operating_systems', label: 'Operating Systems' },
        { value: 'database_systems', label: 'Database Systems' },
        { value: 'software_eng', label: 'Software Engineering' },
        { value: 'ai', label: 'Artificial Intelligence' },
        { value: 'machine_learning', label: 'Machine Learning' },
        { value: 'web_dev', label: 'Web Development' },
        { value: 'networks_security', label: 'Networks and Security' },
      ],
      psychology: [
        { value: 'intro_psychology', label: 'Introduction to Psychology' },
        { value: 'dev_psychology', label: 'Developmental Psychology' },
        { value: 'cognitive_psychology', label: 'Cognitive Psychology' },
        { value: 'abnormal_psychology', label: 'Abnormal Psychology' },
        { value: 'social_psychology', label: 'Social Psychology' },
        { value: 'neuroscience', label: 'Neuroscience' },
        { value: 'research_methods', label: 'Research Methods' },
        { value: 'personality_psych', label: 'Personality Psychology' },
        { value: 'psych_assessment', label: 'Psychological Assessment' },
      ],
      education: [
        { value: 'foundations_edu', label: 'Foundations of Education' },
        { value: 'edu_psychology', label: 'Educational Psychology' },
        { value: 'curriculum_dev', label: 'Curriculum Development' },
        { value: 'class_mgmt', label: 'Classroom Management' },
        { value: 'assessment_eval', label: 'Assessment and Evaluation' },
        { value: 'special_education', label: 'Special Education' },
        { value: 'teaching_practicum', label: 'Teaching Practicum' },
        { value: 'edu_technology', label: 'Educational Technology' },
      ],
      architecture: [
        { value: 'design_studio1', label: 'Design Studio I' },
        { value: 'design_studio2', label: 'Design Studio II' },
        { value: 'arch_history', label: 'Architectural History' },
        { value: 'building_tech', label: 'Building Technology' },
        { value: 'structures', label: 'Structures' },
        { value: 'env_systems', label: 'Environmental Systems' },
        { value: 'digital_design', label: 'Digital Design' },
        { value: 'urban_planning', label: 'Urban Planning' },
        { value: 'sustainable_design', label: 'Sustainable Design' },
      ],
    },
    multiple: true,
    dependsOn: { id: 'universityProgram' },
  },
  {
    id: 'hobbyInterests',
    question: 'Select your personal areas of interest:',
    options: hobbyInterestsOptions,
    dependsOn: { id: 'userType', value: 'any' },
    multiple: true,
  },
];

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    position: 'relative',
    minHeight: '400px', // Adjust as needed
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    overflowY: 'auto',
  },
  fixedButtonContainer: {
    position: 'sticky',
    bottom: '-20px',
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: '0px',
  },
}));

const TopicSelectorModal = ({ open, onClose, onTopicsChanged }) => {
  const classes = useStyles();
  const t = useTranslations('TopicSelection')

  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState({});
  const [isAIProcessing, setIsAIProcessing] = useState(false);
  const [generatedTopics, setGeneratedTopics] = useState([]);
  const [error, setError] = useState(null);
  const [userCountry, setUserCountry] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState([]);

  const optionTopicCounts = useMemo(() => buildOptionTopicCounts(), []);

  const locale = useLocale();

  // Detect user's country
  useEffect(() => {
    if(locale !== userCountry) {
      setUserCountry(locale)
    }
 
  }, [locale]);

  const modifiedQuestions = useMemo(
    () => modifyQuestionsForCountry(questions, userCountry),
    [userCountry]
  );

  const userId = useSelector((state) => state.websocket.userProfile?.id); // Retrieve userId via Redux

  function modifyQuestionsForCountry(originalQuestions, locale) {
    const translations = translationsMap[locale];
  
    return originalQuestions.map((question) => {
      if (translations && translations[question.id]) {
        const translation = translations[question.id];
        const translatedQuestion = {
          ...question,
          question: translation.question || question.question,
          multiple:
            translation.multiple !== undefined
              ? translation.multiple
              : question.multiple,
        };
  
        // Special handling for 'schoolCurriculum' when country is 'sv'
        if (question.id === 'schoolCurriculum' && locale === 'sv') {
          translatedQuestion.options = [
            { label: 'Svenska nationella läroplanen', value: 'swedish' },
          ];
        } else if (question.options) {
          if (Array.isArray(translation.options)) {
            // For nested options (e.g., hobbyInterests with categories and subOptions)
            translatedQuestion.options = translation.options;
          } else if (translation.options) {
            // For flat options
            translatedQuestion.options = question.options.map((option) => ({
              ...option,
              label: translation.options[option.value] || option.label,
            }));
          }
        }
  
        // Translate optionsByCurriculum
        if (question.optionsByCurriculum) {
          translatedQuestion.optionsByCurriculum = {};
          for (const [curriculumKey, optionsArray] of Object.entries(
            question.optionsByCurriculum
          )) {
            const translatedOptions =
              translation.optionsByCurriculum?.[curriculumKey] || optionsArray;
            translatedQuestion.optionsByCurriculum[curriculumKey] =
              translatedOptions;
          }
        }
  
        // Translate optionsByProgram
        if (question.optionsByProgram) {
          translatedQuestion.optionsByProgram = {};
          for (const [programKey, optionsArray] of Object.entries(
            question.optionsByProgram
          )) {
            const translatedOptions =
              translation.optionsByProgram?.[programKey] || optionsArray;
            translatedQuestion.optionsByProgram[programKey] = translatedOptions;
          }
        }
  
        return translatedQuestion;
      }
  
      // If no translation available, return the original question
      return question;
    });
  }

  // Modify the `questions` array to add `dependsOn` to 'hobbyInterests'
  const adjustedQuestions = useMemo(() => {
    return modifiedQuestions.map((q) => {
      if (q.id === 'hobbyInterests') {
        return {
          ...q,
          dependsOn: { id: 'userType', value: 'any' },
        };
      }
      return q;
    });
  }, [modifiedQuestions]);

  const getFirstPageQuestions = () => {
    const initialQuestions = adjustedQuestions.filter(
      (q) => !q.dependsOn && q.id !== 'hobbyInterests'
    );
    const displayedQuestions = [...initialQuestions];

    const addDependentRadioQuestions = (parentQuestionIds) => {
      let addedQuestion;
      do {
        addedQuestion = false;
        adjustedQuestions.forEach((q) => {
          if (
            q.dependsOn &&
            parentQuestionIds.includes(q.dependsOn.id) &&
            !displayedQuestions.find((dq) => dq.id === q.id) &&
            q.options &&
            !q.multiple &&
            (q.dependsOn.value === 'any' ||
              responses[q.dependsOn.id] === q.dependsOn.value ||
              !q.dependsOn.value)
          ) {
            displayedQuestions.push(q);
            addedQuestion = true;
          }
        });
        parentQuestionIds = displayedQuestions.map((dq) => dq.id);
      } while (addedQuestion);
    };

    const parentQuestionIds = initialQuestions.map((q) => q.id);
    addDependentRadioQuestions(parentQuestionIds);

    return displayedQuestions;
  };

  const firstPageQuestions = useMemo(
    () => getFirstPageQuestions(),
    [adjustedQuestions, responses]
  );

  const getSubsequentSteps = () => {
    const steps = [];

    const displayedQuestionIds = new Set(firstPageQuestions.map((q) => q.id));

    const collectSteps = (parentIds) => {
      let addedStep = false;
      adjustedQuestions.forEach((q) => {
        if (
          q.dependsOn &&
          !displayedQuestionIds.has(q.id) &&
          parentIds.includes(q.dependsOn.id) &&
          (q.dependsOn.value === 'any' ||
            responses[q.dependsOn.id] === q.dependsOn.value ||
            !q.dependsOn.value)
        ) {
          steps.push([q]);
          displayedQuestionIds.add(q.id);
          addedStep = true;
        }
      });
      if (addedStep) {
        const newParentIds = Array.from(displayedQuestionIds);
        collectSteps(newParentIds);
      }
    };

    const initialQuestionIds = firstPageQuestions.map((q) => q.id);
    collectSteps(initialQuestionIds);

    return steps;
  };

  const subsequentSteps = useMemo(
    () => getSubsequentSteps(),
    [adjustedQuestions, firstPageQuestions, responses]
  );

  const hobbyInterestsQuestion = useMemo(
    () => adjustedQuestions.find((q) => q.id === 'hobbyInterests'),
    [adjustedQuestions]
  );

  const steps = useMemo(
    () => [
      firstPageQuestions,
      ...subsequentSteps.filter((step) => step[0].id !== 'hobbyInterests'),
      [hobbyInterestsQuestion],
    ],
    [firstPageQuestions, subsequentSteps, hobbyInterestsQuestion]
  );

  const renderCurrentStep = () => {
    const currentQuestions = steps[currentStep];

    if (!currentQuestions || currentQuestions.length === 0) {
      handleAIProcessing();
      return null;
    }

    return (
      <div>
        {currentQuestions.map((question, index) => {
          if (!question) return null;

          return (
            <div key={question.id} style={{ marginBottom: 24 }}>
              {/* Question heading stays with its input */}
              {currentQuestions.length > 1 ? (
                <Typography variant="h6" gutterBottom>
                  {question.question}
                </Typography>
              ) : (
                ''
              )}
              {renderQuestionInput(question)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderQuestionInput = (question) => {

    const handleChange = (event) => {
      const value = event.target.value;
      setResponses((prevResponses) => {
        const updatedResponses = { ...prevResponses, [question.id]: value };

        // Reset dependent answers if parent selection changes
        resetDependentResponses(question.id, updatedResponses);

        return updatedResponses;
      });
    };

    const handleCheckboxChange = (event) => {
      const { name, checked } = event.target;
      let updatedValues = responses[question.id] || [];
      if (checked) {
        updatedValues = [...updatedValues, name];
      } else {
        updatedValues = updatedValues.filter((item) => item !== name);
      }
      setResponses((prevResponses) => ({
        ...prevResponses,
        [question.id]: updatedValues,
      }));
    };

    let options = question.options;

    if (question.optionsByCurriculum) {
      const curriculum = responses['schoolCurriculum'];
      options = question.optionsByCurriculum[curriculum] || [];
    }

    if (question.optionsByProgram) {
      const program = responses['universityProgram'];
      options = question.optionsByProgram[program] || [];
    }

    const currentTopics = getSelectedTopics(responses);

    if (
      question.id === 'hobbyInterests' &&
      options &&
      options[0] &&
      options[0].subOptions
    ) {
      const handleCategoryCheckboxClick = (category) => {
        const subOptionValues = category.subOptions.map(
          (subOption) => `${category.category}.${subOption.value}`
        );
        const selectedValues = responses[question.id] || [];
        const subOptionsSelected = subOptionValues.filter((value) =>
          selectedValues.includes(value)
        );
      
        const isAllSelected = subOptionsSelected.length === subOptionValues.length;
      
        if (isAllSelected) {
          // All sub-options are selected, so unselect all sub-options and collapse accordion
          const updatedValues = selectedValues.filter(
            (value) => !subOptionValues.includes(value)
          );
          setResponses((prevResponses) => ({
            ...prevResponses,
            [question.id]: updatedValues,
          }));
          setExpandedCategories((prev) =>
            prev.filter((cat) => cat !== category.category)
          );
        } else {
          // Some or none sub-options selected, so select all sub-options and expand accordion
          const updatedValues = [
            ...selectedValues.filter((value) => !subOptionValues.includes(value)),
            ...subOptionValues,
          ];
          setResponses((prevResponses) => ({
            ...prevResponses,
            [question.id]: updatedValues,
          }));
          setExpandedCategories((prev) => [...prev, category.category]);
        }
      };
    
      const handleSubOptionCheckboxChange = (
        category,
        subOptionValue,
        isChecked
      ) => {
        let updatedValues = responses[question.id] || [];
        const fullValue = `${category.category}.${subOptionValue}`;
      
        if (isChecked) {
          if (!updatedValues.includes(fullValue)) {
            updatedValues = [...updatedValues, fullValue];
          }
        } else {
          updatedValues = updatedValues.filter((item) => item !== fullValue);
        }
      
        setResponses((prevResponses) => ({
          ...prevResponses,
          [question.id]: updatedValues,
        }));
      
        // Determine if all sub-options are selected or none
        const subOptionValues = category.subOptions.map(
          (subOption) => `${category.category}.${subOption.value}`
        );
        const subOptionsSelected = subOptionValues.filter((value) =>
          updatedValues.includes(value)
        );
        const isAllSelected = subOptionsSelected.length === subOptionValues.length;
        const isNoneSelected = subOptionsSelected.length === 0;
      
        if (isNoneSelected) {
          // All sub-options are unselected, collapse the accordion
          setExpandedCategories((prev) =>
            prev.filter((cat) => cat !== category.category)
          );
        } else if (isAllSelected) {
          // All sub-options selected, keep accordion expanded
          if (!expandedCategories.includes(category.category)) {
            setExpandedCategories((prev) => [...prev, category.category]);
          }
        } else {
          // Some sub-options selected, keep accordion expanded
          if (!expandedCategories.includes(category.category)) {
            setExpandedCategories((prev) => [...prev, category.category]);
          }
        }
      };
    
      return (
        <div>
          {options.map((category) => {
            const subOptionValues = category.subOptions.map(
              (subOption) => `${category.category}.${subOption.value}`
            );
            const selectedValues = responses[question.id] || [];
            const subOptionsSelected = subOptionValues.filter((value) =>
              selectedValues.includes(value)
            );
    
            const isAllSelected =
              subOptionsSelected.length === subOptionValues.length;
            const isNoneSelected = subOptionsSelected.length === 0;
            const isIndeterminate =
              subOptionsSelected.length > 0 && !isAllSelected;
    
            return (
              <Accordion
                key={category.category}
                expanded={expandedCategories.includes(category.category)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={() => {
                    // Toggle accordion expansion when clicking the summary
                    setExpandedCategories((prev) =>
                      prev.includes(category.category)
                        ? prev.filter((cat) => cat !== category.category)
                        : [...prev, category.category]
                    );
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAllSelected}
                        indeterminate={isIndeterminate}
                        onChange={() => handleCategoryCheckboxClick(category)}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                      />
                    }
                    label={
                      category.translatedCategory ||
                      category.label ||
                      category.category
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <FormGroup>
                    {category.subOptions.map((subOption) => {
                      const fullValue = `${category.category}.${subOption.value}`;
                      const checked = selectedValues.includes(fullValue);
    
                      // Calculate count of additional topics (existing logic)
                      const hypotheticalResponses = {
                        ...responses,
                        [question.id]: [...selectedValues, fullValue],
                      };
                      const topicsWithOption = getSelectedTopics(
                        hypotheticalResponses
                      );
                      const additionalTopics = topicsWithOption.filter(
                        (topic) => !currentTopics.includes(topic)
                      );
                      const count = additionalTopics.length;
    
                      return (
                        <FormControlLabel
                          key={fullValue}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(e) =>
                                handleSubOptionCheckboxChange(
                                   category,
                                  subOption.value,
                                  e.target.checked
                                )
                              }
                              name={fullValue}
                            />
                          }
                          label={`${subOption.label} (${count})`}
                        />
                      );
                    })}
                  </FormGroup>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      );
    } else if (question.inputType === 'text') {
      return (
        <TextField
          variant="outlined"
          fullWidth
          margin="normal"
          value={responses[question.id] || ''}
          onChange={handleChange}
          placeholder="Please specify"
        />
      );
    } else if (options && question.multiple) {
      console.log('options', options)
      if(!options || !options.length) return
      return (
        <FormGroup>
          {options?.map((option) => {
            // Create a hypothetical responses object including this option
            const hypotheticalResponses = {
              ...responses,
              [question.id]: [...(responses[question.id] || []), option.value],
            };
            const topicsWithOption = getSelectedTopics(hypotheticalResponses);
            const additionalTopics = topicsWithOption.filter(
              (topic) => !currentTopics.includes(topic)
            );
            const selectedTopicObjects = getSelectedTopicObjects(currentTopics)
            const topicsAddedFromOption = selectedTopicObjects?.filter(topic => 
              topic.when_to_add && topic.when_to_add.some(condition => condition.includes(option.value))
          );

          const count = additionalTopics.length + (topicsAddedFromOption?.length || 0);

            if(count === 0) {
              return null
            }

            return (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={(responses[question.id] || []).includes(option.value)}
                    onChange={handleCheckboxChange}
                    name={option.value}
                  />
                }
                label={`${option.label} (${count})`}
              />
            );
          })}
        </FormGroup>
      );
    } else if (options) {
      console.log('options in fieldset', options)
      if(!options || !options.length) return
      return (
        <FormControl component="fieldset">
          <RadioGroup value={responses[question.id] || ''} onChange={handleChange}>
            {options?.map((option) => {
              // Create a hypothetical responses object including this option
              const hypotheticalResponses = { ...responses, [question.id]: option.value };
              const topicsWithOption = getSelectedTopics(hypotheticalResponses);
              const additionalTopics = topicsWithOption.filter(
                (topic) => !currentTopics.includes(topic)
              );
              const count = additionalTopics.length;

              return (
                <FormControlLabel
                  key={option.value}
                  control={<Radio value={option.value} />}
                  label={`${option.label} (${count})`}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  const resetDependentResponses = (changedQuestionId, updatedResponses) => {
    adjustedQuestions.forEach((q) => {
      if (q.dependsOn && q.dependsOn.id === changedQuestionId) {
        if (updatedResponses[q.id]) {
          delete updatedResponses[q.id];
        }
        resetDependentResponses(q.id, updatedResponses);
      }
    });
  };

  useEffect(() => {
    // const currentQuestions = steps[currentStep] || [];
    currentQuestions.forEach((question) => {
      // Skip if the response is already set
      if (responses[question.id] !== undefined) return;

      // Determine the options for the current question
      let options = question.options;

      if (question.optionsByCurriculum) {
        const curriculum = responses['schoolCurriculum'];
        options = question.optionsByCurriculum[curriculum] || [];
      }

      if (question.optionsByProgram) {
        const program = responses['universityProgram'];
        options = question.optionsByProgram[program] || [];
      }

      // If there's only one option, set it as the response
      if (options && options.length === 1) {
        setResponses((prevResponses) => ({
          ...prevResponses,
          [question.id]: options[0].value,
        }));
      }
    });
  }, [currentStep, responses, steps]);

  const handleNext = () => {
    if (currentStep + 1 < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      handleAIProcessing();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAIProcessing = async () => {
    setIsAIProcessing(true);
    setError(null);
    try {
      const selectedTopics = getSelectedTopics(responses); // Call the new function here

      // Update the state with generated topics
      setGeneratedTopics(selectedTopics);

      await setSelectedTopics(userId, selectedTopics); // Call your function to save selected topics

      setIsAIProcessing(false);
      setCurrentStep(currentStep + 1); // Move to next step to display topics
    } catch (err) {
      setError(
        err.message ||
          t("ERROR_AI")
      );
      setIsAIProcessing(false);
    }
  };

  const renderGeneratedTopics = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden', // Prevents the whole content from scrolling
        }}
      >
        {/* Header at the top */}
        <div>
          <Typography variant="h6" gutterBottom>
            {t('SELECTED_TOPICS')}
          </Typography>
        </div>

        {/* Scrollable content in the middle */}
        <div
          style={{
            flex: 1,
            overflowY: 'auto', // Enables vertical scrolling for this div
            marginTop: 16,
          }}
        >
          {generatedTopics.map((topic) => (
            <Typography key={topic} variant="body1">
              -{' '}
              {userCountry === 'sv'
                ? getSelectedTopicObjects([topic])?.[0]?.name_sv
                : getSelectedTopicObjects([topic])?.[0]?.name_en}
            </Typography>
          ))}
        </div>

        {/* Button at the bottom */}
        <div style={{ marginTop: 24, textAlign: 'right' }}>
          <PrimaryButton
            variant="contained"
            color="primary"
            onClick={handleConfirmTopics}
          >
            {t('CONFIRM_CONTINUE')}
          </PrimaryButton>
        </div>
      </div>
    );
  };

  const handleConfirmTopics = async () => {
    try {
      onTopicsChanged(generatedTopics);
      onClose();
    } catch (error) {
      setError(
       t("FAILED_SAVE")
      );
    }
  };

  const currentQuestions = steps[currentStep] || [];
  const areAllResponsesFilled = currentQuestions.every((q) => {
    const response = responses[q.id];
    return response && (Array.isArray(response) ? response.length > 0 : true);
  });

  if (userCountry === null) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent className={classes.dialogContent}>
          <div style={{ textAlign: 'center', padding: 20 }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: 16 }}>
             {t("LOADING")}

            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent className={classes.dialogContent}>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        {isAIProcessing ? (
          <div style={{ textAlign: 'center', padding: 20 }}>
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: 16 }}>
              {userCountry === 'sv'
                ? 'Analyserar dina svar...'
                : 'Analyzing your responses...'}
            </Typography>
          </div>
        ) : currentStep < steps.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflow: 'hidden',
            }}
          >
            {/* Heading at the top */}
            <div>
              {steps[currentStep]?.length === 1 ? (
                <Typography variant="h6" gutterBottom>
                  {steps[currentStep]?.[0]?.question}
                </Typography>
              ) : (
                ''
              )}
            </div>

            {/* Scrollable content in the middle */}
            <div
              style={{
                flex: 1,
                overflowY: 'auto',
                marginTop: 16,
              }}
            >
              {renderCurrentStep()}
            </div>

            {/* Fixed Next and Back buttons at the bottom of the modal */}
            <div className={classes.fixedButtonContainer}>
              {currentStep > 0 ? (
                <PrimaryTransparentButton
                  variant="outlined"
                  onClick={
                    currentStep === steps.length - 1 ? handleNext : handleBack
                  }
                >
                  {currentStep === steps.length - 1
                    ? t('SKIP')
                    : t('BACK')}
                </PrimaryTransparentButton>
              ) : (
                <div /> // Placeholder to align Next button to the right
              )}
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={!areAllResponsesFilled}
              >
                {currentStep + 1 < steps.length || !isAIProcessing
                  ? t("NEXT")
                  : t("SUBMIT")}
              </PrimaryButton>
            </div>
          </div>
        ) : (
          renderGeneratedTopics()
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TopicSelectorModal;