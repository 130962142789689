import ForgotPasswordContainer from '@studygenius/shared/signupflow/ForgotPasswordContainer';
import { getUser } from '@studygenius/shared/Requests'
import { Typography, Popover, TextField, Box } from '@mui/material';
import { MUIStyles } from '@studygenius/shared/Variables';
import { PrimaryButton } from '../../Components';
import {PopoverWrapper} from '../../AccountPopovers';


const ForgotPasswordScreen = ({email, setEmail, sendResetPasswordEmail, setPopoverContent} ) => {

    return(<Popover
        anchorReference="anchorPosition"
        anchorPosition={{ top: window.innerHeight / 2, left: window.innerWidth / 2}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        open
        sx={{borderRadius: '30px'}}
        PaperProps={{
          sx: {
            width: '550px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            padding: '30px'

          }
        }}
    
    >
         <PopoverWrapper setPopoverContent={setPopoverContent} />
         <Box sx={{paddingLeft: '30px', paddingRight: '30px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>

        <img src={'ForgotPasswordImage.png'} style={{width: '225px', height: '190px'}} />
        <Typography sx={{...MUIStyles.largeParagraph, marginTop: 2}}>
            Forgot your password? Enter the email address associated with your account.
        </Typography>
        <TextField label="Email" variant="standard" placeholder="example@gmail.com" sx={{marginTop: 1, width: '100%'}} onChange={(event) => setEmail(event.target.value)} value={email}></TextField>
        <PrimaryButton onClick={sendResetPasswordEmail} sx={{marginTop: 3}}>Send email</PrimaryButton>
        </Box>

    </Popover>
    )
}

export default function ForgotPassword({close, setPopoverContent}) {
  return <ForgotPasswordContainer UIComponent={ForgotPasswordScreen} close={close} setPopoverContent={setPopoverContent} />
}