'use client'

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, Button, Modal, Box, Typography } from '@mui/material';
import colors from '@studygenius/shared/Colors';
import { getSelectedTopicObjects } from './getSelectedTopics';
import { MUIStyles } from '@studygenius/shared/Variables';
import { useTranslations } from 'next-intl';
import { OutlinedPrimaryOpacityButton } from './Components';

const QuizzesCompletedStats = () => {
  const quizzesCompleted = useSelector((state) => state.websocket.quizzesCompleted);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openModal, setOpenModal] = useState(false);
  const t = useTranslations('HomePage')

  console.log('quizzesCompleted', quizzesCompleted)

  const renderStatsContent = () => (
    <Box sx={{paddingTop: {xs: 0, md: '30px'}}}>
      <h2 style={{...MUIStyles.latoBoldMediumParagraph}}>{t('COMPLETED_QUIZZES')}</h2>
      {Object.entries(quizzesCompleted).map(([topic, count]) => (
        <div key={topic}>
          <Typography sx={{...MUIStyles.latoBoldParagraph, display: 'inline-block', opacity: 0.7}}>{getSelectedTopicObjects([topic])?.[0]?.name_en || topic}:</Typography>  <Typography sx={{...MUIStyles.paragraph, display: 'inline-block', opacity: 0.7}}>{count} quizzes completed</Typography>
        </div>
      ))}
    </Box>
  );

  if (isMobile) {
    return (
      <>
        <OutlinedPrimaryOpacityButton
          onClick={() => setOpenModal(true)}
          sx={{ marginBottom: '16px', marginTop: '16px' }} // Add margin-bottom to create space
        >
          See Stats
        </OutlinedPrimaryOpacityButton>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            {renderStatsContent()}
          </Box>
        </Modal>
      </>
    );
  } else {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '280px',
          maxWidth: '360px',
          padding: '24px',
          height: '100vh',
          overflowY: 'auto',
          position: 'sticky',
          top: 0,
        }}
      >
        {renderStatsContent()}
      </div>
    );
  }
};

export default QuizzesCompletedStats;