
"use client"
import { Typography, Box } from '@mui/material';
import Link from 'next/link'
import { PrimaryButton } from '../Components';
import BaseLayout from '../BaseLayout';
import { MUIStyles } from '@studygenius/shared/Variables';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'

const linkStyle = {
    textDecoration: 'none', // Remove underline
    color: 'inherit',       // Inherit text color from the parent
    display: 'inherit'      // Use the display value from the `Typography` component
  };
function NotFound() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <Box sx={{height: {xs: 'calc(100vh - 200px)', md: 'calc(100vh - 100px)'}, display: 'flex', alignItems: 'center', justifyContent: {xs: 'space-between', md: 'center'}, marginY: {xs: 4, md: 0}, paddingX: 1, flexDirection: 'column'}}>


    <Typography sx={{...MUIStyles.largeHeader}}>Ooops! We can't find the page you're looking for</Typography>
      <img src={'/404-not-found.png'} style={{...isMobile ? {width: '80%', aspectRatio: '3:2'} : {maxWidth: '500px', maxHeight: '360px', marginTop: '50px'}}} />
      <Box>

      <Link href="/" passHref>
          <PrimaryButton variant="contained" color="primary" sx={{marginTop: 10}}>
            {/* Typography component for styling the text */}
            <Typography component="a" style={{...linkStyle, ...MUIStyles.buttonText}}>
              Take me back to studygenius
            </Typography>
          </PrimaryButton>
        </Link>
      
      </Box>
  </Box>
}

export default BaseLayout(NotFound);