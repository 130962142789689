'use client';

import { useEffect } from 'react';

export default function VisibilitySwap() {
  useEffect(() => {
    const hpContent = document.getElementById('hp-content');
    const categoriesPage = document.getElementById('categories-page');

    if (hpContent) {
      hpContent.style.display = 'block';
    }
    if (categoriesPage) {
      categoriesPage.style.display = 'none';
    }
  }, []);

  return null;
}