export const british = [
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_NumberOperations",
    "name_en": "Number Operations for Year 7 Mathematics",
    "name_sv": "Taloperationer för Årskurs 7 Matematik",
    "name_is": "Talavertur fyrir 7. bekk Matematik",
    "learning_objectives": "Understand and apply basic arithmetic operations including addition, subtraction, multiplication, and division to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_FractionsDecimalsPercentages",
    "name_en": "Fractions, Decimals, and Percentages for Year 7 Mathematics",
    "name_sv": "Bråk, Decimaler och Procent för Årskurs 7 Matematik",
    "name_is": "Brot, Aukastaf og Prósent fyrir 7. bekk Matematik",
    "learning_objectives": "Convert between fractions, decimals, and percentages, and solve problems involving these concepts to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_IntroToAlgebra",
    "name_en": "Introduction to Algebra for Year 7 Mathematics",
    "name_sv": "Introduktion till Algebra för Årskurs 7 Matematik",
    "name_is": "Inngangur að Algebru fyrir 7. bekk Matematik",
    "learning_objectives": "Understand the basics of algebra including variables, expressions, and simple equations to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_PropertiesOfShapes",
    "name_en": "Properties of Shapes for Year 7 Mathematics",
    "name_sv": "Egenskaper hos Former för Årskurs 7 Matematik",
    "name_is": "Eiginleikar forma fyrir 7. bekk Matematik",
    "learning_objectives": "Identify and describe the properties of different shapes, including angles and sides, to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_Measurement",
    "name_en": "Measurement for Year 7 Mathematics",
    "name_sv": "Mätning för Årskurs 7 Matematik",
    "name_is": "Mæling fyrir 7. bekk Matematik",
    "learning_objectives": "Use appropriate units to measure lengths, areas, volumes, and other quantities to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_UnderstandingAngles",
    "name_en": "Understanding Angles for Year 7 Mathematics",
    "name_sv": "Förståelse av Vinklar för Årskurs 7 Matematik",
    "name_is": "Skilning á Vinklum fyrir 7. bekk Matematik",
    "learning_objectives": "Identify, measure, and construct different types of angles to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_IntroToStatistics",
    "name_en": "Introduction to Statistics for Year 7 Mathematics",
    "name_sv": "Introduktion till Statistik för Årskurs 7 Matematik",
    "name_is": "Inngangur að Stærðfræði fyrir 7. bekk Matematik",
    "learning_objectives": "Collect, organize, and interpret data to understand basic statistical concepts to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_BasicProbability",
    "name_en": "Basic Probability for Year 7 Mathematics",
    "name_sv": "Grundläggande Sannolikhet för Årskurs 7 Matematik",
    "name_is": "Grunnlýsing Sannorðis fyrir 7. bekk Matematik",
    "learning_objectives": "Understand basic concepts of probability and how to calculate simple probabilities to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_RatioAndProportion",
    "name_en": "Ratio and Proportion for Year 7 Mathematics",
    "name_sv": "Förhållande och Proportion för Årskurs 7 Matematik",
    "name_is": "Hlutföll og Hlutfall fyrir 7. bekk Matematik",
    "learning_objectives": "Understand and use ratios and proportions in various contexts to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 7",
    "id": "topic_British_Y7_Math_UnderstandingTime",
    "name_en": "Understanding Time for Year 7 Mathematics",
    "name_sv": "Förståelse av Tid för Årskurs 7 Matematik",
    "name_is": "Skilning á Tíma fyrir 7. bekk Matematik",
    "learning_objectives": "Read and interpret clocks and calendars, calculate durations, and solve problems involving time to meet the requirements for passing Year 7 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_AlgebraicExpressions",
    "name_en": "Algebraic Expressions for Year 8 Mathematics",
    "name_sv": "Algebraiska Uttryck för Årskurs 8 Matematik",
    "name_is": "Algebruíhengi fyrir 8. bekk Matematik",
    "learning_objectives": "Manipulate algebraic expressions, including simplifying and expanding to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_SolvingLinearEquations",
    "name_en": "Solving Linear Equations for Year 8 Mathematics",
    "name_sv": "Lösning av Linjära Ekvationer för Årskurs 8 Matematik",
    "name_is": "Leysting á Línulegum Jafna fyrir 8. bekk Matematik",
    "learning_objectives": "Solve linear equations and inequalities and represent solutions graphically to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_QuadraticEquations",
    "name_en": "Quadratic Equations for Year 8 Mathematics",
    "name_sv": "Kvadratiska Ekvationer för Årskurs 8 Matematik",
    "name_is": "Ferill Jafna fyrir 8. bekk Matematik",
    "learning_objectives": "Solve quadratic equations and understand their properties to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_GraphsOfFunctions",
    "name_en": "Graphs of Functions for Year 8 Mathematics",
    "name_sv": "Grafer av Funktioner för Årskurs 8 Matematik",
    "name_is": "Föllin af Dæmiger fyrir 8. bekk Matematik",
    "learning_objectives": "Understand and interpret graphs of functions and their properties to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_Transformations",
    "name_en": "Transformations for Year 8 Mathematics",
    "name_sv": "Transformationer för Årskurs 8 Matematik",
    "name_is": "Umbreytingar fyrir 8. bekk Matematik",
    "learning_objectives": "Identify and perform transformations such as translations, rotations, reflections, and enlargements to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 8",
    "id": "topic_British_Y8_Math_DataHandling",
    "name_en": "Data Handling for Year 8 Mathematics",
    "name_sv": "Databehandling för Årskurs 8 Matematik",
    "name_is": "Gagnahafandi fyrir 8. bekk Matematik",
    "learning_objectives": "Collect, analyze, and present data using appropriate methods to meet the requirements for passing Year 8 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 9",
    "id": "topic_British_Y9_Math_AdvancedProbability",
    "name_en": "Advanced Probability for Year 9 Mathematics",
    "name_sv": "Avancerad Sannolikhet för Årskurs 9 Matematik",
    "name_is": "Flóknar Sannorðis fyrir 9. bekk Matematik",
    "learning_objectives": "Understand advanced concepts of probability, including independence and conditional probability to meet the requirements for passing Year 9 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 9",
    "id": "topic_British_Y9_Math_SequencesAndSeries",
    "name_en": "Sequences and Series for Year 9 Mathematics",
    "name_sv": "Följder och Serier för Årskurs 9 Matematik",
    "name_is": "Röð og Röðin fyrir 9. bekk Matematik",
    "learning_objectives": "Identify, describe, and analyze sequences and series to understand their properties and apply them to problems to meet the requirements for passing Year 9 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 9",
    "id": "topic_British_Y9_Math_ComplexNumbers",
    "name_en": "Complex Numbers for Year 9 Mathematics",
    "name_sv": "Komplexa Tal för Årskurs 9 Matematik",
    "name_is": "Flókin Tal fyrir 9. bekk Matematik",
    "learning_objectives": "Understand complex numbers and perform basic operations on them to explore their properties to meet the requirements for passing Year 9 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 9",
    "id": "topic_British_Y9_Math_CalculusFundamentals",
    "name_en": "Calculus Fundamentals for Year 9 Mathematics",
    "name_sv": "Grunder i Kalkyl för Årskurs 9 Matematik",
    "name_is": "Grunndin í Hagnýtingu fyrir 9. bekk Matematik",
    "learning_objectives": "Explore the basics of calculus including limits and derivatives, focusing on their applications to meet the requirements for passing Year 9 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_AlgebraicExpressions",
    "name_en": "Algebraic Expressions for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Algebraiska Uttryck för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Algebruíhengi fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Manipulate and transform algebraic expressions, factorization, and expand brackets to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_SolvingLinearEquations",
    "name_en": "Solving Linear Equations for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Lösning av Linjära Ekvationer för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Leysting á Línulegum Jafna fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Solve linear equations involving one variable and represent solutions graphically to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_QuadraticEquations",
    "name_en": "Quadratic Equations for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Kvadratiska Ekvationer för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Ferill Jafna fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Solve quadratic equations using various methods including factoring, completing the square, and the quadratic formula to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_GraphsOfFunctions",
    "name_en": "Graphs of Functions for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Grafer av Funktioner för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Föllin af Dæmiger fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Analyze and interpret graphs of functions, including linear and quadratic functions to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_Transformations",
    "name_en": "Transformations for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Transformationer för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Umbreytingar fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Understand and perform transformations including translations, rotations, reflections, and enlargements on shapes to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_DataHandling",
    "name_en": "Data Handling for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Databehandling för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Gagnahafandi fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Collect and interpret data using appropriate methods for statistical analysis to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Math_RatiosAndProportions",
    "name_en": "Ratios and Proportions for Year 10 Mathematics (GCSE Year 1)",
    "name_sv": "Förhållanden och Proportioner för Årskurs 10 Matematik (GCSE År 1)",
    "name_is": "Hlutföll og Hlutfall fyrir 10. bekk Matematik (GCSE Árs 1)",
    "learning_objectives": "Understand and apply ratios and proportions in various contexts to meet the requirements for passing Year 10 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_AlgebraicExpressions",
    "name_en": "Algebraic Expressions for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Algebraiska Uttryck för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Algebruíhengi fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Deepen understanding of algebraic expressions, including complex factorization and manipulation techniques to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_SolvingLinearEquations",
    "name_en": "Solving Linear Equations for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Lösning av Linjära Ekvationer för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Leysting á Línulegum Jafna fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Further develop skills in solving various types of linear equations and inequalities, including systems of equations to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_QuadraticEquations",
    "name_en": "Quadratic Equations for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Kvadratiska Ekvationer för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Ferill Jafna fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Explore advanced solving techniques for quadratic equations and their applications in real-world contexts to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_GraphsOfFunctions",
    "name_en": "Graphs of Functions for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Grafer av Funktioner för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Föllin af Dæmiger fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Investigate the characteristics of different types of functions and their graphs, including transformations and inverses to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_DataHandling",
    "name_en": "Data Handling for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Databehandling för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Gagnahafandi fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Analyze statistical data using advanced methods and represent it visually, understanding bias and reliability to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Math_RatiosAndProportions",
    "name_en": "Ratios and Proportions for Year 11 Mathematics (GCSE Year 2)",
    "name_sv": "Förhållanden och Proportioner för Årskurs 11 Matematik (GCSE År 2)",
    "name_is": "Hlutföll og Hlutfall fyrir 11. bekk Matematik (GCSE Árs 2)",
    "learning_objectives": "Solve advanced problems involving ratios and proportions, including compound ratios and scale drawings to meet the requirements for passing Year 11 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Math_IntroductionToAlgebra",
    "name_en": "Introduction to Algebra for Year 12 Mathematics (AS-Level)",
    "name_sv": "Introduktion till Algebra för Årskurs 12 Matematik (AS-Nivå)",
    "name_is": "Inngangur að Algebru fyrir 12. bekk Matematik (AS-Nivå)",
    "learning_objectives": "Deepen understanding of algebraic structures, inequalities, and functions, applying these to solve complex problems to meet the requirements for passing Year 12 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Math_TrigonometricRatios",
    "name_en": "Trigonometric Ratios for Year 12 Mathematics (AS-Level)",
    "name_sv": "Trigonometriska Förhållanden för Årskurs 12 Matematik (AS-Nivå)",
    "name_is": "Trigonometric Ratio fyrir 12. bekk Matematik (AS-Nivå)",
    "learning_objectives": "Understand and apply trigonometric ratios and identities in various contexts including right-angled triangles to meet the requirements for passing Year 12 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Math_CalculusFundamentals",
    "name_en": "Calculus Fundamentals for Year 12 Mathematics (AS-Level)",
    "name_sv": "Grunder i Kalkyl för Årskurs 12 Matematik (AS-Nivå)",
    "name_is": "Grunndin í Hagnýtingu fyrir 12. bekk Matematik (AS-Nivå)",
    "learning_objectives": "Explore the fundamental concepts of calculus including limits, continuity, and differentiation to solve real-world problems to meet the requirements for passing Year 12 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Math_GraphsOfFunctions",
    "name_en": "Graphs of Functions for Year 12 Mathematics (AS-Level)",
    "name_sv": "Grafer av Funktioner för Årskurs 12 Matematik (AS-Nivå)",
    "name_is": "Föllin af Dæmiger fyrir 12. bekk Matematik (AS-Nivå)",
    "learning_objectives": "Investigate various types of functions, including polynomial and exponential functions, and their graphs to meet the requirements for passing Year 12 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Math_Statistics",
    "name_en": "Statistics for Year 12 Mathematics (AS-Level)",
    "name_sv": "Statistik för Årskurs 12 Matematik (AS-Nivå)",
    "name_is": "Stærðfræði fyrir 12. bekk Matematik (AS-Nivå)",
    "learning_objectives": "Analyze statistical data using advanced methods including hypothesis testing, correlation, and regression to meet the requirements for passing Year 12 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Math_FurtherCalculus",
    "name_en": "Further Calculus for Year 13 Mathematics (A-Level)",
    "name_sv": "Vidare Kalkyl för Årskurs 13 Matematik (A-Nivå)",
    "name_is": "Frekar Hagnýtingar fyrir 13. bekk Matematik (A-Nivå)",
    "learning_objectives": "Explore advanced topics in calculus, including integration techniques and applications to solve complex problems to meet the requirements for passing Year 13 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Math_StatisticsAndProbability",
    "name_en": "Statistics and Probability for Year 13 Mathematics (A-Level)",
    "name_sv": "Statistik och Sannolikhet för Årskurs 13 Matematik (A-Nivå)",
    "name_is": "Stærðfræði og Sannorðis fyrir 13. bekk Matematik (A-Nivå)",
    "learning_objectives": "Understand and apply advanced concepts of statistics and probability, including distributions and inferential statistics to solve real-world problems to meet the requirements for passing Year 13 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Math_FurtherStatistics",
    "name_en": "Further Statistics for Year 13 Mathematics (A-Level)",
    "name_sv": "Vidare Statistik för Årskurs 13 Matematik (A-Nivå)",
    "name_is": "Frekar Stærðfræði fyrir 13. bekk Matematik (A-Nivå)",
    "learning_objectives": "Investigate further topics in statistics, including time series analysis and advanced statistical tests to meet the requirements for passing Year 13 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Math_ComplexNumbers",
    "name_en": "Complex Numbers for Year 13 Mathematics (A-Level)",
    "name_sv": "Komplexa Tal för Årskurs 13 Matematik (A-Nivå)",
    "name_is": "Flókin Tal fyrir 13. bekk Matematik (A-Nivå)",
    "learning_objectives": "Explore the theory and application of complex numbers in advanced mathematical problems to meet the requirements for passing Year 13 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Mathematics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Math_AdvancedAlgebra",
    "name_en": "Advanced Algebra for Year 13 Mathematics (A-Level)",
    "name_sv": "Avancerad Algebra för Årskurs 13 Matematik (A-Nivå)",
    "name_is": "Flókin Algebru fyrir 13. bekk Matematik (A-Nivå)",
    "learning_objectives": "Investigate advanced algebraic concepts, including matrices and vector spaces, applicable in various fields to meet the requirements for passing Year 13 Mathematics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.mathematics",
      "interests.mathematics",
      "generalInterests.mathematics",
      "generalInterests.science"
    ]
  },
  
  // Physics Topics
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Physics_ForcesAndMotion",
    "name_en": "Forces and Motion for Year 10 Physics (GCSE Year 1)",
    "name_sv": "Krafter och Rörelse för Årskurs 10 Fysik (GCSE År 1)",
    "name_is": "Kraftir og Hreyfing fyrir 10. bekk Fysik (GCSE Árs 1)",
    "learning_objectives": "Understand the laws of motion, types of forces, and how they relate to the movement of objects to meet the requirements for passing Year 10 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Physics_EnergyTransfers",
    "name_en": "Energy Transfers for Year 10 Physics (GCSE Year 1)",
    "name_sv": "Energiöverföringar för Årskurs 10 Fysik (GCSE År 1)",
    "name_is": "Orkuskipti fyrir 10. bekk Fysik (GCSE Árs 1)",
    "learning_objectives": "Explore the different forms of energy and the concept of conservation of energy to analyze energy transfers in systems to meet the requirements for passing Year 10 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Physics_PropertiesOfWaves",
    "name_en": "Properties of Waves for Year 10 Physics (GCSE Year 1)",
    "name_sv": "Vågens Egenskaper för Årskurs 10 Fysik (GCSE År 1)",
    "name_is": "Eiginleikar bylgja fyrir 10. bekk Fysik (GCSE Árs 1)",
    "learning_objectives": "Study the characteristics of waves, types of waves, and the phenomena of reflection, refraction, and diffraction to meet the requirements for passing Year 10 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 10",
    "id": "topic_British_Y10_Physics_ElectricityAndMagnetism",
    "name_en": "Electricity and Magnetism for Year 10 Physics (GCSE Year 1)",
    "name_sv": "Elektricitet och Magnetism för Årskurs 10 Fysik (GCSE År 1)",
    "name_is": "Rafmagn og Segulmagn fyrir 10. bekk Fysik (GCSE Árs 1)",
    "learning_objectives": "Explore the principles of electric circuits, magnetic fields, and electromagnetism to meet the requirements for passing Year 10 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Physics_ThermalPhysics",
    "name_en": "Thermal Physics for Year 11 Physics (GCSE Year 2)",
    "name_sv": "Termisk Fysik för Årskurs 11 Fysik (GCSE År 2)",
    "name_is": "Hitastýring fyrir 11. bekk Fysik (GCSE Árs 2)",
    "learning_objectives": "Understand the principles of heat transfer, thermal energy, and temperature to analyze real-world thermal systems to meet the requirements for passing Year 11 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 11",
    "id": "topic_British_Y11_Physics_ModernPhysics",
    "name_en": "Modern Physics for Year 11 Physics (GCSE Year 2)",
    "name_sv": "Modern Fysik för Årskurs 11 Fysik (GCSE År 2)",
    "name_is": "Nútíma Fysik fyrir 11. bekk Fysik (GCSE Árs 2)",
    "learning_objectives": "Explore topics in modern physics including quantum mechanics, atomic structure, and relativity to meet the requirements for passing Year 11 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Physics_StructuresAndForces",
    "name_en": "Structures and Forces for Year 12 Physics (AS-Level)",
    "name_sv": "Strukturer och Krafter för Årskurs 12 Fysik (AS-Nivå)",
    "name_is": "Strúktúr og Kraftir fyrir 12. bekk Fysik (AS-Nivå)",
    "learning_objectives": "Understand the principles of structural forces, moments, and equilibrium in various systems to meet the requirements for passing Year 12 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Physics_Electromagnetism",
    "name_en": "Electromagnetism for Year 12 Physics (AS-Level)",
    "name_sv": "Elektromagnetism för Årskurs 12 Fysik (AS-Nivå)",
    "name_is": "Rafmagns- og Segulmagn fyrir 12. bekk Fysik (AS-Nivå)",
    "learning_objectives": "Explore advanced topics in electromagnetism including electromagnetic induction and applications to meet the requirements for passing Year 12 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 12",
    "id": "topic_British_Y12_Physics_Waves",
    "name_en": "Waves for Year 12 Physics (AS-Level)",
    "name_sv": "Vågor för Årskurs 12 Fysik (AS-Nivå)",
    "name_is": "Bylgjur fyrir 12. bekk Fysik (AS-Nivå)",
    "learning_objectives": "Investigate the nature and behavior of different types of waves, including light and sound waves, to meet the requirements for passing Year 12 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Physics_ClassicalMechanics",
    "name_en": "Classical Mechanics for Year 13 Physics (A-Level)",
    "name_sv": "Klassisk Mekanik för Årskurs 13 Fysik (A-Nivå)",
    "name_is": "Klassísk Vélfræði fyrir 13. bekk Fysik (A-Nivå)",
    "learning_objectives": "Develop a deep understanding of the principles of classical mechanics, including dynamics, kinematics, and conservation laws to meet the requirements for passing Year 13 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Physics",
    "subject": "Year 13",
    "id": "topic_British_Y13_Physics_ModernPhysics",
    "name_en": "Modern Physics for Year 13 Physics (A-Level)",
    "name_sv": "Modern Fysik för Årskurs 13 Fysik (A-Nivå)",
    "name_is": "Nútíma Fysik fyrir 13. bekk Fysik (A-Nivå)",
    "learning_objectives": "Explore advanced concepts in modern physics including quantum theory and relativistic physics to meet the requirements for passing Year 13 Physics.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.physics",
      "interests.physics",
      "generalInterests.physics",
      "generalInterests.science"
    ]
  },
  
  // Chemistry Topics
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 10",
    "id": "topic_British_Y10_Chemistry_AtomicStructure",
    "name_en": "Atomic Structure for Year 10 Chemistry (GCSE Year 1)",
    "name_sv": "Atomstruktur för Årskurs 10 Kemi (GCSE År 1)",
    "name_is": "Atomuppbygging fyrir 10. bekk Kemi (GCSE Árs 1)",
    "learning_objectives": "Understand the basic structure of atoms, including subatomic particles and isotopes to meet the requirements for passing Year 10 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 10",
    "id": "topic_British_Y10_Chemistry_ThePeriodicTable",
    "name_en": "The Periodic Table for Year 10 Chemistry (GCSE Year 1)",
    "name_sv": "Det Periodiska Systemet för Årskurs 10 Kemi (GCSE År 1)",
    "name_is": "Það Periodíska Kerfi fyrir 10. bekk Kemi (GCSE Árs 1)",
    "learning_objectives": "Explore the organization and trends of the periodic table, including groups and periods to meet the requirements for passing Year 10 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 10",
    "id": "topic_British_Y10_Chemistry_ChemicalBonds",
    "name_en": "Chemical Bonds for Year 10 Chemistry (GCSE Year 1)",
    "name_sv": "Kemiska Bindningar för Årskurs 10 Kemi (GCSE År 1)",
    "name_is": "Efnafræðileg Bindin fyrir 10. bekk Kemi (GCSE Árs 1)",
    "learning_objectives": "Understand types of chemical bonds including ionic, covalent, and metallic bonding to meet the requirements for passing Year 10 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 10",
    "id": "topic_British_Y10_Chemistry_ReactionTypes",
    "name_en": "Reaction Types for Year 10 Chemistry (GCSE Year 1)",
    "name_sv": "Reaktionstyper för Årskurs 10 Kemi (GCSE År 1)",
    "name_is": "Hvarfategundir fyrir 10. bekk Kemi (GCSE Árs 1)",
    "learning_objectives": "Identify and classify different chemical reactions including synthesis, decomposition, and oxidation-reduction reactions to meet the requirements for passing Year 10 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 11",
    "id": "topic_British_Y11_Chemistry_AtomicStructure",
    "name_en": "Atomic Structure for Year 11 Chemistry (GCSE Year 2)",
    "name_sv": "Atomstruktur för Årskurs 11 Kemi (GCSE År 2)",
    "name_is": "Atomuppbygging fyrir 11. bekk Kemi (GCSE Árs 2)",
    "learning_objectives": "Examine advanced concepts in atomic structure and electron configuration to meet the requirements for passing Year 11 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 11",
    "id": "topic_British_Y11_Chemistry_ThePeriodicTable",
    "name_en": "The Periodic Table for Year 11 Chemistry (GCSE Year 2)",
    "name_sv": "Det Periodiska Systemet för Årskurs 11 Kemi (GCSE År 2)",
    "name_is": "Það Periodíska Kerfi fyrir 11. bekk Kemi (GCSE Árs 2)",
    "learning_objectives": "Explore the significance and arrangement of the periodic table in predicting element properties and reactions to meet the requirements for passing Year 11 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 11",
    "id": "topic_British_Y11_Chemistry_ChemicalBonds",
    "name_en": "Chemical Bonds for Year 11 Chemistry (GCSE Year 2)",
    "name_sv": "Kemiska Bindningar för Årskurs 11 Kemi (GCSE År 2)",
    "name_is": "Efnafræðileg Bindin fyrir 11. bekk Kemi (GCSE Árs 2)",
    "learning_objectives": "Analyze and compare different types of chemical bonds and their effects on the properties of substances to meet the requirements for passing Year 11 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 11",
    "id": "topic_British_Y11_Chemistry_ReactionTypes",
    "name_en": "Reaction Types for Year 11 Chemistry (GCSE Year 2)",
    "name_sv": "Reaktionstyper för Årskurs 11 Kemi (GCSE År 2)",
    "name_is": "Hvarfategundir fyrir 11. bekk Kemi (GCSE Árs 2)",
    "learning_objectives": "Investigate complex reactions including equilibrium, kinetics, and rates of reaction to meet the requirements for passing Year 11 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 12",
    "id": "topic_British_Y12_Chemistry_ConstitutionAndPropertiesOfMatter",
    "name_en": "Constitution and Properties of Matter for Year 12 Chemistry (AS-Level)",
    "name_sv": "Konstitution och Egenskaper hos Materia för Årskurs 12 Kemi (AS-Nivå)",
    "name_is": "Samsetning og Eiginleikar efnis fyrir 12. bekk Kemi (AS-Nivå)",
    "learning_objectives": "Explore the properties of matter, including states of matter, intermolecular forces, and phase changes to meet the requirements for passing Year 12 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 12",
    "id": "topic_British_Y12_Chemistry_BondingAndInteractions",
    "name_en": "Bonding and Interactions for Year 12 Chemistry (AS-Level)",
    "name_sv": "Bindning och Interaktioner för Årskurs 12 Kemi (AS-Nivå)",
    "name_is": "Bindingar og Samskipti fyrir 12. bekk Kemi (AS-Nivå)",
    "learning_objectives": "Investigate types of chemical bonding, including ionic, covalent, and metallic bonding, and their effects on the properties of substances to meet the requirements for passing Year 12 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 12",
    "id": "topic_British_Y12_Chemistry_ChemicalReactions",
    "name_en": "Chemical Reactions for Year 12 Chemistry (AS-Level)",
    "name_sv": "Kemiska Reaktioner för Årskurs 12 Kemi (AS-Nivå)",
    "name_is": "Efnafræðilegar Hvarf fyrir 12. bekk Kemi (AS-Nivå)",
    "learning_objectives": "Study reaction kinetics, equilibrium, and thermochemistry to apply these concepts in practical situations to meet the requirements for passing Year 12 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 13",
    "id": "topic_British_Y13_Chemistry_OrganicChemistry",
    "name_en": "Organic Chemistry for Year 13 Chemistry (A-Level)",
    "name_sv": "Organisk Kemi för Årskurs 13 Kemi (A-Nivå)",
    "name_is": "Organísk Kemi fyrir 13. bekk Kemi (A-Nivå)",
    "learning_objectives": "Explore the structure, properties, and reactions of organic compounds to meet the requirements for passing Year 13 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Chemistry",
    "subject": "Year 13",
    "id": "topic_British_Y13_Chemistry_InorganicChemistry",
    "name_en": "Inorganic Chemistry for Year 13 Chemistry (A-Level)",
    "name_sv": "Oorganisk Kemi för Årskurs 13 Kemi (A-Nivå)",
    "name_is": "Óorganísk Kemi fyrir 13. bekk Kemi (A-Nivå)",
    "learning_objectives": "Investigate the properties and reactions of inorganic compounds, and the role they play in various processes to meet the requirements for passing Year 13 Chemistry.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.chemistry",
      "interests.chemistry",
      "generalInterests.chemistry",
      "generalInterests.science"
    ]
  },
  
  // Biology Topics
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 10",
    "id": "topic_British_Y10_Biology_CellBiology",
    "name_en": "Cell Biology for Year 10 Biology (GCSE Year 1)",
    "name_sv": "Cellbiologi för Årskurs 10 Biologi (GCSE År 1)",
    "name_is": "Frumefni Frumufræði fyrir 10. bekk Biologi (GCSE Árs 1)",
    "learning_objectives": "Understand the structure and function of cells, including cellular processes and cell division to meet the requirements for passing Year 10 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 10",
    "id": "topic_British_Y10_Biology_Genetics",
    "name_en": "Genetics for Year 10 Biology (GCSE Year 1)",
    "name_sv": "Genetik för Årskurs 10 Biologi (GCSE År 1)",
    "name_is": "Genetík fyrir 10. bekk Biologi (GCSE Árs 1)",
    "learning_objectives": "Explore the principles of inheritance, DNA, and genetic variation to meet the requirements for passing Year 10 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 10",
    "id": "topic_British_Y10_Biology_Evolution",
    "name_en": "Evolution for Year 10 Biology (GCSE Year 1)",
    "name_sv": "Evolution för Årskurs 10 Biologi (GCSE År 1)",
    "name_is": "Þróun fyrir 10. bekk Biologi (GCSE Árs 1)",
    "learning_objectives": "Understand the principles of evolution, natural selection, and the evidence supporting evolutionary theory to meet the requirements for passing Year 10 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 10",
    "id": "topic_British_Y10_Biology_Ecology",
    "name_en": "Ecology for Year 10 Biology (GCSE Year 1)",
    "name_sv": "Ekologi för Årskurs 10 Biologi (GCSE År 1)",
    "name_is": "Ekólogía fyrir 10. bekk Biologi (GCSE Árs 1)",
    "learning_objectives": "Investigate ecosystems, biomes, and the interactions between organisms and their environments to meet the requirements for passing Year 10 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 11",
    "id": "topic_British_Y11_Biology_CellBiology",
    "name_en": "Cell Biology for Year 11 Biology (GCSE Year 2)",
    "name_sv": "Cellbiologi för Årskurs 11 Biologi (GCSE År 2)",
    "name_is": "Frumefni Frumufræði fyrir 11. bekk Biologi (GCSE Árs 2)",
    "learning_objectives": "Deepen understanding of eukaryotic and prokaryotic cells, cellular respiration, and photosynthesis to meet the requirements for passing Year 11 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 11",
    "id": "topic_British_Y11_Biology_Genetics",
    "name_en": "Genetics for Year 11 Biology (GCSE Year 2)",
    "name_sv": "Genetik för Årskurs 11 Biologi (GCSE År 2)",
    "name_is": "Genetík fyrir 11. bekk Biologi (GCSE Árs 2)",
    "learning_objectives": "Explore advanced genetics concepts including genomics and biotechnology to meet the requirements for passing Year 11 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 11",
    "id": "topic_British_Y11_Biology_Evolution",
    "name_en": "Evolution for Year 11 Biology (GCSE Year 2)",
    "name_sv": "Evolution för Årskurs 11 Biologi (GCSE År 2)",
    "name_is": "Þróun fyrir 11. bekk Biologi (GCSE Árs 2)",
    "learning_objectives": "Investigate evolutionary mechanisms, speciation, and human evolution to meet the requirements for passing Year 11 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 11",
    "id": "topic_British_Y11_Biology_Ecology",
    "name_en": "Ecology for Year 11 Biology (GCSE Year 2)",
    "name_sv": "Ekologi för Årskurs 11 Biologi (GCSE År 2)",
    "name_is": "Ekólogía fyrir 11. bekk Biologi (GCSE Árs 2)",
    "learning_objectives": "Analyze ecosystem dynamics, human impacts on ecosystems, and conservation biology to meet the requirements for passing Year 11 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 12",
    "id": "topic_British_Y12_Biology_CellBiology",
    "name_en": "Cell Biology for Year 12 Biology (AS-Level)",
    "name_sv": "Cellbiologi för Årskurs 12 Biologi (AS-Nivå)",
    "name_is": "Frumefni Frumufræði fyrir 12. bekk Biologi (AS-Nivå)",
    "learning_objectives": "Delve into the structure and function of cells, including cellular communication and the cell cycle to meet the requirements for passing Year 12 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 12",
    "id": "topic_British_Y12_Biology_Genetics",
    "name_en": "Genetics for Year 12 Biology (AS-Level)",
    "name_sv": "Genetik för Årskurs 12 Biologi (AS-Nivå)",
    "name_is": "Genetík fyrir 12. bekk Biologi (AS-Nivå)",
    "learning_objectives": "Explore the structure of DNA, gene expression, and advanced genetic technologies to meet the requirements for passing Year 12 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },
  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 12",
    "id": "topic_British_Y12_Biology_Evolution",
    "name_en": "Evolution for Year 12 Biology (AS-Level)",
    "name_sv": "Evolution för Årskurs 12 Biologi (AS-Nivå)",
    "name_is": "Þróun fyrir 12. bekk Biologi (AS-Nivå)",
    "learning_objectives": "Understand evolutionary theory, evidence for evolution, and human impact on evolution to meet the requirements for passing Year 12 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.biology",
      "interests.biology",
      "generalInterests.biology",
      "generalInterests.science"
    ]
  },


  {
    "curriculum": "British",
    "field": "Biology",
    "subject": "Year 12",
    "id": "topic_British_Y12_Biology_Ecology",
    "name_en": "Ecology for Year 12 Biology (AS-Level)",
    "name_sv": "Ekologi för Årskurs 12 Biologi (AS-Nivå)",
    "name_is": "Ekólogía fyrir 12. bekk Biologi (AS-Nivå)",
    "learning_objectives": "Analyze the interactions within ecosystems, human impacts on populations, and conservation efforts to meet the requirements for passing Year 12 Biology.",
    "when_to_add": [
      "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.biology",
      "interests.biology",
  "generalInterests.biology",
  "generalInterests.science"
]
},
{
"curriculum": "British",
"field": "Biology",
"subject": "Year 13",
"id": "topic_British_Y13_Biology_AdvancedCellBiology",
"name_en": "Advanced Cell Biology for Year 13 Biology (A-Level)",
"name_sv": "Avancerad Cellbiologi för Årskurs 13 Biologi (A-Nivå)",
"name_is": "Avancerad Frumufræði fyrir 13. bekk Biologi (A-Nivå)",
"learning_objectives": "Delve deeper into the complexities of cellular processes, signaling pathways, and cellular differentiation to meet the requirements for passing Year 13 Biology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.biology",
  "interests.biology",
  "generalInterests.biology",
  "generalInterests.science"
]
},
{
"curriculum": "British",
"field": "Biology",
"subject": "Year 13",
"id": "topic_British_Y13_Biology_AdvancedGenetics",
"name_en": "Advanced Genetics for Year 13 Biology (A-Level)",
"name_sv": "Avancerad Genetik för Årskurs 13 Biologi (A-Nivå)",
"name_is": "Avanceruð Genetík fyrir 13. bekk Biologi (A-Nivå)",
"learning_objectives": "Explore advanced genetics concepts, including cloning, genetic engineering, and population genetics to meet the requirements for passing Year 13 Biology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.biology",
  "interests.biology",
  "generalInterests.biology",
  "generalInterests.science"
]
},
{
"curriculum": "British",
"field": "Biology",
"subject": "Year 13",
"id": "topic_British_Y13_Biology_AdvancedEcology",
"name_en": "Advanced Ecology for Year 13 Biology (A-Level)",
"name_sv": "Avancerad Ekologi för Årskurs 13 Biologi (A-Nivå)",
"name_is": "Avanceruð Ekólogía fyrir 13. bekk Biologi (A-Nivå)",
"learning_objectives": "Investigate complex ecological interactions, human impacts, and conservation strategies at a deeper level to meet the requirements for passing Year 13 Biology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.biology",
  "interests.biology",
  "generalInterests.biology",
  "generalInterests.science"
]
},

// History Topics
{
"curriculum": "British",
"field": "History",
"subject": "Year 10",
"id": "topic_British_Y10_History_BritishHistory",
"name_en": "British History for Year 10 History (GCSE Year 1)",
"name_sv": "Brittisk Historia för Årskurs 10 Historia (GCSE År 1)",
"name_is": "Brittisk Saga fyrir 10. bekk Sögu (GCSE Árs 1)",
"learning_objectives": "Understand key events and figures in British history from the medieval period to modern times to meet the requirements for passing Year 10 History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 10",
"id": "topic_British_Y10_History_WorldHistory",
"name_en": "World History for Year 10 History (GCSE Year 1)",
"name_sv": "Världshistoria för Årskurs 10 Historia (GCSE År 1)",
"name_is": "Heimsaga fyrir 10. bekk Sögu (GCSE Árs 1)",
"learning_objectives": "Analyze major events and movements in world history, their causes, and impacts to meet the requirements for passing Year 10 History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 11",
"id": "topic_British_Y11_History_BritishHistory",
"name_en": "British History for Year 11 History (GCSE Year 2)",
"name_sv": "Brittisk Historia för Årskurs 11 Historia (GCSE År 2)",
"name_is": "Brittisk Saga fyrir 11. bekk Sögu (GCSE Árs 2)",
"learning_objectives": "Deepen understanding of British history, focusing on key historical developments and their global context to meet the requirements for passing Year 11 History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 11",
"id": "topic_British_Y11_History_WorldHistory",
"name_en": "World History for Year 11 History (GCSE Year 2)",
"name_sv": "Världshistoria för Årskurs 11 Historia (GCSE År 2)",
"name_is": "Heimsaga fyrir 11. bekk Sögu (GCSE Árs 2)",
"learning_objectives": "Explore significant events in world history, examining causes, consequences, and perspectives to meet the requirements for passing Year 11 History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 12",
"id": "topic_British_Y12_History_BritishHistory",
"name_en": "British History for Year 12 History (AS-Level)",
"name_sv": "Brittisk Historia för Årskurs 12 Historia (AS-Nivå)",
"name_is": "Brittisk Saga fyrir 12. bekk Sögu (AS-Nivå)",
"learning_objectives": "Examine key themes and events in British history from 1900 to the present to develop a deep understanding for passing AS-Level History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 12",
"id": "topic_British_Y12_History_WorldHistory",
"name_en": "World History for Year 12 History (AS-Level)",
"name_sv": "Världshistoria för Årskurs 12 Historia (AS-Nivå)",
"name_is": "Heimsaga fyrir 12. bekk Sögu (AS-Nivå)",
"learning_objectives": "Analyze significant global events and movements from the 20th century to understand their impact on today's world for passing AS-Level History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 13",
"id": "topic_British_Y13_History_BritishHistory",
"name_en": "British History for Year 13 History (A-Level)",
"name_sv": "Brittisk Historia för Årskurs 13 Historia (A-Nivå)",
"name_is": "Brittisk Saga fyrir 13. bekk Sögu (A-Nivå)",
"learning_objectives": "Investigate detailed studies in British history focusing on key events and their implications to meet the requirements for passing A-Level History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "History",
"subject": "Year 13",
"id": "topic_British_Y13_History_WorldHistory",
"name_en": "World History for Year 13 History (A-Level)",
"name_sv": "Världshistoria för Årskurs 13 Historia (A-Nivå)",
"name_is": "Heimsaga fyrir 13. bekk Sögu (A-Nivå)",
"learning_objectives": "Examine global events of significance in world history, analyzing them in context and their impacts to meet the requirements for passing A-Level History.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.history",
  "interests.history",
  "generalInterests.history",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 10",
"id": "topic_British_Y10_Geography_PhysicalGeography",
"name_en": "Physical Geography for Year 10 Geography (GCSE Year 1)",
"name_sv": "Fysisk Geografi för Årskurs 10 Geografi (GCSE År 1)",
"name_is": "Fýsisk Geografi fyrir 10. bekk Geografi (GCSE Árs 1)",
"learning_objectives": "Understand key physical processes and landforms, including weathering, erosion, and ecosystems to meet the requirements for passing Year 10 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 10",
"id": "topic_British_Y10_Geography_HumanGeography",
"name_en": "Human Geography for Year 10 Geography (GCSE Year 1)",
"name_sv": "Human Geografi för Årskurs 10 Geografi (GCSE År 1)",
"name_is": "Mannvísindi Geografi fyrir 10. bekk Geografi (GCSE Árs 1)",
"learning_objectives": "Explore human interactions with the physical environment, including urbanization, migration, and globalization to meet the requirements for passing Year 10 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 11",
"id": "topic_British_Y11_Geography_PhysicalGeography",
"name_en": "Physical Geography for Year 11 Geography (GCSE Year 2)",
"name_sv": "Fysisk Geografi för Årskurs 11 Geografi (GCSE År 2)",
"name_is": "Fýsisk Geografi fyrir 11. bekk Geografi (GCSE Árs 2)",
"learning_objectives": "Investigate advanced topics in physical geography, such as climate change and its impacts, and natural hazards to meet the requirements for passing Year 11 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 11",
"id": "topic_British_Y11_Geography_HumanGeography",
"name_en": "Human Geography for Year 11 Geography (GCSE Year 2)",
"name_sv": "Human Geografi för Årskurs 11 Geografi (GCSE År 2)",
"name_is": "Mannvísindi Geografi fyrir 11. bekk Geografi (GCSE Árs 2)",
"learning_objectives": "Analyze significant trends in human geography, including development, urbanization, and global interdependence to meet the requirements for passing Year 11 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 12",
"id": "topic_British_Y12_Geography_PhysicalGeography",
"name_en": "Physical Geography for Year 12 Geography (AS-Level)",
"name_sv": "Fysisk Geografi för Årskurs 12 Geografi (AS-Nivå)",
"name_is": "Fýsisk Geografi fyrir 12. bekk Geografi (AS-Nivå)",
"learning_objectives": "Delve into advanced physical geography topics, including biogeography, geomorphology, and environmental management to meet the requirements for passing Year 12 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 12",
"id": "topic_British_Y12_Geography_HumanGeography",
"name_en": "Human Geography for Year 12 Geography (AS-Level)",
"name_sv": "Human Geografi för Årskurs 12 Geografi (AS-Nivå)",
"name_is": "Mannvísindi Geografi fyrir 12. bekk Geografi (AS-Nivå)",
"learning_objectives": "Analyze complex human systems, including cultural geography, economic patterns, and resource management to meet the requirements for passing Year 12 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 13",
"id": "topic_British_Y13_Geography_PhysicalGeography",
"name_en": "Physical Geography for Year 13 Geography (A-Level)",
"name_sv": "Fysisk Geografi för Årskurs 13 Geografi (A-Nivå)",
"name_is": "Fýsisk Geografi fyrir 13. bekk Geografi (A-Nivå)",
"learning_objectives": "Investigate advanced concepts in physical geography, including climate change mitigation and adaptation strategies to meet the requirements for passing Year 13 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Geography",
"subject": "Year 13",
"id": "topic_British_Y13_Geography_HumanGeography",
"name_en": "Human Geography for Year 13 Geography (A-Level)",
"name_sv": "Human Geografi för Årskurs 13 Geografi (A-Nivå)",
"name_is": "Mannvísindi Geografi fyrir 13. bekk Geografi (A-Nivå)",
"learning_objectives": "Explore the significance of global patterns of inequality, development, and sustainability in human geography to meet the requirements for passing Year 13 Geography.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.geography",
  "interests.geography",
  "generalInterests.geography",
  "generalInterests.socialStudies"
]
},

// Economics Topics
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 10",
"id": "topic_British_Y10_Economics_Microeconomics",
"name_en": "Microeconomics for Year 10 Economics (GCSE Year 1)",
"name_sv": "Mikroekonomi för Årskurs 10 Ekonomi (GCSE År 1)",
"name_is": "Mikrohagfræði fyrir 10. bekk Hagfræði (GCSE Árs 1)",
"learning_objectives": "Understand the basic principles of microeconomics including supply and demand, and the behavior of consumers and firms to meet the requirements for passing Year 10 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 10",
"id": "topic_British_Y10_Economics_Macroeconomics",
"name_en": "Macroeconomics for Year 10 Economics (GCSE Year 1)",
"name_sv": "Makroekonomi för Årskurs 10 Ekonomi (GCSE År 1)",
"name_is": "Makrohagfræði fyrir 10. bekk Hagfræði (GCSE Árs 1)",
"learning_objectives": "Explore the national economy, focusing on aggregate demand and supply, monetary and fiscal policy to meet the requirements for passing Year 10 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 11",
"id": "topic_British_Y11_Economics_Microeconomics",
"name_en": "Microeconomics for Year 11 Economics (GCSE Year 2)",
"name_sv": "Mikroekonomi för Årskurs 11 Ekonomi (GCSE År 2)",
"name_is": "Mikrohagfræði fyrir 11. bekk Hagfræði (GCSE Árs 2)",
"learning_objectives": "Analyze microeconomic theories including market structures, pricing strategies, and the role of government in the economy to meet the requirements for passing Year 11 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 11",
"id": "topic_British_Y11_Economics_Macroeconomics",
"name_en": "Macroeconomics for Year 11 Economics (GCSE Year 2)",
"name_sv": "Makroekonomi för Årskurs 11 Ekonomi (GCSE År 2)",
"name_is": "Makrohagfræði fyrir 11. bekk Hagfræði (GCSE Árs 2)",
"learning_objectives": "Examine macroeconomic indicators, economic growth, and externalities in the economy to meet the requirements for passing Year 11 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 12",
"id": "topic_British_Y12_Economics_Microeconomics",
"name_en": "Microeconomics for Year 12 Economics (AS-Level)",
"name_sv": "Mikroekonomi för Årskurs 12 Ekonomi (AS-Nivå)",
"name_is": "Mikrohagfræði fyrir 12. bekk Hagfræði (AS-Nivå)",
"learning_objectives": "Analyze advanced concepts in microeconomic theory including market failures and welfare economics to meet the requirements for passing Year 12 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 12",
"id": "topic_British_Y12_Economics_Macroeconomics",
"name_en": "Macroeconomics for Year 12 Economics (AS-Level)",
"name_sv": "Makroekonomi för Årskurs 12 Ekonomi (AS-Nivå)",
"name_is": "Makrohagfræði fyrir 12. bekk Hagfræði (AS-Nivå)",
"learning_objectives": "Investigate advanced macroeconomic concepts including international trade, exchange rates, and economic policy to meet the requirements for passing Year 12 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year12_as.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 13",
"id": "topic_British_Y13_Economics_Microeconomics",
"name_en": "Microeconomics for Year 13 Economics (A-Level)",
"name_sv": "Mikroekonomi för Årskurs 13 Ekonomi (A-Nivå)",
"name_is": "Mikrohagfræði fyrir 13. bekk Hagfræði (A-Nivå)",
"learning_objectives": "Explore advanced theories in microeconomics, including market structures and competition policies to meet the requirements for passing Year 13 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Economics",
"subject": "Year 13",
"id": "topic_British_Y13_Economics_Macroeconomics",
"name_en": "Macroeconomics for Year 13 Economics (A-Level)",
"name_sv": "Makroekonomi för Årskurs 13 Ekonomi (A-Nivå)",
"name_is": "Makrohagfræði fyrir 13. bekk Hagfræði (A-Nivå)",
"learning_objectives": "Analyze complex issues in macroeconomics, including economic cycles, growth, and global economic challenges to meet the requirements for passing Year 13 Economics.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year13_a.schoolSubjects.economics",
  "interests.economics",
  "generalInterests.economics",
  "generalInterests.socialStudies"
]
},

// Business Studies Topics
{
"curriculum": "British",
"field": "Business Studies",
"subject": "Year 10",
"id": "topic_British_Y10_BusinessStudies_BusinessFunctions",
"name_en": "Business Functions for Year 10 Business Studies (GCSE Year 1)",
"name_sv": "Affärsfunktioner för Årskurs 10 Företagsekonomi (GCSE År 1)",
"name_is": "Fyrirtækjafunctioner fyrir 10. bekk Viðskipti (GCSE Árs 1)",
"learning_objectives": "Understand the role and function of different areas of a business, including marketing, finance, and operations to meet the requirements for passing Year 10 Business Studies.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.business_studies",
  "interests.business_studies",
  "generalInterests.business_studies",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Business Studies",
"subject": "Year 10",
"id": "topic_British_Y10_BusinessStudies_MarketingPrinciples",
"name_en": "Marketing Principles for Year 10 Business Studies (GCSE Year 1)",
"name_sv": "Marknadsföringsprinciper för Årskurs 10 Företagsekonomi (GCSE År 1)",
"name_is": "Markaðssetningarprinsipp fyrir 10. bekk Viðskipti (GCSE Árs 1)",
"learning_objectives": "Explore key principles of marketing, including market research, target markets, and marketing strategies to meet the requirements for passing Year 10 Business Studies.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.business_studies",
  "interests.business_studies",
  "generalInterests.business_studies",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Business Studies",
"subject": "Year 11",
"id": "topic_British_Y11_BusinessStudies_BusinessFunctions",
"name_en": "Business Functions for Year 11 Business Studies (GCSE Year 2)",
"name_sv": "Affärsfunktioner för Årskurs 11 Företagsekonomi (GCSE År 2)",
"name_is": "Fyrirtækjafunctioner fyrir 11. bekk Viðskipti (GCSE Árs 2)",
"learning_objectives": "Analyze the integration and role of various business functions in decision-making processes to meet the requirements for passing Year 11 Business Studies.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.business_studies",
  "interests.business_studies",
  "generalInterests.business_studies",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Business Studies",
"subject": "Year 11",
"id": "topic_British_Y11_BusinessStudies_MarketingPrinciples",
"name_en": "Marketing Principles for Year 11 Business Studies (GCSE Year 2)",
"name_sv": "Marknadsföringsprinciper för Årskurs 11 Företagsekonomi (GCSE År 2)",
"name_is": "Markaðssetningarprinsipp fyrir 11. bekk Viðskipti (GCSE Árs 2)",
"learning_objectives": "Evaluate the effectiveness of different marketing techniques and digital marketing strategies in real-world applications to meet the requirements for passing Year 11 Business Studies.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.business_studies",
  "interests.business_studies",
  "generalInterests.business_studies",
  "generalInterests.socialStudies"
]
},

// Psychology Topics
{
"curriculum": "British",
"field": "Psychology",
"subject": "Year 10",
"id": "topic_British_Y10_Psychology_IntroductionToPsychology",
"name_en": "Introduction to Psychology for Year 10 Psychology (GCSE Year 1)",
"name_sv": "Introduktion till Psykologi för Årskurs 10 Psykologi (GCSE År 1)",
"name_is": "Inngangur að Sálfræði fyrir 10. bekk Sálfræði (GCSE Árs 1)",
"learning_objectives": "Understand the fundamental concepts of psychology, including major theories and research methods used in the field to meet the requirements for passing Year 10 Psychology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.psychology",
  "interests.psychology",
  "generalInterests.psychology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Psychology",
"subject": "Year 10",
"id": "topic_British_Y10_Psychology_HumanDevelopment",
"name_en": "Human Development for Year 10 Psychology (GCSE Year 1)",
"name_sv": "Mänsklig Utveckling för Årskurs 10 Psykologi (GCSE År 1)",
"name_is": "Mannleg Þróun fyrir 10. bekk Sálfræði (GCSE Árs 1)",
"learning_objectives": "Explore the stages of human development, including physical, cognitive, and emotional development across the lifespan to meet the requirements for passing Year 10 Psychology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.psychology",
  "interests.psychology",
  "generalInterests.psychology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Psychology",
"subject": "Year 11",
"id": "topic_British_Y11_Psychology_IntroductionToPsychology",
"name_en": "Introduction to Psychology for Year 11 Psychology (GCSE Year 2)",
"name_sv": "Introduktion till Psykologi för Årskurs 11 Psykologi (GCSE År 2)",
"name_is": "Inngangur að Sálfræði fyrir 11. bekk Sálfræði (GCSE Árs 2)",
"learning_objectives": "Deepen understanding of psychological theories, including behaviorism, cognitive psychology, and social psychology to meet the requirements for passing Year 11 Psychology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.psychology",
  "interests.psychology",
  "generalInterests.psychology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Psychology",
"subject": "Year 11",
"id": "topic_British_Y11_Psychology_HumanDevelopment",
"name_en": "Human Development for Year 11 Psychology (GCSE Year 2)",
"name_sv": "Mänsklig Utveckling för Årskurs 11 Psykologi (GCSE År 2)",
"name_is": "Mannleg Þróun fyrir 11. bekk Sálfræði (GCSE Árs 2)",
"learning_objectives": "Investigate advanced concepts in human development, including attachment theory and developmental milestones to meet the requirements for passing Year 11 Psychology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.psychology",
  "interests.psychology",
  "generalInterests.psychology",
  "generalInterests.socialStudies"
]
},

// Sociology Topics
{
"curriculum": "British",
"field": "Sociology",
"subject": "Year 10",
"id": "topic_British_Y10_Sociology_IntroductionToSociology",
"name_en": "Introduction to Sociology for Year 10 Sociology (GCSE Year 1)",
"name_sv": "Introduktion till Sociologi för Årskurs 10 Sociologi (GCSE År 1)",
"name_is": "Inngangur að Félagsfræði fyrir 10. bekk Félagsfræði (GCSE Árs 1)",
"learning_objectives": "Understand the key concepts, theories, and methods used in sociology to analyze human behavior and social structures to meet the requirements for passing Year 10 Sociology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.sociology",
  "interests.sociology",
  "generalInterests.sociology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Sociology",
"subject": "Year 10",
"id": "topic_British_Y10_Sociology_FamilyAndHouseholds",
"name_en": "Family and Households for Year 10 Sociology (GCSE Year 1)",
"name_sv": "Familj och Hus för Årskurs 10 Sociologi (GCSE År 1)",
"name_is": "Fjölskyldur og Heimili fyrir 10. bekk Félagsfræði (GCSE Árs 1)",
"learning_objectives": "Explore the role and functions of family and households in society and their impact on individuals to meet the requirements for passing Year 10 Sociology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.sociology",
  "interests.sociology",
  "generalInterests.sociology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Sociology",
"subject": "Year 11",
"id": "topic_British_Y11_Sociology_IntroductionToSociology",
"name_en": "Introduction to Sociology for Year 11 Sociology (GCSE Year 2)",
"name_sv": "Introduktion till Sociologi för Årskurs 11 Sociologi (GCSE År 2)",
"name_is": "Inngangur að Félagsfræði fyrir 11. bekk Félagsfræði (GCSE Árs 2)",
"learning_objectives": "Deepen understanding of sociological theories and concepts, including socialization, culture, and identity to meet the requirements for passing Year 11 Sociology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.sociology",
  "interests.sociology",
  "generalInterests.sociology",
  "generalInterests.socialStudies"
]
},
{
"curriculum": "British",
"field": "Sociology",
"subject": "Year 11",
"id": "topic_British_Y11_Sociology_FamilyAndHouseholds",
"name_en": "Family and Households for Year 11 Sociology (GCSE Year 2)",
"name_sv": "Familj och Hus för Årskurs 11 Sociologi (GCSE År 2)",
"name_is": "Fjölskyldur og Heimili fyrir 11. bekk Félagsfræði (GCSE Árs 2)",
"learning_objectives": "Analyze the changing dynamics of family structures, functions, and the implications for society to meet the requirements for passing Year 11 Sociology.",
"when_to_add": [
  "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse2.schoolSubjects.sociology",
  "interests.sociology",
  "generalInterests.sociology",
  "generalInterests.socialStudies"
]
}
];