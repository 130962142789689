export const american = [
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_AlgebraI_NumberOperations",
        "name_en": "Number Operations for Grade 9 Algebra I",
        "name_sv": "Taloperationer för Årskurs 9 Algebra I",
        "name_is": "Talavritun fyrir 9. bekk Algebra I",
        "learning_objectives": "Understand and apply basic arithmetic operations including addition, subtraction, multiplication, and division to meet the requirements for passing Grade 9 Algebra I.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_AlgebraI_LinearEquations",
        "name_en": "Linear Equations for Grade 9 Algebra I",
        "name_sv": "Linjära Ekvationer för Årskurs 9 Algebra I",
        "name_is": "Línulegar jöfnu fyrir 9. bekk Algebra I",
        "learning_objectives": "Solve linear equations and inequalities to develop foundational skills in algebra.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_AlgebraI_QuadraticEquations",
        "name_en": "Quadratic Equations for Grade 9 Algebra I",
        "name_sv": "Kvadratiska Ekvationer för Årskurs 9 Algebra I",
        "name_is": "Ferlinur jöfnu fyrir 9. bekk Algebra I",
        "learning_objectives": "Learn to solve quadratic equations using factoring and the quadratic formula.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_AlgebraI_Functions",
        "name_en": "Functions for Grade 9 Algebra I",
        "name_sv": "Funktioner för Årskurs 9 Algebra I",
        "name_is": "Föllur fyrir 9. bekk Algebra I",
        "learning_objectives": "Understand the concept of functions and how to interpret function notation.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Geometry_BasicShapes",
        "name_en": "Basic Geometric Shapes for Grade 9 Geometry",
        "name_sv": "Grundläggande Geometriska Figurer för Årskurs 9 Geometri",
        "name_is": "Grunnlögðu rúmform fyrir 9. bekk rúmfræði",
        "learning_objectives": "Identify and calculate properties of geometric shapes such as triangles, squares, and circles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Geometry_SimilarityAndCongruence",
        "name_en": "Similarity and Congruence for Grade 9 Geometry",
        "name_sv": "Similaritet och Kongruens för Årskurs 9 Geometri",
        "name_is": "Líking og samkvæmni fyrir 9. bekk rúmfræði",
        "learning_objectives": "Explore the concepts of similarity and congruence in geometric figures.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Geometry_PythagoreanTheorem",
        "name_en": "Pythagorean Theorem for Grade 9 Geometry",
        "name_sv": "Pythagoras Satsen för Årskurs 9 Geometri",
        "name_is": "Pýþagórasar setning fyrir 9. bekk rúmfræði",
        "learning_objectives": "Apply the Pythagorean theorem to find the lengths of sides in right triangles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Geometry_SurfaceAreaAndVolume",
        "name_en": "Surface Area and Volume for Grade 9 Geometry",
        "name_sv": "Yta och Volym för Årskurs 9 Geometri",
        "name_is": "Yfirborðsvæði og rúmmál fyrir 9. bekk rúmfræði",
        "learning_objectives": "Calculate surface area and volume for various geometric shapes.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_PreCalculus_IntroToFunctions",
        "name_en": "Introduction to Functions for Grade 9 Pre-Calculus",
        "name_sv": "Introduktion till Funktioner för Årskurs 9 Förberäkningskurs",
        "name_is": "Inngangur að föllum fyrir 9. bekk fæðingarkúrs",
        "learning_objectives": "Establish an understanding of different types of functions and their graphical representations.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_PreCalculus_TrigonometryBasics",
        "name_en": "Basic Trigonometry for Grade 9 Pre-Calculus",
        "name_sv": "Grundläggande Trigonometri för Årskurs 9 Förberäkningskurs",
        "name_is": "Grunnlögð trigonometrlíkan fyrir 9. bekk Akademískt",
        "learning_objectives": "Understand basic trigonometric ratios and the unit circle.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_PreCalculus_Limits",
        "name_en": "Introduction to Limits for Grade 9 Pre-Calculus",
        "name_sv": "Introduktion till Gränser för Årskurs 9 Förberäkningskurs",
        "name_is": "Inngangur að mörkum fyrir 9. bekk Akademískt",
        "learning_objectives": "Understand the concept of limits and their importance in calculus.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Physics_NewtonsLaws",
        "name_en": "Newton's Laws for Grade 9 Physics",
        "name_sv": "Newtons Lag för Årskurs 9 Fysik",
        "name_is": "Newton's lögmál fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Understand and apply Newton's laws of motion to analyze the forces acting on an object.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Physics_ConservationOfEnergy",
        "name_en": "Conservation of Energy for Grade 9 Physics",
        "name_sv": "Energi Bevarande för Årskurs 9 Fysik",
        "name_is": "Energie varðveisla fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Explore the principle of conservation of energy in various systems.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Physics_WavesAndSound",
        "name_en": "Waves and Sound for Grade 9 Physics",
        "name_sv": "Vågor och Ljud för Årskurs 9 Fysik",
        "name_is": "Bylgjur og hljóð fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Understand the properties of waves and sound.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Physics_ElectricalCircuits",
        "name_en": "Electrical Circuits for Grade 9 Physics",
        "name_sv": "Elektriska Kretsar för Årskurs 9 Fysik",
        "name_is": "Rafmagnslausnir fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Investigate electrical circuits and their components.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Chemistry_AtomsAndMolecules",
        "name_en": "Atoms and Molecules for Grade 9 Chemistry",
        "name_sv": "Atomer och Molekyler för Årskurs 9 Kemi",
        "name_is": "Atómur og sameindir fyrir 9. bekk efnafræði",
        "learning_objectives": "Understand the structure of atoms and the formation of molecules.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Grade 9 Chemistry",
        "name_sv": "Kemiska Reaktioner för Årskurs 9 Kemi",
        "name_is": "Efnafræðilegar viðbrögð fyrir 9. bekk efnafræði",
        "learning_objectives": "Explore types of chemical reactions and their characteristics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Chemistry_StatesOfMatter",
        "name_en": "States of Matter for Grade 9 Chemistry",
        "name_sv": "Materiens Tillstånd för Årskurs 9 Kemi",
        "name_is": "Hagtölu efnis fyrir 9. bekk efnafræði",
        "learning_objectives": "Understand the different states of matter and changes in phase.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Chemistry_Stoichiometry",
        "name_en": "Stoichiometry for Grade 9 Chemistry",
        "name_sv": "Stökiometri för Årskurs 9 Kemi",
        "name_is": "Stökiometría fyrir 9. bekk efnafræði",
        "learning_objectives": "Begin to understand stoichiometry and its applications in quantitative chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Biology_CellStructure",
        "name_en": "Cell Structure for Grade 9 Biology",
        "name_sv": "Cellstruktur för Årskurs 9 Biologi",
        "name_is": "Frumufræði fyrir 9. bekk líffræði",
        "learning_objectives": "Explore the components of cells and their functions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Biology_ClassificationOfLife",
        "name_en": "Classification of Life for Grade 9 Biology",
        "name_sv": "Klassificering av Liv för Årskurs 9 Biologi",
        "name_is": "Flokkun lífs fyrir 9. bekk líffræði",
        "learning_objectives": "Learn to classify organisms and understand taxonomy.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Biology_Ecosystems",
        "name_en": "Ecosystems for Grade 9 Biology",
        "name_sv": "Ekosystem för Årskurs 9 Biologi",
        "name_is": "Vistkerfi fyrir 9. bekk líffræði",
        "learning_objectives": "Understand the interactions within ecosystems and the flow of energy.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Biology_Genetics",
        "name_en": "Introduction to Genetics for Grade 9 Biology",
        "name_sv": "Introduktion till Genetik för Årskurs 9 Biologi",
        "name_is": "Inngangur að genatíkinni fyrir 9. bekk líffræði",
        "learning_objectives": "Explore basic principles of genetics and heredity.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_EnglishLanguage_WritingSkills",
        "name_en": "Writing Skills for Grade 9 English Language",
        "name_sv": "Skrivfärdigheter för Årskurs 9 Engelska",
        "name_is": "Skriftræning fyrir 9. bekk enska",
        "learning_objectives": "Develop effective writing skills including organization, structure, and clarity.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_EnglishLanguage_VocabularyDevelopment",
        "name_en": "Vocabulary Development for Grade 9 English Language",
        "name_sv": "Ordförrådsutveckling för Årskurs 9 Engelska",
        "name_is": "Orðaforði þróun fyrir 9. bekk enska",
        "learning_objectives": "Expand vocabulary through reading and writing exercises.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_EnglishLanguage_LiteraryAnalysis",
        "name_en": "Literary Analysis for Grade 9 English Language",
        "name_sv": "Litterär Analys för Årskurs 9 Engelska",
        "name_is": "Bókmennta greining fyrir 9. bekk enska",
        "learning_objectives": "Develop skills to analyze literature critically.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_EnglishLanguage_SpeakingAndListening",
        "name_en": "Speaking and Listening Skills for Grade 9 English Language",
        "name_sv": "Tal- och Lyssningsförmåga för Årskurs 9 Engelska",
        "name_is": "Tala og hlusta færni fyrir 9. bekk enska",
        "learning_objectives": "Enhance speaking and listening skills through discussion and presentations.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_USHistory_Colonization",
        "name_en": "Colonization for Grade 9 US History",
        "name_sv": "Kolonisering för Årskurs 9 US Historia",
        "name_is": "Kolonisering fyrir 9. bekk bandaríska sögu",
        "learning_objectives": "Study the impact of colonization on Native American societies.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_USHistory_Independence",
        "name_en": "Independence for Grade 9 US History",
        "name_sv": "Oberoende för Årskurs 9 US Historia",
        "name_is": "Sjálfstæði fyrir 9. bekk bandaríska sögu",
        "learning_objectives": "Analyze the causes and effects of the American Revolution.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_USHistory_AmericanRevolution",
        "name_en": "The American Revolution for Grade 9 US History",
        "name_sv": "Den Amerikanska Revolutionen för Årskurs 9 US Historia",
        "name_is": "Bandaríska byltingin fyrir 9. bekk bandaríska sögu",
        "learning_objectives": "Understand the key events and figures of the American Revolution.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_USHistory_Constitution",
        "name_en": "The Constitution for Grade 9 US History",
        "name_sv": "Konstitutionen för Årskurs 9 US Historia",
        "name_is": "Stjórnarskráin fyrir 9. bekk bandaríska sögu",
        "learning_objectives": "Explore the principles and significance of the US Constitution.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Spanish_BasicVocabulary",
        "name_en": "Basic Vocabulary for Grade 9 Spanish",
        "name_sv": "Grundläggande Ordförråd för Årskurs 9 Spanska",
        "name_is": "Grunnvocabulary fyrir 9. bekk spænsku",
        "learning_objectives": "Learn and apply basic vocabulary in Spanish for effective communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Spanish_ConversationalSkills",
        "name_en": "Conversational Skills for Grade 9 Spanish",
        "name_sv": "Konversationsfärdigheter för Årskurs 9 Spanska",
        "name_is": "Samtalsfærdar fyrir 9. bekk spænsku",
        "learning_objectives": "Develop conversational skills in Spanish to engage in basic dialogues.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 9",
        "id": "topic_American_Grade9_Spanish_VerbsAndGrammar",
        "name_en": "Verbs and Grammar for Grade 9 Spanish",
        "name_sv": "Verb och Grammatik för Årskurs 9 Spanska",
        "name_is": "Sagnir og málfræði fyrir 9. bekk spænsku",
        "learning_objectives": "Understand the basics of Spanish verbs and sentence structure.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade9.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_AlgebraI_LinearEquations",
        "name_en": "Linear Equations for Grade 10 Algebra I",
        "name_sv": "Linjära Ekvationer för Årskurs 10 Algebra I",
        "name_is": "Línulegar jöfnu fyrir 10. bekk Algebra I",
        "learning_objectives": "Solve linear equations and understand the relationships between variables to meet the requirements for passing Grade 10 Algebra I.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_AlgebraI_Factoring",
        "name_en": "Factoring Polynomials for Grade 10 Algebra I",
        "name_sv": "Faktorisera Polynom för Årskurs 10 Algebra I",
        "name_is": "Faktorisering á pólýnomum fyrir 10. bekk Algebra I",
        "learning_objectives": "Understand and apply factoring techniques on polynomials to prepare for more advanced algebraic concepts.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_AlgebraI_QuadraticEquations",
        "name_en": "Quadratic Equations for Grade 10 Algebra I",
        "name_sv": "Kvadratiska Ekvationer för Årskurs 10 Algebra I",
        "name_is": "Ferlinur jöfnu fyrir 10. bekk Algebra I",
        "learning_objectives": "Solve and graph quadratic equations to understand the properties of quadratic functions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Geometry_TriangleProperties",
        "name_en": "Triangle Properties for Grade 10 Geometry",
        "name_sv": "Trianglars Egenskaper för Årskurs 10 Geometri",
        "name_is": "Eiginleikar þríhyrninga fyrir 10. bekk rúmfræði",
        "learning_objectives": "Learn about the properties of triangles, including congruence and similarity.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Geometry_Circles",
        "name_en": "Circles and Their Properties for Grade 10 Geometry",
        "name_sv": "Cirklar och Deras Egenskaper för Årskurs 10 Geometri",
        "name_is": "Hringir og þeirra eiginleikar fyrir 10. bekk rúmfræði",
        "learning_objectives": "Understand the properties of circles and apply theorems involving angles and arcs.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.geometry",
            "interests.geometry",
            "generalInterests.geometry",
            "generalInterests.math"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_AgebraII_Functions",
        "name_en": "Functions for Grade 10 Algebra II",
        "name_sv": "Funktioner för Årskurs 10 Algebra II",
        "name_is": "Föllur fyrir 10. bekk Algebra II",
        "learning_objectives": "Explore types of functions (linear, quadratic, exponential) and their characteristics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.algebra2",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_PreCalculus_SequencesAndSeries",
        "name_en": "Sequences and Series for Grade 10 Pre-Calculus",
        "name_sv": "Sekvenser och Serier för Årskurs 10 Förberäkningskurs",
        "name_is": "Röð og raðir fyrir 10. bekk fyrir rannsóknum",
        "learning_objectives": "Understand arithmetic and geometric sequences and series and their uses.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_PreCalculus_Trigonometry",
        "name_en": "Introduction to Trigonometry for Grade 10 Pre-Calculus",
        "name_sv": "Introduktion till Trigonometri för Årskurs 10 Förberäkningskurs",
        "name_is": "Inngangur að trigonometri fyrir 10. bekk fyrir rannsóknum",
        "learning_objectives": "Explore basic trigonometric functions and their applications in solving triangles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Physics_Kinematics",
        "name_en": "Kinematics for Grade 10 Physics",
        "name_sv": "Kinematik för Årskurs 10 Fysik",
        "name_is": "Hreyfifræði fyrir 10. bekk eðlisfræði",
        "learning_objectives": "Understand the motion of objects, including speed, velocity, and acceleration.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Physics_EnergyAndWork",
        "name_en": "Energy and Work for Grade 10 Physics",
        "name_sv": "Energi och Arbete för Årskurs 10 Fysik",
        "name_is": "Orka og vinnu fyrir 10. bekk eðlisfræði",
        "learning_objectives": "Explore the concepts of energy and work, including conservation and transformation.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Physics_Waves",
        "name_en": "Waves for Grade 10 Physics",
        "name_sv": "Vågor för Årskurs 10 Fysik",
        "name_is": "Bylgjur fyrir 10. bekk eðlisfræði",
        "learning_objectives": "Understand the characteristics of waves, including frequency, wavelength, and amplitude.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Chemistry_MatterAndChange",
        "name_en": "Matter and Change for Grade 10 Chemistry",
        "name_sv": "Materie och Förändring för Årskurs 10 Kemi",
        "name_is": "Efni og breytingar fyrir 10. bekk efnafræði",
        "learning_objectives": "Examine the different states of matter and changes they undergo.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Grade 10 Chemistry",
        "name_sv": "Kemiska Reaktioner för Årskurs 10 Kemi",
        "name_is": "Efnafræðilegar viðbrögð fyrir 10. bekk efnafræði",
        "learning_objectives": "Explore the types and processes of chemical reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Chemistry_Stoichiometry",
        "name_en": "Stoichiometry for Grade 10 Chemistry",
        "name_sv": "Stökiometri för Årskurs 10 Kemi",
        "name_is": "Stökiometría fyrir 10. bekk efnafræði",
        "learning_objectives": "Understand stoichiometry and its applications in chemical reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Biology_CellStructure",
        "name_en": "Cell Structure for Grade 10 Biology",
        "name_sv": "Cellstruktur för Årskurs 10 Biologi",
        "name_is": "Frumufræði fyrir 10. bekk líffræði",
        "learning_objectives": "Understand the basic structure and function of different cell types.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Biology_Genetics",
        "name_en": "Genetics for Grade 10 Biology",
        "name_sv": "Genetik för Årskurs 10 Biologi",
        "name_is": "Genetik fyrir 10. bekk líffræði",
        "learning_objectives": "Explore the principles of heredity and genetic variation.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Biology_Ecology",
        "name_en": "Ecology for Grade 10 Biology",
        "name_sv": "Ekologi för Årskurs 10 Biologi",
        "name_is": "Ráðstefna fyrir 10. bekk líffræði",
        "learning_objectives": "Understand the interactions between organisms and their environments.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_EnglishLanguage_Writing",
        "name_en": "Writing Techniques for Grade 10 English Language",
        "name_sv": "Skrivteknik för Årskurs 10 Engelska",
        "name_is": "Skriftartækni fyrir 10. bekk enska",
        "learning_objectives": "Enhance writing skills and techniques for various styles and purposes.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_EnglishLanguage_LiteraryAnalysis",
        "name_en": "Literary Analysis for Grade 10 English Language",
        "name_sv": "Litterär Analys för Årskurs 10 Engelska",
        "name_is": "Bókmennta greining fyrir 10. bekk enska",
        "learning_objectives": "Analyze various texts for themes, literary devices, and author techniques.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.english_language",
            "interests.language",
            "generalInterests.language",
            "generalInterests.literature"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_EnglishLiterature_NarrativeWriting",
        "name_en": "Narrative Writing for Grade 10 English Literature",
        "name_sv": "Berättande Skrivning för Årskurs 10 Engelsk Litteratur",
        "name_is": "Frásagnarskrift fyrir 10. bekk enska bókmenntafræði",
        "learning_objectives": "Develop narrative writing skills through storytelling and character development.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.english_lit",
            "interests.language",
            "generalInterests.language",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_USHistory_CivilWar",
        "name_en": "Civil War Era for Grade 10 US History",
        "name_sv": "Inbördeskriget för Årskurs 10 US Historia",
        "name_is": "Félagsstríðsárin fyrir 10. bekk bandaríska sögu",
        "learning_objectives": "Study the causes, events, and consequences of the Civil War.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_USHistory_Reconstruction",
        "name_en": "Reconstruction Era for Grade 10 US History",
        "name_sv": "Rekonstruktion för Årskurs 10 US Historia",
        "name_is": "Enduruppbygging á tímabilinu fyrir 10. bekk bandaríska sögu",
        "learning_objectives": "Explore the social, economic, and political impacts of the Reconstruction era.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_WorldHistory_Renaissance",
        "name_en": "Renaissance for Grade 10 World History",
        "name_sv": "Renässansen för Årskurs 10 Världshistoria",
        "name_is": "Endurreisnin fyrir 10. bekk heimspeki",
        "learning_objectives": "Examine the cultural, artistic, and intellectual revival during the Renaissance.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.world_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Government_Constitution",
        "name_en": "US Constitution for Grade 10 Government",
        "name_sv": "US Konstitutionen för Årskurs 10 Regering",
        "name_is": "US stjórnarskráin fyrir 10. bekk ríkisstjórn",
        "learning_objectives": "Understand the structure, principles, and significance of the US Constitution.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.government",
            "interests.government",
            "generalInterests.government",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Economics_MicroEconomics",
        "name_en": "Microeconomics for Grade 10 Economics",
        "name_sv": "Mikroekonomi för Årskurs 10 Ekonomi",
        "name_is": "Mikroefnafræði fyrir 10. bekk efnahagsfræði",
        "learning_objectives": "Explore the concepts of supply and demand, and understand consumer behavior.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Economics_MacroEconomics",
        "name_en": "Macroeconomics for Grade 10 Economics",
        "name_sv": "Makroekonomi för Årskurs 10 Ekonomi",
        "name_is": "Makroefnafræði fyrir 10. bekk efnahagsfræði",
        "learning_objectives": "Understand the broader economic factors that affect markets and economies.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Spanish_ConversationalSkills",
        "name_en": "Conversational Skills for Grade 10 Spanish",
        "name_sv": "Konversationsfärdigheter för Årskurs 10 Spanska",
        "name_is": "Samtalsfærdar fyrir 10. bekk spænsku",
        "learning_objectives": "Develop conversational proficiency in Spanish through practice and exercises.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 10",
        "id": "topic_American_Grade10_Spanish_VocabularyAndGrammar",
        "name_en": "Vocabulary and Grammar for Grade 10 Spanish",
        "name_sv": "Ordförråd och Grammatik för Årskurs 10 Spanska",
        "name_is": "Orðaforði og málfræði fyrir 10. bekk spænsku",
        "learning_objectives": "Enhance vocabulary and grammar skills for effective written and verbal communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade10.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Math_AlgebraI",
        "name_en": "Algebra I Concepts for Grade 11",
        "name_sv": "Algebra I Koncept för Årskurs 11",
        "name_is": "Algebra I hugtök fyrir 11. bekk",
        "learning_objectives": "Reinforce foundational concepts of algebra including expressions, equations, and functions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Math_Geometry",
        "name_en": "Geometry Applications for Grade 11",
        "name_sv": "Geometri Tillämpningar för Årskurs 11",
        "name_is": "Geometrí Túlkanir fyrir 11. bekk",
        "learning_objectives": "Apply geometric principles to real-world problems and proof strategies.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.geometry",
            "interests.mathematics",
            "generalInterests.mathematics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Math_AlgebraII",
        "name_en": "Algebra II Functions for Grade 11",
        "name_sv": "Algebra II Funktioner för Årskurs 11",
        "name_is": "Algebra II föllur fyrir 11. bekk",
        "learning_objectives": "Explore polynomial, rational, exponential, and logarithmic functions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.algebra2",
            "interests.mathematics",
            "generalInterests.mathematics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Math_PreCalculus",
        "name_en": "Pre-Calculus Concepts for Grade 11",
        "name_sv": "Förberäkningskurs Koncept för Årskurs 11",
        "name_is": "Fyrirfæðingar ansi fyrir 11. bekk",
        "learning_objectives": "Prepare for calculus by studying vector functions and trigonometric identities.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.mathematics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Math_Calculus",
        "name_en": "Introduction to Calculus for Grade 11",
        "name_sv": "Introduktion till Kalkyl för Årskurs 11",
        "name_is": "Inngangur að kalkúli fyrir 11. bekk",
        "learning_objectives": "Understand limits, continuity, and derivatives to prepare for AP Calculus.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.calculus",
            "interests.mathematics",
            "generalInterests.mathematics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Physics_NewtonsLaws",
        "name_en": "Newton's Laws for Grade 11 Physics",
        "name_sv": "Newtons Lag för Årskurs 11 Fysik",
        "name_is": "Newton's lögmál fyrir 11. bekk eðlisfræði",
        "learning_objectives": "Apply Newton's laws of motion to solve problems involving force and motion.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Physics_SimpleHarmonicMotion",
        "name_en": "Simple Harmonic Motion for Grade 11 Physics",
        "name_sv": "Enkel Harmonisk Rörelse för Årskurs 11 Fysik",
        "name_is": "Einfalt hagnýta hreyfing fyrir 11. bekk eðlisfræði",
        "learning_objectives": "Explore concepts of oscillation and wave properties in physical systems.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Physics_EnergyConservation",
        "name_en": "Conservation of Energy for Grade 11 Physics",
        "name_sv": "Energi Bevarande för Årskurs 11 Fysik",
        "name_is": "Orku varðveisla fyrir 11. bekk eðlisfræði",
        "learning_objectives": "Understand the principle of conservation of energy in mechanical systems.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Physics_Waves",
        "name_en": "Waves for Grade 11 Physics",
        "name_sv": "Vågor för Årskurs 11 Fysik",
        "name_is": "Bylgjur fyrir 11. bekk eðlisfræði",
        "learning_objectives": "Analyze wave properties including frequency, wavelength, and amplitude.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Chemistry_AtomicStructure",
        "name_en": "Atomic Structure for Grade 11 Chemistry",
        "name_sv": "Atomstruktur för Årskurs 11 Kemi",
        "name_is": "Atóm 구조 fyrir 11. bekk efnafræði",
        "learning_objectives": "Understand atomic structure and the arrangement of electrons in atoms.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Chemistry_ChemicalBonding",
        "name_en": "Chemical Bonding for Grade 11 Chemistry",
        "name_sv": "Kemiska Bindningar för Årskurs 11 Kemi",
        "name_is": "Efnafræðilegar tengingar fyrir 11. bekk efnafræði",
        "learning_objectives": "Explore different types of chemical bonds and their properties.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Chemistry_ChemicalReactions",
        "name_en": "Types of Chemical Reactions for Grade 11 Chemistry",
        "name_sv": "Typer av Kemiska Reaktioner för Årskurs 11 Kemi",
        "name_is": "Tegundir efnafræðilegra viðbragða fyrir 11. bekk efnafræði",
        "learning_objectives": "Identify and classify various types of chemical reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Chemistry_Stoichiometry",
        "name_en": "Stoichiometry for Grade 11 Chemistry",
        "name_sv": "Stökiometri för Årskurs 11 Kemi",
        "name_is": "Stökiometría fyrir 11. bekk efnafræði",
        "learning_objectives": "Apply stoichiometric principles to solve problems in chemical reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Biology_CellBiology",
        "name_en": "Cell Biology for Grade 11 Biology",
        "name_sv": "Cellbiologi för Årskurs 11 Biologi",
        "name_is": "Frumufræði fyrir 11. bekk líffræði",
        "learning_objectives": "Examine the structure and function of cell organelles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Biology_Genetics",
        "name_en": "Genetics for Grade 11 Biology",
        "name_sv": "Genetik för Årskurs 11 Biologi",
        "name_is": "Genetik fyrir 11. bekk líffræði",
        "learning_objectives": "Explore the principles of inheritance and genetic variation.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Biology_Evolution",
        "name_en": "Evolution for Grade 11 Biology",
        "name_sv": "Evolution för Årskurs 11 Biologi",
        "name_is": "Evolútion fyrir 11. bekk líffræði",
        "learning_objectives": "Understand the theory of evolution and natural selection.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Biology_Ecosystems",
        "name_en": "Ecosystems for Grade 11 Biology",
        "name_sv": "Ekosystem för Årskurs 11 Biologi",
        "name_is": "Vistkerfi fyrir 11. bekk líffræði",
        "learning_objectives": "Analyze the interactions within ecosystems and the impact of human activity.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_EnglishLiterature_LiteraryAnalysis",
        "name_en": "Literary Analysis for Grade 11 English Literature",
        "name_sv": "Litterär Analys för Årskurs 11 Engelsk Litteratur",
        "name_is": "Bókmennta greining fyrir 11. bekk enska bókmenntafræði",
        "learning_objectives": "Teach students to analyze themes, characters, and structure in literature.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.english_lit",
            "interests.literature",
            "generalInterests.literature"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_EnglishLiterature_WritingSkills",
        "name_en": "Writing Skills for Grade 11 English Literature",
        "name_sv": "Skrivfärdigheter för Årskurs 11 Engelsk Litteratur",
        "name_is": "Skriftræning fyrir 11. bekk enska bókmenntafræði",
        "learning_objectives": "Refine persuasive and narrative writing techniques.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.english_lit",
            "interests.writing",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_EnglishLiterature_SpeakingSkills",
        "name_en": "Speaking Skills for Grade 11 English Literature",
        "name_sv": "Talarfärdigheter för Årskurs 11 Engelsk Litteratur",
        "name_is": "Tala færni fyrir 11. bekk enska bókmenntafræði",
        "learning_objectives": "Develop effective public speaking and presentation skills.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.english_lit",
            "interests.speaking",
            "generalInterests.communication"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_USHistory_ModernAmerica",
        "name_en": "Modern America for Grade 11 US History",
        "name_sv": "Modern Amerika för Årskurs 11 US Historia",
        "name_is": "Núverandi Amerika fyrir 11. bekk bandaríska sögu",
        "learning_objectives": "Analyze the major events and social changes in contemporary America.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_USHistory_CivilRights",
        "name_en": "Civil Rights Movement for Grade 11 US History",
        "name_sv": "Medborgarrättsrörelsen för Årskurs 11 US Historia",
        "name_is": "Réttindahreyfingin fyrir 11. bekk bandaríska sögu",
        "learning_objectives": "Study the Civil Rights Movement and its impact on American society.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_USHistory_20thCentury",
        "name_en": "20th Century America for Grade 11 US History",
        "name_sv": "20-talet Amerika för Årskurs 11 US Historia",
        "name_is": "20. öld Amerika fyrir 11. bekk bandaríska sögu",
        "learning_objectives": "Examine key events and movements in 20th century America.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_APUSH_Colonization",
        "name_en": "American Colonization for Grade 11 AP US History",
        "name_sv": "Amerikansk Kolonisering för Årskurs 11 AP US Historia",
        "name_is": "Bandarísk kolonisering fyrir 11. bekk AP bandaríska sögu",
        "learning_objectives": "Analyze the processes and impacts of colonization on American history.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.ap_us_history",
            "interests.history",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Government_Constitution",
        "name_en": "US Constitution for Grade 11 Government",
        "name_sv": "US Konstitution för Årskurs 11 Regering",
        "name_is": "US stjórnarskráin fyrir 11. bekk ríkisstjórn",
        "learning_objectives": "Study the principles outlined in the US Constitution and its amendments.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.government",
            "interests.government",
            "generalInterests.government"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Government_RolesOfGovernment",
        "name_en": "Roles of Government for Grade 11 Government",
        "name_sv": "Regeringens Roller för Årskurs 11 Regering",
        "name_is": "Hlutverk ríkisstjórnar fyrir 11. bekk ríkisstjórn",
        "learning_objectives": "Explore the functions and responsibilities of local, state, and federal government.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.government",
            "interests.government",
            "generalInterests.government"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Economics_Basics",
        "name_en": "Economic Basics for Grade 11 Economics",
        "name_sv": "Ekonomiska Grunder för Årskurs 11 Ekonomi",
        "name_is": "Efnahags grunntækni fyrir 11. bekk efnahagsfræði",
        "learning_objectives": "Understand basic economic concepts such as scarcity, supply and demand.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Economics_MarketTypes",
        "name_en": "Types of Markets for Grade 11 Economics",
        "name_sv": "Typer av Marknader för Årskurs 11 Ekonomi",
        "name_is": "Tegundir markaða fyrir 11. bekk efnahagsfræði",
        "learning_objectives": "Analyze different market structures and their implications.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Economics_Trade",
        "name_en": "Trade and Economics for Grade 11 Economics",
        "name_sv": "Handel och Ekonomi för Årskurs 11 Ekonomi",
        "name_is": "Viðskipti og efnahagsfræði fyrir 11. bekk efnahagsfræði",
        "learning_objectives": "Study the effects of trade on economic growth and development.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Spanish_BasicVocabulary",
        "name_en": "Basic Vocabulary for Grade 11 Spanish",
        "name_sv": "Grundläggande Ordförråd för Årskurs 11 Spanska",
        "name_is": "Grunnvocabulary fyrir 11. bekk spænsku",
        "learning_objectives": "Learn essential vocabulary in Spanish for daily communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 11",
        "id": "topic_American_Grade11_Spanish_ConversationalSkills",
        "name_en": "Conversational Skills for Grade 11 Spanish",
        "name_sv": "Konversationsfärdigheter för Årskurs 11 Spanska",
        "learning_objectives": "Develop conversational fluency in Spanish through dialogues and discussions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade11.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_AlgebraI_LinearEquations",
        "name_en": "Linear Equations for Grade 12 Algebra I",
        "name_sv": "Linjära Ekvationer för Årskurs 12 Algebra I",
        "name_is": "Línulegar jöfnu fyrir 12. bekk Algebra I",
        "learning_objectives": "Solve and graph linear equations in one and two variables.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_AlgebraI_Functions",
        "name_en": "Functions for Grade 12 Algebra I",
        "name_sv": "Funktioner för Årskurs 12 Algebra I",
        "name_is": "Föllur fyrir 12. bekk Algebra I",
        "learning_objectives": "Understand and apply different types of functions, including quadratic and exponential.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.algebra1",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.algebra"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Geometry_TriangleProperties",
        "name_en": "Triangle Properties for Grade 12 Geometry",
        "name_sv": "Triangel Egenskaper för Årskurs 12 Geometri",
        "name_is": "Eiginleikar þríhyrninga fyrir 12. bekk rúmfræði",
        "learning_objectives": "Explore properties and theorems related to triangles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.geometry",
            "interests.mathematics",
            "generalInterests.geometry",
            "generalInterests.trigonometry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Geometry_Circles",
        "name_en": "Circles and Their Properties for Grade 12 Geometry",
        "name_sv": "Cirklar och Deras Egenskaper för Årskurs 12 Geometri",
        "name_is": "Hringir og þeirra eiginleikar fyrir 12. bekk rúmfræði",
        "learning_objectives": "Understand the properties of circles including tangents and arcs.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.geometry",
            "interests.mathematics",
            "generalInterests.geometry",
            "generalInterests.trigonometry"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_AlgebraII_Polynomials",
        "name_en": "Polynomials for Grade 12 Algebra II",
        "name_sv": "Polynom för Årskurs 12 Algebra II",
        "name_is": "Pólýnom fyrir 12. bekk Algebra II",
        "learning_objectives": "Analyze and perform operations with polynomials.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.algebra2",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.polynomials"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_AlgebraII_ComplexNumbers",
        "name_en": "Complex Numbers for Grade 12 Algebra II",
        "name_sv": "Komplexa Tal för Årskurs 12 Algebra II",
        "name_is": "Flókin tölur fyrir 12. bekk Algebra II",
        "learning_objectives": "Perform operations with complex numbers and understand their applications.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.algebra2",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.complex_numbers"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_PreCalculus_TrigonometricFunctions",
        "name_en": "Trigonometric Functions for Grade 12 Pre-Calculus",
        "name_sv": "Trigonometriska Funktioner för Årskurs 12 Förberäkningskurs",
        "name_is": "Trigonometrlíkan fyrir 12. bekk fæðingarkúrs",
        "learning_objectives": "Analyze and apply trigonometric functions and identities.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.trigonometry",
            "generalInterests.pre_calculus"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_PreCalculus_SequencesAndSeries",
        "name_en": "Sequences and Series for Grade 12 Pre-Calculus",
        "name_sv": "Sekvenser och Serier för Årskurs 12 Förberäkningskurs",
        "name_is": "Röð og raðir fyrir 12. bekk fyrir rannsóknum",
        "learning_objectives": "Understand the concepts of sequences and series, including arithmetic and geometric.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.pre_calculus",
            "interests.mathematics",
            "generalInterests.pre_calculus",
            "generalInterests.sequences"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Calculus_Derivatives",
        "name_en": "Derivatives for Grade 12 Calculus",
        "name_sv": "Derivator för Årskurs 12 Kalkyl",
        "name_is": "Derivata fyrir 12. bekk kalkúl",
        "learning_objectives": "Understand the concept of derivatives and find derivatives of functions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.calculus",
            "interests.mathematics",
            "generalInterests.calculus",
            "generalInterests.differentiation"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Calculus_Integrals",
        "name_en": "Integrals for Grade 12 Calculus",
        "name_sv": "Integraler för Årskurs 12 Kalkyl",
        "name_is": "Samlag fyrir 12. bekk kalkúl",
        "learning_objectives": "Understand the fundamental theorem of calculus and solve definite and indefinite integrals.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.calculus",
            "interests.mathematics",
            "generalInterests.calculus",
            "generalInterests.integration"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APCalculusAB_AdvancedDerivatives",
        "name_en": "Advanced Derivatives for Grade 12 AP Calculus AB",
        "name_sv": "Avancerade Derivator för Årskurs 12 AP Kalkyl AB",
        "name_is": "Áreita Derivata fyrir 12. bekk AP kalkúl AB",
        "learning_objectives": "Explore advanced rules for finding derivatives and apply them to solve complex problems.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_calc_ab",
            "interests.mathematics",
            "generalInterests.calculus",
            "generalInterests.differentiation"
        ]
    },
    {
        "curriculum": "American",
        "field": "Mathematics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APCalculusAB_AreaUnderCurves",
        "name_en": "Area Under Curves for Grade 12 AP Calculus AB",
        "name_sv": "Area under Kurer för Årskurs 12 AP Kalkyl AB",
        "name_is": "Svæði undir kúrvum fyrir 12. bekk AP kalkúl AB",
        "learning_objectives": "Calculate the area under curves using integration techniques.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_calc_ab",
            "interests.mathematics",
            "generalInterests.calculus",
            "generalInterests.integration"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Physics_NewtonsLaws",
        "name_en": "Newton's Laws for Grade 12 Physics",
        "name_sv": "Newtons Lag för Årskurs 12 Fysik",
        "name_is": "Newton's lögmál fyrir 12. bekk eðlisfræði",
        "learning_objectives": "Apply Newton's laws of motion to solve physics problems.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Physics_EnergyConservation",
        "name_en": "Conservation of Energy for Grade 12 Physics",
        "name_sv": "Energi Bevarande för Årskurs 12 Fysik",
        "name_is": "Orku varðveisla fyrir 12. bekk eðlisfræði",
        "learning_objectives": "Discuss the conservation of energy principle and apply it to real-life scenarios.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Physics_WaveProperties",
        "name_en": "Properties of Waves for Grade 12 Physics",
        "name_sv": "Vågens Egenskaper för Årskurs 12 Fysik",
        "name_is": "Eiginleikar bylgja fyrir 12. bekk eðlisfræði",
        "learning_objectives": "Explore the properties of waves, including frequency, wavelength, and amplitude.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Chemistry_ReactionTypes",
        "name_en": "Types of Chemical Reactions for Grade 12 Chemistry",
        "name_sv": "Typer av Kemiska Reaktioner för Årskurs 12 Kemi",
        "name_is": "Tegundir efnafræðilegra viðbragða fyrir 12. bekk efnafræði",
        "learning_objectives": "Classify and predict products of chemical reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Chemistry_Stoichiometry",
        "name_en": "Stoichiometry for Grade 12 Chemistry",
        "name_sv": "Stökiometri för Årskurs 12 Kemi",
        "name_is": "Stökiometría fyrir 12. bekk efnafræði",
        "learning_objectives": "Apply stoichiometric principles to calculate reactants and products.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Chemistry_AcidBaseReactions",
        "name_en": "Acid-Base Reactions for Grade 12 Chemistry",
        "name_sv": "Syra-Bas Reaktioner för Årskurs 12 Kemi",
        "name_is": "Sýru-grundar viðbragð fyrir 12. bekk efnafræði",
        "learning_objectives": "Explore the concepts of acids, bases, and their neutralization reactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APBiology_CellStructure",
        "name_en": "Cell Structure for Grade 12 AP Biology",
        "name_sv": "Cellstruktur för Årskurs 12 AP Biologi",
        "name_is": "Frumufræði fyrir 12. bekk AP líffræði",
        "learning_objectives": "Analyze the structure and function of various cell organelles.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APBiology_Genetics",
        "name_en": "Genetics for Grade 12 AP Biology",
        "name_sv": "Genetik för Årskurs 12 AP Biologi",
        "name_is": "Genetik fyrir 12. bekk AP líffræði",
        "learning_objectives": "Understand the principles of heredity and genetic variations.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Science",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APBiology_Evolution",
        "name_en": "Evolution for Grade 12 AP Biology",
        "name_sv": "Evolution för Årskurs 12 AP Biologi",
        "name_is": "Evolútion fyrir 12. bekk AP líffræði",
        "learning_objectives": "Explore the principles of evolution and natural selection.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_EnglishLiterature_LiteraryTerms",
        "name_en": "Literary Terms for Grade 12 English Literature",
        "name_sv": "Litterära Termen för Årskurs 12 Engelsk Litteratur",
        "name_is": "Bókmennta hugtök fyrir 12. bekk enska bókmenntafræði",
        "learning_objectives": "Identify and analyze key literary terms and devices.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.english_lit",
            "interests.literature",
            "generalInterests.literature",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_EnglishLiterature_Analysis",
        "name_en": "Critical Analysis for Grade 12 English Literature",
        "name_sv": "Kritisk Analys för Årskurs 12 Engelsk Litteratur",
        "name_is": "Kritisk greining fyrir 12. bekk enska bókmenntafræði",
        "learning_objectives": "Analyze and interpret various literary works critically.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.english_lit",
            "interests.literature",
            "generalInterests.literature",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language Arts",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APEnglishLiterature_AppliedAnalysis",
        "name_en": "Applied Literary Analysis for Grade 12 AP English Literature",
        "name_sv": "Tillämpad Litterär Analys för Årskurs 12 AP Engelsk Litteratur",
        "name_is": "Tilmæmt bókmenntagreining fyrir 12. bekk AP enska bókmenntafræði",
        "learning_objectives": "Apply advanced analytical concepts to several major works of literature.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_english_lit",
            "interests.literature",
            "generalInterests.literature",
            "generalInterests.writing"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_USHistory_ModernEra",
        "name_en": "Modern Era for Grade 12 US History",
        "name_sv": "Modern Tid för Årskurs 12 US Historia",
        "name_is": "Núverandi tímaskeið fyrir 12. bekk bandaríska sögu",
        "learning_objectives": "Examine the critical events and developments in the modern era of American history.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_USHistory_CivilRights",
        "name_en": "Civil Rights Movement for Grade 12 US History",
        "name_sv": "Borgarättsrörelsen för Årskurs 12 US Historia",
        "name_is": "Réttindahreyfingin fyrir 12. bekk bandaríska sögu",
        "learning_objectives": "Explore the key events and figures of the Civil Rights Movement.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "History",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APUSH_GlobalInfluence",
        "name_en": "Global Influence for Grade 12 AP US History",
        "name_sv": "Global Påverkan för Årskurs 12 AP US Historia",
        "name_is": "Alþjóðleg áhrif fyrir 12. bekk AP bandaríska sögu",
        "learning_objectives": "Analyze the global implications of American history and how it shapes world events.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_us_history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Government_CivicResponsibility",
        "name_en": "Civic Responsibility for Grade 12 Government",
        "name_sv": "Civilt Ansvar för Årskurs 12 Regering",
        "name_is": "Sveitarfærni fyrir 12. bekk ríkisstjórn",
        "learning_objectives": "Understand the role of citizenship and civic engagement in a democratic society.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.government",
            "interests.government",
            "generalInterests.government",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Government_Constitution",
        "name_en": "The Constitution for Grade 12 Government",
        "name_sv": "Konstitutionen för Årskurs 12 Regering",
        "name_is": "Stjórnarskráin fyrir 12. bekk ríkisstjórn",
        "learning_objectives": "Examine the principles and structure of the US Constitution.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.government",
            "interests.government",
            "generalInterests.government",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Government",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APGovernment_AnalyzingGovernment",
        "name_en": "Analyzing Government for Grade 12 AP Government",
        "name_sv": "Analysera Regering för Årskurs 12 AP Regering",
        "name_is": "Greina ríkisstjórn fyrir 12. bekk AP ríkisstjórn",
        "learning_objectives": "Apply analytical skills to evaluate government institutions and their impact.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_government",
            "interests.government",
            "generalInterests.government",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Economics_MacroEconomics",
        "name_en": "Macro Economics for Grade 12 Economics",
        "name_sv": "Makroekonomi för Årskurs 12 Ekonomi",
        "name_is": "Makroefnafræði fyrir 12. bekk efnahagsfræði",
        "learning_objectives": "Examine macroeconomic principles and the global economy.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Economics_MicroEconomics",
        "name_en": "Micro Economics for Grade 12 Economics",
        "name_sv": "Mikroekonomi för Årskurs 12 Ekonomi",
        "name_is": "Mikroefnafræði fyrir 12. bekk efnahagsfræði",
        "learning_objectives": "Understand microeconomic concepts including supply and demand.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Economics",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Economics_GlobalEconomy",
        "name_en": "Global Economy for Grade 12 Economics",
        "name_sv": "Global Ekonomi för Årskurs 12 Ekonomi",
        "name_is": "Alþjóðleg efnahagsfræði fyrir 12. bekk efnahagsfræði",
        "learning_objectives": "Examine the interconnectedness of nations in the global economy.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.social_studies"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Spanish_ConversationalSkills",
        "name_en": "Conversational Skills for Grade 12 Spanish",
        "name_sv": "Konversationsfärdigheter för Årskurs 12 Spanska",
        "name_is": "Samtalsfærdar fyrir 12. bekk spænsku",
        "learning_objectives": "Enhance skills in spoken Spanish to facilitate conversations.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_Spanish_Culture",
        "name_en": "Spanish Culture for Grade 12 Spanish",
        "name_sv": "Spansk Kultur för Årskurs 12 Spanska",
        "name_is": "Spænsk menning fyrir 12. bekk spænsku",
        "learning_objectives": "Explore cultural aspects of Spanish-speaking countries.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    },
    {
        "curriculum": "American",
        "field": "Language",
        "subject": "Grade 12",
        "id": "topic_American_Grade12_APSpanish_LanguageSkills",
        "name_en": "Language Skills for Grade 12 AP Spanish",
        "name_sv": "Språkfärdigheter för Årskurs 12 AP Spanska",
        "name_is": "Tungumál færni fyrir 12. bekk AP spænsku",
        "learning_objectives": "Develop advanced Spanish language skills for fluency.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.american.schoolYear.grade12.schoolSubjects.ap_spanish",
            "interests.language",
            "generalInterests.language",
            "generalInterests.spanish"
        ]
    }
]