import React, {useState} from 'react';
import { Typography, Card, CardContent, Box, Button, Tooltip,  IconButton,
  Snackbar,
  Alert, } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import Link from 'next/link';
import Head from 'next/head';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { deleteArticle } from '@studygenius/shared/Requests';
import { PrimaryButton,  OutlinedPrimaryOpacityButton} from './Components';
import ShareIcon from '@mui/icons-material/Share';
import colors from '@studygenius/shared/Colors';
import { MUIStyles } from '@studygenius/shared/Variables';
import {useTranslations} from 'next-intl';

const ArticleComponent = ({ article, expandedIds, toggleExpand, markdownStyle, languagePreference }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isExpanded = expandedIds.includes(article.id);


  console.log('article is', article)

  const handleShare = () => {
    const baseUrl = window.location.origin;
    const shareUrl = `${baseUrl}/${languagePreference}/post/${article.id}`;
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setOpenSnackbar(true);
      })
      .catch((err) => {
        console.error('Failed to copy the link: ', err);
      });
  };

  const handleDeleteArticle = () => deleteArticle(article.id)
//   const titleCleaned = article.title.replace(/^[1-9]\.\s*/, '').replace(/\*\*/g, '').trim();
  const titleCleaned = article.title.replace(/^([1-9]|1[0-9]|20)\.\s*/, '').replace(/\*\*/g, '').trim();

  // Custom components for ReactMarkdown to handle link clicks
  const customComponents = {
    a: ({ node, ...props }) => (
      <a
        {...props}
        onClick={(e) => {
          e.preventDefault();
          toggleExpand(article.id);
        }}
        style={{ color: '#3333AC', cursor: 'pointer', textDecoration: 'underline' }}
      />
    ),
    p: ({ node, ...props }) => <p style={markdownStyle} {...props} />,
    img: ({ node, ...props }) => (
      <img
        {...props}
        style={{ width: '100%', borderRadius: '8px', marginTop: '1rem' }}
      />
    ),
    h1: ({ node, ...props }) => <h1 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
    h2: ({ node, ...props }) => <h2 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
    h3: ({ node, ...props }) => <h3 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
    h4: ({ node, ...props }) => <h4 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
    h5: ({ node, ...props }) => <h5 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
    h6: ({ node, ...props }) => <h6 style={{ fontSize: '16px', margin: '0.5em 0' }} {...props} />,
  };

  const t = useTranslations('Article')

  // Define styles for collapsed content
  const contentStyle = !isExpanded
    ? {
        height: {xs: '150px', md: '220px'}, // Adjust as needed
        overflow: 'hidden',
        position: 'relative',
      }
    : {};

  // Fade-out effect
  const fadeOutStyle = !isExpanded
    ? {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: { xs: '80px', md: '80px' }, // Adjust as needed
        background: 'linear-gradient(transparent, white)', // Adjust colors as needed
      }
    : {};

  const lineClampStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 5, // Number of lines to display
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  };

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  const escapedTitle = escapeRegExp(titleCleaned);
  

  
  // Updated regex pattern to handle bold markers before and after numbering
  const titlePattern = new RegExp(
    '^\\s*(#+\\s*)?' +                        // Optional Markdown headers
    '((\\*{1,2}|_{1,2})\\s*)*' +              // Optional bold/italic markers
    '(\\d+\\.\\s*)?' +                        // Optional numbering
    '((\\*{1,2}|_{1,2})\\s*)*' +              // Optional bold/italic markers
    escapedTitle +                            // The escaped title
    '\\s*((\\*{1,2}|_{1,2})\\s*)*\\s*$' ,     // Optional bold/italic markers at the end
    'im' // 'i' for case-insensitive, 'm' for multiline matching
  );
  
  // First, remove any introduction before '1.' or the title
  const contentWithoutIntro = article.content.trim();
  
  // Then, remove the title from the content
  
  const newContent = contentWithoutIntro.replace(titlePattern, '').trim();



  // Use the function to process newContent
  const processedContent = newContent;

  return (
    <React.Fragment key={article.id}>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/katex@0.16.7/dist/katex.min.css"
          integrity="sha384-vZTGAF6Qv92PQp4IEeOkUSf47TBDPUIxDe2CqBXzmK/ibYardvWUrdZG/HWfX6Z5"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&family=Lato:wght@400;600;700&display=swap"
          rel="stylesheet"
        />
      </Head>


      {article.hasImage && article.image ? (
        <Card
          sx={{
            marginBottom: '1rem',
            width: '100%',
            borderRadius: '8px',
            boxShadow: '1.8px 0px 9px 0px #0303031A',
          }}
        >
          <img
            src={article.image}
            style={{ height: '350px', objectFit: 'cover', width: '100%' }}
          />
        </Card>
      ) : null}




      <Card
        sx={{
          marginBottom: '1rem',
          width: '100%',
          borderRadius: '8px',
          boxShadow: '1.8px 0px 9px 0px #0303031A',
        }}
      >
        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

        >
          <Alert
          severity="info"
            sx={{ width: '100%' }}
          >
            {t('COPIED_LINK')}
          </Alert>
        </Snackbar>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            {titleCleaned}
          </Typography>

          <div style={{ position: 'relative', marginTop: '1rem' }}>
            <Box sx={{ ...contentStyle, ...markdownStyle }}>
              {!isExpanded ? (
                // Clamped content
                <div>
                  <ReactMarkdown
                    components={customComponents}
                    remarkPlugins={[[remarkMath]]}
                    rehypePlugins={[rehypeKatex]}
                  >
                    {processedContent}
                  </ReactMarkdown>
                </div>
              ) : (
                <ReactMarkdown
                  components={customComponents}
                  remarkPlugins={[[remarkMath]]}


                  rehypePlugins={[rehypeKatex]}
                >
                  {processedContent}
                </ReactMarkdown>
              )}
            </Box>
            {!isExpanded && <Box sx={fadeOutStyle}></Box>}
          </div>

          {/* Read more / Show less link */}
          <Box sx={{ marginTop: '0.5rem' }}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                toggleExpand(article.id);
              }}
              style={{ color: '#3333AC', cursor: 'pointer', textDecoration: 'underline' }}
            >
              {isExpanded ? t('SHOW_LESS') : t('READ_MORE')}
            </a>
          </Box>

          {isExpanded ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
                marginTop: '0rem',
              }}
            >
              <Typography>
                <Link href={`/${languagePreference}/topic/${article.topicId}/1`} passHref>
                  <span style={{ cursor: 'pointer', textDecoration: 'underline', width: '250px', ...MUIStyles.XSParagraph }}>
                    {article.topicName}
                  </span>
                </Link>
               
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <OutlinedPrimaryOpacityButton  onClick={handleShare} sx={{paddingX: '10px'}}><Typography sx={{...MUIStyles.smallBold, color: colors.main}}>{t('SHARE')}</Typography>
           
                <ShareIcon style={{color: colors.main, marginLeft: '8px', height: '20px', width: '20px'}}/>
                </OutlinedPrimaryOpacityButton>
          </Box>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default ArticleComponent;