export const engineering = [
    {
        "curriculum": "University",
        "field": "Calculus",
        "subject": "I Topics",
        "id": "topic_University_Calculus_I_DerivativesAndApplications",
        "name_en": "Derivatives and Applications for University Calculus I",
        "name_sv": "Derivativer och Tillämpningar för Universitetets Kalkyl I",
        "learning_objectives": "Techniques of differentiation and applying derivatives to motion and rate problems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.calculus1",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Calculus",
        "subject": "I Topics",
        "id": "topic_University_Calculus_I_IntegralsAndApplications",
        "name_en": "Integrals and Applications for University Calculus I",
        "name_sv": "Integraler och Tillämpningar för Universitetets Kalkyl I",
        "learning_objectives": "Fundamental theorem of calculus, techniques of integration, and calculating areas.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.calculus1",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Calculus",
        "subject": "II Topics",
        "id": "topic_University_Calculus_II_AdvancedIntegrationTechniques",
        "name_en": "Advanced Integration Techniques for University Calculus II",
        "name_sv": "Avancerade Integreringstekniker för Universitetets Kalkyl II",
        "learning_objectives": "Application of various techniques such as parts, trigonometric substitution, and partial fractions.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.calculus2",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Calculus",
        "subject": "II Topics",
        "id": "topic_University_Calculus_II_SeriesAndSequences",
        "name_en": "Series and Sequences for University Calculus II",
        "name_sv": "Serier och Sekvenser för Universitetets Kalkyl II",
        "learning_objectives": "Understanding convergence and divergence of series and sequences.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.calculus2",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Calculus",
        "subject": "II Topics",
        "id": "topic_University_Calculus_II_ParametricEquations",
        "name_en": "Parametric Equations and Polar Coordinates for University Calculus II",
        "name_sv": "Parametriska Ekvationer och Polära Koordinater för Universitetets Kalkyl II",
        "learning_objectives": "Sketching curves defined by parametric equations and polar coordinates.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.calculus2",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Linear Algebra",
        "subject": "Topics",
        "id": "topic_University_LinearAlgebra_VectorsAndMatrices",
        "name_en": "Vectors and Matrices for University Linear Algebra",
        "name_sv": "Vektorer och Matriser för Universitetet Linjär Algebra",
        "learning_objectives": "Introduction to vectors, matrices, their operations and applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Linear Algebra",
        "subject": "Topics",
        "id": "topic_University_LinearAlgebra_Determinants",
        "name_en": "Determinants and Their Properties for University Linear Algebra",
        "name_sv": "Determinanter och Deras Egenskaper för Universitetet Linjär Algebra",
        "learning_objectives": "Calculating determinants and understanding their geometrical interpretations.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Linear Algebra",
        "subject": "Topics",
        "id": "topic_University_LinearAlgebra_EigenvaluesAndEigenvectors",
        "name_en": "Eigenvalues and Eigenvectors for University Linear Algebra",
        "name_sv": "Egenvärden och Egenvektorer för Universitetet Linjär Algebra",
        "learning_objectives": "Finding eigenvalues and eigenvectors and their implications in various applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Linear Algebra",
        "subject": "Topics",
        "id": "topic_University_LinearAlgebra_LinearTransformations",
        "name_en": "Linear Transformations for University Linear Algebra",
        "name_sv": "Linjära Transformationer för Universitetet Linjär Algebra",
        "learning_objectives": "Understanding linear mappings, their matrix representations, and applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_ForceAndMotion",
        "name_en": "Force and Motion for University Physics I",
        "name_sv": "Kraft och Rörelse för Universitetets Fysik I",
        "learning_objectives": "Newton's laws of motion and their applications to various systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_WorkEnergy",
        "name_en": "Work and Energy for University Physics I",
        "name_sv": "Arbete och Energi för Universitetets Fysik I",
        "learning_objectives": "Understanding the concepts of work, energy, and the conservation of energy.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_Momentum",
        "name_en": "Momentum for University Physics I",
        "name_sv": "Impuls för Universitetets Fysik I",
        "learning_objectives": "Principles of momentum and impulse, and conservation of momentum.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_RotationalMotion",
        "name_en": "Rotational Motion for University Physics I",
        "name_sv": "Rotationell Rörelse för Universitetets Fysik I",
        "learning_objectives": "Understanding rotational kinematics, dynamics, and angular momentum.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "II Topics",
        "id": "topic_University_Physics_II_ElectricityAndMagnetism",
        "name_en": "Electricity and Magnetism for University Physics II",
        "name_sv": "Elektricitet och Magnetism för Universitetets Fysik II",
        "learning_objectives": "Fundamentals of electric forces, fields, and circuits.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics2",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "II Topics",
        "id": "topic_University_Physics_II_Optics",
        "name_en": "Optics for University Physics II",
        "name_sv": "Optik för Universitetets Fysik II",
        "learning_objectives": "Understanding the behavior of light, lenses, and optical instruments.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.physics2",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "For Engineers",
        "id": "topic_University_Chemistry_ForEng_ChemicalReactions",
        "name_en": "Chemical Reactions for University Chemistry for Engineers",
        "name_sv": "Kemiska Reaktioner för Universitetets Kemi för Ingenjörer",
        "learning_objectives": "Understanding types of chemical reactions and balancing chemical equations.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.chem_for_eng",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "For Engineers",
        "id": "topic_University_Chemistry_ForEng_Thermochemistry",
        "name_en": "Thermochemistry for University Chemistry for Engineers",
        "name_sv": "Termokemi för Universitetets Kemi för Ingenjörer",
        "learning_objectives": "Understanding heat transfer in chemical reactions and thermodynamic principles.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.chem_for_eng",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "For Engineers",
        "id": "topic_University_Chemistry_ForEng_Stoichiometry",
        "name_en": "Stoichiometry for University Chemistry for Engineers",
        "name_sv": "Stökiometri för Universitetets Kemi för Ingenjörer",
        "learning_objectives": "Calculating reactant and product quantities in chemical reactions.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.chem_for_eng",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "For Engineers",
        "id": "topic_University_Chemistry_ForEng_GasLaws",
        "name_en": "Gas Laws for University Chemistry for Engineers",
        "name_sv": "Gaslagar för Universitetets Kemi för Ingenjörer",
        "learning_objectives": "Understanding gas behavior and the laws governing gases under various conditions.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.chem_for_eng",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statics",
        "subject": "Topics",
        "id": "topic_University_Statics_Equilibrium",
        "name_en": "Equilibrium for University Statics",
        "name_sv": "Jämvikt för Universitetets Statik",
        "learning_objectives": "Understanding static equilibrium and analyzing forces on structures.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.statics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statics",
        "subject": "Topics",
        "id": "topic_University_Statics_ForcesIn2D",
        "name_en": "Forces in 2D for University Statics",
        "name_sv": "Krafter i 2D för Universitetets Statik",
        "learning_objectives": "Analyzing two-dimensional force systems using vector mathematics.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.statics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statics",
        "subject": "Topics",
        "id": "topic_University_Statics_AnalysisOfStructures",
        "name_en": "Analysis of Structures for University Statics",
        "name_sv": "Analys av Strukturer för Universitetets Statik",
        "learning_objectives": "Evaluating different structural systems and their stability.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.statics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Dynamics",
        "subject": "Topics",
        "id": "topic_University_Dynamics_Kinematics",
        "name_en": "Kinematics for University Dynamics",
        "name_sv": "Kinematik för Universitetets Dynamik",
        "learning_objectives": "Analyzing motion in one and two dimensions with kinematic equations.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.dynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Dynamics",
        "subject": "Topics",
        "id": "topic_University_Dynamics_ForcesAndNewton",
        "name_en": "Forces and Newton's Laws for University Dynamics",
        "name_sv": "Krafter och Newtons Lagar för Universitetets Dynamik",
        "learning_objectives": "Applying Newton's laws to analyze dynamic systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.dynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Dynamics",
        "subject": "Topics",
        "id": "topic_University_Dynamics_EnergyAndWork",
        "name_en": "Energy and Work for University Dynamics",
        "name_sv": "Energi och Arbete för Universitetets Dynamik",
        "learning_objectives": "Understanding the relationship between work, energy, and mechanical systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.dynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Thermodynamics",
        "subject": "Topics",
        "id": "topic_University_Thermodynamics_LawsOfThermodynamics",
        "name_en": "Laws of Thermodynamics for University Thermodynamics",
        "name_sv": "Termodynamikens Lagar för Universitetets Termodynamik",
        "learning_objectives": "Understanding the fundamental laws governing thermodynamic processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.thermodynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Thermodynamics",
        "subject": "Topics",
        "id": "topic_University_Thermodynamics_ThermalProperties",
        "name_en": "Thermal Properties of Matter for University Thermodynamics",
        "name_sv": "Termiska Egenskaper hos Materia för Universitetets Termodynamik",
        "learning_objectives": "Examining properties such as temperature, heat, and phase transitions.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.thermodynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Thermodynamics",
        "subject": "Topics",
        "id": "topic_University_Thermodynamics_CyclesAndEfficiency",
        "name_en": "Thermodynamic Cycles and Efficiency for University Thermodynamics",
        "name_sv": "Termodynamiska Cykler och Effektivitet för Universitetets Termodynamik",
        "learning_objectives": "Analyzing various thermodynamic cycles and their efficiencies.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.thermodynamics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Materials Science",
        "subject": "Topics",
        "id": "topic_University_MaterialsScience_MaterialProperties",
        "name_en": "Material Properties for University Materials Science",
        "name_sv": "Materialegenskaper för Universitetets Materialvetenskap",
        "learning_objectives": "Understanding mechanical, thermal, and electrical properties of materials.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.materials_science",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Materials Science",
        "subject": "Topics",
        "id": "topic_University_MaterialsScience_CrystalStructures",
        "name_en": "Crystal Structures for University Materials Science",
        "name_sv": "Kristallstrukturer för Universitetets Materialvetenskap",
        "learning_objectives": "Exploring different types of crystal structures and their implications.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.materials_science",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Materials Science",
        "subject": "Topics",
        "id": "topic_University_MaterialsScience_FailureMechanisms",
        "name_en": "Material Failure Mechanisms for University Materials Science",
        "name_sv": "Materialfelmekanismer för Universitetets Materialvetenskap",
        "learning_objectives": "Identifying and understanding failure mechanisms in materials.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.materials_science",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Fluid Mechanics",
        "subject": "Topics",
        "id": "topic_University_FluidMechanics_FluidProperties",
        "name_en": "Fluid Properties for University Fluid Mechanics",
        "name_sv": "Fluidegenskaper för Universitetets Fluidmekanik",
        "learning_objectives": "Understanding the properties of fluids and their behaviors.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.fluid_mechanics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Fluid Mechanics",
        "subject": "Topics",
        "id": "topic_University_FluidMechanics_FluidDynamics",
        "name_en": "Fluid Dynamics for University Fluid Mechanics",
        "name_sv": "Fluiddynamik för Universitetets Fluidmekanik",
        "learning_objectives": "Examining the motion of fluids and the forces acting on them.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.fluid_mechanics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Fluid Mechanics",
        "subject": "Topics",
        "id": "topic_University_FluidMechanics_Applications",
        "name_en": "Applications of Fluid Mechanics for University Fluid Mechanics",
        "name_sv": "Tillämpningar inom Fluidmekanik för Universitetets Fluidmekanik",
        "learning_objectives": "Understanding the applications of fluid mechanics in engineering systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.fluid_mechanics",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Electrical Engineering",
        "subject": "Topics",
        "id": "topic_University_ElectricalCircuits_CircuitAnalysis",
        "name_en": "Circuit Analysis for University Electrical Circuits",
        "name_sv": "Kretsanalys för Universitetets Elektriska Kretsar",
        "learning_objectives": "Analyzing electrical circuits, including series and parallel configurations.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.electrical_circuits",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Electrical Engineering",
        "subject": "Topics",
        "id": "topic_University_ElectricalCircuits_KirchhoffsLaws",
        "name_en": "Kirchhoff's Laws for University Electrical Circuits",
        "name_sv": "Kirchhoffs Lagar för Universitetets Elektriska Kretsar",
        "learning_objectives": "Understanding and applying Kirchhoff's voltage and current laws in circuit problems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.electrical_circuits",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Electrical Engineering",
        "subject": "Topics",
        "id": "topic_University_ElectricalCircuits_TheveninAndNorton",
        "name_en": "Thevenin and Norton Theorems for University Electrical Circuits",
        "name_sv": "Thevenin och Norton Teoremen för Universitetets Elektriska Kretsar",
        "learning_objectives": "Simplifying networks using Thevenin's and Norton's theorems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.electrical_circuits",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Electrical Engineering",
        "subject": "Topics",
        "id": "topic_University_ElectricalCircuits_ACAndDCAnalysis",
        "name_en": "AC and DC Circuit Analysis for University Electrical Circuits",
        "name_sv": "AC och DC Kretsanalys för Universitetets Elektriska Kretsar",
        "learning_objectives": "Analyzing circuits using both alternating and direct current methods.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.electrical_circuits",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Engineering Design",
        "subject": "Topics",
        "id": "topic_University_EngDesign_DesignProcess",
        "name_en": "The Design Process for University Engineering Design",
        "name_sv": "Designprocess för Universitetets Ingenjörsdesign",
        "learning_objectives": "Understanding the systematic design process and project management.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.eng_design",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Engineering Design",
        "subject": "Topics",
        "id": "topic_University_EngDesign_Prototyping",
        "name_en": "Prototyping and Testing for University Engineering Design",
        "name_sv": "Prototyping och Testning för Universitetets Ingenjörsdesign",
        "learning_objectives": "Creating prototypes to test and iterate on designs.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.eng_design",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Engineering Design",
        "subject": "Topics",
        "id": "topic_University_EngDesign_DesignThinking",
        "name_en": "Design Thinking for University Engineering Design",
        "name_sv": "Design Thinking för Universitetets Ingenjörsdesign",
        "learning_objectives": "Applying human-centered design methods to solve engineering problems.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.eng_design",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Engineering Design",
        "subject": "Topics",
        "id": "topic_University_EngDesign_TechnicalDocumentation",
        "name_en": "Technical Documentation for University Engineering Design",
        "name_sv": "Teknisk Dokumentation för Universitetets Ingenjörsdesign",
        "learning_objectives": "Creating documentation that communicates designs clearly and effectively.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.eng_design",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Mechanics of Materials",
        "subject": "Topics",
        "id": "topic_University_MechMaterials_StressStrain",
        "name_en": "Stress and Strain for University Mechanics of Materials",
        "name_sv": "Spänning och Deformation för Universitetets Materialmekanik",
        "learning_objectives": "Understanding stress, strain, and material properties.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.mech_materials",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Mechanics of Materials",
        "subject": "Topics",
        "id": "topic_University_MechMaterials_BendingAndShear",
        "name_en": "Bending and Shear for University Mechanics of Materials",
        "name_sv": "Böjning och Skjuvning för Universitetets Materialmekanik",
        "learning_objectives": "Analyzing bending moments and shear forces in beams.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.mech_materials",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Mechanics of Materials",
        "subject": "Topics",
        "id": "topic_University_MechMaterials_FailureCriteria",
        "name_en": "Failure Criteria for University Mechanics of Materials",
        "name_sv": "Felcriteria för Universitetets Materialmekanik",
        "learning_objectives": "Understanding various failure theories and when they apply.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.mech_materials",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Mechanics of Materials",
        "subject": "Topics",
        "id": "topic_University_MechMaterials_Torsion",
        "name_en": "Torsion for University Mechanics of Materials",
        "name_sv": "Torsion för Universitetets Materialmekanik",
        "learning_objectives": "Analyzing torsional effects in structural members.",
        "when_to_add": [
            "userType.university_student.universityProgram.engineering.universityCourses.mech_materials",
            "generalInterests.engineering",
            "generalInterests.science"
        ]
    }
  ]

  export const arts = [
    {
        "curriculum": "University",
        "field": "Literature",
        "subject": "Introductory Topics",
        "id": "topic_University_Intro_Literature_LiteraryGenres",
        "name_en": "Literary Genres for Introduction to Literature",
        "name_sv": "Litterära Genrer för Introduktion till Litteratur",
        "learning_objectives": "Understanding different literary genres and their characteristics.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.intro_literature",
            "generalInterests.literature",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Literature",
        "subject": "Introductory Topics",
        "id": "topic_University_Intro_Literature_CriticalTheory",
        "name_en": "Critical Theory for Introduction to Literature",
        "name_sv": "Kritisk Teori för Introduktion till Litteratur",
        "learning_objectives": "Exploring various critical theories and their applications to literature.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.intro_literature",
            "generalInterests.literature",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Literature",
        "subject": "Introductory Topics",
        "id": "topic_University_Intro_Literature_HistoricalContext",
        "name_en": "Historical Context of Literature for Introduction to Literature",
        "name_sv": "Historisk Kontext av Litteratur för Introduktion till Litteratur",
        "learning_objectives": "Understanding the historical context surrounding literary works.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.intro_literature",
            "generalInterests.literature",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Art History",
        "subject": "Topics",
        "id": "topic_University_Art_History_ArtMovements",
        "name_en": "Art Movements for Art History",
        "name_sv": "Konst Rörelser för Konsthistoria",
        "learning_objectives": "Exploring major art movements and their historical significance.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.art_history",
            "generalInterests.art",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "University",
        "field": "Art History",
        "subject": "Topics",
        "id": "topic_University_Art_History_ImpactOfCulture",
        "name_en": "Impact of Culture on Art for Art History",
        "name_sv": "Kulturell Påverkan på Konst för Konsthistoria",
        "learning_objectives": "Understanding how culture shapes artistic expression throughout history.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.art_history",
            "generalInterests.art",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "University",
        "field": "Art History",
        "subject": "Topics",
        "id": "topic_University_Art_History_CriticalAnalysis",
        "name_en": "Critical Analysis of Art Works for Art History",
        "name_sv": "Kritisk Analys av Konstverk för Konsthistoria",
        "learning_objectives": "Evaluating and critiquing various art pieces and their significance.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.art_history",
            "generalInterests.art",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "University",
        "field": "Philosophy",
        "subject": "101 Topics",
        "id": "topic_University_Philosophy_IntroductionToPhilosophy",
        "name_en": "Introduction to Philosophy for Philosophy 101",
        "name_sv": "Introduktion till Filosofi för Filosofi 101",
        "learning_objectives": "Exploring fundamental philosophical concepts and ideas.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.philosophy_101",
            "generalInterests.philosophy",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Philosophy",
        "subject": "101 Topics",
        "id": "topic_University_Philosophy_Ethics",
        "name_en": "Ethics for Philosophy 101",
        "name_sv": "Etik för Filosofi 101",
        "learning_objectives": "Understanding ethical theories and their application to modern issues.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.philosophy_101",
            "generalInterests.philosophy",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Philosophy",
        "subject": "101 Topics",
        "id": "topic_University_Philosophy_Logic",
        "name_en": "Introduction to Logic for Philosophy 101",
        "name_sv": "Introduktion till Logik för Filosofi 101",
        "learning_objectives": "Learning foundational principles of logic and reasoning.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.philosophy_101",
            "generalInterests.philosophy",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "101 Topics",
        "id": "topic_University_Psychology_IntroductionToPsychology",
        "name_en": "Introduction to Psychology for Psychology 101",
        "name_sv": "Introduktion till Psykologi för Psykologi 101",
        "learning_objectives": "Understanding key concepts and theories in psychology.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.psychology_101",
            "generalInterests.psychology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "101 Topics",
        "id": "topic_University_Psychology_BehavioralPsychology",
        "name_en": "Behavioral Psychology for Psychology 101",
        "name_sv": "Beteendepsykologi för Psykologi 101",
        "learning_objectives": "Examining behavior and learning processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.psychology_101",
            "generalInterests.psychology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "101 Topics",
        "id": "topic_University_Psychology_CognitivePsychology",
        "name_en": "Cognitive Psychology for Psychology 101",
        "name_sv": "Kognitiv Psykologi för Psykologi 101",
        "learning_objectives": "Understanding processes of thought, perception, and memory.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.psychology_101",
            "generalInterests.psychology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Sociology",
        "subject": "101 Topics",
        "id": "topic_University_Sociology_IntroductionToSociology",
        "name_en": "Introduction to Sociology for Sociology 101",
        "name_sv": "Introduktion till Sociologi för Sociologi 101",
        "learning_objectives": "Exploring core concepts and perspectives in sociology.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.sociology_101",
            "generalInterests.sociology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Sociology",
        "subject": "101 Topics",
        "id": "topic_University_Sociology_SocialStructures",
        "name_en": "Social Structures for Sociology 101",
        "name_sv": "Sociala Strukturer för Sociologi 101",
        "learning_objectives": "Understanding the role of social structures in shaping behavior.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.sociology_101",
            "generalInterests.sociology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Sociology",
        "subject": "101 Topics",
        "id": "topic_University_Sociology_CulturalSociology",
        "name_en": "Cultural Sociology for Sociology 101",
        "name_sv": "Kulturell Sociologi för Sociologi 101",
        "learning_objectives": "Examining the relationship between culture and society.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.sociology_101",
            "generalInterests.sociology",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Creative Writing",
        "subject": "Topics",
        "id": "topic_University_CreativeWriting_BasicsOfCreativeWriting",
        "name_en": "Basics of Creative Writing for Creative Writing",
        "name_sv": "Grunderna i Kreativt Skrivande för Kreativt Skrivande",
        "learning_objectives": "Developing foundational skills in creative writing techniques.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.creative_writing",
            "generalInterests.writing",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Creative Writing",
        "subject": "Topics",
        "id": "topic_University_CreativeWriting_EditingAndRevision",
        "name_en": "Editing and Revision for Creative Writing",
        "name_sv": "Redigering och Revision för Kreativt Skrivande",
        "learning_objectives": "Learning the importance of editing and revising for impactful writing.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.creative_writing",
            "generalInterests.writing",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Creative Writing",
        "subject": "Topics",
        "id": "topic_University_CreativeWriting_StylesAndGenres",
        "name_en": "Styles and Genres for Creative Writing",
        "name_sv": "Stilar och Genrer för Kreativt Skrivande",
        "learning_objectives": "Exploring various writing styles and genres to enhance creativity.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.creative_writing",
            "generalInterests.writing",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Performing Arts",
        "subject": "Topics",
        "id": "topic_University_PerformingArts_IntroductionToPerformingArts",
        "name_en": "Introduction to Performing Arts for Performing Arts",
        "name_sv": "Introduktion till Utövande Konst för Utövande Konst",
        "learning_objectives": "Understanding the fundamentals of various performing arts forms.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.performing_arts",
            "generalInterests.performingArts",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Performing Arts",
        "subject": "Topics",
        "id": "topic_University_PerformingArts_PerformanceTechniques",
        "name_en": "Performance Techniques for Performing Arts",
        "name_sv": "Prestandatekniker för Utövande Konst",
        "learning_objectives": "Exploring techniques and skills essential for effective performance.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.performing_arts",
            "generalInterests.performingArts",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Performing Arts",
        "subject": "Topics",
        "id": "topic_University_PerformingArts_StagePresence",
        "name_en": "Stage Presence for Performing Arts",
        "name_sv": "Scennärvaro för Utövande Konst",
        "learning_objectives": "Understanding the importance of presence and charisma in performances.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.performing_arts",
            "generalInterests.performingArts",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "History",
        "subject": "Western Civilization",
        "id": "topic_University_History_WesternCivilization_Eras",
        "name_en": "Eras of Western Civilization for History of Western Civilization",
        "name_sv": "Epoker av Västerländsk Civilisation för Västerländsk Civilisation Historik",
        "learning_objectives": "Understanding key eras and their impact on Western civilization.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.history_west_civ",
            "generalInterests.history",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "History",
        "subject": "Western Civilization",
        "id": "topic_University_History_WesternCivilization_InfluentialFigures",
        "name_en": "Influential Figures in Western Civilization for History of Western Civilization",
        "name_sv": "Påverkan Gestalter i Västerländsk Civilisation för Västerländsk Civilisation Historik",
        "learning_objectives": "Examining key figures and their contributions to Western thought.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.history_west_civ",
            "generalInterests.history",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Cultural Studies",
        "subject": "Topics",
        "id": "topic_University_CulturalStudies_TheoriesOfCulture",
        "name_en": "Theories of Culture for Cultural Studies",
        "name_sv": "Kulturteorier för Kulturella Studier",
        "learning_objectives": "Exploring various theories that shape our understanding of culture.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.cultural_studies",
            "generalInterests.culture",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Cultural Studies",
        "subject": "Topics",
        "id": "topic_University_CulturalStudies_CulturalIdentity",
        "name_en": "Cultural Identity for Cultural Studies",
        "name_sv": "Kulturell Identitet för Kulturella Studier",
        "learning_objectives": "Understanding the concept of cultural identity and its significance.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.cultural_studies",
            "generalInterests.culture",
            "generalInterests.arts"
        ]
    },
    {
        "curriculum": "University",
        "field": "Cultural Studies",
        "subject": "Topics",
        "id": "topic_University_CulturalStudies_ImpactOfMedia",
        "name_en": "The Impact of Media on Culture for Cultural Studies",
        "name_sv": "Medias Påverkan på Kultur för Kulturella Studier",
        "learning_objectives": "Analyzing the role of media in shaping cultural perceptions.",
        "when_to_add": [
            "userType.university_student.universityProgram.arts.universityCourses.cultural_studies",
            "generalInterests.culture",
            "generalInterests.arts"
        ]
    }
  ]

  export const science = [
    {
        "curriculum": "University",
        "field": "Biology",
        "subject": "I Topics",
        "id": "topic_University_Biology_I_CellBiology",
        "name_en": "Cell Biology for University Biology I",
        "name_sv": "Cellbiologi för Universitetets Biologi I",
        "learning_objectives": "Understanding cell structure, function, and processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.bio1",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Biology",
        "subject": "I Topics",
        "id": "topic_University_Biology_I_HumanAnatomy",
        "name_en": "Human Anatomy for University Biology I",
        "name_sv": "Mänsklig Anatomi för Universitetets Biologi I",
        "learning_objectives": "Overview of human body systems and their functions.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.bio1",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Biology",
        "subject": "I Topics",
        "id": "topic_University_Biology_I_Ecology",
        "name_en": "Ecology for University Biology I",
        "name_sv": "Ekologi för Universitetets Biologi I",
        "learning_objectives": "Understanding ecosystems, energy flow, and interactions among species.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.bio1",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Biology",
        "subject": "II Topics",
        "id": "topic_University_Biology_II_DevelopmentalBiology",
        "name_en": "Developmental Biology for University Biology II",
        "name_sv": "Utvecklingsbiologi för Universitetets Biologi II",
        "learning_objectives": "Study of the processes that lead to the growth and development of organisms.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.bio2",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Biology",
        "subject": "II Topics",
        "id": "topic_University_Biology_II_Classification",
        "name_en": "Classification and Taxonomy for University Biology II",
        "name_sv": "Klassificering och Taksonomi för Universitetets Biologi II",
        "learning_objectives": "Understanding biological classification and the diversity of life.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.bio2",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "I Topics",
        "id": "topic_University_Chemistry_I_MatterAndEnergy",
        "name_en": "Matter and Energy for University Chemistry I",
        "name_sv": "Materi och Energi för Universitetets Kemi I",
        "learning_objectives": "Understanding the properties of matter and energy transformations.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem1",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "I Topics",
        "id": "topic_University_Chemistry_I_ChemicalReactions",
        "name_en": "Chemical Reactions for University Chemistry I",
        "name_sv": "Kemiska Reaktioner för Universitetets Kemi I",
        "learning_objectives": "Introduction to types of chemical reactions and stoichiometry.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem1",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "I Topics",
        "id": "topic_University_Chemistry_I_AtomicStructure",
        "name_en": "Atomic Structure for University Chemistry I",
        "name_sv": "Atomstruktur för Universitetets Kemi I",
        "learning_objectives": "Understanding atomic theory, electron configurations, and periodic properties.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem1",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "II Topics",
        "id": "topic_University_Chemistry_II_OrganicChemistry",
        "name_en": "Organic Chemistry for University Chemistry II",
        "name_sv": "Organisk Kemi för Universitetets Kemi II",
        "learning_objectives": "Understanding the structure, properties, and reactions of organic molecules.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem2",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "II Topics",
        "id": "topic_University_Chemistry_II_InorganicChemistry",
        "name_en": "Inorganic Chemistry for University Chemistry II",
        "name_sv": "Oorganisk Kemi för Universitetets Kemi II",
        "learning_objectives": "Study of inorganic compounds, their properties, and reactions.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem2",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Chemistry",
        "subject": "II Topics",
        "id": "topic_University_Chemistry_II_Equilibrium",
        "name_en": "Chemical Equilibrium for University Chemistry II",
        "name_sv": "Kemisk Jämvikt för Universitetets Kemi II",
        "learning_objectives": "Understanding the principles of chemical equilibrium and Le Chatelier's principle.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.chem2",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_Motion",
        "name_en": "Motion for University Physics I",
        "name_sv": "Rörelse för Universitetets Fysik I",
        "learning_objectives": "Understanding the kinematics of motion in one and two dimensions.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_Forces",
        "name_en": "Forces for University Physics I",
        "name_sv": "Krafter för Universitetets Fysik I",
        "learning_objectives": "Newton's laws of motion and applications in problem-solving.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "I Topics",
        "id": "topic_University_Physics_I_Energy",
        "name_en": "Energy for University Physics I",
        "name_sv": "Energi för Universitetets Fysik I",
        "learning_objectives": "Understanding work, energy conservation, and power.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics1",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "II Topics",
        "id": "topic_University_Physics_II_Electricity",
        "name_en": "Electricity for University Physics II",
        "name_sv": "Elektricitet för Universitetets Fysik II",
        "learning_objectives": "Understanding electric fields, circuits, and electromagnetic principles.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics2",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "II Topics",
        "id": "topic_University_Physics_II_Magnetism",
        "name_en": "Magnetism for University Physics II",
        "name_sv": "Magnetism för Universitetets Fysik II",
        "learning_objectives": "Examining magnetic fields and their interactions with electric currents.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics2",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Physics",
        "subject": "II Topics",
        "id": "topic_University_Physics_II_Waves",
        "name_en": "Waves for University Physics II",
        "name_sv": "Vågor för Universitetets Fysik II",
        "learning_objectives": "Understanding wave properties, sound, and light waves.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.physics2",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Earth Science",
        "subject": "Topics",
        "id": "topic_University_EarthScience_Geology",
        "name_en": "Geology for University Earth Science",
        "name_sv": "Geologi för Universitetets Geovetenskap",
        "learning_objectives": "Understanding the Earth's structure, processes, and materials.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.earth_science",
            "generalInterests.earth_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Earth Science",
        "subject": "Topics",
        "id": "topic_University_EarthScience_WeatherAndClimate",
        "name_en": "Weather and Climate for University Earth Science",
        "name_sv": "Väder och Klimat för Universitetets Geovetenskap",
        "learning_objectives": "Examining atmospheric processes and patterns.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.earth_science",
            "generalInterests.earth_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Earth Science",
        "subject": "Topics",
        "id": "topic_University_EarthScience_Oceanography",
        "name_en": "Oceanography for University Earth Science",
        "name_sv": "Oceanografi för Universitetets Geovetenskap",
        "learning_objectives": "Understanding ocean systems, currents, and marine ecosystems.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.earth_science",
            "generalInterests.earth_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Environmental Science",
        "subject": "Topics",
        "id": "topic_University_EnvScience_Ecosystems",
        "name_en": "Ecosystems for University Environmental Science",
        "name_sv": "Ekosystem för Universitetets Miljövetenskap",
        "learning_objectives": "Understanding ecological relationships and their impact on the environment.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.env_science",
            "generalInterests.environmental_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Environmental Science",
        "subject": "Topics",
        "id": "topic_University_EnvScience_HumansAndEnvironment",
        "name_en": "Humans and the Environment for University Environmental Science",
        "name_sv": "Människor och Miljön för Universitetets Miljövetenskap",
        "learning_objectives": "Examining human impact on ecosystems and sustainable practices.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.env_science",
            "generalInterests.environmental_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Environmental Science",
        "subject": "Topics",
        "id": "topic_University_EnvScience_Conservation",
        "name_en": "Conservation Biology for University Environmental Science",
        "name_sv": "Bevarande Biologi för Universitetets Miljövetenskap",
        "learning_objectives": "Understanding biodiversity, conservation methods, and restoration ecology.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.env_science",
            "generalInterests.environmental_science",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statistics",
        "subject": "Topics",
        "id": "topic_University_Statistics_DescriptiveStatistics",
        "name_en": "Descriptive Statistics for University Statistics",
        "name_sv": "Deskriptiv Statistik för Universitetets Statistik",
        "learning_objectives": "Understanding summary measures such as mean, median, mode, and standard deviation.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.statistics",
            "generalInterests.statistics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statistics",
        "subject": "Topics",
        "id": "topic_University_Statistics_InferentialStatistics",
        "name_en": "Inferential Statistics for University Statistics",
        "name_sv": "Inledande Statistik för Universitetets Statistik",
        "learning_objectives": "Understanding hypothesis testing, confidence intervals, and regression analysis.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.statistics",
            "generalInterests.statistics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Statistics",
        "subject": "Topics",
        "id": "topic_University_Statistics_Probability",
        "name_en": "Probability for University Statistics",
        "name_sv": "Sannolikhet för Universitetets Statistik",
        "learning_objectives": "Understanding probabilistic models and calculations.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.statistics",
            "generalInterests.statistics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Genetics",
        "subject": "Topics",
        "id": "topic_University_Genetics_MolecularGenetics",
        "name_en": "Molecular Genetics for University Genetics",
        "name_sv": "Molekylär Genetik för Universitetets Genetik",
        "learning_objectives": "Understanding the molecular basis of genes and heredity.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.genetics",
            "generalInterests.genetics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Genetics",
        "subject": "Topics",
        "id": "topic_University_Genetics_QuantitativeGenetics",
        "name_en": "Quantitative Genetics for University Genetics",
        "name_sv": "Kvantitativ Genetik för Universitetets Genetik",
        "learning_objectives": "Understanding the genetic basis of quantitative traits and phenotypes.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.genetics",
            "generalInterests.genetics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Genetics",
        "subject": "Topics",
        "id": "topic_University_Genetics_DevelopmentalGenetics",
        "name_en": "Developmental Genetics for University Genetics",
        "name_sv": "Utvecklingsgenetik för Universitetets Genetik",
        "learning_objectives": "Understanding genetic regulation of development and formation of organisms.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.genetics",
            "generalInterests.genetics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Organic Chemistry",
        "subject": "Topics",
        "id": "topic_University_OrganicChemistry_StructureAndBonding",
        "name_en": "Structure and Bonding for University Organic Chemistry",
        "name_sv": "Struktur och Bindning för Universitetets Organisk Kemi",
        "learning_objectives": "Understanding molecular structure, bonding theories, and functional groups.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.organic_chem",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Organic Chemistry",
        "subject": "Topics",
        "id": "topic_University_OrganicChemistry_Reactions",
        "name_en": "Reactions of Organic Compounds for University Organic Chemistry",
        "name_sv": "Reaktioner av Organiska Föreningar för Universitetets Organisk Kemi",
        "learning_objectives": "Studying the mechanisms and types of reactions in organic compounds.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.organic_chem",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Organic Chemistry",
        "subject": "Topics",
        "id": "topic_University_OrganicChemistry_Synthesis",
        "name_en": "Organic Synthesis for University Organic Chemistry",
        "name_sv": "Organisk Syntes för Universitetets Organisk Kemi",
        "learning_objectives": "Learning about strategies for the synthesis of complex organic molecules.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.organic_chem",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Ecology",
        "subject": "Topics",
        "id": "topic_University_Ecology_Interactions",
        "name_en": "Interactions in Ecosystems for University Ecology",
        "name_sv": "Interaktioner i Ekosystem för Universitetets Ekologi",
        "learning_objectives": "Understanding biotic and abiotic interactions within ecosystems.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.ecology",
            "generalInterests.ecology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Ecology",
        "subject": "Topics",
        "id": "topic_University_Ecology_Biodiversity",
        "name_en": "Biodiversity for University Ecology",
        "name_sv": "Biodiversitet för Universitetets Ekologi",
        "learning_objectives": "Understanding the importance of biodiversity and conservation efforts.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.ecology",
            "generalInterests.ecology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Ecology",
        "subject": "Topics",
        "id": "topic_University_Ecology_EnvironmentalImpact",
        "name_en": "Environmental Impact for University Ecology",
        "name_sv": "Miljöpåverkan för Universitetets Ekologi",
        "learning_objectives": "Examining human impacts on ecosystems and methods for mitigation.",
        "when_to_add": [
            "userType.university_student.universityProgram.science.universityCourses.ecology",
            "generalInterests.ecology",
            "generalInterests.science"
        ]
    }
]

export const business = [
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Introduction",
        "id": "topic_University_IntroBusiness_Overview",
        "name_en": "Overview of Business for University Introduction to Business",
        "name_sv": "Översikt av Affärer för Universitetets Introduktion till Företagande",
        "learning_objectives": "Introduction to concepts of business, types of businesses, and economic systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.intro_business",
            "generalInterests.business",
            "generalInterests.economics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Introduction",
        "id": "topic_University_IntroBusiness_BusinessEnvironment",
        "name_en": "Business Environment for University Introduction to Business",
        "name_sv": "Affärsmiljö för Universitetets Introduktion till Företagande",
        "learning_objectives": "Understanding the external and internal environments affecting business operations.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.intro_business",
            "generalInterests.business",
            "generalInterests.economics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Accounting I",
        "id": "topic_University_Accounting_I_BasicConcepts",
        "name_en": "Basic Concepts of Accounting for University Accounting I",
        "name_sv": "Grundläggande Begrepp inom Bokföring för Universitetets Bokföring I",
        "learning_objectives": "Introduction to accounting principles, the accounting cycle, and financial statements.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.accounting1",
            "generalInterests.business",
            "generalInterests.finance"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Accounting I",
        "id": "topic_University_Accounting_I_FinancialStatements",
        "name_en": "Financial Statements for University Accounting I",
        "name_sv": "Finansiella Rapporteringar för Universitetets Bokföring I",
        "learning_objectives": "Understanding balance sheets, income statements, and cash flow statements.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.accounting1",
            "generalInterests.business",
            "generalInterests.finance"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Accounting II",
        "id": "topic_University_Accounting_II_IntermediateAccounting",
        "name_en": "Intermediate Accounting for University Accounting II",
        "name_sv": "Avancerad Bokföring för Universitetets Bokföring II",
        "learning_objectives": "Deeper dive into accounting principles, standards, and practices.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.accounting2",
            "generalInterests.business",
            "generalInterests.finance"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Accounting II",
        "id": "topic_University_Accounting_II_Auditing",
        "name_en": "Auditing for University Accounting II",
        "name_sv": "Revision för Universitetets Bokföring II",
        "learning_objectives": "Understanding the auditing process and the role of auditors.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.accounting2",
            "generalInterests.business",
            "generalInterests.finance"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Microeconomics",
        "id": "topic_University_Microeconomics_SupplyDemand",
        "name_en": "Supply and Demand for University Microeconomics",
        "name_sv": "Utbud och Efterfrågan för Universitetets Mikroekonomi",
        "learning_objectives": "Understanding market dynamics, supply and demand curves, and price equilibrium.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.microeconomics",
            "generalInterests.economics",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Microeconomics",
        "id": "topic_University_Microeconomics_ConsumerBehavior",
        "name_en": "Consumer Behavior for University Microeconomics",
        "name_sv": "Konsumentbeteende för Universitetets Mikroekonomi",
        "learning_objectives": "Examining how consumers make decisions based on preferences and budget constraints.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.microeconomics",
            "generalInterests.economics",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Macroeconomics",
        "id": "topic_University_Macroeconomics_EconomicIndicators",
        "name_en": "Economic Indicators for University Macroeconomics",
        "name_sv": "Ekonomiska Indikatorer för Universitetets Makroekonomi",
        "learning_objectives": "Understanding GDP, inflation rates, and unemployment metrics.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.macroeconomics",
            "generalInterests.economics",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Macroeconomics",
        "id": "topic_University_Macroeconomics_FiscalMonetaryPolicy",
        "name_en": "Fiscal and Monetary Policy for University Macroeconomics",
        "name_sv": "Finans- och Penningpolitik för Universitetets Makroekonomi",
        "learning_objectives": "Exploring the role of governments and banks in regulating the economy.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.macroeconomics",
            "generalInterests.economics",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Marketing Principles",
        "id": "topic_University_MarketingPrinciples_Overview",
        "name_en": "Overview of Marketing Principles for University Marketing Principles",
        "name_sv": "Översikt av Marknadsföringsprinciper för Universitetets Marknadsföringsprinciper",
        "learning_objectives": "Introduction to marketing concepts, strategies, and consumer behavior.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.marketing_principles",
            "generalInterests.marketing",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Marketing Principles",
        "id": "topic_University_MarketingPrinciples_4Ps",
        "name_en": "The Marketing Mix (4 Ps) for University Marketing Principles",
        "name_sv": "Marknadsföringsmixen (4 Ps) för Universitetets Marknadsföringsprinciper",
        "learning_objectives": "Understanding product, price, place, and promotion as key components of marketing strategy.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.marketing_principles",
            "generalInterests.marketing",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Business Law",
        "id": "topic_University_BusinessLaw_Contracts",
        "name_en": "Contracts for University Business Law",
        "name_sv": "Kontrakt för Universitetets Affärsjuridik",
        "learning_objectives": "Understanding the legal framework surrounding contracts in business.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.business_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Business Law",
        "id": "topic_University_BusinessLaw_CorporateLaw",
        "name_en": "Corporate Law for University Business Law",
        "name_sv": "Bolagsrätt för Universitetets Affärsjuridik",
        "learning_objectives": "Understanding legal issues affecting businesses, including formation and liability.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.business_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Organizational Behavior",
        "id": "topic_University_OrgBehavior_Theories",
        "name_en": "Theories of Organizational Behavior for University Organizational Behavior",
        "name_sv": "Teorier om Organisatoriskt Beteende för Universitetets Organisatoriska Beteende",
        "learning_objectives": "Understanding key theories related to motivation, team dynamics, and leadership.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.org_behavior",
            "generalInterests.business",
            "generalInterests.human_resources"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Organizational Behavior",
        "id": "topic_University_OrgBehavior_Culture",
        "name_en": "Organizational Culture for University Organizational Behavior",
        "name_sv": "Organisationskultur för Universitetets Organisatoriska Beteende",
        "learning_objectives": "Examining how organizational culture affects behavior and performance.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.org_behavior",
            "generalInterests.business",
            "generalInterests.human_resources"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Finance",
        "id": "topic_University_Finance_InvestmentPrinciples",
        "name_en": "Investment Principles for University Finance",
        "name_sv": "Investeringsprinciper för Universitetets Finans",
        "learning_objectives": "Understanding different types of investments and associated risks.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.finance",
            "generalInterests.finance",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Finance",
        "id": "topic_University_Finance_CorporateFinance",
        "name_en": "Corporate Finance for University Finance",
        "name_sv": "Företagsfinansiering för Universitetets Finans",
        "learning_objectives": "Understanding financial management rules in corporations, capital budgeting, and financial analysis.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.finance",
            "generalInterests.finance",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Operations Management",
        "id": "topic_University_OperationsMgmt_Strategies",
        "name_en": "Operations Strategies for University Operations Management",
        "name_sv": "Operationsstrategier för Universitetets Operations Management",
        "learning_objectives": "Understanding the role of operations strategy in enhancing efficiency and quality.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.operations_mgmt",
            "generalInterests.operations",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "Operations Management",
        "id": "topic_University_OperationsMgmt_ProcessAnalysis",
        "name_en": "Process Analysis for University Operations Management",
        "name_sv": "Processanalys för Universitetets Operations Management",
        "learning_objectives": "Analyzing and improving operational processes within organizations.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.operations_mgmt",
            "generalInterests.operations",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "International Business",
        "id": "topic_University_InternationalBusiness_GlobalTrade",
        "name_en": "Global Trade for University International Business",
        "name_sv": "Global Handel för Universitetets Internationella Företagande",
        "learning_objectives": "Understanding the principles and practices of international trade.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.intl_business",
            "generalInterests.international_business",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Business",
        "subject": "International Business",
        "id": "topic_University_InternationalBusiness_CrossCulturalManagement",
        "name_en": "Cross-Cultural Management for University International Business",
        "name_sv": "Kulturövergripande Ledarskap för Universitetets Internationella Företagande",
        "learning_objectives": "Examining challenges and strategies in managing across different cultures.",
        "when_to_add": [
            "userType.university_student.universityProgram.business.universityCourses.intl_business",
            "generalInterests.international_business",
            "generalInterests.business"
        ]
    }
  ]

  export const law = [
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Introductory Topics",
        "id": "topic_University_IntroLaw_Overview",
        "name_en": "Overview of Legal Systems for University Introduction to Law",
        "name_sv": "Översikt över Rättssystem för Universitetets Introduktion till Rätt",
        "learning_objectives": "Understanding the structure and function of legal systems and the sources of law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.intro_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Introductory Topics",
        "id": "topic_University_IntroLaw_LegalResearch",
        "name_en": "Legal Research Methods for University Introduction to Law",
        "name_sv": "Rättsliga Forskningsmetoder för Universitetets Introduktion till Rätt",
        "learning_objectives": "Learning how to conduct legal research effectively and efficiently.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.intro_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Contract Law",
        "id": "topic_University_ContractLaw_Principles",
        "name_en": "Principles of Contract Law for University Contract Law",
        "name_sv": "Principer för Avtal för Universitetets Avtalsrätt",
        "learning_objectives": "Understanding the formation, execution, and enforcement of contracts.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.contract_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Contract Law",
        "id": "topic_University_ContractLaw_BreachAndRemedies",
        "name_en": "Breach of Contract and Remedies for University Contract Law",
        "name_sv": "Brist på Avtal och Åtgärder för Universitetets Avtalsrätt",
        "learning_objectives": "Examining the consequences of breach of contract and the available remedies.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.contract_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Criminal Law",
        "id": "topic_University_CriminalLaw_BasicPrinciples",
        "name_en": "Basic Principles of Criminal Law for University Criminal Law",
        "name_sv": "Grundprinciper för Strafflag för Universitetets Straffrätt",
        "learning_objectives": "Understanding the fundamental concepts and principles of criminal law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.criminal_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Criminal Law",
        "id": "topic_University_CriminalLaw_ElementsOfCrime",
        "name_en": "Elements of Crime for University Criminal Law",
        "name_sv": "Brottets Element för Universitetets Straffrätt",
        "learning_objectives": "Identifying and analyzing the elements that constitute a crime.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.criminal_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Property Law",
        "id": "topic_University_PropertyLaw_RealEstatePrinciples",
        "name_en": "Real Estate Principles for University Property Law",
        "name_sv": "Fastighetsprinciper för Universitetets Fastighetsrätt",
        "learning_objectives": "Understanding the legal principles governing real property ownership and transactions.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.property_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Property Law",
        "id": "topic_University_PropertyLaw_TransferOfProperty",
        "name_en": "Transfer of Property for University Property Law",
        "name_sv": "Överföring av Egendom för Universitetets Fastighetsrätt",
        "learning_objectives": "Examining the processes and legal considerations for transferring property.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.property_law",
            "generalInterests.law",
            "generalInterests.business"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Tort Law",
        "id": "topic_University_TortLaw_Negligence",
        "name_en": "Negligence for University Tort Law",
        "name_sv": "Vårdslöshet för Universitetets Skadeståndsrätt",
        "learning_objectives": "Understanding the legal standards and elements of negligence in tort law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.tort_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Tort Law",
        "id": "topic_University_TortLaw_IntentionalTorts",
        "name_en": "Intentional Torts for University Tort Law",
        "name_sv": "Avsiktliga Skador för Universitetets Skadeståndsrätt",
        "learning_objectives": "Analyzing different types of intentional torts and applicable defenses.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.tort_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "International Law",
        "id": "topic_University_InternationalLaw_Principles",
        "name_en": "Principles of International Law for University International Law",
        "name_sv": "Principer för Internationell Rätt för Universitetets Internationella Rätt",
        "learning_objectives": "Understanding the fundamental principles governing international legal relations.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.intl_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "International Law",
        "id": "topic_University_InternationalLaw_Treaties",
        "name_en": "Treaties and International Agreements for University International Law",
        "name_sv": "Fördrag och Internationella Avtal för Universitetets Internationella Rätt",
        "learning_objectives": "Exploring the nature and significance of treaties in international law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.intl_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Constitutional Law",
        "id": "topic_University_ConstitutionalLaw_Overview",
        "name_en": "Overview of Constitutional Principles for University Constitutional Law",
        "name_sv": "Översikt över Konstitutionella Principer för Universitetets Konstitutionell Rätt",
        "learning_objectives": "Understanding the framework and principles of constitutional law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.constitutional_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Constitutional Law",
        "id": "topic_University_ConstitutionalLaw_BillOfRights",
        "name_en": "The Bill of Rights for University Constitutional Law",
        "name_sv": "Rättighetsdeklarationen för Universitetets Konstitutionell Rätt",
        "learning_objectives": "Exploring rights and liberties enshrined in the Bill of Rights.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.constitutional_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Legal Writing",
        "id": "topic_University_LegalWriting_Research",
        "name_en": "Legal Research and Writing for University Legal Writing",
        "name_sv": "Rättslig Forskning och Skrivande för Universitetets Rättsliga Skrivande",
        "learning_objectives": "Developing skills in legal analysis and writing clear, concise legal documents.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.legal_writing",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Legal Writing",
        "id": "topic_University_LegalWriting_Formatting",
        "name_en": "Formatting Legal Documents for University Legal Writing",
        "name_sv": "Formatering av Rättsliga Dokument för Universitetets Rättsliga Skrivande",
        "learning_objectives": "Understanding the proper format and presentation of legal documents.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.legal_writing",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Administrative Law",
        "id": "topic_University_AdminLaw_Overview",
        "name_en": "Overview of Administrative Law for University Administrative Law",
        "name_sv": "Översikt över Förvaltningsrätt för Universitetets Förvaltningsrätt",
        "learning_objectives": "Understanding the nature, purpose, and scope of administrative law.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.admin_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    },
    {
        "curriculum": "University",
        "field": "Law",
        "subject": "Administrative Law",
        "id": "topic_University_AdminLaw_AgenciesAndRegulations",
        "name_en": "Administrative Agencies and Regulations for University Administrative Law",
        "name_sv": "Förvaltningsmyndigheter och Regler för Universitetets Förvaltningsrätt",
        "learning_objectives": "Analyzing the function and powers of administrative agencies.",
        "when_to_add": [
            "userType.university_student.universityProgram.law.universityCourses.admin_law",
            "generalInterests.law",
            "generalInterests.politics"
        ]
    }
  ]

  export const medicine = [
    {
        "curriculum": "Medical School",
        "field": "Human Anatomy",
        "subject": "Topics",
        "id": "topic_Medical_HumanAnatomy_SkeletalSystem",
        "name_en": "Skeletal System for Medical Anatomy",
        "name_sv": "Skeletsystem för Medicinsk Anatomi",
        "learning_objectives": "Understanding the structure and function of the human skeletal system.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_anatomy",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Human Anatomy",
        "subject": "Topics",
        "id": "topic_Medical_HumanAnatomy_MuscularSystem",
        "name_en": "Muscular System for Medical Anatomy",
        "name_sv": "Muskelsystem för Medicinsk Anatomi",
        "learning_objectives": "Understanding the anatomy and function of major muscle groups.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_anatomy",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Human Anatomy",
        "subject": "Topics",
        "id": "topic_Medical_HumanAnatomy_NervousSystem",
        "name_en": "Nervous System for Medical Anatomy",
        "name_sv": "Nervsystem för Medicinsk Anatomi",
        "learning_objectives": "Understanding the structure and function of the central and peripheral nervous systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_anatomy",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Human Physiology",
        "subject": "Topics",
        "id": "topic_Medical_HumanPhysiology_CellPhysiology",
        "name_en": "Cell Physiology for Medical Physiology",
        "name_sv": "Cellfysiologi för Medicinsk Fysiologi",
        "learning_objectives": "Understanding basic cellular functions and processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_physiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Human Physiology",
        "subject": "Topics",
        "id": "topic_Medical_HumanPhysiology_CardiovascularPhysiology",
        "name_en": "Cardiovascular Physiology for Medical Physiology",
        "name_sv": "Kardiovaskulär Fysiologi för Medicinsk Fysiologi",
        "learning_objectives": "Understanding the functions of the heart and blood vessels.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_physiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Human Physiology",
        "subject": "Topics",
        "id": "topic_Medical_HumanPhysiology_RespiratoryPhysiology",
        "name_en": "Respiratory Physiology for Medical Physiology",
        "name_sv": "Respiratorisk Fysiologi för Medicinsk Fysiologi",
        "learning_objectives": "Understanding the processes of respiration and gas exchange.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.human_physiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Biochemistry",
        "subject": "Topics",
        "id": "topic_Medical_Biochemistry_Macromolecules",
        "name_en": "Macromolecules for Medical Biochemistry",
        "name_sv": "Makromolekyler för Medicinsk Biokemi",
        "learning_objectives": "Understanding the structure and functions of proteins, carbohydrates, and lipids.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.biochemistry",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Biochemistry",
        "subject": "Topics",
        "id": "topic_Medical_Biochemistry_EnzymeKinetics",
        "name_en": "Enzyme Kinetics for Medical Biochemistry",
        "name_sv": "Enzymkinetik för Medicinsk Biokemi",
        "learning_objectives": "Understanding enzyme function, kinetics, and regulation.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.biochemistry",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Biochemistry",
        "subject": "Topics",
        "id": "topic_Medical_Biochemistry_Metabolism",
        "name_en": "Metabolism for Medical Biochemistry",
        "name_sv": "Metabolism för Medicinsk Biokemi",
        "learning_objectives": "Understanding metabolic pathways and their regulation.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.biochemistry",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Medical Ethics",
        "subject": "Topics",
        "id": "topic_Medical_Ethics_Bioethics",
        "name_en": "Bioethics for Medical Ethics",
        "name_sv": "Bioetik för Medicinsk Etik",
        "learning_objectives": "Understanding ethical issues in biotechnology and medicine.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.medical_ethics",
            "generalInterests.philosophy",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Medical Ethics",
        "subject": "Topics",
        "id": "topic_Medical_Ethics_InformedConsent",
        "name_en": "Informed Consent for Medical Ethics",
        "name_sv": "Informerat Samtycke för Medicinsk Etik",
        "learning_objectives": "Understanding the principles of informed consent and patient autonomy.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.medical_ethics",
            "generalInterests.philosophy",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Medical Ethics",
        "subject": "Topics",
        "id": "topic_Medical_Ethics_Confidentiality",
        "name_en": "Confidentiality for Medical Ethics",
        "name_sv": "Konfidentialitet för Medicinsk Etik",
        "learning_objectives": "Understanding the importance of patient confidentiality in healthcare.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.medical_ethics",
            "generalInterests.philosophy",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Microbiology",
        "subject": "Topics",
        "id": "topic_Medical_Microbiology_Bacteriology",
        "name_en": "Bacteriology for Medical Microbiology",
        "name_sv": "Bakteriologi för Medicinsk Mikrobiologi",
        "learning_objectives": "Understanding bacterial structure, metabolism, and pathogenesis.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.microbiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Microbiology",
        "subject": "Topics",
        "id": "topic_Medical_Microbiology_Virology",
        "name_en": "Virology for Medical Microbiology",
        "name_sv": "Virologi för Medicinsk Mikrobiologi",
        "learning_objectives": "Understanding viral structure, replication, and pathogenesis.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.microbiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Microbiology",
        "subject": "Topics",
        "id": "topic_Medical_Microbiology_Immunology",
        "name_en": "Immunology for Medical Microbiology",
        "name_sv": "Immunologi för Medicinsk Mikrobiologi",
        "learning_objectives": "Understanding the immune response and its components.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.microbiology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pathology",
        "subject": "Topics",
        "id": "topic_Medical_Pathology_GeneralPathology",
        "name_en": "General Pathology for Medical Pathology",
        "name_sv": "Allmän Patologi för Medicinsk Patologi",
        "learning_objectives": "Understanding the basic mechanisms of disease.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pathology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pathology",
        "subject": "Topics",
        "id": "topic_Medical_Pathology_SystemsPathology",
        "name_en": "Systems Pathology for Medical Pathology",
        "name_sv": "Systempatologi för Medicinsk Patologi",
        "learning_objectives": "Understanding pathology of specific organ systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pathology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pathology",
        "subject": "Topics",
        "id": "topic_Medical_Pathology_NeoplasticPathology",
        "name_en": "Neoplastic Pathology for Medical Pathology",
        "name_sv": "Neoplastisk Patologi för Medicinsk Patologi",
        "learning_objectives": "Understanding the mechanisms of tumorigenesis.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pathology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pharmacology",
        "subject": "Topics",
        "id": "topic_Medical_Pharmacology_BasicsOfPharmacology",
        "name_en": "Basics of Pharmacology for Medical Pharmacology",
        "name_sv": "Grunderna i Farmakologi för Medicinsk Farmakologi",
        "learning_objectives": "Understanding pharmacokinetics and pharmacodynamics.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pharmacology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pharmacology",
        "subject": "Topics",
        "id": "topic_Medical_Pharmacology_Therapeutics",
        "name_en": "Therapeutics for Medical Pharmacology",
        "name_sv": "Terapeutik för Medicinsk Farmakologi",
        "learning_objectives": "Understanding drug mechanisms and their therapeutic applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pharmacology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Pharmacology",
        "subject": "Topics",
        "id": "topic_Medical_Pharmacology_ClinicalPharmacology",
        "name_en": "Clinical Pharmacology for Medical Pharmacology",
        "name_sv": "Klinisk Farmakologi för Medicinsk Farmakologi",
        "learning_objectives": "Understanding the clinical aspects of drug therapy.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.pharmacology",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Neuroscience",
        "subject": "Topics",
        "id": "topic_Medical_Neuroscience_Neuroanatomy",
        "name_en": "Neuroanatomy for Medical Neuroscience",
        "name_sv": "Neuroanatomi för Medicinsk Neurovetenskap",
        "learning_objectives": "Understanding the structure of the nervous system.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.neuroscience",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Neuroscience",
        "subject": "Topics",
        "id": "topic_Medical_Neuroscience_Neurophysiology",
        "name_en": "Neurophysiology for Medical Neuroscience",
        "name_sv": "Neurofysiologi för Medicinsk Neurovetenskap",
        "learning_objectives": "Understanding basic functions of the nervous system.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.neuroscience",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Neuroscience",
        "subject": "Topics",
        "id": "topic_Medical_Neuroscience_Pathophysiology",
        "name_en": "Neuro-pathophysiology for Medical Neuroscience",
        "name_sv": "Neuro-patofysiologi för Medicinsk Neurovetenskap",
        "learning_objectives": "Understanding neurological disorders and their biological basis.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.neuroscience",
            "generalInterests.biomedical_sciences",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Clinical Skills",
        "subject": "Topics",
        "id": "topic_Medical_ClinicalSkills_Communication",
        "name_en": "Communication Skills for Medical Clinical Skills",
        "name_sv": "Kommunikationsfärdigheter för Medicinska Kliniska Färdigheter",
        "learning_objectives": "Building effective communication skills for patient interaction.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.clinical_skills",
            "generalInterests.healthcare",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Clinical Skills",
        "subject": "Topics",
        "id": "topic_Medical_ClinicalSkills_PhysicalExamination",
        "name_en": "Physical Examination Skills for Medical Clinical Skills",
        "name_sv": "Fysisk Undersökningsfärdigheter för Medicinska Kliniska Färdigheter",
        "learning_objectives": "Performing comprehensive physical examinations.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.clinical_skills",
            "generalInterests.healthcare",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Medical School",
        "field": "Clinical Skills",
        "subject": "Topics",
        "id": "topic_Medical_ClinicalSkills_ClinicalReasoning",
        "name_en": "Clinical Reasoning for Medical Clinical Skills",
        "name_sv": "Klinisk Resonemang för Medicinska Kliniska Färdigheter",
        "learning_objectives": "Understanding clinical reasoning and decision-making processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.medicine.universityCourses.clinical_skills",
            "generalInterests.healthcare",
            "generalInterests.health"
        ]
    }
];

  export const computer_science = [
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Introduction to Programming",
        "id": "topic_University_IntroProgramming_Fundamentals",
        "name_en": "Fundamentals of Programming for University Introduction to Programming",
        "name_sv": "Programmeringens Grunder för Universitetets Introduktion till Programmering",
        "learning_objectives": "Understanding the basic concepts of programming and problem-solving techniques.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.intro_programming",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Introduction to Programming",
        "id": "topic_University_IntroProgramming_ControlStructures",
        "name_en": "Control Structures for University Introduction to Programming",
        "name_sv": "Kontrollstrukturer för Universitetets Introduktion till Programmering",
        "learning_objectives": "Understanding conditional statements and loops in programming.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.intro_programming",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Introduction to Programming",
        "id": "topic_University_IntroProgramming_Functions",
        "name_en": "Functions and Procedures for University Introduction to Programming",
        "name_sv": "Funktioner och Procedurer för Universitetets Introduktion till Programmering",
        "learning_objectives": "Understanding how to define and use functions and procedures in programming.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.intro_programming",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Object-Oriented Programming",
        "id": "topic_University_OOP_ClassesAndObjects",
        "name_en": "Classes and Objects for University Object-Oriented Programming",
        "name_sv": "Klasser och Objekt för Universitetets Objektorienterade Programmering",
        "learning_objectives": "Understanding the concepts of classes, objects, and encapsulation.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.oop",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Object-Oriented Programming",
        "id": "topic_University_OOP_InheritanceAndPolymorphism",
        "name_en": "Inheritance and Polymorphism for University Object-Oriented Programming",
        "name_sv": "Ärvande och Polymorfism för Universitetets Objektorienterade Programmering",
        "learning_objectives": "Understanding the principles of inheritance, polymorphism, and dynamic method resolution.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.oop",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Object-Oriented Programming",
        "id": "topic_University_OOP_Abstraction",
        "name_en": "Abstraction for University Object-Oriented Programming",
        "name_sv": "Abstraktion för Universitetets Objektorienterade Programmering",
        "learning_objectives": "Understanding the concept of abstraction and how to implement it in programming.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.oop",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Data Structures",
        "id": "topic_University_DataStructures_LinearDataStructures",
        "name_en": "Linear Data Structures for University Data Structures",
        "name_sv": "Linjära Datatyper för Universitetets Datatyper",
        "learning_objectives": "Understanding linear data structures such as arrays, lists, and stacks.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.data_structures",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Data Structures",
        "id": "topic_University_DataStructures_TreesAndGraphs",
        "name_en": "Trees and Graphs for University Data Structures",
        "name_sv": "Träd och Grafer för Universitetets Datatyper",
        "learning_objectives": "Understanding tree and graph data structures and their applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.data_structures",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Data Structures",
        "id": "topic_University_DataStructures_ComplexityAnalysis",
        "name_en": "Complexity Analysis for University Data Structures",
        "name_sv": "Komplexitetsanalys för Universitetets Datatyper",
        "learning_objectives": "Analyzing the time and space complexity of data structures and algorithms.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.data_structures",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Algorithms",
        "id": "topic_University_Algorithms_SortingAlgorithms",
        "name_en": "Sorting Algorithms for University Algorithms",
        "name_sv": "Sorteringsalgoritmer för Universitetets Algoritmer",
        "learning_objectives": "Understanding and implementing various sorting algorithms and their efficiencies.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.algorithms",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Algorithms",
        "id": "topic_University_Algorithms_SearchingAlgorithms",
        "name_en": "Searching Algorithms for University Algorithms",
        "name_sv": "Sökningsalgoritmer för Universitetets Algoritmer",
        "learning_objectives": "Understanding searching algorithms and their applications in data retrieval.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.algorithms",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Algorithms",
        "id": "topic_University_Algorithms_GraphAlgorithms",
        "name_en": "Graph Algorithms for University Algorithms",
        "name_sv": "Grafalgoritmer för Universitetets Algoritmer",
        "learning_objectives": "Understanding common graph algorithms such as Dijkstra's and depth-first search.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.algorithms",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Computer Systems",
        "id": "topic_University_CompSystems_HardwareOverview",
        "name_en": "Overview of Computer Hardware for University Computer Systems",
        "name_sv": "Översikt över Datorhårdvara för Universitetets Datorsystem",
        "learning_objectives": "Understanding various hardware components and their functions.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.comp_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Computer Systems",
        "id": "topic_University_CompSystems_Architecture",
        "name_en": "Computer Architecture for University Computer Systems",
        "name_sv": "Datorarkitektur för Universitetets Datorsystem",
        "learning_objectives": "Understanding the organization and architecture of computer systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.comp_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Computer Systems",
        "id": "topic_University_CompSystems_I_OSystems",
        "name_en": "Input/Output Systems for University Computer Systems",
        "name_sv": "In-/Utgångssystem för Universitetets Datorsystem",
        "learning_objectives": "Understanding how input and output devices operate and interact with the computer.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.comp_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Operating Systems",
        "id": "topic_University_OperatingSystems_Processes",
        "name_en": "Processes for University Operating Systems",
        "name_sv": "Processer för Universitetets Operativsystem",
        "learning_objectives": "Understanding process management, scheduling, and synchronization.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.operating_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Operating Systems",
        "id": "topic_University_OperatingSystems_MemoryManagement",
        "name_en": "Memory Management for University Operating Systems",
        "name_sv": "Minnehantering för Universitetets Operativsystem",
        "learning_objectives": "Examining memory allocation, paging, and segmentation.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.operating_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Operating Systems",
        "id": "topic_University_OperatingSystems_FileSystems",
        "name_en": "File Systems for University Operating Systems",
        "name_sv": "Filhantering för Universitetets Operativsystem",
        "learning_objectives": "Understanding file operations, types, and organization.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.operating_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Database Systems",
        "id": "topic_University_DatabaseSystems_DataModeling",
        "name_en": "Data Modeling for University Database Systems",
        "name_sv": "Datamodellering för Universitetets Databassystem",
        "learning_objectives": "Learning how to design and implement data models.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.database_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Database Systems",
        "id": "topic_University_DatabaseSystems_SQL",
        "name_en": "SQL for University Database Systems",
        "name_sv": "SQL för Universitetets Databassystem",
        "learning_objectives": "Understanding Structured Query Language for database operations.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.database_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Database Systems",
        "id": "topic_University_DatabaseSystems_Normailization",
        "name_en": "Normalization for University Database Systems",
        "name_sv": "Normalisering för Universitetets Databassystem",
        "learning_objectives": "Understanding the principles of database normalization to reduce redundancy.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.database_systems",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Software Engineering",
        "id": "topic_University_SoftwareEng_SoftwareDevelopmentLifecycle",
        "name_en": "Software Development Lifecycle for University Software Engineering",
        "name_sv": "Programvaruutvecklingslivscykel för Universitetets Programvaruteknik",
        "learning_objectives": "Understanding the phases of software development from inception to delivery.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.software_eng",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Software Engineering",
        "id": "topic_University_SoftwareEng_AgileMethods",
        "name_en": "Agile Methods for University Software Engineering",
        "name_sv": "Agila Metoder för Universitetets Programvaruteknik",
        "learning_objectives": "Learning about Agile methodologies and their applications in software projects.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.software_eng",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Software Engineering",
        "id": "topic_University_SoftwareEng_Testing",
        "name_en": "Software Testing for University Software Engineering",
        "name_sv": "Programvarutestning för Universitetets Programvaruteknik",
        "learning_objectives": "Understanding different testing strategies and methodologies.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.software_eng",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Artificial Intelligence",
        "id": "topic_University_AI_Foundations",
        "name_en": "Foundations of Artificial Intelligence for University Artificial Intelligence",
        "name_sv": "Grunderna för Artificiell Intelligens för Universitetets Artificiell Intelligens",
        "learning_objectives": "Understanding the key concepts and techniques in AI.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.ai",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Artificial Intelligence",
        "id": "topic_University_AI_MachineLearning",
        "name_en": "Machine Learning for University Artificial Intelligence",
        "name_sv": "Maskininlärning för Universitetets Artificiell Intelligens",
        "learning_objectives": "Understanding the fundamentals and algorithms of machine learning.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.ai",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Artificial Intelligence",
        "id": "topic_University_AI_Applications",
        "name_en": "Applications of Artificial Intelligence for University Artificial Intelligence",
        "name_sv": "Tillämpningar av Artificiell Intelligens för Universitetets Artificiell Intelligens",
        "learning_objectives": "Exploring various applications of AI across different domains.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.ai",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_University_MachineLearning_SupervisedLearning",
        "name_en": "Supervised Learning for University Machine Learning",
        "name_sv": "Övervakad Inlärning för Universitetets Maskininlärning",
        "learning_objectives": "Understanding supervised learning techniques and their applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.machine_learning",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_University_MachineLearning_UnsupervisedLearning",
        "name_en": "Unsupervised Learning for University Machine Learning",
        "name_sv": "Oövervakad Inlärning för Universitetets Maskininlärning",
        "learning_objectives": "Understanding unsupervised learning techniques and clustering methods.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.machine_learning",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_University_MachineLearning_DeepLearning",
        "name_en": "Deep Learning for University Machine Learning",
        "name_sv": "Djupinlärning för Universitetets Maskininlärning",
        "learning_objectives": "Understanding deep learning architectures and neural networks.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.machine_learning",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Web Development",
        "id": "topic_University_WebDevelopment_FrontendDevelopment",
        "name_en": "Frontend Development for University Web Development",
        "name_sv": "Frontendutveckling för Universitetets WebbUtveckling",
        "learning_objectives": "Understanding HTML, CSS, and JavaScript for creating user interfaces.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.web_dev",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Web Development",
        "id": "topic_University_WebDevelopment_BackendDevelopment",
        "name_en": "Backend Development for University Web Development",
        "name_sv": "Backendutveckling för Universitetets WebbUtveckling",
        "learning_objectives": "Understanding server-side programming and database interaction.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.web_dev",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Web Development",
        "id": "topic_University_WebDevelopment_FullStackDevelopment",
        "name_en": "Full Stack Development for University Web Development",
        "name_sv": "Full Stack Utveckling för Universitetets WebbUtveckling",
        "learning_objectives": "Understanding the integration of frontend and backend technologies.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.web_dev",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Networks and Security",
        "id": "topic_University_NetworksSecurity_NetworkFundamentals",
        "name_en": "Network Fundamentals for University Networks and Security",
        "name_sv": "Nätverksgrundläggande för Universitetets Nätverks- och Säkerhetssystem",
        "learning_objectives": "Understanding the basic concepts of networking and protocols.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.networks_security",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Networks and Security",
        "id": "topic_University_NetworksSecurity_CyberSecurity",
        "name_en": "Cybersecurity for University Networks and Security",
        "name_sv": "Cybersäkerhet för Universitetets Nätverks- och Säkerhetssystem",
        "learning_objectives": "Understanding the principles and practices of cybersecurity.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.networks_security",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Computer Science",
        "subject": "Networks and Security",
        "id": "topic_University_NetworksSecurity_Encryption",
        "name_en": "Encryption Techniques for University Networks and Security",
        "name_sv": "Kryptering för Universitetets Nätverks- och Säkerhetssystem",
        "learning_objectives": "Understanding various encryption techniques and their applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.computer_science.universityCourses.networks_security",
            "generalInterests.computer_science",
            "generalInterests.technology"
        ]
    }
  ]

  export const psychology = [
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Introduction Topics",
        "id": "topic_University_IntroPsych_OverviewOfPsychology",
        "name_en": "Overview of Psychology for Introduction to Psychology",
        "name_sv": "Översikt av Psykologi för Introduktion till Psykologi",
        "learning_objectives": "Understanding the main concepts, history, and subfields of psychology.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.intro_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Introduction Topics",
        "id": "topic_University_IntroPsych_HistoryOfPsychology",
        "name_en": "History of Psychology for Introduction to Psychology",
        "name_sv": "Psykologins Historia för Introduktion till Psykologi",
        "learning_objectives": "Examining the development of psychology from ancient times to the modern era.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.intro_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Developmental Psychology",
        "id": "topic_University_DevPsych_DevelopmentStages",
        "name_en": "Developmental Stages for Developmental Psychology",
        "name_sv": "Utvecklingsstadier för Utvecklingspsykologi",
        "learning_objectives": "Understanding the major developmental stages across the lifespan.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.dev_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Developmental Psychology",
        "id": "topic_University_DevPsych_TheoriesOfDevelopment",
        "name_en": "Theories of Development for Developmental Psychology",
        "name_sv": "Utvecklingsteorier för Utvecklingspsykologi",
        "learning_objectives": "Examining major theories of development, such as Piaget and Erikson.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.dev_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Cognitive Psychology",
        "id": "topic_University_CogPsych_CognitiveProcesses",
        "name_en": "Cognitive Processes for Cognitive Psychology",
        "name_sv": "Kognitiva Processer för Kognitiv Psykologi",
        "learning_objectives": "Understanding perception, memory, and problem-solving processes.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.cognitive_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Cognitive Psychology",
        "id": "topic_University_CogPsych_Psycholinguistics",
        "name_en": "Psycholinguistics for Cognitive Psychology",
        "name_sv": "Psykolingvistik för Kognitiv Psykologi",
        "learning_objectives": "Exploring the relationship between language and cognition.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.cognitive_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Abnormal Psychology",
        "id": "topic_University_AbPsych_ClassificationOfDisorders",
        "name_en": "Classification of Disorders for Abnormal Psychology",
        "name_sv": "Klassifikation av Störningar för Abnormal Psykologi",
        "learning_objectives": "Understanding the DSM-5 and the classification of psychological disorders.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.abnormal_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Abnormal Psychology",
        "id": "topic_University_AbPsych_TreatmentApproaches",
        "name_en": "Treatment Approaches for Abnormal Psychology",
        "name_sv": "Behandlingsmetoder för Abnormal Psykologi",
        "learning_objectives": "Exploring various treatment methods including psychotherapy and pharmacotherapy.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.abnormal_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Social Psychology",
        "id": "topic_University_SocPsych_AttractionAndRelationships",
        "name_en": "Attraction and Relationships for Social Psychology",
        "name_sv": "Attraktion och Relationer för Social Psykologi",
        "learning_objectives": "Understanding the factors influencing attraction and interpersonal relations.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.social_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Social Psychology",
        "id": "topic_University_SocPsych_GroupDynamics",
        "name_en": "Group Dynamics for Social Psychology",
        "name_sv": "Gruppdynamik för Social Psykologi",
        "learning_objectives": "Studying how group behaviors and dynamics affect individual behavior.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.social_psychology",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Neuroscience",
        "id": "topic_University_Neuroscience_BrainAndBehavior",
        "name_en": "Brain and Behavior for Neuroscience",
        "name_sv": "Hjärna och Beteende för Neurovetenskap",
        "learning_objectives": "Understanding the relationship between brain structure/function and behavior.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.neuroscience",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Neuroscience",
        "id": "topic_University_Neuroscience_Neurotransmitters",
        "name_en": "Neurotransmitters and Their Functions for Neuroscience",
        "name_sv": "Neurotransmittorer och Deras Funktioner för Neurovetenskap",
        "learning_objectives": "Examining various neurotransmitters and their roles in mood and behavior.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.neuroscience",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Research Methods",
        "id": "topic_University_ResearchMethods_ExperimentalDesign",
        "name_en": "Experimental Design for Research Methods",
        "name_sv": "Experimentell Design för Forskningsmetoder",
        "learning_objectives": "Understanding how to design and conduct psychological experiments.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.research_methods",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Research Methods",
        "id": "topic_University_ResearchMethods_DataAnalysis",
        "name_en": "Data Analysis for Research Methods",
        "name_sv": "Dataanalys för Forskningsmetoder",
        "learning_objectives": "Applying statistical methods to analyze psychological research data.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.research_methods",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Personality Psychology",
        "id": "topic_University_PersonalityPsych_TraitsTheories",
        "name_en": "Traits Theories for Personality Psychology",
        "name_sv": "Egenskapsmodeller för Personlighetspsykologi",
        "learning_objectives": "Examining trait theories and their implications for understanding personality.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.personality_psych",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Personality Psychology",
        "id": "topic_University_PersonalityPsych_Assessment",
        "name_en": "Personality Assessment for Personality Psychology",
        "name_sv": "Personlighetsbedömning för Personlighetspsykologi",
        "learning_objectives": "Understanding methods of personality assessment and their effectiveness.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.personality_psych",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Psychological Assessment",
        "id": "topic_University_PsychAssessment_TypesOfTests",
        "name_en": "Types of Psychological Tests for Psychological Assessment",
        "name_sv": "Typer av Psykologiska Tester för Psykologisk Bedömning",
        "learning_objectives": "Understanding various types of psychological tests and their applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.psych_assessment",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "University",
        "field": "Psychology",
        "subject": "Psychological Assessment",
        "id": "topic_University_PsychAssessment_InterpretingResults",
        "name_en": "Interpreting Psychological Assessment Results for Psychological Assessment",
        "name_sv": "Tolkning av Resultat från Psykologisk Bedömning",
        "learning_objectives": "Learning how to interpret and communicate assessment results effectively.",
        "when_to_add": [
            "userType.university_student.universityProgram.psychology.universityCourses.psych_assessment",
            "generalInterests.psychology",
            "generalInterests.science"
        ]
    }
  ]

  export const education = [
    {
        "curriculum": "University",
        "field": "Foundations of Education",
        "subject": "Topics",
        "id": "topic_University_FoundationsOfEducation_HistoryAndPhilosophy",
        "name_en": "History and Philosophy of Education for Foundations of Education",
        "name_sv": "Utbildningens Historia och Filosofi för Utbildningens Grunder",
        "learning_objectives": "Understanding the historical perspectives and philosophical foundations of education.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.foundations_edu",
            "generalInterests.education",
            "generalInterests.social_sciences"
        ]
    },
    {
        "curriculum": "University",
        "field": "Foundations of Education",
        "subject": "Topics",
        "id": "topic_University_FoundationsOfEducation_SocialJustice",
        "name_en": "Social Justice in Education for Foundations of Education",
        "name_sv": "Social Rättvisa i Utbildning för Utbildningens Grunder",
        "learning_objectives": "Exploring the role of education in promoting social justice and equity.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.foundations_edu",
            "generalInterests.education",
            "generalInterests.social_sciences"
        ]
    },
    {
        "curriculum": "University",
        "field": "Educational Psychology",
        "subject": "Topics",
        "id": "topic_University_EducationalPsychology_DevelopmentTheories",
        "name_en": "Learning and Development Theories for Educational Psychology",
        "name_sv": "Inlärnings- och Utvecklingsteorier för Utbildningspsykologi",
        "learning_objectives": "Understanding major theories of learning, development, and their application in education.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.edu_psychology",
            "generalInterests.education",
            "generalInterests.psychology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Educational Psychology",
        "subject": "Topics",
        "id": "topic_University_EducationalPsychology_Motivation",
        "name_en": "Motivation and Learning for Educational Psychology",
        "name_sv": "Motivation och Inlärning för Utbildningspsykologi",
        "learning_objectives": "Examining factors that influence motivation and learning in educational settings.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.edu_psychology",
            "generalInterests.education",
            "generalInterests.psychology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Curriculum Development",
        "subject": "Topics",
        "id": "topic_University_CurriculumDevelopment_TheoryAndDesign",
        "name_en": "Curriculum Theory and Design for Curriculum Development",
        "name_sv": "Läroplans Teori och Design för Läroplansutveckling",
        "learning_objectives": "Understanding the principles of curriculum design and evaluation.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.curriculum_dev",
            "generalInterests.education",
            "generalInterests.curriculum"
        ]
    },
    {
        "curriculum": "University",
        "field": "Curriculum Development",
        "subject": "Topics",
        "id": "topic_University_CurriculumDevelopment_Implementation",
        "name_en": "Curriculum Implementation for Curriculum Development",
        "name_sv": "Genomförande av Läroplanen för Läroplansutveckling",
        "learning_objectives": "Strategies for implementing and managing curriculum changes in educational settings.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.curriculum_dev",
            "generalInterests.education",
            "generalInterests.curriculum"
        ]
    },
    {
        "curriculum": "University",
        "field": "Classroom Management",
        "subject": "Topics",
        "id": "topic_University_ClassroomManagement_BehavioralStrategies",
        "name_en": "Behavioral Strategies for Classroom Management",
        "name_sv": "Beteendesstrategier för Klassrumsledning",
        "learning_objectives": "Understanding effective behavioral management techniques for diverse classrooms.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.class_mgmt",
            "generalInterests.education",
            "generalInterests.psychology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Classroom Management",
        "subject": "Topics",
        "id": "topic_University_ClassroomManagement_EnvironmentSetup",
        "name_en": "Creating a Positive Classroom Environment for Classroom Management",
        "name_sv": "Skapa en Positiv Klassen Miljö för Klassrumsledning",
        "learning_objectives": "Techniques for establishing a positive and productive classroom atmosphere.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.class_mgmt",
            "generalInterests.education",
            "generalInterests.psychology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Assessment and Evaluation",
        "subject": "Topics",
        "id": "topic_University_AssessmentAndEvaluation_FormativeAssessment",
        "name_en": "Formative Assessment in Education for Assessment and Evaluation",
        "name_sv": "Formativ Bedömning i Utbildning för Bedömning och Utvärdering",
        "learning_objectives": "Using formative assessments to inform instruction and support student learning.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.assessment_eval",
            "generalInterests.education",
            "generalInterests.assessment"
        ]
    },
    {
        "curriculum": "University",
        "field": "Assessment and Evaluation",
        "subject": "Topics",
        "id": "topic_University_AssessmentAndEvaluation_SummativeAssessment",
        "name_en": "Summative Assessment in Education for Assessment and Evaluation",
        "name_sv": "Summativ Bedömning i Utbildning för Bedömning och Utvärdering",
        "learning_objectives": "Designing and implementing effective summative assessments to evaluate student learning.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.assessment_eval",
            "generalInterests.education",
            "generalInterests.assessment"
        ]
    },
    {
        "curriculum": "University",
        "field": "Special Education",
        "subject": "Topics",
        "id": "topic_University_SpecialEducation_LearningDifferences",
        "name_en": "Understanding Learning Differences for Special Education",
        "name_sv": "Förståelse för Inlärningsskillnader för Specialutbildning",
        "learning_objectives": "Exploring the diverse learning needs and capabilities of students.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.special_education",
            "generalInterests.education",
            "generalInterests.special_needs"
        ]
    },
    {
        "curriculum": "University",
        "field": "Special Education",
        "subject": "Topics",
        "id": "topic_University_SpecialEducation_Interventions",
        "name_en": "Interventions for Diverse Learners for Special Education",
        "name_sv": "Insatser för Mångsidiga Lärande för Specialutbildning",
        "learning_objectives": "Strategies for designing effective interventions for special needs students.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.special_education",
            "generalInterests.education",
            "generalInterests.special_needs"
        ]
    },
    {
        "curriculum": "University",
        "field": "Teaching Practicum",
        "subject": "Topics",
        "id": "topic_University_TeachingPracticum_ClassroomExperience",
        "name_en": "Classroom Experience for Teaching Practicum",
        "name_sv": "Klassrumserfarenhet för Lärarpraktik",
        "learning_objectives": "Gaining hands-on experience in various classroom settings.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.teaching_practicum",
            "generalInterests.education",
            "generalInterests.teaching"
        ]
    },
    {
        "curriculum": "University",
        "field": "Teaching Practicum",
        "subject": "Topics",
        "id": "topic_University_TeachingPracticum_ReflectivePractice",
        "name_en": "Reflective Practice for Teaching Practicum",
        "name_sv": "Reflekterande Praktik för Lärarpraktik",
        "learning_objectives": "Developing the ability to reflect on teaching practices for continuous improvement.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.teaching_practicum",
            "generalInterests.education",
            "generalInterests.teaching"
        ]
    },
    {
        "curriculum": "University",
        "field": "Educational Technology",
        "subject": "Topics",
        "id": "topic_University_EducationalTechnology_IntegratingTechnology",
        "name_en": "Integrating Technology in Education for Educational Technology",
        "name_sv": "Integrera Teknik i Utbildning för Utbildningsteknologi",
        "learning_objectives": "Exploring various technologies and their applications in educational contexts.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.edu_technology",
            "generalInterests.education",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "University",
        "field": "Educational Technology",
        "subject": "Topics",
        "id": "topic_University_EducationalTechnology_Effectiveness",
        "name_en": "Effectiveness of Educational Technologies for Educational Technology",
        "name_sv": "Effektivitet i Utbildningsteknologi för Utbildningsteknologi",
        "learning_objectives": "Evaluating the effectiveness of various educational technologies on learning outcomes.",
        "when_to_add": [
            "userType.university_student.universityProgram.education.universityCourses.edu_technology",
            "generalInterests.education",
            "generalInterests.technology"
        ]
    }
  ]

  export const architecture = [
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Design Studio I",
        "id": "topic_University_DesignStudioI_ConceptDevelopment",
        "name_en": "Concept Development for University Design Studio I",
        "name_sv": "Konceptutveckling för Universitetets Design Studio I",
        "learning_objectives": "Exploring design concepts and spatial organization through project development.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.design_studio1",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Design Studio I",
        "id": "topic_University_DesignStudioI_SiteAnalysis",
        "name_en": "Site Analysis for University Design Studio I",
        "name_sv": "Platsanalys för Universitetets Design Studio I",
        "learning_objectives": "Understanding site conditions, context, and factors influencing design.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.design_studio1",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Design Studio I",
        "id": "topic_University_DesignStudioI_ModelMaking",
        "name_en": "Model Making for University Design Studio I",
        "name_sv": "Modellbyggande för Universitetets Design Studio I",
        "learning_objectives": "Developing physical models to represent design ideas.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.design_studio1",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Design Studio II",
        "id": "topic_University_DesignStudioII_AdvancedDesignConcepts",
        "name_en": "Advanced Design Concepts for University Design Studio II",
        "name_sv": "Avancerade Designkoncept för Universitetets Design Studio II",
        "learning_objectives": "Exploring complex design ideas and implementing advanced design techniques.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.design_studio2",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Design Studio II",
        "id": "topic_University_DesignStudioII_IntegrationOfSystems",
        "name_en": "Integration of Systems for University Design Studio II",
        "name_sv": "Integrering av System för Universitetets Design Studio II",
        "learning_objectives": "Understanding the relationship between design elements and building systems.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.design_studio2",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Architectural History",
        "id": "topic_University_ArchHistory_StylesAndMovements",
        "name_en": "Architectural Styles and Movements for University Architectural History",
        "name_sv": "Arkitektoniska Stilar och Rörelser för Universitetets Arkitekturhistoria",
        "learning_objectives": "Understanding key architectural movements and their historical context.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.arch_history",
            "generalInterests.architecture",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Architectural History",
        "id": "topic_University_ArchHistory_BuildingTypes",
        "name_en": "Building Types and Their Evolution for University Architectural History",
        "name_sv": "Byggnadstyper och Deras Utveckling för Universitetets Arkitekturhistoria",
        "learning_objectives": "Analyzing various building types and their development through history.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.arch_history",
            "generalInterests.architecture",
            "generalInterests.history"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Building Technology",
        "id": "topic_University_BuildingTech_Materials",
        "name_en": "Materials for University Building Technology",
        "name_sv": "Material för Universitetets Byggteknik",
        "learning_objectives": "Studying various building materials and their properties.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.building_tech",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Building Technology",
        "id": "topic_University_BuildingTech_ConstructionMethods",
        "name_en": "Construction Methods for University Building Technology",
        "name_sv": "Byggmetoder för Universitetets Byggteknik",
        "learning_objectives": "Understanding different construction techniques and their applications.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.building_tech",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Structures",
        "id": "topic_University_Structures_StructuralAnalysis",
        "name_en": "Structural Analysis for University Structures",
        "name_sv": "Strukturell Analys för Universitetets Strukturer",
        "learning_objectives": "Analyzing forces and moments in structural elements.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.structures",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Structures",
        "id": "topic_University_Structures_MaterialsBehavior",
        "name_en": "Behavior of Materials in Structures for University Structures",
        "name_sv": "Materialers Beteende i Strukturer för Universitetets Strukturer",
        "learning_objectives": "Understanding how different materials behave under various loads.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.structures",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Environmental Systems",
        "id": "topic_University_EnvSystems_SustainableDesign",
        "name_en": "Sustainable Design Practices for Environmental Systems",
        "name_sv": "Hållbara Designmetoder för Miljösystem",
        "learning_objectives": "Incorporating sustainable practices into design to minimize environmental impact.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.env_systems",
            "generalInterests.architecture",
            "generalInterests.environment"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Environmental Systems",
        "id": "topic_University_EnvSystems_EnergyEfficiency",
        "name_en": "Energy Efficiency in Buildings for University Environmental Systems",
        "name_sv": "Energieffektivitet i Byggnader för Universitetets Miljösystem",
        "learning_objectives": "Understanding energy-efficient design and its importance in architecture.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.env_systems",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Digital Design",
        "id": "topic_University_DigitalDesign_ModelingSoftware",
        "name_en": "Modeling Software for University Digital Design",
        "name_sv": "Modelleringsprogramvara för Universitetets Digital Design",
        "learning_objectives": "Using software tools for architectural modeling and design visualization.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.digital_design",
            "generalInterests.architecture",
            "generalInterests.design"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Digital Design",
        "id": "topic_University_DigitalDesign_DigitalFabrication",
        "name_en": "Digital Fabrication Techniques for University Digital Design",
        "name_sv": "Digitala Fabrikationstekniker för Universitetets Digital Design",
        "learning_objectives": "Exploring digital fabrication methods including 3D printing and CNC machining.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.digital_design",
            "generalInterests.architecture",
            "generalInterests.engineering"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Urban Planning",
        "id": "topic_University_UrbanPlanning_CityDesign",
        "name_en": "City Design and Planning for University Urban Planning",
        "name_sv": "Stadsdesign och Planering för Universitetets Stadsplanering",
        "learning_objectives": "Analyzing urban design principles and their applications in contemporary cities.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.urban_planning",
            "generalInterests.architecture",
            "generalInterests.urbanism"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Urban Planning",
        "id": "topic_University_UrbanPlanning_PublicSpaces",
        "name_en": "Designing Public Spaces for University Urban Planning",
        "name_sv": "Design av Offentliga Utrymmen för Universitetets Stadsplanering",
        "learning_objectives": "Understanding the role of public spaces in urban environments and design principles.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.urban_planning",
            "generalInterests.architecture",
            "generalInterests.urbanism"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Sustainable Design",
        "id": "topic_University_SustainableDesign_Principles",
        "name_en": "Principles of Sustainable Design for University Sustainable Design",
        "name_sv": "Principer för Hållbar Design för Universitetets Hållbara Design",
        "learning_objectives": "Incorporating sustainability principles into architectural design.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.sustainable_design",
            "generalInterests.architecture",
            "generalInterests.environment"
        ]
    },
    {
        "curriculum": "University",
        "field": "Architecture",
        "subject": "Sustainable Design",
        "id": "topic_University_SustainableDesign_MaterialSelection",
        "name_en": "Material Selection for Sustainable Design for University Sustainable Design",
        "name_sv": "Materialval för Hållbar Design för Universitetets Hållbara Design",
        "learning_objectives": "Evaluating and selecting sustainable materials for architectural projects.",
        "when_to_add": [
            "userType.university_student.universityProgram.architecture.universityCourses.sustainable_design",
            "generalInterests.architecture",
            "generalInterests.environment"
        ]
    }
  ]

