import Link from 'next/link';

export default function SimpleMenu() {
  const navItems = [
    { text: 'Home', link: '/' },
    // {
    //   text: 'About us',
    //   subItems: [
    //     { text: 'Blog', link: '/blog' },
    //     { text: 'About Us', link: '/about-us' },
    //   ],
    // },
    // {
    //   text: 'Features',
    //   subItems: [
    //     { text: 'Youtube summaries and quizzes', link: '/youtube-summarizer-quiz-generator' },
    //     { text: 'PDF summaries and quizzes', link: '/pdf-summarizer-quiz-generator' },
    //     { text: 'Webpage summaries and quizzes', link: '/website-summarizer-quiz-generator' },
    //     { text: 'Manual summaries and quizzes', link: '/manual-text-summarizer-quiz-generator' },
    //     { text: 'Auto generation and quizzes', link: '/automatic-quiz-generator' },
    //   ],
    // },
    // Add other items as needed
  ];

  return (
    <nav className="simple-menu">
      <ul>
        {navItems.map((item, index) => (
          <li key={index}>
            {/* If the item has a link, render it as a Link; otherwise, just display the text */}
            {item.link ? (
              <Link href={item.link}>{item.text}</Link>
            ) : (
              <span>{item.text}</span>
            )}

            {/* If the item has subItems, render them in a nested list */}
            {item.subItems && (
              <ul>
                {item.subItems.map((subItem, subIndex) => (
                  <li key={subIndex}>
                    <Link href={subItem.link}>{subItem.text}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}