'use client';

import _toConsumableArray from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _createReducer;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { createReducer } from '@reduxjs/toolkit';
import { WS_CONNECTED, WS_MESSAGE_RECEIVED, WS_DISCONNECTED, CLEAR_MESSAGES, UPDATE_CHARACTERS, CURRENT_FACT, UPDATE_NEW_FACT_FOCUS_STATUS, ADD_PENDING_FACT_UPDATE, PROCESS_PENDING_FACT_UPDATE, CLEAR_PENDING_FACT_UPDATE, UPDATE_QUIZZES_COMPLETED, SET_USER_PROFILE, UPDATE_ITERATION, ADD_TO_COMPLETED_ITERATIONS, SET_CURRENT_ITERATION, SET_USER_FACT_GROUP, SET_USER_FACT, SET_HAS_USER_CREDIT_CARD, ADD_USED_RESOURCE, SET_USER_SUBSCRIPTION, UPDATE_LANGUAGE, SET_QUIZZES_COMPLETED, SET_GAME_CHARACTERS, HAS_GOTTEN_USER_POINTS } from './actions';
export var wsReducer = createReducer({
  isConnected: false,
  messages: [],
  quizzesCompleted: {},
  currentFact: false,
  isNewFactFocused: true,
  pendingFactUpdates: [],
  userProfile: {}
}, (_createReducer = {}, _defineProperty(_createReducer, WS_CONNECTED.type, function (state) {
  state.isConnected = true;
}), _defineProperty(_createReducer, WS_DISCONNECTED.type, function (state) {
  state.isConnected = false;
  state.messages = [];
}), _defineProperty(_createReducer, WS_MESSAGE_RECEIVED.type, function (state, action) {
  console.log('did recieve a message');
  state.messages.push(action.payload);
}), _defineProperty(_createReducer, CLEAR_MESSAGES.type, function (state) {
  state.messages = [];
}), _defineProperty(_createReducer, ADD_PENDING_FACT_UPDATE.type, function (state, action) {
  state.pendingFactUpdates.push(action.payload);
}), _defineProperty(_createReducer, PROCESS_PENDING_FACT_UPDATE.type, function (state, action) {
  // Concats pendingMessages to messages
  state.messages = state.messages.concat(state.pendingFactUpdates);
}), _defineProperty(_createReducer, CLEAR_PENDING_FACT_UPDATE.type, function (state) {
  state.pendingFactUpdates = [];
}), _defineProperty(_createReducer, SET_QUIZZES_COMPLETED.type, function (state, action) {
  state.quizzesCompleted = action.payload;
}), _defineProperty(_createReducer, UPDATE_QUIZZES_COMPLETED.type, function (state, action) {
  var topic = action.payload;
  var currentCount = state.quizzesCompleted[topic] || 0;
  state.quizzesCompleted = _objectSpread(_objectSpread({}, state.quizzesCompleted), {}, _defineProperty({}, topic, currentCount + 1));
}), _defineProperty(_createReducer, UPDATE_NEW_FACT_FOCUS_STATUS.type, function (state, action) {
  state.isFocused = action.payload;
}), _defineProperty(_createReducer, CURRENT_FACT.type, function (state, action) {
  state.currentFact = action.payload;
}), _defineProperty(_createReducer, SET_USER_PROFILE.type, function (state, action) {
  state.userProfile = action.payload;
}), _defineProperty(_createReducer, SET_USER_FACT_GROUP.type, function (state, action) {
  state.userProfile.facts[action.payload.categoryName] = action.payload.factGroup;
}), _defineProperty(_createReducer, SET_USER_FACT.type, function (state, action) {
  state.userProfile.facts[action.payload.categoryName][action.payload.id] = action.payload.fact;
}), _defineProperty(_createReducer, SET_USER_SUBSCRIPTION.type, function (state, action) {
  state.userProfile.activeSubscription = action.payload;
}), _defineProperty(_createReducer, ADD_TO_COMPLETED_ITERATIONS.type, function (state, action) {
  var specificFact = state.userProfile.facts[action.payload.groupName][action.payload.id];
  specificFact.completedIterations = specificFact.completedIterations || [];
  specificFact.completedIterations.push({
    time: Date.now(),
    correct: action.payload.sessionCorrect,
    total: action.payload.sessionTotal,
    grade: action.payload.grade
  });
  state.userProfile.facts[action.payload.groupName][action.payload.id] = specificFact;
}), _defineProperty(_createReducer, UPDATE_CHARACTERS.type, function (state, action) {
  var _action$payload = action.payload,
    category = _action$payload.category,
    id = _action$payload.id,
    characters = _action$payload.characters;
  return _objectSpread(_objectSpread({}, state), {}, {
    userProfile: _objectSpread(_objectSpread({}, state.userProfile), {}, {
      facts: _objectSpread(_objectSpread({}, state.userProfile.facts), {}, _defineProperty({}, category, _objectSpread(_objectSpread({}, state.userProfile.facts[category]), {}, _defineProperty({}, id, _objectSpread(_objectSpread({}, state.userProfile.facts[category][id]), {}, {
        gameCharacters: characters
      })))))
    })
  });
}), _defineProperty(_createReducer, SET_CURRENT_ITERATION.type, function (state, action) {
  var _action$payload2 = action.payload,
    category = _action$payload2.category,
    id = _action$payload2.id,
    newIteration = _action$payload2.newIteration,
    characters = _action$payload2.characters;
  if (!state.userProfile.facts) {
    state.userProfile.facts = {};
  }
  if (!state.userProfile.facts[category]) {
    state.userProfile.facts[category] = {};
  }
  if (!state.userProfile.facts[category][id]) {
    state.userProfile.facts[category][id] = {
      currentIteration: newIteration,
      gameCharacters: characters
    };
  }
  var updatedFacts = _objectSpread({}, state.userProfile.facts);
  updatedFacts[category][id] = _objectSpread(_objectSpread({}, updatedFacts[category][id]), {}, {
    gameCharacters: characters,
    currentIteration: _toConsumableArray(newIteration)
  });

  // updatedFacts[category][id].currentIteration[iterationIndex] = newCurrentFact;

  var newUserProfile = _objectSpread(_objectSpread({}, state.userProfile), {}, {
    facts: updatedFacts
  });
  state.userProfile = newUserProfile;
}), _defineProperty(_createReducer, SET_HAS_USER_CREDIT_CARD.type, function (state, action) {
  state.userProfile.hasCreditCard = action.payload;
}), _defineProperty(_createReducer, UPDATE_LANGUAGE.type, function (state, action) {
  state.userProfile.language = action.payload;
}), _defineProperty(_createReducer, ADD_USED_RESOURCE.type, function (state, action) {
  if (!state.userProfile.consumedResources) {
    state.userProfile.consumedResources = _defineProperty({}, action.payload.element, action.payload.number);
  } else {
    state.userProfile.consumedResources[action.payload.element] = action.payload.number;
  }
}), _defineProperty(_createReducer, UPDATE_ITERATION.type, function (state, action) {
  var _action$payload3 = action.payload,
    category = _action$payload3.category,
    id = _action$payload3.id,
    iterationIndex = _action$payload3.iterationIndex,
    newCurrentFact = _action$payload3.newCurrentFact;
  if (!state.userProfile.facts) {
    state.userProfile.facts = {};
  }
  if (!state.userProfile.facts[category]) {
    state.userProfile.facts[category] = {};
  }
  if (!state.userProfile.facts[category][id]) {
    state.userProfile.facts[category][id] = {
      currentIteration: []
    };
  }
  var updatedFacts = _objectSpread({}, state.userProfile.facts);
  updatedFacts[category][id] = _objectSpread(_objectSpread({}, updatedFacts[category][id]), {}, {
    currentIteration: _toConsumableArray(updatedFacts[category][id].currentIteration)
  });
  updatedFacts[category][id].currentIteration[iterationIndex] = newCurrentFact;
  var newUserProfile = _objectSpread(_objectSpread({}, state.userProfile), {}, {
    facts: updatedFacts
  });
  state.userProfile = newUserProfile;
}), _createReducer));