export const generalInterests = [
    // Psychology - Cognitive Psychology
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Cognitive Psychology",
        "id": "topic_Psychology_CognitivePsychology_Memory",
        "name_en": "Memory: Understanding Cognitive Processes",
        "name_sv": "Minnet: Förstå kognitiva processer",
        "name_is": "Minnis: Skilja hugræna ferli",
        "learning_objectives": "Explore different types of memory and how they affect learning.",
        "when_to_add": [
            "hobbyInterests.Psychology.cognitive_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Cognitive Psychology",
        "id": "topic_Psychology_CognitivePsychology_Attention",
        "name_en": "Attention: The Key to Focused Learning",
        "name_sv": "Uppmärksamhet: Nyckeln till fokuserat lärande",
        "name_is": "Uppmærðsemi: Lykillin að einbeittum námsferlum",
        "learning_objectives": "Understand the mechanisms of attention and its impact on performance.",
        "when_to_add": [
            "hobbyInterests.Psychology.cognitive_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Cognitive Psychology",
        "id": "topic_Psychology_CognitivePsychology_ProblemSolving",
        "name_en": "Problem-Solving Strategies in Cognitive Psychology",
        "name_sv": "Problemlösningsstrategier inom kognitiv psykologi",
        "name_is": "Vandamálalausnaraðferðir í hugræna sálfræði",
        "learning_objectives": "Learn various cognitive strategies to enhance problem-solving skills.",
        "when_to_add": [
            "hobbyInterests.Psychology.cognitive_psychology"
        ]
    },
    // Psychology - Behavioral Psychology
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Behavioral Psychology",
        "id": "topic_Psychology_BehavioralPsychology_Conditioning",
        "name_en": "Conditioning: Foundations of Behavioral Psychology",
        "name_sv": "Betingning: Grunderna i beteendepsykologi",
        "name_is": "Betinging: Grunnvall á hegðunar sálfræði",
        "learning_objectives": "Understand classical and operant conditioning techniques.",
        "when_to_add": [
            "hobbyInterests.Psychology.behavioral_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Behavioral Psychology",
        "id": "topic_Psychology_BehavioralPsychology_InfluenceOfEnvironment",
        "name_en": "The Influence of Environment on Behavior",
        "name_sv": "Miljöns påverkan på beteende",
        "name_is": "Áhrif umhverfis á hegðun",
        "learning_objectives": "Explore how environmental factors shape human behavior.",
        "when_to_add": [
            "hobbyInterests.Psychology.behavioral_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Behavioral Psychology",
        "id": "topic_Psychology_BehavioralPsychology_PsychologicalTreatments",
        "name_en": "Psychological Treatments in Behavioral Psychology",
        "name_sv": "Psykologiska behandlingar inom beteendepsykologi",
        "name_is": "Sálfræðilegar meðferðir í hegðunar sálfræði",
        "learning_objectives": "Investigate various therapeutic approaches based on behavioral psychology.",
        "when_to_add": [
            "hobbyInterests.Psychology.behavioral_psychology"
        ]
    },
    // Psychology - Developmental Psychology
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Developmental Psychology",
        "id": "topic_Psychology_DevelopmentalPsychology_LifespanDevelopment",
        "name_en": "Lifespan Development: An Overview",
        "name_sv": "Utveckling över livet: En översikt",
        "name_is": "Þróun yfir líf: Yfirlit",
        "learning_objectives": "Understand the stages of human development from infancy to adulthood.",
        "when_to_add": [
            "hobbyInterests.Psychology.developmental_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Developmental Psychology",
        "id": "topic_Psychology_DevelopmentalPsychology_CognitiveDevelopment",
        "name_en": "Cognitive Development: Key Theories",
        "name_sv": "Kognitiv utveckling: Nyckelteorier",
        "name_is": "Hugrænn þróun: Lykiltal",
        "learning_objectives": "Explore major theories of cognitive development.",
        "when_to_add": [
            "hobbyInterests.Psychology.developmental_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Developmental Psychology",
        "id": "topic_Psychology_DevelopmentalPsychology_SocialDevelopment",
        "name_en": "Social Development: Interactions and Relationships",
        "name_sv": "Social utveckling: Interaktioner och relationer",
        "name_is": "Félagsleg þróun: Samskipti og tengsl",
        "learning_objectives": "Learn about the importance of social interactions in development.",
        "when_to_add": [
            "hobbyInterests.Psychology.developmental_psychology"
        ]
    },
    // Psychology - Social Psychology
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Social Psychology",
        "id": "topic_Psychology_SocialPsychology_AttributionTheory",
        "name_en": "Attribution Theory: Understanding Social Behavior",
        "name_sv": "Attributionsteori: Förstå socialt beteende",
        "name_is": "Tilvísunarfræði: Skilja félagslegarhegðun",
        "learning_objectives": "Examine how people explain their own and others' behavior.",
        "when_to_add": [
            "hobbyInterests.Psychology.social_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Social Psychology",
        "id": "topic_Psychology_SocialPsychology_GroupDynamics",
        "name_en": "Group Dynamics: The Power of Groups",
        "name_sv": "Gruppdynamik: Gruppers makt",
        "name_is": "Hópavefur: Völd hópa",
        "learning_objectives": "Explore how group dynamics influence individual behavior.",
        "when_to_add": [
            "hobbyInterests.Psychology.social_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Social Psychology",
        "id": "topic_Psychology_SocialPsychology_Conformity",
        "name_en": "Conformity: Following the Crowd",
        "name_sv": "Konformitet: Följa mängden",
        "name_is": "Samræmi: Fylgja mannfjölda",
        "learning_objectives": "Understand the concept of conformity and its implications.",
        "when_to_add": [
            "hobbyInterests.Psychology.social_psychology"
        ]
    },
    // Psychology - Positive Psychology
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Positive Psychology",
        "id": "topic_Psychology_PositivePsychology_Happiness",
        "name_en": "The Science of Happiness: Positive Psychological Techniques",
        "name_sv": "Lyckans vetenskap: Positiva psykologiska tekniker",
        "name_is": "Vísindin um hamingju: Jákvæð sálfræðitegundir",
        "learning_objectives": "Explore techniques that enhance well-being and happiness.",
        "when_to_add": [
            "hobbyInterests.Psychology.positive_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Positive Psychology",
        "id": "topic_Psychology_PositivePsychology_Resilience",
        "name_en": "Resilience: Bouncing Back from Adversity",
        "name_sv": "Resiliens: Studsa tillbaka från motgångar",
        "name_is": "Seigla: Hopp aftur frá erfiðleikum",
        "learning_objectives": "Understand the factors that contribute to resilience.",
        "when_to_add": [
            "hobbyInterests.Psychology.positive_psychology"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Positive Psychology",
        "id": "topic_Psychology_PositivePsychology_Flourishing",
        "name_en": "Flourishing: Pathways to a Meaningful Life",
        "name_sv": "Blomstring: Vägar till ett meningsfullt liv",
        "name_is": "Blómgun: Vegir að merkingarfylltu lífi",
        "learning_objectives": "Discover strategies for living a fulfilling and meaningful life.",
        "when_to_add": [
            "hobbyInterests.Psychology.positive_psychology"
        ]
    },
    // Psychology - Therapy Techniques
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Therapy Techniques",
        "id": "topic_Psychology_TherapyTechniques_CognitiveBehavioralTherapy",
        "name_en": "Cognitive Behavioral Therapy: Basics and Applications",
        "name_sv": "Kognitiv beteendeterapi: Grunder och tillämpningar",
        "name_is": "Hugrænn hegðunarmeðferð: Grunn atriði og notkun",
        "learning_objectives": "Learn about the principles and techniques of CBT.",
        "when_to_add": [
            "hobbyInterests.Psychology.therapy_techniques"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Therapy Techniques",
        "id": "topic_Psychology_TherapyTechniques_Mindfulness",
        "name_en": "Mindfulness Techniques for Emotional Regulation",
        "name_sv": "Mindfulness-tekniker för emotionell reglering",
        "name_is": "Vitundartækni fyrir tilfinningastjórn",
        "learning_objectives": "Explore mindfulness techniques for managing emotions.",
        "when_to_add": [
            "hobbyInterests.Psychology.therapy_techniques"
        ]
    },
    {
        "curriculum": "Psychology",
        "field": "Psychology",
        "subject": "Therapy Techniques",
        "id": "topic_Psychology_TherapyTechniques_ArtTherapy",
        "name_en": "Art Therapy: Expressing Emotions through Creativity",
        "name_sv": "Konstterapi: Uttrycka känslor genom kreativitet",
        "name_is": "Listmeðferð: Útgefa tilfinningar með skapandi hætti",
        "learning_objectives": "Discover how art can be used in therapeutic settings.",
        "when_to_add": [
            "hobbyInterests.Psychology.therapy_techniques"
        ]
    },
    // Playing Music - Guitar Beginner
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Beginner",
        "id": "topic_PlayingMusic_GuitarBeginner_Basics",
        "name_en": "Guitar Basics: Introduction to Chords and Strumming",
        "name_sv": "Gitarrens grunder: Introduktion till ackord och strumming",
        "name_is": "Grunn Þensla: Inngangur að ákordum og strumming",
        "learning_objectives": "Learn basic guitar chords and simple strumming patterns.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Beginner",
        "id": "topic_PlayingMusic_GuitarBeginner_Songs",
        "name_en": "Beginner Songs: Play Along Fun",
        "name_sv": "Nybörjarsånger: Spela med roligt",
        "name_is": "Nybörjarsögur: Spila með ánægju",
        "learning_objectives": "Learn to play easy songs on guitar with basic chords.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Beginner",
        "id": "topic_PlayingMusic_GuitarBeginner_Tuning",
        "name_en": "Tuning Your Guitar: Getting Started",
        "name_sv": "Stämma din gitarr: Komma igång",
        "name_is": "Stemma gítarinn: Komma af stað",
        "learning_objectives": "Understand how to properly tune your guitar.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_beginner"
        ]
    },
    // Playing Music - Guitar Intermediate/Advanced
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Intermediate/Advanced",
        "id": "topic_PlayingMusic_GuitarIntermediateAdvanced_Soloing",
        "name_en": "Guitar Soloing Techniques: Master the Fretboard",
        "name_sv": "Gitarrsolo tekniker: Behärska greppbrädan",
        "name_is": "Gítarsólo tækni: Beina á grip",
        "learning_objectives": "Learn scales and techniques for soloing on guitar.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Intermediate/Advanced",
        "id": "topic_PlayingMusic_GuitarIntermediateAdvanced_Improvisation",
        "name_en": "Improvisation for Guitarists: Finding Your Voice",
        "name_sv": "Improvisation för gitarrister: Hitta din röst",
        "name_is": "Breyting fyrir gítarleikara: Finna þína rödd",
        "learning_objectives": "Develop skills to improvise over different styles.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Guitar Intermediate/Advanced",
        "id": "topic_PlayingMusic_GuitarIntermediateAdvanced_Technique",
        "name_en": "Advanced Techniques: Tapping and Sweep Picking",
        "name_sv": "Avancerade tekniker: Tapping och Sweep Picking",
        "name_is": "Tæknilausar: Tapping og Sweep Picking",
        "learning_objectives": "Learn advanced techniques to enhance guitar playing.",
        "when_to_add": [
            "hobbyInterests.Playing Music.guitar_intermediate_advanced"
        ]
    },
    // Playing Music - Piano Beginner
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Beginner",
        "id": "topic_PlayingMusic_PianoBeginner_Basics",
        "name_en": "Piano Basics: Notes and Finger Positions",
        "name_sv": "Pianons grunder: Noter och fingerpositioner",
        "name_is": "Píanogrundir: Nótu og fingra staðsetningar",
        "learning_objectives": "Learn the names of notes and how to position your fingers.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Beginner",
        "id": "topic_PlayingMusic_PianoBeginner_SimpleSongs",
        "name_en": "Playing Simple Songs on Piano",
        "name_sv": "Spela enkla sånger på piano",
        "name_is": "Spila einfaldar lög á píanó",
        "learning_objectives": "Play popular simple songs using basic melodies.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Beginner",
        "id": "topic_PlayingMusic_PianoBeginner_Techniques",
        "name_en": "Basic Piano Techniques for Beginners",
        "name_sv": "Grundläggande pianotekniker för nybörjare",
        "name_is": "Grunnpíanatækni fyrir byrjendur",
        "learning_objectives": "Learn about hand positioning and basic techniques.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_beginner"
        ]
    },
    // Playing Music - Piano Intermediate/Advanced
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Intermediate/Advanced",
        "id": "topic_PlayingMusic_PianoIntermediateAdvanced_AdvancedTechniques",
        "name_en": "Advanced Piano Techniques: Dynamics and Expression",
        "name_sv": "Avancerade pianotekniker: Dynamik och uttryck",
        "name_is": "Aðferð píanó: Dýnamík og tjáning",
        "learning_objectives": "Explore advanced techniques to convey emotion in music.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Intermediate/Advanced",
        "id": "topic_PlayingMusic_PianoIntermediateAdvanced_SightReading",
        "name_en": "Sight Reading: Developing Your Skills",
        "name_sv": "Notläsning: Utveckla dina färdigheter",
        "name_is": "Lesnofn: Þróa þínar færni",
        "learning_objectives": "Improve your ability to read music at first sight.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Intermediate/Advanced",
        "id": "topic_PlayingMusic_PianoIntermediateAdvanced_Improvisation",
        "name_en": "Improvisation on Piano: Creative Expressions",
        "name_sv": "Improvisation på piano: Krea uttryck",
        "name_is": "Breyting á píanói: Skapandi tjáning",
        "learning_objectives": "Learn to improvise and create your own melodies.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_intermediate_advanced"
        ]
    },
    // Playing Music - Piano Advanced
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Advanced",
        "id": "topic_PlayingMusic_PianoAdvanced_AdvancedRepertoire",
        "name_en": "Exploring Advanced Repertoire for Piano",
        "name_sv": "Utforska avancerad repertoar för piano",
        "name_is": "Bland í dýrmætum vinsælum listum fyrir píanó",
        "learning_objectives": "Study intricate pieces from the classical and modern repertoire.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Advanced",
        "id": "topic_PlayingMusic_PianoAdvanced_Expression",
        "name_en": "Expression in Piano Performance: Bringing Music to Life",
        "name_sv": "Uttryck i pianouppträdande: Ge liv åt musiken",
        "name_is": "Tjáning í píanóframmistöðu: Ge líf á tónlistina",
        "learning_objectives": "Learn techniques to enhance expressiveness in piano performance.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Piano Advanced",
        "id": "topic_PlayingMusic_PianoAdvanced_Interpretation",
        "name_en": "Interpretation: Playing with Your Own Style",
        "name_sv": "Tolkning: Spela med din egen stil",
        "name_is": "Túlkun: Spila með þinni eigin stíl",
        "learning_objectives": "Cultivate a personal style in interpreting pieces.",
        "when_to_add": [
            "hobbyInterests.Playing Music.piano_advanced"
        ]
    },
    // Playing Music - Violin
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Violin",
        "id": "topic_PlayingMusic_Violin_Basics",
        "name_en": "Violin Basics: Posture and Bowing Techniques",
        "name_sv": "Violens grunder: Ställning och bågtekniker",
        "name_is": "Grunnir fiðlu: Staða og bogateiknin",
        "learning_objectives": "Learn proper posture and basic bowing techniques for beginners.",
        "when_to_add": [
            "hobbyInterests.Playing Music.violin"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Violin",
        "id": "topic_PlayingMusic_Violin_SimpleSongs",
        "name_en": "Playing Simple Songs on Violin",
        "name_sv": "Spela enkla sånger på violin",
        "name_is": "Spila einfaldar lög á fiðlu",
        "learning_objectives": "Learn to play easy recognizable tunes on the violin.",
        "when_to_add": [
            "hobbyInterests.Playing Music.violin"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Violin",
        "id": "topic_PlayingMusic_Violin_AdvancedTechniques",
        "name_en": "Advanced Violin Techniques: Shifting and Vibrato",
        "name_sv": "Avancerade violin tekniker: Förskjutning och vibrato",
        "name_is": "Aðferð fiðlu: Skifting og vibrato",
        "learning_objectives": "Master advanced techniques such as shifting and vibrato.",
        "when_to_add": [
            "hobbyInterests.Playing Music.violin"
        ]
    },
    // Playing Music - Drums Beginner
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Beginner",
        "id": "topic_PlayingMusic_DrumsBeginner_Basics",
        "name_en": "Drum Basics: Understanding Rhythm and Beats",
        "name_sv": "Trumgrunder: Förstå rytm och takter",
        "name_is": "Grunnir trommu: Skilja í rytma og taktu",
        "learning_objectives": "Learn basic rhythms and how to play simple beats.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Beginner",
        "id": "topic_PlayingMusic_DrumsBeginner_SimpleSongs",
        "name_en": "Playing Simple Songs on Drums",
        "name_sv": "Spela enkla sånger på trummor",
        "name_is": "Spila einfaldar lög á trommur",
        "learning_objectives": "Get familiar with playing popular tracks with simple drumming.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_beginner"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Beginner",
        "id": "topic_PlayingMusic_DrumsBeginner_Technique",
        "name_en": "Basic Drumming Techniques: Sticks and Grip",
        "name_sv": "Grundläggande trumtekniker: Sticks och grepp",
        "name_is": "Grunn trommuteikn: Stöng og grip",
        "learning_objectives": "Learn about drumsticks, grip techniques, and stroke basics.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_beginner"
        ]
    },
    // Playing Music - Drums Intermediate/Advanced
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Intermediate/Advanced",
        "id": "topic_PlayingMusic_DrumsIntermediateAdvanced_Rudiments",
        "name_en": "Drum Rudiments: Building a Strong Foundation",
        "name_sv": "Trumruds: Bygga en stark grund",
        "name_is": "Trummforeldri: Byggja sterk grunn",
        "learning_objectives": "Master basic drum rudiments to improve technique and speed.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Intermediate/Advanced",
        "id": "topic_PlayingMusic_DrumsIntermediateAdvanced_Styles",
        "name_en": "Exploring Different Drumming Styles",
        "name_sv": "Utforska olika trumstilar",
        "name_is": "Kanna mismunandi trommusraðir",
        "learning_objectives": "Learn various drumming styles such as rock, jazz, and funk.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_intermediate_advanced"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Drums Intermediate/Advanced",
        "id": "topic_PlayingMusic_DrumsIntermediateAdvanced_Improvisation",
        "name_en": "Drumming Improvisation: Expressing Creativity on the Kit",
        "name_sv": "Trumimprovisation: Uttrycka kreativitet på kit",
        "name_is": "Trommuímprovisation: Tjá skapandi á settinu",
        "learning_objectives": "Develop skills to creatively improvise during a performance.",
        "when_to_add": [
            "hobbyInterests.Playing Music.drums_intermediate_advanced"
        ]
    },
    // Playing Music - Brass Instruments
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Brass Instruments",
        "id": "topic_PlayingMusic_BrassInstruments_Basics",
        "name_en": "Brass Basics: Understanding Mouthpieces and Techniques",
        "name_sv": "Blåsinstrumentens grunder: Förstå munstycken och tekniker",
        "name_is": "Grunnir blásins: Skilja munstykki og tækni",
        "learning_objectives": "Learn the basics of playing brass instruments including breath control.",
        "when_to_add": [
            "hobbyInterests.Playing Music.brass_instruments"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Brass Instruments",
        "id": "topic_PlayingMusic_BrassInstruments_SimpleSongs",
        "name_en": "Playing Simple Songs on Brass Instruments",
        "name_sv": "Spela enkla sånger på blåsinstrument",
        "name_is": "Spila einfaldar lög á blásins",
        "learning_objectives": "Master easy tunes and popular pieces in the brass repertoire.",
        "when_to_add": [
            "hobbyInterests.Playing Music.brass_instruments"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Brass Instruments",
        "id": "topic_PlayingMusic_BrassInstruments_AdvancedTechniques",
        "name_en": "Advanced Brass Techniques: Articulation and Dynamics",
        "name_sv": "Avancerade blåstekniker: Artikulation och dynamik",
        "name_is": "Aðferðir blástu: Tjáning og dýnamík",
        "learning_objectives": "Learn advanced techniques for expression and performance in brass.",
        "when_to_add": [
            "hobbyInterests.Playing Music.brass_instruments"
        ]
    },
    // Playing Music - Woodwind Instruments
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Woodwind Instruments",
        "id": "topic_PlayingMusic_WoodwindInstruments_Basics",
        "name_en": "Woodwind Basics: Reeds and Fingerings",
        "name_sv": "Träblåsinstrumentets grunder: Reeds och fingerings",
        "name_is": "Grunnir tréblásins: Reed og fingra",
        "learning_objectives": "Understand the basics of woodwind instruments, focusing on reeds.",
        "when_to_add": [
            "hobbyInterests.Playing Music.woodwind_instruments"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Woodwind Instruments",
        "id": "topic_PlayingMusic_WoodwindInstruments_SimpleSongs",
        "name_en": "Playing Simple Songs on Woodwind Instruments",
        "name_sv": "Spela enkla sånger på träblåsinstrument",
        "name_is": "Spila einfaldar lög á tréblásins",
        "learning_objectives": "Learn to play simple and easy songs on woodwind instruments.",
        "when_to_add": [
            "hobbyInterests.Playing Music.woodwind_instruments"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Woodwind Instruments",
        "id": "topic_PlayingMusic_WoodwindInstruments_AdvancedTechniques",
        "name_en": "Advanced Woodwind Techniques: Vibrato and Tonguing",
        "name_sv": "Avancerade träblåstekniker: Vibrato och tungning",
        "name_is": "Aðferð tréblásins: Vibrato og tungumál",
        "learning_objectives": "Master advanced techniques for enhanced performance.",
        "when_to_add": [
            "hobbyInterests.Playing Music.woodwind_instruments"
        ]
    },
    // Playing Music - Music Theory
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Theory",
        "id": "topic_PlayingMusic_MusicTheory_Basics",
        "name_en": "Music Theory Basics: Understanding Notes and Scales",
        "name_sv": "Musikteori grunder: Förstå noter och skalor",
        "name_is": "Grunn tónfræði: Skilja nótur og skala",
        "learning_objectives": "Learn the fundamentals of music theory including notes and scales.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_theory"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Theory",
        "id": "topic_PlayingMusic_MusicTheory_Harmony",
        "name_en": "Understanding Harmony in Music",
        "name_sv": "Förstå harmoni i musik",
        "name_is": "Skilja harmóní í tónlist",
        "learning_objectives": "Explore harmony and its role in music composition.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_theory"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Theory",
        "id": "topic_PlayingMusic_MusicTheory_TimeSignatures",
        "name_en": "Time Signatures: The Framework of Rhythm",
        "name_sv": "Taktarter: Ramverket för rytm",
        "name_is": "Takt: Rammi rytma",
        "learning_objectives": "Understand different time signatures and their musical implications.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_theory"
        ]
    },
    // Playing Music - Music Production
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Production",
        "id": "topic_PlayingMusic_MusicProduction_Basics",
        "name_en": "Music Production Basics: Introduction to Tools",
        "name_sv": "Musikproduktions grunder: Introduktion till verktyg",
        "name_is": "Grunn tónlistarframleiðslu: Inngangur að tólum",
        "learning_objectives": "Learn the basics of music production and essential tools used.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_production"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Production",
        "id": "topic_PlayingMusic_MusicProduction_Recording",
        "name_en": "Recording Music: Techniques and Tips",
        "name_sv": "Spela in musik: Tekniker och tips",
        "name_is": "Tónlistin: Tækni og ráð",
        "learning_objectives": "Understand different recording techniques for high-quality sound.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_production"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Music Production",
        "id": "topic_PlayingMusic_MusicProduction_Mixing",
        "name_en": "Mixing Music: Balancing Elements for Quality",
        "name_sv": "Mixning av musik: Balansera element för kvalitet",
        "name_is": "Blanda tónlist: Jafna hlutum fyrir gæði",
        "learning_objectives": "Learn how to mix tracks effectively for a polished sound.",
        "when_to_add": [
            "hobbyInterests.Playing Music.music_production"
        ]
    },
    // Playing Music - Songwriting: Lyric Writing
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Lyric Writing",
        "id": "topic_PlayingMusic_SongwritingLyricWriting_Basics",
        "name_en": "Lyric Writing Basics: Crafting Meaningful Lyrics",
        "name_sv": "Textskrivning grunder: Smida meningsfulla texter",
        "name_is": "Textgerð grundir: Mótun merkingarsamra texta",
        "learning_objectives": "Learn techniques for writing impactful lyrics.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_lyrics"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Lyric Writing",
        "id": "topic_PlayingMusic_SongwritingLyricWriting_Storytelling",
        "name_en": "Storytelling in Songwriting: Weaving Narratives",
        "name_sv": "Berättande i låtskrivning: Väv berättelser",
        "name_is": "Söguþjóðir í lögmyndun: Vafning sögur",
        "learning_objectives": "Explore the art of storytelling through lyrics.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_lyrics"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Lyric Writing",
        "id": "topic_PlayingMusic_SongwritingLyricWriting_Editing",
        "name_en": "Editing Lyrics: Polishing Your Work",
        "name_sv": "Redigera texter: Polera ditt arbete",
        "name_is": "Gagnrétur texta: Snerpa vinnuna þína",
        "learning_objectives": "Learn how to edit and refine your lyrics.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_lyrics"
        ]
    },
    // Playing Music - Songwriting: Melody Creation
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Melody Creation",
        "id": "topic_PlayingMusic_SongwritingMelodyCreation_Basics",
        "name_en": "Melody Creation Basics: Crafting Catchy Tunes",
        "name_sv": "Melodiskapande grunder: Smida iögonfallande melodier",
        "name_is": "Melófingur grundir: Mótun fanga melódía",
        "learning_objectives": "Understand the basics of melody creation.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_melody"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Melody Creation",
        "id": "topic_PlayingMusic_SongwritingMelodyCreation_Structure",
        "name_en": "Song Structure: Building Engaging Melodies",
        "name_sv": "Låtstruktur: Bygga engagerande melodier",
        "name_is": "Láturinn: Byggja nádhuðandi melódíur",
        "learning_objectives": "Explore various structures used in melody creation.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_melody"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Melody Creation",
        "id": "topic_PlayingMusic_SongwritingMelodyCreation_Harmony",
        "name_en": "Melody and Harmony: The Perfect Blend",
        "name_sv": "Melodi och harmoni: Den perfekta blandningen",
        "name_is": "Melódi og harmóní: Hin fullkomna blanda",
        "learning_objectives": "Learn how to combine melody and harmony in songwriting.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_melody"
        ]
    },
    // Playing Music - Songwriting: Song Structure
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Song Structure",
        "id": "topic_PlayingMusic_SongwritingSongStructure_Basics",
        "name_en": "Song Structure Basics: Understanding Form",
        "name_sv": "Låtstruktur grunder: Förstå form",
        "name_is": "Láturinn: Skilja form",
        "learning_objectives": "Learn the fundamental structures of popular songs.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_structure"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Song Structure",
        "id": "topic_PlayingMusic_SongwritingSongStructure_Creativity",
        "name_en": "Creativity in Song Structure: Breaking Norms",
        "name_sv": "Kreativitet i låtstruktur: Bryta normer",
        "name_is": "Skapandi í látaformi: Brjóta norm",
        "learning_objectives": "Explore creative ways to structure your songs.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_structure"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Song Structure",
        "id": "topic_PlayingMusic_SongwritingSongStructure_Analysis",
        "name_en": "Analyzing Song Structures: What Makes Them Work?",
        "name_sv": "Analys av låtstrukturer: Vad får dem att fungera?",
        "name_is": "Greining látaforma: Hvað gerir þau virk?",
        "learning_objectives": "Study popular songs to understand effective structures.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_structure"
        ]
    },
    // Playing Music - Songwriting: Genres
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Genres",
        "id": "topic_PlayingMusic_SongwritingGenres_Basics",
        "name_en": "Understanding Music Genres: An Introduction",
        "name_sv": "Förstå musikgenrer: En introduktion",
        "name_is": "Skilja tónlistargiftur: Inngangur",
        "learning_objectives": "Learn about different music genres and their characteristics.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_genres"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Genres",
        "id": "topic_PlayingMusic_SongwritingGenres_Creativity",
        "name_en": "Creativity in Genre Blending: Expanding Your Horizons",
        "name_sv": "Kreativitet i genreblandning: Utvidga dina horisonter",
        "name_is": "Skapandi í blandun tónlistargátur: Útvíkka hórusín",
        "learning_objectives": "Explore how to blend genres in your songwriting.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_genres"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Songwriting: Genres",
        "id": "topic_PlayingMusic_SongwritingGenres_Analysis",
        "name_en": "Analyzing Genres: Understanding Trends in Music",
        "name_sv": "Analys av genrer: Förstå trender i musik",
        "name_is": "Greining tónlistargátta: Skilja á tólum í tónlist",
        "learning_objectives": "Study musical trends and how genres evolve.",
        "when_to_add": [
            "hobbyInterests.Playing Music.songwriting_genres"
        ]
    },
    // Playing Music - Classical Composition
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Classical Composition",
        "id": "topic_PlayingMusic_ClassicalComposition_Basics",
        "name_en": "Classical Composition Basics: Understanding Form",
        "name_sv": "Klassisk komposition grunder: Förstå form",
        "name_is": "Klassísk samsetning grunnir: Skilja form",
        "learning_objectives": "Learn about the basic elements of classical music composition.",
        "when_to_add": [
            "hobbyInterests.Playing Music.classical_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Classical Composition",
        "id": "topic_PlayingMusic_ClassicalComposition_Techniques",
        "name_en": "Advanced Techniques in Classical Composition",
        "name_sv": "Avancerade tekniker i klassisk komposition",
        "name_is": "Aðferðir í klassískri samsetningu",
        "learning_objectives": "Explore complex techniques used in classical compositions.",
        "when_to_add": [
            "hobbyInterests.Playing Music.classical_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Classical Composition",
        "id": "topic_PlayingMusic_ClassicalComposition_Analysis",
        "name_en": "Analyzing Great Classical Works",
        "name_sv": "Analys av stora klassiska verk",
        "name_is": "Greining stórra klassískra verka",
        "learning_objectives": "Study classic pieces to understand compositional techniques.",
        "when_to_add": [
            "hobbyInterests.Playing Music.classical_composition"
        ]
    },
    // Playing Music - Contemporary Composition
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Contemporary Composition",
        "id": "topic_PlayingMusic_ContemporaryComposition_Basics",
        "name_en": "Contemporary Composition Basics: Modern Styles",
        "name_sv": "Samtida komposition grunder: Moderna stilar",
        "name_is": "Samtíð komposicion: Nútímasar",
        "learning_objectives": "Explore the foundations of contemporary music composition.",
        "when_to_add": [
            "hobbyInterests.Playing Music.contemporary_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Contemporary Composition",
        "id": "topic_PlayingMusic_ContemporaryComposition_Techniques",
        "name_en": "Advanced Techniques in Contemporary Composition",
        "name_sv": "Avancerade tekniker i samtida komposition",
        "name_is": "Aðferðir í samtíða samsetningu",
        "learning_objectives": "Learn contemporary techniques that define modern compositions.",
        "when_to_add": [
            "hobbyInterests.Playing Music.contemporary_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Contemporary Composition",
        "id": "topic_PlayingMusic_ContemporaryComposition_Analysis",
        "name_en": "Analyzing Modern Music Composition",
        "name_sv": "Analys av modern musikkomposition",
        "name_is": "Greining nútímatónlistar samsetningu",
        "learning_objectives": "Study modern works to gain insights into contemporary styles.",
        "when_to_add": [
            "hobbyInterests.Playing Music.contemporary_composition"
        ]
    },
    // Playing Music - Arranging Music
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Arranging Music",
        "id": "topic_PlayingMusic_ArrangingMusic_Basics",
        "name_en": "Arranging Basics: Techniques for Musicians",
        "name_sv": "Arrangemang grunder: Tekniker för musiker",
        "name_is": "Grunder í arrangerande: Tœknir fyrir tónlistarmenn",
        "learning_objectives": "Learn essential arranging techniques for musicians.",
        "when_to_add": [
            "hobbyInterests.Playing Music.arranging_music"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Arranging Music",
        "id": "topic_PlayingMusic_ArrangingMusic_Instrumentation",
        "name_en": "Instrumentation: Choosing the Right Instruments",
        "name_sv": "Instrumentering: Vala de rätta instrumenten",
        "name_is": "Instrumentari: Velja rétta hljóðfæri",
        "learning_objectives": "Understand how to choose instruments for your arrangement.",
        "when_to_add": [
            "hobbyInterests.Playing Music.arranging_music"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Arranging Music",
        "id": "topic_PlayingMusic_ArrangingMusic_Audio",
        "name_en": "Arranging for Audio: Techniques for Recording",
        "name_sv": "Arrangemang för ljud: Tekniker för inspelning",
        "name_is": "Arrangering fyrir hljóð: Tœknir fyrir upptöku",
        "learning_objectives": "Learn how to arrange music for recording sessions effectively.",
        "when_to_add": [
            "hobbyInterests.Playing Music.arranging_music"
        ]
    },
    // Playing Music - Electronic Composition
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Electronic Composition",
        "id": "topic_PlayingMusic_ElectronicComposition_Basics",
        "name_en": "Electronic Composition Basics: Introduction to Digital Tools",
        "name_sv": "Elektronisk komposition grunder: Introduktion till digitala verktyg",
        "name_is": "Grunn rafrænir samsetningar: Inngangur á tölvutólum",
        "learning_objectives": "Get started with electronic music composition using digital tools.",
        "when_to_add": [
            "hobbyInterests.Playing Music.electronic_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Electronic Composition",
        "id": "topic_PlayingMusic_ElectronicComposition_Production",
        "name_en": "Production Techniques in Electronic Composition",
        "name_sv": "Produktionstekniker i elektronisk komposition",
        "name_is": "Framleiðsla tækni í rafrænu samsetningu",
        "learning_objectives": "Explore production techniques for electronic music.",
        "when_to_add": [
            "hobbyInterests.Playing Music.electronic_composition"
        ]
    },
    {
        "curriculum": "Playing Music",
        "field": "Music",
        "subject": "Electronic Composition",
        "id": "topic_PlayingMusic_ElectronicComposition_Analysis",
        "name_en": "Analyzing Electronic Music: Trends and Techniques",
        "name_sv": "Analys av elektronisk musik: Trender och tekniker",
        "name_is": "Greining rafræn tónlist: Tísku og tækni",
        "learning_objectives": "Study electronic music to understand current trends.",
        "when_to_add": [
            "hobbyInterests.Playing Music.electronic_composition"
        ]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Beginner",
        "id": "topic_Spanish_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in Spanish",
        "name_sv": "Grundläggande ordförråd på spanska",
        "name_is": "Grunnorðaforði á spænsku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.spanish_beginner"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Beginner",
        "id": "topic_Spanish_Beginner_Grammar",
        "name_en": "Introduction to Spanish Grammar",
        "name_sv": "Introduktion till spansk grammatik",
        "name_is": "Inngangur að spænskri málfræði",
        "learning_objectives": "Understand the fundamental rules of Spanish grammar.",
        "when_to_add": ["hobbyInterests.Languages.spanish_beginner"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Beginner",
        "id": "topic_Spanish_Beginner_Conversation",
        "name_en": "Basic Conversations in Spanish",
        "name_sv": "Grundläggande konversationer på spanska",
        "name_is": "Grunnsamræður á spænsku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.spanish_beginner"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Intermediate",
        "id": "topic_Spanish_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in Spanish",
        "name_sv": "Förbättrat ordförråd på spanska",
        "name_is": "Bætt orðaforði á spænsku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.spanish_intermediate"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Intermediate",
        "id": "topic_Spanish_Intermediate_Grammar",
        "name_en": "Intermediate Spanish Grammar",
        "name_sv": "Intermediär spansk grammatik",
        "name_is": "Millist spansk málfræði",
        "learning_objectives": "Grasp intermediate grammar concepts in Spanish.",
        "when_to_add": ["hobbyInterests.Languages.spanish_intermediate"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Intermediate",
        "id": "topic_Spanish_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in Spanish",
        "name_sv": "Intermediära konversationer på spanska",
        "name_is": "Millisk samræður á spænsku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.spanish_intermediate"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Advanced",
        "id": "topic_Spanish_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in Spanish",
        "name_sv": "Avancerat ordförråd på spanska",
        "name_is": "Vanda orðaforði á spænsku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.spanish_advanced"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Advanced",
        "id": "topic_Spanish_Advanced_Grammar",
        "name_en": "Advanced Spanish Grammar",
        "name_sv": "Avancerad spansk grammatik",
        "name_is": "Vanda spænsk málfræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.spanish_advanced"]
    },
    {
        "curriculum": "Spanish Language",
        "field": "Language",
        "subject": "Spanish Advanced",
        "id": "topic_Spanish_Advanced_Literature",
        "name_en": "Spanish Literature Analysis",
        "name_sv": "Analys av spansk litteratur",
        "name_is": "Greining spænsku bókmennta",
        "learning_objectives": "Analyze and discuss classic and modern Spanish literature.",
        "when_to_add": ["hobbyInterests.Languages.spanish_advanced"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Beginner",
        "id": "topic_French_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in French",
        "name_sv": "Grundläggande ordförråd på franska",
        "name_is": "Grunnorðaforði á frönsku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.french_beginner"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Beginner",
        "id": "topic_French_Beginner_Grammar",
        "name_en": "Introduction to French Grammar",
        "name_sv": "Introduktion till fransk grammatik",
        "name_is": "Inngangur að franskri málfræði",
        "learning_objectives": "Understand the fundamental rules of French grammar.",
        "when_to_add": ["hobbyInterests.Languages.french_beginner"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Beginner",
        "id": "topic_French_Beginner_Conversation",
        "name_en": "Basic Conversations in French",
        "name_sv": "Grundläggande konversationer på franska",
        "name_is": "Grunnsamræður á frönsku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.french_beginner"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Intermediate",
        "id": "topic_French_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in French",
        "name_sv": "Förbättrat ordförråd på franska",
        "name_is": "Bætt orðaforði á frönsku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.french_intermediate"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Intermediate",
        "id": "topic_French_Intermediate_Grammar",
        "name_en": "Intermediate French Grammar",
        "name_sv": "Intermediär fransk grammatik",
        "name_is": "Millisk fransk málfræði",
        "learning_objectives": "Grasp intermediate grammar concepts in French.",
        "when_to_add": ["hobbyInterests.Languages.french_intermediate"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Intermediate",
        "id": "topic_French_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in French",
        "name_sv": "Intermediära konversationer på franska",
        "name_is": "Millisamræður á frönsku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.french_intermediate"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Advanced",
        "id": "topic_French_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in French",
        "name_sv": "Avancerat ordförråd på franska",
        "name_is": "Vanda orðaforði á frönsku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.french_advanced"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Advanced",
        "id": "topic_French_Advanced_Grammar",
        "name_en": "Advanced French Grammar",
        "name_sv": "Avancerad fransk grammatik",
        "name_is": "Vanda fransk málfræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.french_advanced"]
    },
    {
        "curriculum": "French Language",
        "field": "Language",
        "subject": "French Advanced",
        "id": "topic_French_Advanced_Literature",
        "name_en": "French Literature Analysis",
        "name_sv": "Analys av fransk litteratur",
        "name_is": "Greining franskra bókmennta",
        "learning_objectives": "Analyze and discuss classic and modern French literature.",
        "when_to_add": ["hobbyInterests.Languages.french_advanced"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Beginner",
        "id": "topic_Mandarin_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in Mandarin Chinese",
        "name_sv": "Grundläggande ordförråd på mandarin",
        "name_is": "Grunnorðaforði á mandarin",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_beginner"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Beginner",
        "id": "topic_Mandarin_Beginner_Grammar",
        "name_en": "Introduction to Mandarin Grammar",
        "name_sv": "Introduktion till mandarin grammatik",
        "name_is": "Inngangur að mandarin málfræði",
        "learning_objectives": "Understand the fundamental rules of Mandarin grammar.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_beginner"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Beginner",
        "id": "topic_Mandarin_Beginner_Conversation",
        "name_en": "Basic Conversations in Mandarin",
        "name_sv": "Grundläggande konversationer på mandarin",
        "name_is": "Grunnsamræður á mandarin",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_beginner"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Intermediate",
        "id": "topic_Mandarin_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in Mandarin Chinese",
        "name_sv": "Förbättrat ordförråd på mandarin",
        "name_is": "Bætt orðaforði á mandarin",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_intermediate"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Intermediate",
        "id": "topic_Mandarin_Intermediate_Grammar",
        "name_en": "Intermediate Mandarin Grammar",
        "name_sv": "Intermediär mandarin grammatik",
        "name_is": "Millisk mandarin málfræði",
        "learning_objectives": "Grasp intermediate grammar concepts in Mandarin.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_intermediate"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Intermediate",
        "id": "topic_Mandarin_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in Mandarin",
        "name_sv": "Intermediära konversationer på mandarin",
        "name_is": "Millisamræður á mandarin",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_intermediate"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Advanced",
        "id": "topic_Mandarin_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in Mandarin Chinese",
        "name_sv": "Avancerat ordförråd på mandarin",
        "name_is": "Vanda orðaforði á mandarin",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_advanced"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Advanced",
        "id": "topic_Mandarin_Advanced_Grammar",
        "name_en": "Advanced Mandarin Grammar",
        "name_sv": "Avancerad mandarin grammatik",
        "name_is": "Vanda mandarin málfræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_advanced"]
    },
    {
        "curriculum": "Mandarin Chinese Language",
        "field": "Language",
        "subject": "Mandarin Chinese Advanced",
        "id": "topic_Mandarin_Advanced_Literature",
        "name_en": "Mandarin Literature Analysis",
        "name_sv": "Analys av mandarinlitteratur",
        "name_is": "Greining mandarin lýðfræði",
        "learning_objectives": "Analyze and discuss classic and modern Mandarin literature.",
        "when_to_add": ["hobbyInterests.Languages.mandarin_advanced"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Beginner",
        "id": "topic_German_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in German",
        "name_sv": "Grundläggande ordförråd på tyska",
        "name_is": "Grunnorðaforði á þýsku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.german_beginner"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Beginner",
        "id": "topic_German_Beginner_Grammar",
        "name_en": "Introduction to German Grammar",
        "name_sv": "Introduktion till tysk grammatik",
        "name_is": "Inngangur að þýskt málfræði",
        "learning_objectives": "Understand the fundamental rules of German grammar.",
        "when_to_add": ["hobbyInterests.Languages.german_beginner"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Beginner",
        "id": "topic_German_Beginner_Conversation",
        "name_en": "Basic Conversations in German",
        "name_sv": "Grundläggande konversationer på tyska",
        "name_is": "Grunnsamræður á þýsku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.german_beginner"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Intermediate",
        "id": "topic_German_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in German",
        "name_sv": "Förbättrat ordförråd på tyska",
        "name_is": "Bætt orðaforði á þýsku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.german_intermediate"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Intermediate",
        "id": "topic_German_Intermediate_Grammar",
        "name_en": "Intermediate German Grammar",
        "name_sv": "Intermediär tysk grammatik",
        "name_is": "Millisk þýsk málfræði",
        "learning_objectives": "Grasp intermediate grammar concepts in German.",
        "when_to_add": ["hobbyInterests.Languages.german_intermediate"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Intermediate",
        "id": "topic_German_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in German",
        "name_sv": "Intermediära konversationer på tyska",
        "name_is": "Millisamræður á þýsku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.german_intermediate"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Advanced",
        "id": "topic_German_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in German",
        "name_sv": "Avancerat ordförråd på tyska",
        "name_is": "Vanda orðaforði á þýsku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.german_advanced"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Advanced",
        "id": "topic_German_Advanced_Grammar",
        "name_en": "Advanced German Grammar",
        "name_sv": "Avancerad tysk grammatik",
        "name_is": "Vanda þýsk málfræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.german_advanced"]
    },
    {
        "curriculum": "German Language",
        "field": "Language",
        "subject": "German Advanced",
        "id": "topic_German_Advanced_Literature",
        "name_en": "German Literature Analysis",
        "name_sv": "Analys av tysk litteratur",
        "name_is": "Greining þýsks bókmennta",
        "learning_objectives": "Analyze and discuss classic and modern German literature.",
        "when_to_add": ["hobbyInterests.Languages.german_advanced"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Beginner",
        "id": "topic_Italian_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in Italian",
        "name_sv": "Grundläggande ordförråd på italienska",
        "name_is": "Grunnorðaforði á ítalsku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.italian_beginner"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Beginner",
        "id": "topic_Italian_Beginner_Grammar",
        "name_en": "Introduction to Italian Grammar",
        "name_sv": "Introduktion till italiensk grammatik",
        "name_is": "Inngangur að ítalskri málfræði",
        "learning_objectives": "Understand the fundamental rules of Italian grammar.",
        "when_to_add": ["hobbyInterests.Languages.italian_beginner"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Beginner",
        "id": "topic_Italian_Beginner_Conversation",
        "name_en": "Basic Conversations in Italian",
        "name_sv": "Grundläggande konversationer på italienska",
        "name_is": "Grunnsamræður á ítalsku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.italian_beginner"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Intermediate",
        "id": "topic_Italian_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in Italian",
        "name_sv": "Förbättrat ordförråd på italienska",
        "name_is": "Bætt orðaforði á ítalsku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.italian_intermediate"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Intermediate",
        "id": "topic_Italian_Intermediate_Grammar",
        "name_en": "Intermediate Italian Grammar",
        "name_sv": "Intermediär italiensk grammatik",
        "name_is": "Millisk ítölsk málfræði",
        "learning_objectives": "Grasp intermediate grammar concepts in Italian.",
        "when_to_add": ["hobbyInterests.Languages.italian_intermediate"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Intermediate",
        "id": "topic_Italian_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in Italian",
        "name_sv": "Intermediära konversationer på italienska",
        "name_is": "Millisamræður á ítalsku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.italian_intermediate"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Advanced",
        "id": "topic_Italian_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in Italian",
        "name_sv": "Avancerat ordförråd på italienska",
        "name_is": "Vanda orðaforði á ítalsku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.italian_advanced"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Advanced",
        "id": "topic_Italian_Advanced_Grammar",
        "name_en": "Advanced Italian Grammar",
        "name_sv": "Avancerad italiensk grammatik",
        "name_is": "Háþróuð ítalsk fræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.italian_advanced"]
    },
    {
        "curriculum": "Italian Language",
        "field": "Language",
        "subject": "Italian Advanced",
        "id": "topic_Italian_Advanced_Literature",
        "name_en": "Italian Literature Analysis",
        "name_sv": "Analys av italiensk litteratur",
        "name_is": "Greining ítalskrar ljóða",
        "learning_objectives": "Analyze and discuss classic and modern Italian literature.",
        "when_to_add": ["hobbyInterests.Languages.italian_advanced"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Beginner",
        "id": "topic_Japanese_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in Japanese",
        "name_sv": "Grundläggande ordförråd på japanska",
        "name_is": "Grunnorðaforði á japönsku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.japanese_beginner"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Beginner",
        "id": "topic_Japanese_Beginner_Grammar",
        "name_en": "Introduction to Japanese Grammar",
        "name_sv": "Introduktion till japansk grammatik",
        "name_is": "Inngangur að japanskri fræði",
        "learning_objectives": "Understand the fundamental rules of Japanese grammar.",
        "when_to_add": ["hobbyInterests.Languages.japanese_beginner"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Beginner",
        "id": "topic_Japanese_Beginner_Conversation",
        "name_en": "Basic Conversations in Japanese",
        "name_sv": "Grundläggande konversationer på japanska",
        "name_is": "Grunnsamræður á japönsku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.japanese_beginner"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Intermediate",
        "id": "topic_Japanese_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in Japanese",
        "name_sv": "Förbättrat ordförråd på japanska",
        "name_is": "Bætt orðaforði á japönsku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.japanese_intermediate"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Intermediate",
        "id": "topic_Japanese_Intermediate_Grammar",
        "name_en": "Intermediate Japanese Grammar",
        "name_sv": "Intermediär japansk grammatik",
        "name_is": "Millistig japansk fræði",
        "learning_objectives": "Grasp intermediate grammar concepts in Japanese.",
        "when_to_add": ["hobbyInterests.Languages.japanese_intermediate"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Intermediate",
        "id": "topic_Japanese_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in Japanese",
        "name_sv": "Intermediära konversationer på japanska",
        "name_is": "Millistig samræður á japönsku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.japanese_intermediate"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Advanced",
        "id": "topic_Japanese_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in Japanese",
        "name_sv": "Avancerat ordförråd på japanska",
        "name_is": "Háþróaður orðaforði á japönsku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.japanese_advanced"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Advanced",
        "id": "topic_Japanese_Advanced_Grammar",
        "name_en": "Advanced Japanese Grammar",
        "name_sv": "Avancerad japansk grammatik",
        "name_is": "Háþróuð japansk fræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.japanese_advanced"]
    },
    {
        "curriculum": "Japanese Language",
        "field": "Language",
        "subject": "Japanese Advanced",
        "id": "topic_Japanese_Advanced_Literature",
        "name_en": "Japanese Literature Analysis",
        "name_sv": "Analys av japansk litteratur",
        "name_is": "Greining japanskra bókmennta",
        "learning_objectives": "Analyze and discuss classic and modern Japanese literature.",
        "when_to_add": ["hobbyInterests.Languages.japanese_advanced"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Beginner",
        "id": "topic_Russian_Beginner_Vocabulary",
        "name_en": "Basic Vocabulary in Russian",
        "name_sv": "Grundläggande ordförråd på ryska",
        "name_is": "Grunnorðaforði á rússnesku",
        "learning_objectives": "Learn essential vocabulary for everyday communication.",
        "when_to_add": ["hobbyInterests.Languages.russian_beginner"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Beginner",
        "id": "topic_Russian_Beginner_Grammar",
        "name_en": "Introduction to Russian Grammar",
        "name_sv": "Introduktion till rysk grammatik",
        "name_is": "Inngangur að rússneskri fræði",
        "learning_objectives": "Understand the fundamental rules of Russian grammar.",
        "when_to_add": ["hobbyInterests.Languages.russian_beginner"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Beginner",
        "id": "topic_Russian_Beginner_Conversation",
        "name_en": "Basic Conversations in Russian",
        "name_sv": "Grundläggande konversationer på ryska",
        "name_is": "Grunnsamræður á rússnesku",
        "learning_objectives": "Engage in simple conversations using learned vocabulary.",
        "when_to_add": ["hobbyInterests.Languages.russian_beginner"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Intermediate",
        "id": "topic_Russian_Intermediate_Vocabulary",
        "name_en": "Enhanced Vocabulary in Russian",
        "name_sv": "Förbättrat ordförråd på ryska",
        "name_is": "Bætt orðaforði á rússnesku",
        "learning_objectives": "Expand vocabulary to discuss various topics.",
        "when_to_add": ["hobbyInterests.Languages.russian_intermediate"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Intermediate",
        "id": "topic_Russian_Intermediate_Grammar",
        "name_en": "Intermediate Russian Grammar",
        "name_sv": "Intermediär rysk grammatik",
        "name_is": "Millistig rússnesk fræði",
        "learning_objectives": "Grasp intermediate grammar concepts in Russian.",
        "when_to_add": ["hobbyInterests.Languages.russian_intermediate"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Intermediate",
        "id": "topic_Russian_Intermediate_Conversation",
        "name_en": "Intermediate Conversations in Russian",
        "name_sv": "Intermediära konversationer på ryska",
        "name_is": "Millistig samræður á rússnesku",
        "learning_objectives": "Participate in conversations on various themes.",
        "when_to_add": ["hobbyInterests.Languages.russian_intermediate"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Advanced",
        "id": "topic_Russian_Advanced_Vocabulary",
        "name_en": "Advanced Vocabulary in Russian",
        "name_sv": "Avancerat ordförråd på ryska",
        "name_is": "Háþróaður orðaforði á rússnesku",
        "learning_objectives": "Master advanced vocabulary for nuanced discussions.",
        "when_to_add": ["hobbyInterests.Languages.russian_advanced"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Advanced",
        "id": "topic_Russian_Advanced_Grammar",
        "name_en": "Advanced Russian Grammar",
        "name_sv": "Avancerad rysk grammatik",
        "name_is": "Háþróuð rússnesk fræði",
        "learning_objectives": "Refine grammar skills for complex sentences.",
        "when_to_add": ["hobbyInterests.Languages.russian_advanced"]
    },
    {
        "curriculum": "Russian Language",
        "field": "Language",
        "subject": "Russian Advanced",
        "id": "topic_Russian_Advanced_Literature",
        "name_en": "Russian Literature Analysis",
        "name_sv": "Analys av rysk litteratur",
        "name_is": "Greining rússneskra bókmennta",
        "learning_objectives": "Analyze and discuss classic and modern Russian literature.",
        "when_to_add": ["hobbyInterests.Languages.russian_advanced"]
    },
    {
        "curriculum": "Web Development Basics",
        "field": "Computer Science",
        "subject": "Front-End Development",
        "id": "topic_Frontend_Dev_HTML_CSS_JavaScript_React",
        "name_en": "Front-End Development (HTML, CSS, JavaScript, React)",
        "name_sv": "Frontendutveckling (HTML, CSS, JavaScript, React)",
        "name_is": "Framsíðuhönnun (HTML, CSS, JavaScript, React)",
        "learning_objectives": "Learn the fundamentals of web development for creating user interfaces.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.frontend_dev"]
    },
    {
        "curriculum": "Web Development Basics",
        "field": "Computer Science",
        "subject": "Front-End Development",
        "id": "topic_Frontend_Dev_User_Experience",
        "name_en": "User Experience Principles in Front-End Development",
        "name_sv": "Användarupplevelseprinciper i frontendutveckling",
        "name_is": "Notendaupplifunareglur í framsíðuhönnun",
        "learning_objectives": "Understand user experience principles to enhance web applications.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.frontend_dev"]
    },
    {
        "curriculum": "Web Development Basics",
        "field": "Computer Science",
        "subject": "Front-End Development",
        "id": "topic_Frontend_Dev_Responsive_Design",
        "name_en": "Responsive Design Techniques in Front-End Development",
        "name_sv": "Responsiv designteknik i frontendutveckling",
        "name_is": "Aðlögunarteknikk í framsíðuhönnun",
        "learning_objectives": "Learn how to create responsive web designs for various devices.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.frontend_dev"]
    },
    {
        "curriculum": "Back-End Development Basics",
        "field": "Computer Science",
        "subject": "Back-End Development",
        "id": "topic_Backend_Dev_Node_JS",
        "name_en": "Back-End Development with Node.js",
        "name_sv": "Backendutveckling med Node.js",
        "name_is": "Bakenduhönnun með Node.js",
        "learning_objectives": "Learn to build server-side applications using Node.js.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.backend_dev"]
    },
    {
        "curriculum": "Back-End Development Basics",
        "field": "Computer Science",
        "subject": "Back-End Development",
        "id": "topic_Backend_Dev_Python",
        "name_en": "Back-End Development with Python",
        "name_sv": "Backendutveckling med Python",
        "name_is": "Bakenduhönnun með Python",
        "learning_objectives": "Learn to develop server-side applications using Python.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.backend_dev"]
    },
    {
        "curriculum": "Back-End Development Basics",
        "field": "Computer Science",
        "subject": "Back-End Development",
        "id": "topic_Backend_Dev_Ruby",
        "name_en": "Back-End Development with Ruby",
        "name_sv": "Backendutveckling med Ruby",
        "name_is": "Bakenduhönnun með Ruby",
        "learning_objectives": "Learn to develop server-side applications using Ruby.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.backend_dev"]
    },
    {
        "curriculum": "Full-Stack Development",
        "field": "Computer Science",
        "subject": "Full-Stack Development",
        "id": "topic_Fullstack_Dev_Overview",
        "name_en": "Overview of Full-Stack Development",
        "name_sv": "Översikt över fullstackutveckling",
        "name_is": "Yfirlit yfir fullstackhönnun",
        "learning_objectives": "Understand the holistic approach to building web applications.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.fullstack_dev"]
    },
    {
        "curriculum": "Full-Stack Development",
        "field": "Computer Science",
        "subject": "Full-Stack Development",
        "id": "topic_Fullstack_Dev_Project",
        "name_en": "Building a Full-Stack Project",
        "name_sv": "Bygga ett fullstackprojekt",
        "name_is": "Bygging fullstackverkefnis",
        "learning_objectives": "Apply skills learned in both front-end and back-end development.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.fullstack_dev"]
    },
    {
        "curriculum": "Full-Stack Development",
        "field": "Computer Science",
        "subject": "Full-Stack Development",
        "id": "topic_Fullstack_Dev_Tools",
        "name_en": "Tools for Full-Stack Development",
        "name_sv": "Verktyg för fullstackutveckling",
        "name_is": "Verkfæri fyrir fullstackhönnun",
        "learning_objectives": "Explore popular tools and frameworks used in full-stack development.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.fullstack_dev"]
    },
    {
        "curriculum": "Design Fundamentals",
        "field": "Computer Science",
        "subject": "UX Design",
        "id": "topic_UX_Design_Principles",
        "name_en": "Principles of User Experience Design",
        "name_sv": "Principer för användarupplevelse design",
        "name_is": "Reglur um notendaupplifunarhönnun",
        "learning_objectives": "Learn the core principles of designing engaging user experiences.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ux_design"]
    },
    {
        "curriculum": "Design Fundamentals",
        "field": "Computer Science",
        "subject": "UX Design",
        "id": "topic_UX_Design_Research",
        "name_en": "User Research Techniques in UX Design",
        "name_sv": "Användarforskningstekniker inom UX-design",
        "name_is": "Notendufræðiprófunartækni í UX-hönnun",
        "learning_objectives": "Understand methods for gathering user feedback and insights.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ux_design"]
    },
    {
        "curriculum": "Design Fundamentals",
        "field": "Computer Science",
        "subject": "UX Design",
        "id": "topic_UX_Design_Prototyping",
        "name_en": "Prototyping in UX Design",
        "name_sv": "Prototyping inom UX-design",
        "name_is": "Prótótýpur í UX-hönnun",
        "learning_objectives": "Learn how to create prototypes for user testing.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ux_design"]
    },
    {
        "curriculum": "User Interface Design",
        "field": "Computer Science",
        "subject": "UI Design",
        "id": "topic_UI_Design_Fundamentals",
        "name_en": "Fundamentals of User Interface Design",
        "name_sv": "Grunderna i användargränssnittsdesign",
        "name_is": "Grunnir í notendagrindsins hönnun",
        "learning_objectives": "Learn the basics of effective user interface design.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ui_design"]
    },
    {
        "curriculum": "User Interface Design",
        "field": "Computer Science",
        "subject": "UI Design",
        "id": "topic_UI_Design_Color_Theory",
        "name_en": "Color Theory in UI Design",
        "name_sv": "Färgteori inom UI-design",
        "name_is": "Litafræði í notendagrindsins hönnun",
        "learning_objectives": "Understand how to use color effectively in user interface design.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ui_design"]
    },
    {
        "curriculum": "User Interface Design",
        "field": "Computer Science",
        "subject": "UI Design",
        "id": "topic_UI_Design_Typography",
        "name_en": "Typography in UI Design",
        "name_sv": "Typografi inom UI-design",
        "name_is": "Létamál í notendagrindsins hönnun",
        "learning_objectives": "Learn how to choose and use typography in user interfaces.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.ui_design"]
    },
    {
        "curriculum": "Game Development Basics",
        "field": "Computer Science",
        "subject": "Game Design",
        "id": "topic_Game_Design_Fundamentals",
        "name_en": "Fundamentals of Game Design",
        "name_sv": "Grunderna i speldesign",
        "name_is": "Grunnir í speldizajni",
        "learning_objectives": "Learn the key principles of game design.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.game_design"]
    },
    {
        "curriculum": "Game Development Basics",
        "field": "Computer Science",
        "subject": "Game Design",
        "id": "topic_Game_Design_Project",
        "name_en": "Creating a Game Project",
        "name_sv": "Skapa ett spelprojekt",
        "name_is": "Búa til leikverkefni",
        "learning_objectives": "Implement your design concepts into an actual game.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.game_design"]
    },
    {
        "curriculum": "Game Development Basics",
        "field": "Computer Science",
        "subject": "Game Design",
        "id": "topic_Game_Design_Playtesting",
        "name_en": "Playtesting and Feedback in Game Design",
        "name_sv": "Speltestning och feedback i speldesign",
        "name_is": "Spilprófanir og endurgjöf í speldizajni",
        "learning_objectives": "Learn how to conduct playtests and gather feedback.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.game_design"]
    },
    {
        "curriculum": "Cybersecurity Essentials",
        "field": "Computer Science",
        "subject": "Cybersecurity",
        "id": "topic_Cybersecurity_Basics",
        "name_en": "Cybersecurity Basics",
        "name_sv": "Grunderna i cybersäkerhet",
        "name_is": "Grunnir í netöryggi",
        "learning_objectives": "Understand the fundamentals of cybersecurity.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cybersecurity"]
    },
    {
        "curriculum": "Cybersecurity Essentials",
        "field": "Computer Science",
        "subject": "Cybersecurity",
        "id": "topic_Cybersecurity_Risk_Management",
        "name_en": "Risk Management in Cybersecurity",
        "name_sv": "Riskhantering inom cybersäkerhet",
        "name_is": "Rizkanýja í netöryggi",
        "learning_objectives": "Learn how to assess and manage cybersecurity risks.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cybersecurity"]
    },
    {
        "curriculum": "Cybersecurity Essentials",
        "field": "Computer Science",
        "subject": "Cybersecurity",
        "id": "topic_Cybersecurity_Incident_Response",
        "name_en": "Incident Response in Cybersecurity",
        "name_sv": "Incidentåtgärder inom cybersäkerhet",
        "name_is": "Viðbrögð við atburðum í netöryggi",
        "learning_objectives": "Understand how to respond to cybersecurity incidents.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cybersecurity"]
    },
    {
        "curriculum": "Data Science Fundamentals",
        "field": "Computer Science",
        "subject": "Data Science",
        "id": "topic_Data_Science_Basics",
        "name_en": "Basics of Data Science",
        "name_sv": "Grunderna i datavetenskap",
        "name_is": "Grunnir í gagnavísindum",
        "learning_objectives": "Learn the fundamentals of data science and analytics.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.data_science"]
    },
    {
        "curriculum": "Data Science Fundamentals",
        "field": "Computer Science",
        "subject": "Data Science",
        "id": "topic_Data_Science_Statistics",
        "name_en": "Statistics for Data Science",
        "name_sv": "Statistik för datavetenskap",
        "name_is": "Tölfræði fyrir gagnavísindi",
        "learning_objectives": "Understand statistical methods used in data science.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.data_science"]
    },
    {
        "curriculum": "Data Science Fundamentals",
        "field": "Computer Science",
        "subject": "Data Science",
        "id": "topic_Data_Science_Data_Visualization",
        "name_en": "Data Visualization Techniques",
        "name_sv": "Tekniker för datavisualisering",
        "name_is": "Tæknilegar aðferðir í gagnavisualisering",
        "learning_objectives": "Learn how to visually represent data insights.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.data_science"]
    },
    {
        "curriculum": "Machine Learning Basics",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_Machine_Learning_Intro",
        "name_en": "Introduction to Machine Learning",
        "name_sv": "Introduktion till maskininlärning",
        "name_is": "Inngangur að vélanámi",
        "learning_objectives": "Understand the basic concepts of machine learning.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.machine_learning"]
    },
    {
        "curriculum": "Machine Learning Basics",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_Machine_Learning_Supervised_Learning",
        "name_en": "Supervised Learning Algorithms",
        "name_sv": "Övervakade inlärningalgoritmer",
        "name_is": "Yfirfarin inlæringaralgoritmar",
        "learning_objectives": "Learn about different supervised learning algorithms.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.machine_learning"]
    },
    {
        "curriculum": "Machine Learning Basics",
        "field": "Computer Science",
        "subject": "Machine Learning",
        "id": "topic_Machine_Learning_Unsupervised_Learning",
        "name_en": "Unsupervised Learning Concepts",
        "name_sv": "Oövervakad inlärning",
        "name_is": "Óyfirfarin inlæring",
        "learning_objectives": "Understand unsupervised learning methods and their applications.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.machine_learning"]
    },
    {
        "curriculum": "Mobile Development Fundamentals",
        "field": "Computer Science",
        "subject": "Mobile App Development",
        "id": "topic_Mobile_App_Development_Intro",
        "name_en": "Introduction to Mobile App Development",
        "name_sv": "Introduktion till mobilapputveckling",
        "name_is": "Inngangur að þróun farsímaforrita",
        "learning_objectives": "Learn the basics of developing mobile applications.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.mobile_app_dev"]
    },
    {
        "curriculum": "Mobile Development Fundamentals",
        "field": "Computer Science",
        "subject": "Mobile App Development",
        "id": "topic_Mobile_App_Development_Android",
        "name_en": "Android App Development",
        "name_sv": "Utveckling av Android-appar",
        "name_is": "Þróun á Android-forritum",
        "learning_objectives": "Learn to create apps for the Android platform using Java.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.mobile_app_dev"]
    },
    {
        "curriculum": "Mobile Development Fundamentals",
        "field": "Computer Science",
        "subject": "Mobile App Development",
        "id": "topic_Mobile_App_Development_iOS",
        "name_en": "iOS App Development",
        "name_sv": "Utveckling av iOS-appar",
        "name_is": "Þróun á iOS-forritum",
        "learning_objectives": "Learn to create apps for the iOS platform using Swift.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.mobile_app_dev"]
    },
    {
        "curriculum": "Cloud Computing Fundamentals",
        "field": "Computer Science",
        "subject": "Cloud Computing",
        "id": "topic_Cloud_Computing_Basics",
        "name_en": "Basics of Cloud Computing",
        "name_sv": "Grunderna i molnberäkning",
        "name_is": "Grunnir í skýjareikningi",
        "learning_objectives": "Understand the key concepts and benefits of cloud computing.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cloud_computing"]
    },
    {
        "curriculum": "Cloud Computing Fundamentals",
        "field": "Computer Science",
        "subject": "Cloud Computing",
        "id": "topic_Cloud_Computing_Providers",
        "name_en": "Popular Cloud Service Providers",
        "name_sv": "Populära molnservicetjänster",
        "name_is": "Vinsæl skýjaþjónusta",
        "learning_objectives": "Examine different cloud service providers and their offerings.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cloud_computing"]
    },
    {
        "curriculum": "Cloud Computing Fundamentals",
        "field": "Computer Science",
        "subject": "Cloud Computing",
        "id": "topic_Cloud_Computing_Architecture",
        "name_en": "Cloud Computing Architecture",
        "name_sv": "Molnberäkningsarkitektur",
        "name_is": "Architektúra skýjareiknings",
        "learning_objectives": "Learn about the architecture of cloud computing systems.",
        "when_to_add": ["hobbyInterests.Technology_and_Coding.cloud_computing"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "Movie Analysis",
        "id": "topic_Movie_Analysis_Cinematic_Techniques",
        "name_en": "Cinematic Techniques in Movie Analysis",
        "name_sv": "Filmtekniker i filmanalys",
        "name_is": "Kvikmyndatækni í filma greiningu",
        "learning_objectives": "Explore various cinematic techniques used in filmmaking.",
        "when_to_add": ["hobbyInterests.Entertainment.movie_analysis"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "Movie Analysis",
        "id": "topic_Movie_Analysis_Thematic_Exploration",
        "name_en": "Thematic Exploration in Movie Analysis",
        "name_sv": "Tematisk utforskning i filmanalys",
        "name_is": "Tematísk könnun í filma greiningu",
        "learning_objectives": "Analyze underlying themes and messages in films.",
        "when_to_add": ["hobbyInterests.Entertainment.movie_analysis"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "Movie Analysis",
        "id": "topic_Movie_Analysis_Character_Development",
        "name_en": "Character Development in Movies",
        "name_sv": "Karaktärsutveckling i filmer",
        "name_is": "Karakter þróun í kvikmyndum",
        "learning_objectives": "Examine character arcs and development in cinema.",
        "when_to_add": ["hobbyInterests.Entertainment.movie_analysis"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "High-Grossing Movies",
        "id": "topic_High_Grossing_Movies_Trends",
        "name_en": "Trends in High-Grossing Movies",
        "name_sv": "Tendenser i högavkastande filmer",
        "name_is": "Tískuhegðun í hátekjum kvikmyndum",
        "learning_objectives": "Understand the trends that lead to financial success in films.",
        "when_to_add": ["hobbyInterests.Entertainment.high_grossing_movies"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "High-Grossing Movies",
        "id": "topic_High_Grossing_Movies_Impact",
        "name_en": "Cultural Impact of High-Grossing Movies",
        "name_sv": "Kulturell påverkan av högavkastande filmer",
        "name_is": "Menningarpáhrif hátekjum kvikmynda",
        "learning_objectives": "Analyze how blockbuster films shape culture and society.",
        "when_to_add": ["hobbyInterests.Entertainment.high_grossing_movies"]
    },
    {
        "curriculum": "Film Studies",
        "field": "Cultural Studies",
        "subject": "High-Grossing Movies",
        "id": "topic_High_Grossing_Movies_Budget_vs_Earnings",
        "name_en": "Budget vs. Earnings in High-Grossing Movies",
        "name_sv": "Budget kontra intäkter i högavkastande filmer",
        "name_is": "Budget á móti tekjum í hátekjum kvikmyndum",
        "learning_objectives": "Study the relationship between production budget and box office earnings.",
        "when_to_add": ["hobbyInterests.Entertainment.high_grossing_movies"]
    },
    {
        "curriculum": "Media Studies",
        "field": "Cultural Studies",
        "subject": "Celebrity News and Gossip",
        "id": "topic_Celebrity_News_Social_Media",
        "name_en": "The Role of Social Media in Celebrity News",
        "name_sv": "Sociala mediers roll i kändisskvaller",
        "name_is": "Hlutverk samfélagsmiðla í frúarómi",
        "learning_objectives": "Explore how social media influences celebrity news and gossip.",
        "when_to_add": ["hobbyInterests.Entertainment.celebrity_news"]
    },
    {
        "curriculum": "Media Studies",
        "field": "Cultural Studies",
        "subject": "Celebrity News and Gossip",
        "id": "topic_Celebrity_News_Pop_Culture",
        "name_en": "Celebrity News and Its Impact on Pop Culture",
        "name_sv": "Kändisskvaller och dess påverkan på popkulturen",
        "name_is": "Frúarskvaldur og áhrif hans á dægurkultúr",
        "learning_objectives": "Analyze the interplay between celebrity news and popular culture.",
        "when_to_add": ["hobbyInterests.Entertainment.celebrity_news"]
    },
    {
        "curriculum": "Media Studies",
        "field": "Cultural Studies",
        "subject": "Celebrity News and Gossip",
        "id": "topic_Celebrity_News_Ethics",
        "name_en": "Ethics of Celebrity News Reporting",
        "name_sv": "Etik inom kändisskvalleverkande",
        "name_is": "Siðfræði frúarskvaldsfréttaskýringa",
        "learning_objectives": "Discuss the ethical implications of celebrity news reporting.",
        "when_to_add": ["hobbyInterests.Entertainment.celebrity_news"]
    },
    {
        "curriculum": "Television Studies",
        "field": "Cultural Studies",
        "subject": "Television Shows and Series",
        "id": "topic_TV_Shows_Analysis",
        "name_en": "Analysis of Television Shows",
        "name_sv": "Analys av tv-program",
        "name_is": "Greining á sjónvarpsþætti",
        "learning_objectives": "Examine narrative techniques used in television series.",
        "when_to_add": ["hobbyInterests.Entertainment.tv_shows"]
    },
    {
        "curriculum": "Television Studies",
        "field": "Cultural Studies",
        "subject": "Television Shows and Series",
        "id": "topic_TV_Shows_Impact",
        "name_en": "Cultural Impact of Television Shows",
        "name_sv": "Kulturell påverkan av tv-program",
        "name_is": "Menningarpáhrif sjónvarpsþátta",
        "learning_objectives": "Analyze how television shapes societal norms and values.",
        "when_to_add": ["hobbyInterests.Entertainment.tv_shows"]
    },
    {
        "curriculum": "Television Studies",
        "field": "Cultural Studies",
        "subject": "Television Shows and Series",
        "id": "topic_TV_Shows_Fandom",
        "name_en": "Fandom in Television Shows",
        "name_sv": "Fandom i tv-program",
        "name_is": "Fandom í sjónvarpsþáttum",
        "learning_objectives": "Explore the dynamics of fan culture around television series.",
        "when_to_add": ["hobbyInterests.Entertainment.tv_shows"]
    },
    {
        "curriculum": "Gaming Studies",
        "field": "Cultural Studies",
        "subject": "Popular Video Games",
        "id": "topic_Popular_Video_Games_Analysis",
        "name_en": "Analysis of Popular Video Games",
        "name_sv": "Analys av populära videospel",
        "name_is": "Greining á vinsældar tölvuleikjum",
        "learning_objectives": "Examine game mechanics and narratives used in popular video games.",
        "when_to_add": ["hobbyInterests.Entertainment.popular_video_games"]
    },
    {
        "curriculum": "Gaming Studies",
        "field": "Cultural Studies",
        "subject": "Popular Video Games",
        "id": "topic_Popular_Video_Games_Culture",
        "name_en": "Video Games and Modern Culture",
        "name_sv": "Videospel och modern kultur",
        "name_is": "Tölvuleikir og nútímamenning",
        "learning_objectives": "Analyze the cultural implications of popular video games.",
        "when_to_add": ["hobbyInterests.Entertainment.popular_video_games"]
    },
    {
        "curriculum": "Gaming Studies",
        "field": "Cultural Studies",
        "subject": "Popular Video Games",
        "id": "topic_Popular_Video_Games_Development",
        "name_en": "The Development of Popular Video Games",
        "name_sv": "Utvecklingen av populära videospel",
        "name_is": "Þróunin á vinsældar tölvuleikjum",
        "learning_objectives": "Study the game development process from concept to release.",
        "when_to_add": ["hobbyInterests.Entertainment.popular_video_games"]
    },
    {
        "curriculum": "Comic Studies",
        "field": "Cultural Studies",
        "subject": "Comic Books and Graphic Novels",
        "id": "topic_Comic_Books_History",
        "name_en": "History of Comic Books",
        "name_sv": "Historien om serietidningar",
        "name_is": "Saga seríutíninga",
        "learning_objectives": "Explore the evolution of comic books and their cultural significance.",
        "when_to_add": ["hobbyInterests.Entertainment.comic_books"]
    },
    {
        "curriculum": "Comic Studies",
        "field": "Cultural Studies",
        "subject": "Comic Books and Graphic Novels",
        "id": "topic_Comic_Books_Analysis",
        "name_en": "Analyzing Graphic Novels",
        "name_sv": "Analys av serieromaner",
        "name_is": "Greining á grafískum skáldsögum",
        "learning_objectives": "Analyze narrative and artistic styles within graphic novels.",
        "when_to_add": ["hobbyInterests.Entertainment.comic_books"]
    },
    {
        "curriculum": "Comic Studies",
        "field": "Cultural Studies",
        "subject": "Comic Books and Graphic Novels",
        "id": "topic_Comic_Books_Representation",
        "name_en": "Representation in Comic Books",
        "name_sv": "Representation i serietidningar",
        "name_is": "Fulltrúi í seríutíningum",
        "learning_objectives": "Discuss issues of representation and diversity in comics.",
        "when_to_add": ["hobbyInterests.Entertainment.comic_books"]
    },
    {
        "curriculum": "Mindfulness Practices",
        "field": "Personal Development",
        "subject": "Mindfulness and Meditation",
        "id": "topic_Mindfulness_Basics",
        "name_en": "Basics of Mindfulness",
        "name_sv": "Grunderna i mindfulness",
        "name_is": "Grunnir í vitund",
        "learning_objectives": "Understand the key principles and benefits of mindfulness.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.mindfulness"]
    },
    {
        "curriculum": "Mindfulness Practices",
        "field": "Personal Development",
        "subject": "Mindfulness and Meditation",
        "id": "topic_Mindfulness_Techniques",
        "name_en": "Techniques for Effective Meditation",
        "name_sv": "Tekniker för effektiv meditation",
        "name_is": "Aðferðir til árangursríkrar hugleiðslu",
        "learning_objectives": "Learn various techniques to practice meditation effectively.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.mindfulness"]
    },
    {
        "curriculum": "Mindfulness Practices",
        "field": "Personal Development",
        "subject": "Mindfulness and Meditation",
        "id": "topic_Mindfulness_Healing",
        "name_en": "Mindfulness for Emotional Healing",
        "name_sv": "Mindfulness för känslomässig läkning",
        "name_is": "Vitund til tilfinningalegs läkningar",
        "learning_objectives": "Explore how mindfulness can aid emotional and psychological healing.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.mindfulness"]
    },
    {
        "curriculum": "Time Management",
        "field": "Personal Development",
        "subject": "Time Management Techniques",
        "id": "topic_Time_Management_Basics",
        "name_en": "Introduction to Time Management",
        "name_sv": "Introduktion till tidshantering",
        "name_is": "Inngangur að tíma stjórnun",
        "learning_objectives": "Learn the basics of time management and planning.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.time_management"]
    },
    {
        "curriculum": "Time Management",
        "field": "Personal Development",
        "subject": "Time Management Techniques",
        "id": "topic_Time_Management_Tools",
        "name_en": "Tools and Techniques for Time Management",
        "name_sv": "Verktyg och tekniker för tidshantering",
        "name_is": "Verkfæri og aðferðir fyrir tíma stjórnun",
        "learning_objectives": "Discover tools and techniques to enhance time management skills.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.time_management"]
    },
    {
        "curriculum": "Time Management",
        "field": "Personal Development",
        "subject": "Time Management Techniques",
        "id": "topic_Time_Management_Productivity",
        "name_en": "Improving Productivity through Time Management",
        "name_sv": "Förbättra produktiviteten genom tidshantering",
        "name_is": "Aukning framleiðni með því að stjórna tíma",
        "learning_objectives": "Learn how effective time management leads to better productivity.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.time_management"]
    },
    {
        "curriculum": "Productivity Studies",
        "field": "Personal Development",
        "subject": "Productivity Hacks",
        "id": "topic_Productivity_Hacks_Overview",
        "name_en": "Overview of Productivity Hacks",
        "name_sv": "Översikt över produktivitetshacks",
        "name_is": "Yfirlit yfir framleiðni hagsmuni",
        "learning_objectives": "Explore various hacks and tips to boost productivity.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.productivity_hacks"]
    },
    {
        "curriculum": "Productivity Studies",
        "field": "Personal Development",
        "subject": "Productivity Hacks",
        "id": "topic_Productivity_Hacks_Techniques",
        "name_en": "Effective Techniques for Enhancing Productivity",
        "name_sv": "Effektiva tekniker för att förbättra produktivitet",
        "name_is": "Arangur aðferðir til að auka framleiðni",
        "learning_objectives": "Learn different techniques to implement productivity hacks effectively.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.productivity_hacks"]
    },
    {
        "curriculum": "Productivity Studies",
        "field": "Personal Development",
        "subject": "Productivity Hacks",
        "id": "topic_Productivity_Hacks_Implementation",
        "name_en": "Implementing Productivity Hacks in Daily Life",
        "name_sv": "Implementera produktivitetshacks i vardagen",
        "name_is": "Framkvæmd framleiðni í daglegu lífi",
        "learning_objectives": "Understand how to incorporate productivity hacks into everyday tasks.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.productivity_hacks"]
    },
    {
        "curriculum": "Emotional Intelligence Development",
        "field": "Personal Development",
        "subject": "Emotional Intelligence",
        "id": "topic_Emotional_Intelligence_Basics",
        "name_en": "Basics of Emotional Intelligence",
        "name_sv": "Grunderna i emotionell intelligens",
        "name_is": "Grunnir í tilfinningalegri skynjun",
        "learning_objectives": "Understand the fundamentals of emotional intelligence.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.emotional_intelligence"]
    },
    {
        "curriculum": "Emotional Intelligence Development",
        "field": "Personal Development",
        "subject": "Emotional Intelligence",
        "id": "topic_Emotional_Intelligence_Improvement",
        "name_en": "Improving Emotional Intelligence",
        "name_sv": "Förbättra emotionell intelligens",
        "name_is": "Bætir tilfinningalega skynjun",
        "learning_objectives": "Learn techniques to enhance emotional intelligence.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.emotional_intelligence"]
    },
    {
        "curriculum": "Emotional Intelligence Development",
        "field": "Personal Development",
        "subject": "Emotional Intelligence",
        "id": "topic_Emotional_Intelligence_Application",
        "name_en": "Applying Emotional Intelligence in Life",
        "name_sv": "Tillämpa emotionell intelligens i livet",
        "name_is": "Aðlaga tilfinningalega skynjun í lífinu",
        "learning_objectives": "Explore how emotional intelligence can be used in personal and professional life.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.emotional_intelligence"]
    },
    {
        "curriculum": "Goal Setting Workshop",
        "field": "Personal Development",
        "subject": "Goal Setting & Habit Formation",
        "id": "topic_Goal_Setting_Basics",
        "name_en": "Basics of Goal Setting",
        "name_sv": "Grunderna i målsättning",
        "name_is": "Grunnir í markmiðssetningu",
        "learning_objectives": "Learn to set SMART goals effectively.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.goal_setting"]
    },
    {
        "curriculum": "Goal Setting Workshop",
        "field": "Personal Development",
        "subject": "Goal Setting & Habit Formation",
        "id": "topic_Goal_Setting_Habits",
        "name_en": "Forming Healthy Habits",
        "name_sv": "Skapa hälsosamma vanor",
        "name_is": "Myndun hollra venja",
        "learning_objectives": "Explore methods for forming and maintaining productive habits.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.goal_setting"]
    },
    {
        "curriculum": "Goal Setting Workshop",
        "field": "Personal Development",
        "subject": "Goal Setting & Habit Formation",
        "id": "topic_Goal_Setting_Overcoming_Obstacles",
        "name_en": "Overcoming Obstacles to Goal Achievement",
        "name_sv": "Övervinna hinder för att nå mål",
        "name_is": "Yfirvinna hindranir við að ná markmiðum",
        "learning_objectives": "Understand strategies to overcome challenges in goal achievement.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.goal_setting"]
    },
    {
        "curriculum": "Public Speaking Essentials",
        "field": "Personal Development",
        "subject": "Public Speaking",
        "id": "topic_Public_Speaking_Basics",
        "name_en": "Basics of Public Speaking",
        "name_sv": "Grunder i offentlig talande",
        "name_is": "Grunnir í opinberri ræðu",
        "learning_objectives": "Learn fundamental skills for effective public speaking.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.public_speaking"]
    },
    {
        "curriculum": "Public Speaking Essentials",
        "field": "Personal Development",
        "subject": "Public Speaking",
        "id": "topic_Public_Speaking_Techniques",
        "name_en": "Techniques for Effective Public Speaking",
        "name_sv": "Tekniker för effektivt offentligt talande",
        "name_is": "Aðferðir til árangursríkrar opinberrar ræðu",
        "learning_objectives": "Discover techniques to enhance public speaking performance.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.public_speaking"]
    },
    {
        "curriculum": "Public Speaking Essentials",
        "field": "Personal Development",
        "subject": "Public Speaking",
        "id": "topic_Public_Speaking_Engagement",
        "name_en": "Engaging the Audience in Public Speaking",
        "name_sv": "Engagera publiken vid offentligt talande",
        "name_is": "Engagera áhorfendur í opinberri ræðu",
        "learning_objectives": "Learn how to connect with and engage your audience.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.public_speaking"]
    },
    {
        "curriculum": "Networking Workshop",
        "field": "Personal Development",
        "subject": "Networking Skills",
        "id": "topic_Networking_Skills_Basics",
        "name_en": "Fundamentals of Networking",
        "name_sv": "Grunder i nätverkande",
        "name_is": "Grunnir í netkerfi",
        "learning_objectives": "Learn the basics of effective networking.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.networking_skills"]
    },
    {
        "curriculum": "Networking Workshop",
        "field": "Personal Development",
        "subject": "Networking Skills",
        "id": "topic_Networking_Skills_Strategies",
        "name_en": "Strategies for Effective Networking",
        "name_sv": "Strategier för effektivt nätverkande",
        "name_is": "Stefna fyrir árangursríkt netkerfi",
        "learning_objectives": "Explore strategies and techniques for successful networking.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.networking_skills"]
    },
    {
        "curriculum": "Networking Workshop",
        "field": "Personal Development",
        "subject": "Networking Skills",
        "id": "topic_Networking_Skills_Online",
        "name_en": "Online Networking Skills",
        "name_sv": "Nätverksfärdigheter online",
        "name_is": "Nettverksfærni á netinu",
        "learning_objectives": "Understand how to network effectively in digital spaces.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.networking_skills"]
    },
    {
        "curriculum": "Negotiation Techniques",
        "field": "Personal Development",
        "subject": "Negotiation Techniques",
        "id": "topic_Negotiation_Techniques_Basics",
        "name_en": "Basics of Negotiation Techniques",
        "name_sv": "Grunderna i förhandlingstekniker",
        "name_is": "Grunnir í samningatækni",
        "learning_objectives": "Learn the fundamentals of effective negotiation.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.negotiation_techniques"]
    },
    {
        "curriculum": "Negotiation Techniques",
        "field": "Personal Development",
        "subject": "Negotiation Techniques",
        "id": "topic_Negotiation_Techniques_Strategies",
        "name_en": "Strategic Negotiation Techniques",
        "name_sv": "Strategiska förhandlingstekniker",
        "name_is": "Stefnis samningatækni",
        "learning_objectives": "Explore advanced strategies for successful negotiations.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.negotiation_techniques"]
    },
    {
        "curriculum": "Negotiation Techniques",
        "field": "Personal Development",
        "subject": "Negotiation Techniques",
        "id": "topic_Negotiation_Techniques_Applications",
        "name_en": "Applying Negotiation Techniques in Real Life",
        "name_sv": "Tillämpa förhandlingstekniker i verkliga livet",
        "name_is": "Aðlaga samningatækni í raunveruleikanum",
        "learning_objectives": "Understand how to apply negotiation skills in everyday situations.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.negotiation_techniques"]
    },
    {
        "curriculum": "Leadership Skills",
        "field": "Personal Development",
        "subject": "Leadership Skills",
        "id": "topic_Leadership_Skills_Basics",
        "name_en": "Basics of Leadership Skills",
        "name_sv": "Grunderna i ledarskapsförmågor",
        "name_is": "Grunnir í leiðtogahæfileikum",
        "learning_objectives": "Learn the foundational principles of effective leadership.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.leadership_skills"]
    },
    {
        "curriculum": "Leadership Skills",
        "field": "Personal Development",
        "subject": "Leadership Skills",
        "id": "topic_Leadership_Skills_Styles",
        "name_en": "Different Leadership Styles",
        "name_sv": "Olika ledarstilar",
        "name_is": "Ólíkar leiðtogastílar",
        "learning_objectives": "Explore various leadership styles and their effectiveness.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.leadership_skills"]
    },
    {
        "curriculum": "Leadership Skills",
        "field": "Personal Development",
        "subject": "Leadership Skills",
        "id": "topic_Leadership_Skills_Development",
        "name_en": "Developing Leadership Skills",
        "name_sv": "Utveckla ledarskapsförmågor",
        "name_is": "Þróun leiðtogahæfileika",
        "learning_objectives": "Understand how to cultivate and refine leadership skills.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.leadership_skills"]
    },
    {
        "curriculum": "Critical Thinking Development",
        "field": "Personal Development",
        "subject": "Critical Thinking",
        "id": "topic_Critical_Thinking_Basics",
        "name_en": "Basics of Critical Thinking",
        "name_sv": "Grunderna i kritiskt tänkande",
        "name_is": "Grunnir í gagnrýnu hugsun",
        "learning_objectives": "Learn the fundamentals of critical thinking skills.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.critical_thinking"]
    },
    {
        "curriculum": "Critical Thinking Development",
        "field": "Personal Development",
        "subject": "Critical Thinking",
        "id": "topic_Critical_Thinking_Applications",
        "name_en": "Applying Critical Thinking in Everyday Life",
        "name_sv": "Tillämpa kritiskt tänkande i vardagen",
        "name_is": "Aðlaga gagnrýna hugsun í daglegu lífi",
        "learning_objectives": "Explore ways to apply critical thinking in real-life situations.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.critical_thinking"]
    },
    {
        "curriculum": "Critical Thinking Development",
        "field": "Personal Development",
        "subject": "Critical Thinking",
        "id": "topic_Critical_Thinking_Improvement",
        "name_en": "Improving Critical Thinking Skills",
        "name_sv": "Förbättra kritiska tänkande färdigheter",
        "name_is": "Bætir gagnrýna hugsun hæfileika",
        "learning_objectives": "Identify techniques for enhancing critical thinking abilities.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.critical_thinking"]
    },
    {
        "curriculum": "Creativity Workshop",
        "field": "Personal Development",
        "subject": "Creativity Enhancement",
        "id": "topic_Creativity_Enhancement_Techniques",
        "name_en": "Techniques for Enhancing Creativity",
        "name_sv": "Tekniker för att förbättra kreativitet",
        "name_is": "Aðferðir til að auka sköpunargáfu",
        "learning_objectives": "Explore various techniques to boost creative thinking.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.creativity_enhancement"]
    },
    {
        "curriculum": "Creativity Workshop",
        "field": "Personal Development",
        "subject": "Creativity Enhancement",
        "id": "topic_Creativity_Enhancement_Overcoming_Blocks",
        "name_en": "Overcoming Creative Blocks",
        "name_sv": "Övervinna kreativa blockeringar",
        "name_is": "Yfirvinna sköpunar hindranir",
        "learning_objectives": "Learn strategies to overcome creativity blocks.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.creativity_enhancement"]
    },
    {
        "curriculum": "Creativity Workshop",
        "field": "Personal Development",
        "subject": "Creativity Enhancement",
        "id": "topic_Creativity_Enhancement_Applications",
        "name_en": "Applying Creativity in Problem Solving",
        "name_sv": "Tillämpa kreativitet i problemlösning",
        "name_is": "Aðlaga sköpunargáfu í vandamálalausn",
        "learning_objectives": "Understand how to apply creative thinking to solve problems.",
        "when_to_add": ["hobbyInterests.Self_Growth_Personal_Development.creativity_enhancement"]
    },
    {
        "curriculum": "Political Studies",
        "field": "Political Science",
        "subject": "Political Theories",
        "id": "topic_Political_Theories_Overview",
        "name_en": "Overview of Political Theories",
        "name_sv": "Översikt över politiska teorier",
        "name_is": "Yfirlit yfir stjórnmálaskáld",
        "learning_objectives": "Understand key political theories and their relevance.",
        "when_to_add": ["hobbyInterests.Politics_Geography.political_theories"]
    },
    {
        "curriculum": "Political Studies",
        "field": "Political Science",
        "subject": "Political Theories",
        "id": "topic_Political_Theories_Application",
        "name_en": "Applying Political Theories",
        "name_sv": "Tillämpa politiska teorier",
        "name_is": "Aðlaga stjórnmálaskáld",
        "learning_objectives": "Learn how to apply political theories in real-world scenarios.",
        "when_to_add": ["hobbyInterests.Politics_Geography.political_theories"]
    },
    {
        "curriculum": "Political Studies",
        "field": "Political Science",
        "subject": "Political Theories",
        "id": "topic_Political_Theories_Philosophers",
        "name_en": "Political Theorists and Their Ideas",
        "name_sv": "Politiska teoretiker och deras idéer",
        "name_is": "Stjórnmálaskáld og hugmyndir þeirra",
        "learning_objectives": "Explore the contributions of key political theorists.",
        "when_to_add": ["hobbyInterests.Politics_Geography.political_theories"]
    },
    {
        "curriculum": "Current Events Studies",
        "field": "Political Science",
        "subject": "Global Current Events",
        "id": "topic_Global_Current_Events_Analysis",
        "name_en": "Analyzing Global Current Events",
        "name_sv": "Analys av globala aktuella händelser",
        "name_is": "Greining á alþjóðlegum atburðum",
        "learning_objectives": "Examine important current events and their implications.",
        "when_to_add": ["hobbyInterests.Politics_Geography.global_current_events"]
    },
    {
        "curriculum": "Current Events Studies",
        "field": "Political Science",
        "subject": "Global Current Events",
        "id": "topic_Global_Current_Events_Impact",
        "name_en": "Impact of Global Current Events",
        "name_sv": "Påverkan av globala aktuella händelser",
        "name_is": "Áhrif alþjóðlegra atburða",
        "learning_objectives": "Understand how global events shape geo-political dynamics.",
        "when_to_add": ["hobbyInterests.Politics_Geography.global_current_events"]
    },
    {
        "curriculum": "Current Events Studies",
        "field": "Political Science",
        "subject": "Global Current Events",
        "id": "topic_Global_Current_Events_Reporting",
        "name_en": "Reporting on Global Current Events",
        "name_sv": "Rapportering om globala aktuella händelser",
        "name_is": "Skýrsla um alþjóðleg atburð",
        "learning_objectives": "Learn best practices for reporting and analyzing current events.",
        "when_to_add": ["hobbyInterests.Politics_Geography.global_current_events"]
    },
    {
        "curriculum": "Environmental Studies",
        "field": "Environmental Science",
        "subject": "Environmental Studies",
        "id": "topic_Environmental_Studies_Basics",
        "name_en": "Basics of Environmental Studies",
        "name_sv": "Grunderna i miljöstudier",
        "name_is": "Grunnir í umhverfisfræði",
        "learning_objectives": "Understand key concepts in environmental studies.",
        "when_to_add": ["hobbyInterests.Politics_Geography.environmental_studies"]
    },
    {
        "curriculum": "Environmental Studies",
        "field": "Environmental Science",
        "subject": "Environmental Studies",
        "id": "topic_Environmental_Studies_Issues",
        "name_en": "Current Environmental Issues",
        "name_sv": "Aktuella miljöfrågor",
        "name_is": "Núverandi umhverfisviðfangsefni",
        "learning_objectives": "Examine pressing environmental issues facing the world today.",
        "when_to_add": ["hobbyInterests.Politics_Geography.environmental_studies"]
    },
    {
        "curriculum": "Environmental Studies",
        "field": "Environmental Science",
        "subject": "Environmental Studies",
        "id": "topic_Environmental_Studies_Solutions",
        "name_en": "Solutions to Environmental Problems",
        "name_sv": "Lösningar på miljöproblem",
        "name_is": "Lausnir við umhverfisvandamálum",
        "learning_objectives": "Explore potential solutions to ongoing environmental challenges.",
        "when_to_add": ["hobbyInterests.Politics_Geography.environmental_studies"]
    },
    {
        "curriculum": "Geography Studies",
        "field": "Geography",
        "subject": "Cultural Geography",
        "id": "topic_Cultural_Geography_Basics",
        "name_en": "Basics of Cultural Geography",
        "name_sv": "Grunderna i kulturell geografi",
        "name_is": "Grunnir í menningarlegri jarðfræði",
        "learning_objectives": "Understand the relationship between culture and geography.",
        "when_to_add": ["hobbyInterests.Politics_Geography.cultural_geography"]
    },
    {
        "curriculum": "Geography Studies",
        "field": "Geography",
        "subject": "Cultural Geography",
        "id": "topic_Cultural_Geography_Analysis",
        "name_en": "Analyzing Cultural Geography",
        "name_sv": "Analys av kulturell geografi",
        "name_is": "Greining á menningarlegri jarðfræði",
        "learning_objectives": "Explore case studies of cultural geography around the world.",
        "when_to_add": ["hobbyInterests.Politics_Geography.cultural_geography"]
    },
    {
        "curriculum": "Geography Studies",
        "field": "Geography",
        "subject": "Cultural Geography",
        "id": "topic_Cultural_Geography_Impact",
        "name_en": "The Impact of Culture on Geography",
        "name_sv": "Kulturens påverkan på geografi",
        "name_is": "Áhrif menningar á jarðfræði",
        "learning_objectives": "Examine how culture shapes geographical landscapes.",
        "when_to_add": ["hobbyInterests.Politics_Geography.cultural_geography"]
    },
    {
        "curriculum": "International Relations",
        "field": "Political Science",
        "subject": "International Relations",
        "id": "topic_International_Relations_Basics",
        "name_en": "Basics of International Relations",
        "name_sv": "Grunderna i internationella relationer",
        "name_is": "Grunnir í alþjóðlegum samskiptum",
        "learning_objectives": "Understand key theories and concepts in international relations.",
        "when_to_add": ["hobbyInterests.Politics_Geography.international_relations"]
    },
    {
        "curriculum": "International Relations",
        "field": "Political Science",
        "subject": "International Relations",
        "id": "topic_International_Relations_Analysis",
        "name_en": "Analyzing International Relations",
        "name_sv": "Analys av internationella relationer",
        "name_is": "Greining á alþjóðlegum samskiptum",
        "learning_objectives": "Examine case studies of international relations and diplomacy.",
        "when_to_add": ["hobbyInterests.Politics_Geography.international_relations"]
    },
    {
        "curriculum": "International Relations",
        "field": "Political Science",
        "subject": "International Relations",
        "id": "topic_International_Relations_Impact",
        "name_en": "Impact of International Relations on Global Politics",
        "name_sv": "Påverkan av internationella relationer på global politik",
        "name_is": "Áhrif alþjóðlegra sambanda á alþjóðleg stjórnmál",
        "learning_objectives": "Understand how international relations shape global policies.",
        "when_to_add": ["hobbyInterests.Politics_Geography.international_relations"]
    },
    {
        "curriculum": "Geopolitical Studies",
        "field": "Political Science",
        "subject": "Geopolitical Analysis",
        "id": "topic_Geopolitical_Analysis_Basics",
        "name_en": "Basics of Geopolitical Analysis",
        "name_sv": "Grunderna i geopolitisk analys",
        "name_is": "Grunnir í geopólitísku greiningu",
        "learning_objectives": "Understand the fundamentals of analyzing geopolitical dynamics.",
        "when_to_add": ["hobbyInterests.Politics_Geography.geopolitical_analysis"]
    },
    {
        "curriculum": "Geopolitical Studies",
        "field": "Political Science",
        "subject": "Geopolitical Analysis",
        "id": "topic_Geopolitical_Analysis_Strategies",
        "name_en": "Geopolitical Strategies in Current Affairs",
        "name_sv": "Geopolitiska strategier i aktuella händelser",
        "name_is": "Geopólitísk aðferðir í núverandi atburðum",
        "learning_objectives": "Explore strategies used in geopolitical analyses of current affairs.",
        "when_to_add": ["hobbyInterests.Politics_Geography.geopolitical_analysis"]
    },
    {
        "curriculum": "Geopolitical Studies",
        "field": "Political Science",
        "subject": "Geopolitical Analysis",
        "id": "topic_Geopolitical_Analysis_Case_Studies",
        "name_en": "Geopolitical Analysis Through Case Studies",
        "name_sv": "Geopolitisk analys genom fallstudier",
        "name_is": "Geopólitísk greining í gegnum fallstudíur",
        "learning_objectives": "Analyze case studies to understand geopolitical concepts.",
        "when_to_add": ["hobbyInterests.Politics_Geography.geopolitical_analysis"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Physical Education",
        "subject": "Gym Techniques",
        "id": "topic_Gym_Techniques_Basics",
        "name_en": "Basics of Gym Techniques",
        "name_sv": "Grunderna i gymtekniker",
        "name_is": "Grunnir í gymtækni",
        "learning_objectives": "Learn the foundational techniques for effective gym workouts.",
        "when_to_add": ["hobbyInterests.Sports.gym_techniques"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Physical Education",
        "subject": "Gym Techniques",
        "id": "topic_Gym_Techniques_Safety",
        "name_en": "Safety in Gym Techniques",
        "name_sv": "Säkerhet i gymtekniker",
        "name_is": "Öryggi í gymtækni",
        "learning_objectives": "Understand safety practices while performing gym techniques.",
        "when_to_add": ["hobbyInterests.Sports.gym_techniques"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Physical Education",
        "subject": "Gym Techniques",
        "id": "topic_Gym_Techniques_Progression",
        "name_en": "Progression in Gym Techniques",
        "name_sv": "Progression i gymtekniker",
        "name_is": "Framgang í gymtækni",
        "learning_objectives": "Learn how to progress in gym techniques for better results.",
        "when_to_add": ["hobbyInterests.Sports.gym_techniques"]
    },
    {
        "curriculum": "Sports Psychology",
        "field": "Physical Education",
        "subject": "Sport Psychology",
        "id": "topic_Sport_Psychology_Basics",
        "name_en": "Basics of Sport Psychology",
        "name_sv": "Grunderna i idrottspsykologi",
        "name_is": "Grunnir í íþrótta psykologíu",
        "learning_objectives": "Understand the role of psychology in sports performance.",
        "when_to_add": ["hobbyInterests.Sports.sport_psychology"]
    },
    {
        "curriculum": "Sports Psychology",
        "field": "Physical Education",
        "subject": "Sport Psychology",
        "id": "topic_Sport_Psychology_Techniques",
        "name_en": "Techniques in Sport Psychology",
        "name_sv": "Tekniker inom idrottspsykologi",
        "name_is": "Aðferðir í íþrótta psykologíu",
        "learning_objectives": "Explore techniques used to enhance sports performance through psychology.",
        "when_to_add": ["hobbyInterests.Sports.sport_psychology"]
    },
    {
        "curriculum": "Sports Psychology",
        "field": "Physical Education",
        "subject": "Sport Psychology",
        "id": "topic_Sport_Psychology_Application",
        "name_en": "Applying Sport Psychology Techniques",
        "name_sv": "Tillämpning av idrottspsykologiska tekniker",
        "name_is": "Aðferðir í íþrótta psykologíu",
        "learning_objectives": "Learn how to apply psychological techniques in sports settings.",
        "when_to_add": ["hobbyInterests.Sports.sport_psychology"]
    },
    {
        "curriculum": "Sports History",
        "field": "Physical Education",
        "subject": "Sport History/Culture",
        "id": "topic_Sport_History_Basics",
        "name_en": "Basics of Sport History",
        "name_sv": "Grunderna i idrottens historia",
        "name_is": "Grunnir í íþróttasögu",
        "learning_objectives": "Understand the evolution of sports throughout history.",
        "when_to_add": ["hobbyInterests.Sports.sport_history"]
    },
    {
        "curriculum": "Sports History",
        "field": "Physical Education",
        "subject": "Sport History/Culture",
        "id": "topic_Sport_History_Culture",
        "name_en": "Cultural Impact of Sports History",
        "name_sv": "Kulturell påverkan av idrottens historia",
        "name_is": "Menningars áhrif í íþróttasögu",
        "learning_objectives": "Explore how sports have influenced culture and society.",
        "when_to_add": ["hobbyInterests.Sports.sport_history"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Painting and Drawing Techniques",
        "id": "topic_Painting_Drawing_Abstract",
        "name_en": "Abstract Painting Techniques",
        "name_sv": "Abstrakta måleritekniker",
        "name_is": "Óhlutbundnar málaratækni",
        "learning_objectives": "Explore techniques for creating abstract art.",
        "when_to_add": ["hobbyInterests.Creative_Arts.painting_drawing"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Painting and Drawing Techniques",
        "id": "topic_Painting_Drawing_Watercolor",
        "name_en": "Watercolor Techniques",
        "name_sv": "Akvarelltekniker",
        "name_is": "Vatnslitamálun tækni",
        "learning_objectives": "Learn the basic techniques for watercolor painting.",
        "when_to_add": ["hobbyInterests.Creative_Arts.painting_drawing"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Painting and Drawing Techniques",
        "id": "topic_Painting_Drawing_Sketching",
        "name_en": "Sketching Fundamentals",
        "name_sv": "Skissgrundläggande",
        "name_is": "Grunnvallarat kvikmyndir",
        "learning_objectives": "Understand the basics of sketching and drawing.",
        "when_to_add": ["hobbyInterests.Creative_Arts.painting_drawing"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Photography for Beginners",
        "id": "topic_Photography_Basics",
        "name_en": "Introduction to Photography",
        "name_sv": "Introduktion till fotografi",
        "name_is": "Inngangur að ljósmyndun",
        "learning_objectives": "Learn the basics of photography and camera usage.",
        "when_to_add": ["hobbyInterests.Creative_Arts.photography"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Photography for Beginners",
        "id": "topic_Photography_Composition",
        "name_en": "Composition in Photography",
        "name_sv": "Komposition i fotografi",
        "name_is": "Fyrirkomulag í ljósmyndun",
        "learning_objectives": "Understand the rules of composition to enhance photos.",
        "when_to_add": ["hobbyInterests.Creative_Arts.photography"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Photography for Beginners",
        "id": "topic_Photography_Lighting",
        "name_en": "Understanding Lighting in Photography",
        "name_sv": "Förståelse för belysning i fotografi",
        "name_is": "Skilningur á lýsingu í ljósmyndun",
        "learning_objectives": "Learn how to manipulate light for better photographs.",
        "when_to_add": ["hobbyInterests.Creative_Arts.photography"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Digital Art and Illustration",
        "id": "topic_Digital_Art_Basics",
        "name_en": "Introduction to Digital Art",
        "name_sv": "Introduktion till digital konst",
        "name_is": "Inngangur að raftónlist",
        "learning_objectives": "Learn the fundamentals of creating digital artwork.",
        "when_to_add": ["hobbyInterests.Creative_Arts.digital_art"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Digital Art and Illustration",
        "id": "topic_Digital_Art_Illustration",
        "name_en": "Digital Illustration Techniques",
        "name_sv": "Digitala illustrationstekniker",
        "name_is": "Rafræn myndsköpunartækni",
        "learning_objectives": "Explore various techniques for digital illustration.",
        "when_to_add": ["hobbyInterests.Creative_Arts.digital_art"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Digital Art and Illustration",
        "id": "topic_Digital_Art_Software",
        "name_en": "Using Digital Art Software",
        "name_sv": "Använda digitala konstprogram",
        "name_is": "Nota rafræna listaverkefni",
        "learning_objectives": "Learn how to effectively use software for digital art.",
        "when_to_add": ["hobbyInterests.Creative_Arts.digital_art"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Crafting and DIY Projects",
        "id": "topic_Crafting_DIY_Basics",
        "name_en": "Basics of Crafting and DIY",
        "name_sv": "Grunderna för hantverk och DIY",
        "name_is": "Grunnvallargáta og DIY",
        "learning_objectives": "Understand the basics of crafting and DIY projects.",
        "when_to_add": ["hobbyInterests.Creative_Arts.crafting_diy"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Crafting and DIY Projects",
        "id": "topic_Crafting_DIY_Sewing",
        "name_en": "Sewing Techniques for Beginners",
        "name_sv": "Sytekniker för nybörjare",
        "name_is": "Sauma tækni fyrir byrjendur",
        "learning_objectives": "Learn basic sewing techniques for DIY projects.",
        "when_to_add": ["hobbyInterests.Creative_Arts.crafting_diy"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Crafting and DIY Projects",
        "id": "topic_Crafting_DIY_Upcycling",
        "name_en": "Upcycling Projects",
        "name_sv": "Upcycling-projekt",
        "name_is": "Upcycling verkefni",
        "learning_objectives": "Explore ways to upcycle materials into new projects.",
        "when_to_add": ["hobbyInterests.Creative_Arts.crafting_diy"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Graphic Design",
        "id": "topic_Graphic_Design_Basics",
        "name_en": "Introduction to Graphic Design",
        "name_sv": "Introduktion till grafisk design",
        "name_is": "Inngangur að grafískri hönnun",
        "learning_objectives": "Understand the fundamental principles of graphic design.",
        "when_to_add": ["hobbyInterests.Creative_Arts.graphic_design"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Graphic Design",
        "id": "topic_Graphic_Design_Tools",
        "name_en": "Graphic Design Tools and Software",
        "name_sv": "Grafisk designverktyg och programvara",
        "name_is": "Grafísk hönnunarverkfæri og hugbúnaður",
        "learning_objectives": "Learn to use popular tools for graphic design.",
        "when_to_add": ["hobbyInterests.Creative_Arts.graphic_design"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Graphic Design",
        "id": "topic_Graphic_Design_Theory",
        "name_en": "Color Theory in Graphic Design",
        "name_sv": "Färgteori i grafisk design",
        "name_is": "Litakenning í grafískri hönnun",
        "learning_objectives": "Understand how to effectively use color in designs.",
        "when_to_add": ["hobbyInterests.Creative_Arts.graphic_design"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Creative Writing",
        "id": "topic_Creative_Writing_Basics",
        "name_en": "Basics of Creative Writing",
        "name_sv": "Grunderna i kreativt skrivande",
        "name_is": "Grunnvallarat ritgerðarsmíði",
        "learning_objectives": "Learn foundational techniques for writing creatively.",
        "when_to_add": ["hobbyInterests.Creative_Arts.creative_writing"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Creative Writing",
        "id": "topic_Creative_Writing_Overcoming_Blocks",
        "name_en": "Overcoming Writer's Block",
        "name_sv": "Övervinna skrivkramp",
        "name_is": "Yfirskoða skrifblokk",
        "learning_objectives": "Explore strategies to overcome writing blocks.",
        "when_to_add": ["hobbyInterests.Creative_Arts.creative_writing"]
    },
    {
        "curriculum": "Creative Arts",
        "field": "Art & Design",
        "subject": "Creative Writing",
        "id": "topic_Creative_Writing_Storytelling",
        "name_en": "Storytelling Techniques",
        "name_sv": "Berättartekniker",
        "name_is": "Söguþynningartækni",
        "learning_objectives": "Understand key techniques for effective storytelling.",
        "when_to_add": ["hobbyInterests.Creative_Arts.creative_writing"]
    },
    {
        "curriculum": "Cooking Basics",
        "field": "Cooking",
        "subject": "Basic Cooking Skills",
        "id": "topic_Basic_Cooking_Knives",
        "name_en": "Knife Skills for Beginners",
        "name_sv": "Grundläggande knivkunskaper",
        "name_is": "Grunnvallarat hnífaskil",
        "learning_objectives": "Learn essential knife skills for food preparation.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.basic_cooking_skills"]
    },
    {
        "curriculum": "Cooking Basics",
        "field": "Cooking",
        "subject": "Basic Cooking Skills",
        "id": "topic_Basic_Cooking_Techniques",
        "name_en": "Essential Cooking Techniques",
        "name_sv": "Viktiga matlagningstekniker",
        "name_is": "Nauðsynlegar matlagningartækni",
        "learning_objectives": "Understand fundamental techniques like sautéing and roasting.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.basic_cooking_skills"]
    },
    {
        "curriculum": "Cooking Basics",
        "field": "Cooking",
        "subject": "Basic Cooking Skills",
        "id": "topic_Basic_Cooking_Safety",
        "name_en": "Kitchen Safety Basics",
        "name_sv": "Grunderna i kökssäkerhet",
        "name_is": "Grunnvallarat öryggisskilyrði í eldhúsi",
        "learning_objectives": "Learn important kitchen safety tips.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.basic_cooking_skills"]
    },
    {
        "curriculum": "Global Cuisine",
        "field": "Cooking",
        "subject": "Specialty Cuisines",
        "id": "topic_Specialty_Cuisines_Italian",
        "name_en": "Italian Cooking Techniques",
        "name_sv": "Italienska matlagningstekniker",
        "name_is": "Ítalskar matlagningartækni",
        "learning_objectives": "Explore the art of cooking traditional Italian dishes.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.specialty_cuisines"]
    },
    {
        "curriculum": "Global Cuisine",
        "field": "Cooking",
        "subject": "Specialty Cuisines",
        "id": "topic_Specialty_Cuisines_Mexican",
        "name_en": "Mexican Cooking Techniques",
        "name_sv": "Mexikanska matlagningstekniker",
        "name_is": "Mexíkónskar matlagningartækni",
        "learning_objectives": "Learn to create authentic Mexican recipes.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.specialty_cuisines"]
    },
    {
        "curriculum": "Global Cuisine",
        "field": "Cooking",
        "subject": "Specialty Cuisines",
        "id": "topic_Specialty_Cuisines_Asian",
        "name_en": "Asian Cooking Techniques",
        "name_sv": "Asiatiska matlagningstekniker",
        "name_is": "Esektas matlagningartækni",
        "learning_objectives": "Discover the flavors and techniques of Asian cuisine.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.specialty_cuisines"]
    },
    {
        "curriculum": "Baking Basics",
        "field": "Cooking",
        "subject": "Baking Techniques",
        "id": "topic_Baking_Techniques_Breads",
        "name_en": "Bread Baking Techniques",
        "name_sv": "Brödbakningstekniker",
        "name_is": "Braæðnar tækni",
        "learning_objectives": "Learn how to bake a variety of bread.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.baking_techniques"]
    },
    {
        "curriculum": "Baking Basics",
        "field": "Cooking",
        "subject": "Baking Techniques",
        "id": "topic_Baking_Techniques_Cakes",
        "name_en": "Cake Baking Techniques",
        "name_sv": "Tårt- och kakbakningstekniker",
        "name_is": "Köku bak'arr tækni",
        "learning_objectives": "Master the art of baking cakes and pastries.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.baking_techniques"]
    },
    {
        "curriculum": "Baking Basics",
        "field": "Cooking",
        "subject": "Baking Techniques",
        "id": "topic_Baking_Techniques_Pastries",
        "name_en": "Pastry Baking Techniques",
        "name_sv": "Bakningstekniker för bakverk",
        "name_is": "Bakstái brauðgerðum",
        "learning_objectives": "Understand how to create various types of pastries.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.baking_techniques"]
    },
    {
        "curriculum": "Meal Planning",
        "field": "Cooking",
        "subject": "Meal Prep Ideas",
        "id": "topic_Meal_Prep_Ideas_Breakfast",
        "name_en": "Breakfast Meal Prep Ideas",
        "name_sv": "Frukost förberedelseidéer",
        "name_is": "Frumun hugmyndir um frukost",
        "learning_objectives": "Learn to prepare healthy breakfasts in advance.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.meal_prep_ideas"]
    },
    {
        "curriculum": "Meal Planning",
        "field": "Cooking",
        "subject": "Meal Prep Ideas",
        "id": "topic_Meal_Prep_Ideas_Lunch",
        "name_en": "Lunch Meal Prep Ideas",
        "name_sv": "Lunch förberedelseidéer",
        "name_is": "Hugmyndir um matseld í hádegismat",
        "learning_objectives": "Explore lunch meal prep strategies for busy schedules.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.meal_prep_ideas"]
    },
    {
        "curriculum": "Meal Planning",
        "field": "Cooking",
        "subject": "Meal Prep Ideas",
        "id": "topic_Meal_Prep_Ideas_Dinner",
        "name_en": "Dinner Meal Prep Ideas",
        "name_sv": "Middagsförberedelseidéer",
        "name_is": "Hugmyndir um kvöldmats undirstöðuhugmyndir",
        "learning_objectives": "Plan and prepare dinners efficiently and healthily.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.meal_prep_ideas"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Cooking",
        "subject": "Healthy Eating and Nutrition",
        "id": "topic_Healthy_Eating_Basics",
        "name_en": "Basics of Healthy Eating",
        "name_sv": "Grunderna för hälsosam kost",
        "name_is": "Grunnvallarat himnagull",
        "learning_objectives": "Understand the principles of healthy eating.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.healthy_eating"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Cooking",
        "subject": "Healthy Eating and Nutrition",
        "id": "topic_Healthy_Eating_Nutrition",
        "name_en": "Understanding Nutrition Labels",
        "name_sv": "Förståelse av näringsdeklarationer",
        "name_is": "Skilning um næringars skýringar",
        "learning_objectives": "Learn to read and interpret nutrition labels.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.healthy_eating"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Cooking",
        "subject": "Healthy Eating and Nutrition",
        "id": "topic_Healthy_Eating_Recipes",
        "name_en": "Healthy Recipe Ideas",
        "name_sv": "Hälsosamma receptidéer",
        "name_is": "Heilsukórein hugmyndir",
        "learning_objectives": "Create and explore a variety of healthy recipes.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.healthy_eating"]
    },
    {
        "curriculum": "Travel Planning",
        "field": "Travel",
        "subject": "Travel Planning Tips",
        "id": "topic_Travel_Planning_Basics",
        "name_en": "Basics of Travel Planning",
        "name_sv": "Grunderna i reseplanering",
        "name_is": "Grunnvallarat ferðaáætlun",
        "learning_objectives": "Learn key steps in planning a successful trip.",
        "when_to_add": ["hobbyInterests.Travel.travel_planning"]
    },
    {
        "curriculum": "Travel Planning",
        "field": "Travel",
        "subject": "Travel Planning Tips",
        "id": "topic_Travel_Planning_Budgeting",
        "name_en": "Budgeting for Travel",
        "name_sv": "Budgetering för resor",
        "name_is": "Fyrirkomulag fyrir ferðir",
        "learning_objectives": "Understand how to create and manage a travel budget.",
        "when_to_add": ["hobbyInterests.Travel.travel_planning"]
    },
    {
        "curriculum": "Travel Planning",
        "field": "Travel",
        "subject": "Travel Planning Tips",
        "id": "topic_Travel_Planning_Itinerary",
        "name_en": "Creating a Travel Itinerary",
        "name_sv": "Skapa en reseplan",
        "name_is": "Búa til ferðaáætlun",
        "learning_objectives": "Learn how to design a detailed travel itinerary.",
        "when_to_add": ["hobbyInterests.Travel.travel_planning"]
    },
    {
        "curriculum": "Cultural Awareness",
        "field": "Travel",
        "subject": "Cultural Etiquette Around the World",
        "id": "topic_Cultural_Etiquette_Greetings",
        "name_en": "Greeting Etiquette in Different Cultures",
        "name_sv": "Hälsningsetikett i olika kulturer",
        "name_is": "Heilsningsskilyrði í mismunandi menningarheimum",
        "learning_objectives": "Understand the importance of greetings in various cultures.",
        "when_to_add": ["hobbyInterests.Travel.cultural_etiquette"]
    },
    {
        "curriculum": "Cultural Awareness",
        "field": "Travel",
        "subject": "Cultural Etiquette Around the World",
        "id": "topic_Cultural_Etiquette_Dining",
        "name_en": "Dining Etiquette Globally",
        "name_sv": "Måltidsetikett globalt",
        "name_is": "Máltíðaskilyrði í heiminum",
        "learning_objectives": "Learn about dining etiquette in different countries.",
        "when_to_add": ["hobbyInterests.Travel.cultural_etiquette"]
    },
    {
        "curriculum": "Cultural Awareness",
        "field": "Travel",
        "subject": "Cultural Etiquette Around the World",
        "id": "topic_Cultural_Etiquette_Negotiating",
        "name_en": "Negotiating Etiquette",
        "name_sv": "Förhandlingsetikett",
        "name_is": "Samtalsskilyrði",
        "learning_objectives": "Discover how to negotiate respectfully in different cultures.",
        "when_to_add": ["hobbyInterests.Travel.cultural_etiquette"]
    },
    {
        "curriculum": "Travel Experiences",
        "field": "Travel",
        "subject": "Adventures and Experiences Abroad",
        "id": "topic_Adventures_Travel_Essentials",
        "name_en": "Travel Essentials for Adventures",
        "name_sv": "Resegrunder för äventyr",
        "name_is": "Ferðalýsingar fyrir ævintýri",
        "learning_objectives": "Learn about essential items for traveling abroad.",
        "when_to_add": ["hobbyInterests.Travel.adventures_abroad"]
    },
    {
        "curriculum": "Travel Experiences",
        "field": "Travel",
        "subject": "Adventures and Experiences Abroad",
        "id": "topic_Adventures_Travel_Experiences",
        "name_en": "Unique Travel Experiences Around the World",
        "name_sv": "Unika reseupplevelser runt om i världen",
        "name_is": "Einstakar ferðaævintýravirkjanir í heiminum",
        "learning_objectives": "Explore extraordinary travel experiences to consider.",
        "when_to_add": ["hobbyInterests.Travel.adventures_abroad"]
    },
    {
        "curriculum": "Travel Experiences",
        "field": "Travel",
        "subject": "Adventures and Experiences Abroad",
        "id": "topic_Adventures_Travel_Safety",
        "name_en": "Safety Tips for Travelers",
        "name_sv": "Säkerhetstips för resenärer",
        "name_is": "Öryggistaði fyrir ferðalanga",
        "learning_objectives": "Understand safety precautions while traveling abroad.",
        "when_to_add": ["hobbyInterests.Travel.adventures_abroad"]
    },
    {
        "curriculum": "Travel Destinations",
        "field": "Travel",
        "subject": "Destination Highlights",
        "id": "topic_Destination_Highlights_Cities",
        "name_en": "City Highlights Around the World",
        "name_sv": "Stads höjdpunkter i hela världen",
        "name_is": "Borgarhápunkar í heiminum",
        "learning_objectives": "Discover must-see cities and their attractions.",
        "when_to_add": ["hobbyInterests.Travel.destination_highlights"]
    },
    {
        "curriculum": "Travel Destinations",
        "field": "Travel",
        "subject": "Destination Highlights",
        "id": "topic_Destination_Highlights_Nature",
        "name_en": "Natural Wonders Around the World",
        "name_sv": "Naturliga underverk runt om i världen",
        "name_is": "Náttúruundur í heiminum",
        "learning_objectives": "Explore breathtaking natural attractions globally.",
        "when_to_add": ["hobbyInterests.Travel.destination_highlights"]
    },
    {
        "curriculum": "Travel Destinations",
        "field": "Travel",
        "subject": "Destination Highlights",
        "id": "topic_Destination_Highlights_Culture",
        "name_en": "Cultural Highlights in Travel",
        "name_sv": "Kulturella höjdpunkter i resor",
        "name_is": "Menningarskálarleikar í ferðalögum",
        "learning_objectives": "Learn about festivals and cultural events to experience.",
        "when_to_add": ["hobbyInterests.Travel.destination_highlights"]
    },
    {
        "curriculum": "Travel Photography",
        "field": "Travel",
        "subject": "Travel Photography",
        "id": "topic_Travel_Photography_Tips",
        "name_en": "Essential Travel Photography Tips",
        "name_sv": "Viktiga tips för resefotografi",
        "name_is": "Nauðsynlegar ferða ljósmyndartips",
        "learning_objectives": "Discover techniques for capturing stunning travel photos.",
        "when_to_add": ["hobbyInterests.Travel.travel_photography"]
    },
    {
        "curriculum": "Travel Photography",
        "field": "Travel",
        "subject": "Travel Photography",
        "id": "topic_Travel_Photography_Landscapes",
        "name_en": "Landscape Photography Techniques",
        "name_sv": "Landskapsfotograferingstekniker",
        "name_is": "Landslagsljósmyndartækni",
        "learning_objectives": "Learn how to capture breathtaking landscapes.",
        "when_to_add": ["hobbyInterests.Travel.travel_photography"]
    },
    {
        "curriculum": "Travel Photography",
        "field": "Travel",
        "subject": "Travel Photography",
        "id": "topic_Travel_Photography_Portraits",
        "name_en": "Portrait Photography While Traveling",
        "name_sv": "Porträttfotografi under resor",
        "name_is": "Portrettljósmyndun á meðan á ferð",
        "learning_objectives": "Discover tips for taking great portraits of people.",
        "when_to_add": ["hobbyInterests.Travel.travel_photography"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Health",
        "subject": "Nutrition and Dieting",
        "id": "topic_Nutrition_Basics",
        "name_en": "Basics of Nutrition",
        "name_sv": "Grunderna i näringslära",
        "name_is": "Grunnvöllur í næringarfræð",
        "learning_objectives": "Understand essential nutrients and their functions.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.nutrition_dieting"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Health",
        "subject": "Nutrition and Dieting",
        "id": "topic_Nutrition_Balanced_Diet",
        "name_en": "Creating a Balanced Diet",
        "name_sv": "Skapa en balanserad kost",
        "name_is": "Búa til jafnvægi í mataræði",
        "learning_objectives": "Learn how to plan a balanced diet for health.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.nutrition_dieting"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Health",
        "subject": "Nutrition and Dieting",
        "id": "topic_Nutrition_Meal_Planning",
        "name_en": "Meal Planning for Nutrition",
        "name_sv": "Måltidsplanering för nutrition",
        "name_is": "Máltíðar skipulagningu fyrir næringu",
        "learning_objectives": "Explore strategies for effective meal planning.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.nutrition_dieting"]
    },
    {
        "curriculum": "Mental Wellness",
        "field": "Health",
        "subject": "Mental Health Awareness",
        "id": "topic_Mental_Health_Basics",
        "name_en": "Understanding Mental Health",
        "name_sv": "Förståelse av mental hälsa",
        "name_is": "Skilningur á andlegri heilsu",
        "learning_objectives": "Learn the basics of mental well-being.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.mental_health"]
    },
    {
        "curriculum": "Mental Wellness",
        "field": "Health",
        "subject": "Mental Health Awareness",
        "id": "topic_Mental_Health_Self_Care",
        "name_en": "Self-Care Strategies",
        "name_sv": "Strategier för självomsorg",
        "name_is": "Sjálfsmeðferðartækni",
        "learning_objectives": "Understand the importance of self-care for mental health.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.mental_health"]
    },
    {
        "curriculum": "Mental Wellness",
        "field": "Health",
        "subject": "Mental Health Awareness",
        "id": "topic_Mental_Health_Support",
        "name_en": "Finding Support for Mental Health",
        "name_sv": "Hitta stöd för mental hälsa",
        "name_is": "Að finna stuðning fyrir andlega heilsu",
        "learning_objectives": "Explore resources for mental health support.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.mental_health"]
    },
    {
        "curriculum": "Fitness Practices",
        "field": "Health",
        "subject": "Yoga and Pilates",
        "id": "topic_Yoga_Basics",
        "name_en": "Basics of Yoga",
        "name_sv": "Grunderna i yoga",
        "name_is": "Grunnvallafyrir jógun",
        "learning_objectives": "Learn foundational yoga poses and principles.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.yoga_pilates"]
    },
    {
        "curriculum": "Fitness Practices",
        "field": "Health",
        "subject": "Yoga and Pilates",
        "id": "topic_Yoga_Pilates_Benefits",
        "name_en": "Health Benefits of Yoga and Pilates",
        "name_sv": "Hälsovinster med yoga och pilates",
        "name_is": "Heilsubyrgðir með jógun og pilates",
        "learning_objectives": "Understand the health benefits associated with these practices.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.yoga_pilates"]
    },
    {
        "curriculum": "Fitness Practices",
        "field": "Health",
        "subject": "Yoga and Pilates",
        "id": "topic_Yoga_Pilates_Practices",
        "name_en": "Yoga and Pilates Routines",
        "name_sv": "Yoga och pilatesrutiner",
        "name_is": "Jóga og pilates venjur",
        "learning_objectives": "Explore various routines for effective practice.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.yoga_pilates"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Health",
        "subject": "Running and Marathon Training",
        "id": "topic_Running_Basics",
        "name_en": "Running Fundamentals",
        "name_sv": "Grunderna i löpning",
        "name_is": "Grunnvöllur í hlaupum",
        "learning_objectives": "Learn the basics of running techniques and form.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.running_marathon"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Health",
        "subject": "Running and Marathon Training",
        "id": "topic_Running_Training_Plans",
        "name_en": "Training Plans for Runners",
        "name_sv": "Träningsprogram för löpare",
        "name_is": "Þjálfunaráætlanir fyrir hlaupara",
        "learning_objectives": "Understand how to develop a personalized training plan.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.running_marathon"]
    },
    {
        "curriculum": "Fitness Training",
        "field": "Health",
        "subject": "Running and Marathon Training",
        "id": "topic_Running_Marathon_Preparation",
        "name_en": "Preparing for Marathons",
        "name_sv": "Förbereda sig för maraton",
        "name_is": "Undirbúningur fyrir maratón",
        "learning_objectives": "Explore strategies for marathon preparation and recovery.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.running_marathon"]
    },
    {
        "curriculum": "Exercise Science",
        "field": "Health",
        "subject": "Exercise Science",
        "id": "topic_Exercise_Science_Basics",
        "name_en": "Introduction to Exercise Science",
        "name_sv": "Introduktion till träningsvetenskap",
        "name_is": "Inngangur að líkamsþjálfun",
        "learning_objectives": "Understand the science behind physical exercise.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.exercise_science"]
    },
    {
        "curriculum": "Exercise Science",
        "field": "Health",
        "subject": "Exercise Science",
        "id": "topic_Exercise_Science_Fitness_Levels",
        "name_en": "Fitness Levels and Assessments",
        "name_sv": "Fysiska nivåer och bedömningar",
        "name_is": "Fyrirtækjas mér og mat",
        "learning_objectives": "Learn how to assess fitness levels.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.exercise_science"]
    },
    {
        "curriculum": "Exercise Science",
        "field": "Health",
        "subject": "Exercise Science",
        "id": "topic_Exercise_Science_Nutrition",
        "name_en": "Nutrition in Sports and Exercise",
        "name_sv": "Nutrition inom sport och träning",
        "name_is": "Næring í íþróttum og þjálfun",
        "learning_objectives": "Discover the role of nutrition in maintaining optimum fitness.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.exercise_science"]
    },
    {
        "curriculum": "Astronomy Basics",
        "field": "Science",
        "subject": "Astronomy and Space Exploration",
        "id": "topic_Astronomy_Basics",
        "name_en": "Basics of Astronomy",
        "name_sv": "Grunderna i astronomi",
        "name_is": "Grunnvallarat stjörnu",
        "learning_objectives": "Learn about celestial bodies and the universe.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.astronomy_space"]
    },
    {
        "curriculum": "Astronomy Basics",
        "field": "Science",
        "subject": "Astronomy and Space Exploration",
        "id": "topic_Astronomy_Telescopes",
        "name_en": "Using Telescopes for Stargazing",
        "name_sv": "Använda teleskop för stjärnskådning",
        "name_is": "Nota teleskop til stjörnust í utan",
        "learning_objectives": "Understand how to use telescopes for astronomical observations.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.astronomy_space"]
    },
    {
        "curriculum": "Astronomy Basics",
        "field": "Science",
        "subject": "Astronomy and Space Exploration",
        "id": "topic_Astronomy_Space_Missions",
        "name_en": "Famous Space Missions",
        "name_sv": "Berömda rymduppdrag",
        "name_is": "Frágerð hjálparsafn",
        "learning_objectives": "Explore significant space missions and their impacts.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.astronomy_space"]
    },
    {
        "curriculum": "Biology Basics",
        "field": "Science",
        "subject": "Biology and Wildlife",
        "id": "topic_Biology_Fundamentals",
        "name_en": "Fundamentals of Biology",
        "name_sv": "Biologins grunder",
        "name_is": "Grunnvallarat líffræði",
        "learning_objectives": "Understand the principles of biology and life sciences.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.biology_wildlife"]
    },
    {
        "curriculum": "Biology Basics",
        "field": "Science",
        "subject": "Biology and Wildlife",
        "id": "topic_Biology_Ecosystems",
        "name_en": "Ecosystems and Biodiversity",
        "name_sv": "Ekosystem och biologisk mångfald",
        "name_is": "Ekkosystem og fjölbreytileiki",
        "learning_objectives": "Learn about the importance of ecosystems.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.biology_wildlife"]
    },
    {
        "curriculum": "Biology Basics",
        "field": "Science",
        "subject": "Biology and Wildlife",
        "id": "topic_Biology_Conservation",
        "name_en": "Wildlife Conservation Efforts",
        "name_sv": "Bevarandeinsatser för djurlivet",
        "name_is": "Verkefni um villta lífverur",
        "learning_objectives": "Understand the significance of wildlife conservation.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.biology_wildlife"]
    },
    {
        "curriculum": "Environmental Science",
        "field": "Science",
        "subject": "Environmental Conservation",
        "id": "topic_Environmental_Conservation_Basics",
        "name_en": "Basics of Environmental Conservation",
        "name_sv": "Grunderna i miljöskydd",
        "name_is": "Grunnvallarat umhverfisskipulag",
        "learning_objectives": "Learn about the principles of environmental conservation.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.environmental_conservation"]
    },
    {
        "curriculum": "Environmental Science",
        "field": "Science",
        "subject": "Environmental Conservation",
        "id": "topic_Environmental_Conservation_Tips",
        "name_en": "Tips for Sustainable Living",
        "name_sv": "Tips för hållbart liv",
        "name_is": "Tips fyrir sjálfbæran lífsstíl",
        "learning_objectives": "Explore practical tips for sustainable living.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.environmental_conservation"]
    },
    {
        "curriculum": "Environmental Science",
        "field": "Science",
        "subject": "Environmental Conservation",
        "id": "topic_Environmental_Conservation_Ecosystems",
        "name_en": "Protecting Ecosystems",
        "name_sv": "Skydd av ekosystem",
        "name_is": "Verndun ekosystema",
        "learning_objectives": "Understand how to protect various ecosystems.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.environmental_conservation"]
    },
    {
        "curriculum": "Physics Basics",
        "field": "Science",
        "subject": "Physics Concepts",
        "id": "topic_Physics_Basics",
        "name_en": "Introduction to Physics",
        "name_sv": "Introduktion till fysik",
        "name_is": "Inngangur að efnisfræði",
        "learning_objectives": "Understand the fundamental concepts of physics.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.physics_concepts"]
    },
    {
        "curriculum": "Physics Basics",
        "field": "Science",
        "subject": "Physics Concepts",
        "id": "topic_Physics_Mechanics",
        "name_en": "Mechanics in Physics",
        "name_sv": "Mekanik i fysik",
        "name_is": "Vélfræði í efnisfræði",
        "learning_objectives": "Learn about the principles of mechanics.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.physics_concepts"]
    },
    {
        "curriculum": "Physics Basics",
        "field": "Science",
        "subject": "Physics Concepts",
        "id": "topic_Physics_Energy",
        "name_en": "Understanding Energy",
        "name_sv": "Förståelse av energi",
        "name_is": "Skilningur á orku",
        "learning_objectives": "Explore various forms of energy and their applications.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.physics_concepts"]
    },
    {
        "curriculum": "Technology Innovations",
        "field": "Science",
        "subject": "Technology Innovations",
        "id": "topic_Technology_Innovations_Future",
        "name_en": "Future Technology Innovations",
        "name_sv": "Framtida teknologiska innovationer",
        "name_is": "Framtíðartækni nýjungar",
        "learning_objectives": "Explore upcoming trends and innovations in technology.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.technology_innovations"]
    },
    {
        "curriculum": "Technology Innovations",
        "field": "Science",
        "subject": "Technology Innovations",
        "id": "topic_Technology_Innovations_Impact",
        "name_en": "Impact of Technology on Society",
        "name_sv": "Teknologins påverkan på samhället",
        "name_is": "Áhrif tækni á samfélagið",
        "learning_objectives": "Understand the societal impacts of technological advancements.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.technology_innovations"]
    },
    {
        "curriculum": "Technology Innovations",
        "field": "Science",
        "subject": "Technology Innovations",
        "id": "topic_Technology_Innovations_Emerging",
        "name_en": "Emerging Technologies",
        "name_sv": "Framväxande teknologier",
        "name_is": "Vaxandi tæknimál",
        "learning_objectives": "Explore technologies that are shaping the future.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.technology_innovations"]
    },
    {
        "curriculum": "Sports History",
        "field": "Physical Education",
        "subject": "Sport History/Culture",
        "id": "topic_Sport_History_Legends",
        "name_en": "Legends in Sports History",
        "name_sv": "Lägers i idrottens historia",
        "name_is": "Legendur í íþróttasögu",
        "learning_objectives": "Examine influential figures and their contributions to sports.",
        "when_to_add": ["hobbyInterests.Sports.sport_history"]
    },
    {
        "curriculum": "Coaching Techniques",
        "field": "Physical Education",
        "subject": "Coaching Techniques",
        "id": "topic_Coaching_Techniques_Basics",
        "name_en": "Basics of Coaching Techniques",
        "name_sv": "Grunderna i coachingtekniker",
        "name_is": "Grunnvallarat áður en a tækni",
        "learning_objectives": "Learn foundational coaching techniques for various sports.",
        "when_to_add": ["hobbyInterests.Sports.coaching_techniques"]
    },
    {
        "curriculum": "Coaching Techniques",
        "field": "Physical Education",
        "subject": "Coaching Techniques",
        "id": "topic_Coaching_Techniques_Communication",
        "name_en": "Communication in Coaching",
        "name_sv": "Kommunikation inom coaching",
        "name_is": "Samskipti í þjálfun",
        "learning_objectives": "Understand the importance of communication in coaching.",
        "when_to_add": ["hobbyInterests.Sports.coaching_techniques"]
    },
    {
        "curriculum": "Coaching Techniques",
        "field": "Physical Education",
        "subject": "Coaching Techniques",
        "id": "topic_Coaching_Techniques_Leadership",
        "name_en": "Leadership in Coaching",
        "name_sv": "Ledarskap inom coaching",
        "name_is": "Leiðtogaþjálfan í",
        "learning_objectives": "Explore leadership qualities essential for effective coaching.",
        "when_to_add": ["hobbyInterests.Sports.coaching_techniques"]
    },
    {
        "curriculum": "Performance Analysis",
        "field": "Physical Education",
        "subject": "Performance Analysis",
        "id": "topic_Performance_Analysis_Basics",
        "name_en": "Introduction to Performance Analysis",
        "name_sv": "Introduktion till prestationsanalys",
        "name_is": "Inngangur að framsóknargreining",
        "learning_objectives": "Learn the basics of analyzing sports performance.",
        "when_to_add": ["hobbyInterests.Sports.performance_analysis"]
    },
    {
        "curriculum": "Performance Analysis",
        "field": "Physical Education",
        "subject": "Performance Analysis",
        "id": "topic_Performance_Analysis_Tools",
        "name_en": "Tools for Performance Analysis",
        "name_sv": "Verktyg för prestationsanalys",
        "name_is": "Verkfæri fyrir framsóknargreining",
        "learning_objectives": "Explore various tools and methods for performance analysis.",
        "when_to_add": ["hobbyInterests.Sports.performance_analysis"]
    },
    {
        "curriculum": "Performance Analysis",
        "field": "Physical Education",
        "subject": "Performance Analysis",
        "id": "topic_Performance_Analysis_Optimization",
        "name_en": "Optimizing Performance through Analysis",
        "name_sv": "Optimera prestationer genom analys",
        "name_is": "Skerði framsóknargreiningar",
        "learning_objectives": "Understand how analysis can help improve performance outcomes.",
        "when_to_add": ["hobbyInterests.Sports.performance_analysis"]
    },
    {
        "curriculum": "Creative Arts Fundamentals",
        "field": "Art & Design",
        "subject": "Painting and Drawing Techniques",
        "id": "topic_Painting_Drawing_Techniques",
        "name_en": "Painting and Drawing Techniques",
        "name_sv": "Målnings- och teckningstekniker",
        "name_is": "Málun og teikningartækni",
        "learning_objectives": "Learn various techniques in painting and drawing.",
        "when_to_add": ["hobbyInterests.Creative_Arts.painting_drawing"]
    },
    {
        "curriculum": "Creative Photography",
        "field": "Art & Design",
        "subject": "Photography",
        "id": "topic_Photography_Beginners",
        "name_en": "Photography for Beginners",
        "name_sv": "Fotografi för nybörjare",
        "name_is": "Ljósmyndun fyrir byrjendur",
        "learning_objectives": "Understand the basics of photography, including composition and lighting.",
        "when_to_add": ["hobbyInterests.Creative_Arts.photography"]
    },
    {
        "curriculum": "Digital Art Basics",
        "field": "Art & Design",
        "subject": "Digital Art",
        "id": "topic_Digital_Art_Illustration",
        "name_en": "Digital Art and Illustration",
        "name_sv": "Digital konst och illustration",
        "name_is": "Rafrænt listafyrirhugakan",
        "learning_objectives": "Learn how to create digital illustrations using various software.",
        "when_to_add": ["hobbyInterests.Creative_Arts.digital_art"]
    },
    {
        "curriculum": "Crafting and DIY Projects",
        "field": "Art & Design",
        "subject": "Crafting and DIY Projects",
        "id": "topic_Crafting_DIY",
        "name_en": "Crafting and DIY Projects",
        "name_sv": "Hantverk och gör-det-själv-projekt",
        "name_is": "Hönnun og DIY verkefni",
        "learning_objectives": "Explore various crafting techniques and DIY projects.",
        "when_to_add": ["hobbyInterests.Creative_Arts.crafting_diy"]
    },
    {
        "curriculum": "Graphic Design Essentials",
        "field": "Art & Design",
        "subject": "Graphic Design",
        "id": "topic_Graphic_Design",
        "name_en": "Graphic Design",
        "name_sv": "Grafisk design",
        "name_is": "Grafísk hönnun",
        "learning_objectives": "Gain an understanding of graphic design principles and techniques.",
        "when_to_add": ["hobbyInterests.Creative_Arts.graphic_design"]
    },
    {
        "curriculum": "Creative Writing Workshop",
        "field": "Literature",
        "subject": "Creative Writing",
        "id": "topic_Creative_Writing",
        "name_en": "Creative Writing",
        "name_sv": "Kreativt skrivande",
        "name_is": "Skapandi skrif",
        "learning_objectives": "Explore techniques for creative writing and storytelling.",
        "when_to_add": ["hobbyInterests.Creative_Arts.creative_writing"]
    },
    {
        "curriculum": "Culinary Basics",
        "field": "Cooking",
        "subject": "Basic Cooking Skills",
        "id": "topic_Basic_Cooking_Skills",
        "name_en": "Basic Cooking Skills",
        "name_sv": "Grundläggande matlagningstekniker",
        "name_is": "Grunnvallarat matlagshæfni",
        "learning_objectives": "Learn fundamental cooking techniques and skills.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.basic_cooking_skills"]
    },
    {
        "curriculum": "Exploring Global Cuisines",
        "field": "Cooking",
        "subject": "Specialty Cuisines",
        "id": "topic_Specialty_Cuisines",
        "name_en": "Specialty Cuisines (Italian, Mexican, Asian)",
        "name_sv": "Specialiteter (italiensk, mexikansk, asiatisk)",
        "name_is": "Sérstakir stefnur (ítalskur, mexíkós, asískur)",
        "learning_objectives": "Discover the unique flavors and techniques of various cuisines.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.specialty_cuisines"]
    },
    {
        "curriculum": "Baking Basics",
        "field": "Cooking",
        "subject": "Baking Techniques",
        "id": "topic_Baking_Techniques",
        "name_en": "Baking Techniques",
        "name_sv": "Bakningstekniker",
        "name_is": "Bakstrarteknik",
        "learning_objectives": "Learn essential techniques for successful baking.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.baking_techniques"]
    },
    {
        "curriculum": "Meal Prep Fundamentals",
        "field": "Cooking",
        "subject": "Meal Prep Ideas",
        "id": "topic_Meal_Prep_Ideas",
        "name_en": "Meal Prep Ideas",
        "name_sv": "Idéer för meal prep",
        "name_is": "Hugmyndir um máltíð undirbúning",
        "learning_objectives": "Discover meal prepping strategies for convenient cooking.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.meal_prep_ideas"]
    },
    {
        "curriculum": "Nutrition Basics",
        "field": "Cooking",
        "subject": "Healthy Eating",
        "id": "topic_Healthy_Eating",
        "name_en": "Healthy Eating and Nutrition",
        "name_sv": "Hälsosam kost och näring",
        "name_is": "Heilsusamur matur og næring",
        "learning_objectives": "Understand the principles of healthy eating and nutrition.",
        "when_to_add": ["hobbyInterests.Cooking_Baking.healthy_eating"]
    },
    {
        "curriculum": "Travel Essentials",
        "field": "Travel",
        "subject": "Travel Planning Tips",
        "id": "topic_Travel_Planning_Tips",
        "name_en": "Travel Planning Tips",
        "name_sv": "Resplaneringstips",
        "name_is": "Ferðaplan til ráðleggingar",
        "learning_objectives": "Learn how to effectively plan and organize your travels.",
        "when_to_add": ["hobbyInterests.Travel.travel_planning"]
    },
    {
        "curriculum": "Global Cultures",
        "field": "Travel",
        "subject": "Cultural Etiquette",
        "id": "topic_Cultural_Etiquette",
        "name_en": "Cultural Etiquette Around the World",
        "name_sv": "Kulturell etikett världen runt",
        "name_is": "Menningarhót á heimsins",
        "learning_objectives": "Understand cultural etiquette across different regions.",
        "when_to_add": ["hobbyInterests.Travel.cultural_etiquette"]
    },
    {
        "curriculum": "Adventure Travel",
        "field": "Travel",
        "subject": "Adventures Abroad",
        "id": "topic_Adventures_Abroad",
        "name_en": "Adventures and Experiences Abroad",
        "name_sv": "Äventyr och upplevelser utomlands",
        "name_is": "Ævintýri og reynsla út um heim",
        "learning_objectives": "Explore exciting adventure travel opportunities.",
        "when_to_add": ["hobbyInterests.Travel.adventures_abroad"]
    },
    {
        "curriculum": "Travel Highlights",
        "field": "Travel",
        "subject": "Destination Highlights",
        "id": "topic_Destination_Highlights",
        "name_en": "Destination Highlights",
        "name_sv": "Resmålshöjdpunkter",
        "name_is": "Ferðamarkverandi niðurstöður",
        "learning_objectives": "Learn about must-visit destinations around the world.",
        "when_to_add": ["hobbyInterests.Travel.destination_highlights"]
    },
    {
        "curriculum": "Travel Photography",
        "field": "Art & Design",
        "subject": "Travel Photography",
        "id": "topic_Travel_Photography",
        "name_en": "Travel Photography",
        "name_sv": "Resefotografi",
        "name_is": "Ljósmyndun ferða",
        "learning_objectives": "Master the art of capturing stunning travel photographs.",
        "when_to_add": ["hobbyInterests.Travel.travel_photography"]
    },
    {
        "curriculum": "Nutrition Fundamentals",
        "field": "Health",
        "subject": "Nutrition and Dieting",
        "id": "topic_Nutrition_Dieting",
        "name_en": "Nutrition and Dieting",
        "name_sv": "Näring och kosthållning",
        "name_is": "Næring og fæðuhald",
        "learning_objectives": "Understand the principles of nutrition and dieting.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.nutrition_dieting"]
    },
    {
        "curriculum": "Mental Health Awareness",
        "field": "Health",
        "subject": "Mental Health",
        "id": "topic_Mental_Health_Awareness",
        "name_en": "Mental Health Awareness",
        "name_sv": "Medvetenhet om mental hälsa",
        "name_is": "Meðvitið um andlega heilsu",
        "learning_objectives": "Learn about mental health issues and awareness.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.mental_health"]
    },
    {
        "curriculum": "Yoga Practices",
        "field": "Health",
        "subject": "Yoga and Pilates",
        "id": "topic_Yoga_Pilates",
        "name_en": "Yoga and Pilates",
        "name_sv": "Yoga och Pilates",
        "name_is": "Jóga og pilates",
        "learning_objectives": "Explore the fundamentals of yoga and pilates practices.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.yoga_pilates"]
    },
    {
        "curriculum": "Running Programs",
        "field": "Health",
        "subject": "Running and Marathon Training",
        "id": "topic_Running_Marathon",
        "name_en": "Running and Marathon Training",
        "name_sv": "Löpning och maratonträning",
        "name_is": "Hlaupa og maratón þjálfun",
        "learning_objectives": "Train effectively for running and marathon events.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.running_marathon"]
    },
    {
        "curriculum": "Exercise Science Basics",
        "field": "Health",
        "subject": "Exercise Science",
        "id": "topic_Exercise_Science",
        "name_en": "Exercise Science",
        "name_sv": "Träningsvetenskap",
        "name_is": "Líkamsþjálfunarfræði",
        "learning_objectives": "Understand the science behind exercise and fitness.",
        "when_to_add": ["hobbyInterests.Health_and_Fitness.exercise_science"]
    },
    {
        "curriculum": "Astronomy Basics",
        "field": "Science",
        "subject": "Astronomy and Space Exploration",
        "id": "topic_Astronomy_Space",
        "name_en": "Astronomy and Space Exploration",
        "name_sv": "Astronomi och rymdforskning",
        "name_is": "Stjarfæði og rymdfyrirspurn",
        "learning_objectives": "Explore the wonders of astronomy and space exploration.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.astronomy_space"]
    },
    {
        "curriculum": "Biology Fundamentals",
        "field": "Science",
        "subject": "Biology and Wildlife",
        "id": "topic_Biology_Wildlife",
        "name_en": "Biology and Wildlife",
        "name_sv": "Biologi och vilda djur",
        "name_is": "Líffræði og villt dýralíf",
        "learning_objectives": "Learn about biology and the diversity of wildlife.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.biology_wildlife"]
    },
    {
        "curriculum": "Environmental Studies",
        "field": "Science",
        "subject": "Environmental Conservation",
        "id": "topic_Environmental_Conservation",
        "name_en": "Environmental Conservation",
        "name_sv": "Miljöskydd",
        "name_is": "Umhverfisvernd",
        "learning_objectives": "Understand the principles of environmental conservation.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.environmental_conservation"]
    },
    {
        "curriculum": "Physics Concepts",
        "field": "Science",
        "subject": "Physics Concepts",
        "id": "topic_Physics_Concepts",
        "name_en": "Physics Concepts",
        "name_sv": "Fysikkoncept",
        "name_is": "Efnisfróðleikur",
        "learning_objectives": "Learn key concepts in physics and their applications.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.physics_concepts"]
    },
    {
        "curriculum": "Technology Innovations",
        "field": "Science",
        "subject": "Technology Innovations",
        "id": "topic_Technology_Innovations",
        "name_en": "Technology Innovations",
        "name_sv": "Teknologiska innovationer",
        "name_is": "Tækninýjungar",
        "learning_objectives": "Explore the latest innovations in technology.",
        "when_to_add": ["hobbyInterests.Science_and_Nature.technology_innovations"]
    },
    {
        "curriculum": "Ancient Civilizations",
        "field": "History",
        "subject": "Ancient Civilizations",
        "id": "topic_Ancient_Civilizations_Egypt",
        "name_en": "Ancient Egyptian Civilization",
        "name_sv": "Den antika egyptiska civilisationen",
        "name_is": "Forn egypzk samfélag",
        "learning_objectives": "Explore the culture, inventions, and history of ancient Egypt.",
        "when_to_add": ["hobbyInterests.History.ancient_civilizations"]
    },
    {
        "curriculum": "Ancient Civilizations",
        "field": "History",
        "subject": "Ancient Civilizations",
        "id": "topic_Ancient_Civilizations_Rome",
        "name_en": "Ancient Roman Civilization",
        "name_sv": "Den antika romerska civilisationen",
        "name_is": "Forn rómversk samfélag",
        "learning_objectives": "Understand the political and cultural impact of ancient Rome.",
        "when_to_add": ["hobbyInterests.History.ancient_civilizations"]
    },
    {
        "curriculum": "Ancient Civilizations",
        "field": "History",
        "subject": "Ancient Civilizations",
        "id": "topic_Ancient_Civilizations_China",
        "name_en": "Ancient Chinese Civilization",
        "name_sv": "Den antika kinesiska civilisationen",
        "name_is": "Forn kínversk samfélag",
        "learning_objectives": "Investigate the history and achievements of ancient China.",
        "when_to_add": ["hobbyInterests.History.ancient_civilizations"]
    },
    {
        "curriculum": "World Wars Studies",
        "field": "History",
        "subject": "World Wars",
        "id": "topic_World_Wars_WWI",
        "name_en": "World War I",
        "name_sv": "Första världskriget",
        "name_is": "Fyrsta heimsstyrjöld",
        "learning_objectives": "Examine the causes and consequences of World War I.",
        "when_to_add": ["hobbyInterests.History.world_wars"]
    },
    {
        "curriculum": "World Wars Studies",
        "field": "History",
        "subject": "World Wars",
        "id": "topic_World_Wars_WWII",
        "name_en": "World War II",
        "name_sv": "Andra världskriget",
        "name_is": "Önnur heimsstyrjöld",
        "learning_objectives": "Understand the key events and impacts of World War II.",
        "when_to_add": ["hobbyInterests.History.world_wars"]
    },
    {
        "curriculum": "World Wars Studies",
        "field": "History",
        "subject": "World Wars",
        "id": "topic_World_Wars_Cold_War",
        "name_en": "The Cold War",
        "name_sv": "Kalla kriget",
        "name_is": "Kalda stríð",
        "learning_objectives": "Explore the geopolitical tensions during the Cold War.",
        "when_to_add": ["hobbyInterests.History.world_wars"]
    },
    {
        "curriculum": "Historical Figures",
        "field": "History",
        "subject": "Historical Figures",
        "id": "topic_Historical_Figures_Gandhi",
        "name_en": "Mahatma Gandhi",
        "name_sv": "Mahatma Gandhi",
        "name_is": "Mahatma Gandhi",
        "learning_objectives": "Analyze the life and leadership of Mahatma Gandhi.",
        "when_to_add": ["hobbyInterests.History.historical_figures"]
    },
    {
        "curriculum": "Historical Figures",
        "field": "History",
        "subject": "Historical Figures",
        "id": "topic_Historical_Figures_MLK",
        "name_en": "Martin Luther King Jr.",
        "name_sv": "Martin Luther King Jr.",
        "name_is": "Martin Luther King yngri",
        "learning_objectives": "Examine the contributions of Martin Luther King Jr. to civil rights.",
        "when_to_add": ["hobbyInterests.History.historical_figures"]
    },
    {
        "curriculum": "Historical Figures",
        "field": "History",
        "subject": "Historical Figures",
        "id": "topic_Historical_Figures_Cleopatra",
        "name_en": "Cleopatra",
        "name_sv": "Kleopatra",
        "name_is": "Kleopatra",
        "learning_objectives": "Understand the role and impact of Cleopatra in history.",
        "when_to_add": ["hobbyInterests.History.historical_figures"]
    },
    {
        "curriculum": "Cultural History",
        "field": "History",
        "subject": "Cultural History",
        "id": "topic_Cultural_History_Art",
        "name_en": "Art Movements Through History",
        "name_sv": "Konstströmningar genom historien",
        "name_is": "Listahreyfingar í gegnum söguna",
        "learning_objectives": "Explore major art movements and their cultural significance.",
        "when_to_add": ["hobbyInterests.History.cultural_history"]
    },
    {
        "curriculum": "Cultural History",
        "field": "History",
        "subject": "Cultural History",
        "id": "topic_Cultural_History_Religion",
        "name_en": "Religions and Their Influence",
        "name_sv": "Religioner och deras inflytande",
        "name_is": "Trúarbrögð og áhrif þeirra",
        "learning_objectives": "Examine major world religions and their impact on culture.",
        "when_to_add": ["hobbyInterests.History.cultural_history"]
    },
    {
        "curriculum": "Cultural History",
        "field": "History",
        "subject": "Cultural History",
        "id": "topic_Cultural_History_Fashion",
        "name_en": "Fashion Through the Ages",
        "name_sv": "Mode genom tiderna",
        "name_is": "Móta í gegnum tíðina",
        "learning_objectives": "Study how fashion reflects cultural shifts and changes.",
        "when_to_add": ["hobbyInterests.History.cultural_history"]
    },
    {
        "curriculum": "Social Movements",
        "field": "History",
        "subject": "Social Movements",
        "id": "topic_Social_Movements_Civil_Rights",
        "name_en": "Civil Rights Movement",
        "name_sv": "Medborgarrättsrörelsen",
        "name_is": "Meðborgarréttur hreyfing",
        "learning_objectives": "Understand the goals and achievements of the Civil Rights Movement.",
        "when_to_add": ["hobbyInterests.History.social_movements"]
    },
    {
        "curriculum": "Social Movements",
        "field": "History",
        "subject": "Social Movements",
        "id": "topic_Social_Movements_Feminism",
        "name_en": "Feminist Movements",
        "name_sv": "Feministiska rörelser",
        "name_is": "Feminista hreyfingar",
        "learning_objectives": "Examine the history and impact of feminist movements worldwide.",
        "when_to_add": ["hobbyInterests.History.social_movements"]
    },
    {
        "curriculum": "Social Movements",
        "field": "History",
        "subject": "Social Movements",
        "id": "topic_Social_Movements_Environmentalism",
        "name_en": "Environmental Movement",
        "name_sv": "Miljörörelsen",
        "name_is": "Umhverfishreyfing",
        "learning_objectives": "Explore the origins and progress of the environmental movement.",
        "when_to_add": ["hobbyInterests.History.social_movements"]
    },
    {
        "curriculum": "History of Science",
        "field": "History",
        "subject": "History of Science",
        "id": "topic_History_of_Science_Inventions",
        "name_en": "Key Inventions Through History",
        "name_sv": "Nyckelinventitioner genom historien",
        "name_is": "Lykiluppfinningar í sögunni",
        "learning_objectives": "Study significant inventions and their impact on society.",
        "when_to_add": ["hobbyInterests.History.history_of_science"]
    },
    {
        "curriculum": "History of Science",
        "field": "History",
        "subject": "History of Science",
        "id": "topic_History_of_Science_Scientists",
        "name_en": "Famous Scientists and Their Contributions",
        "name_sv": "Berömda forskare och deras bidrag",
        "name_is": "Fyrirferðarmiklar vísindamenn og framlag þeirra",
        "learning_objectives": "Understand the contributions of pivotal scientists in history.",
        "when_to_add": ["hobbyInterests.History.history_of_science"]
    },
    {
        "curriculum": "History of Science",
        "field": "History",
        "subject": "History of Science",
        "id": "topic_History_of_Science_Technology",
        "name_en": "The Evolution of Technology",
        "name_sv": "Teknologins utveckling",
        "name_is": "Þróun tækni",
        "learning_objectives": "Investigate the historical development of technology.",
        "when_to_add": ["hobbyInterests.History.history_of_science"]
    },
    {
        "curriculum": "Ethics and Morality",
        "field": "Philosophy",
        "subject": "Ethics and Morality",
        "id": "topic_Ethics_Morality_Moral_Theories",
        "name_en": "Key Moral Theories",
        "name_sv": "Nyckelmoraliska teorier",
        "name_is": "Lykilmoraliska kenningar",
        "learning_objectives": "Explore various moral theories and their implications.",
        "when_to_add": ["hobbyInterests.Philosophy.ethics_morality"]
    },
    {
        "curriculum": "Ethics and Morality",
        "field": "Philosophy",
        "subject": "Ethics and Morality",
        "id": "topic_Ethics_Morality_Contemporary_Ethics",
        "name_en": "Contemporary Ethical Issues",
        "name_sv": "Nutida etiska frågor",
        "name_is": "Núverandi siðferði mál",
        "learning_objectives": "Examine pressing moral issues in today’s society.",
        "when_to_add": ["hobbyInterests.Philosophy.ethics_morality"]
    },
    {
        "curriculum": "Ethics and Morality",
        "field": "Philosophy",
        "subject": "Ethics and Morality",
        "id": "topic_Ethics_Morality_Application",
        "name_en": "Applying Ethical Theories",
        "name_sv": "Tillämpning av etiska teorier",
        "name_is": "Beiting siðfræði kenningar",
        "learning_objectives": "Learn how to apply ethical theories to real-world situations.",
        "when_to_add": ["hobbyInterests.Philosophy.ethics_morality"]
    },
    {
        "curriculum": "Existentialism",
        "field": "Philosophy",
        "subject": "Existentialism",
        "id": "topic_Existentialism_Key_Thinkers",
        "name_en": "Key Existentialist Thinkers",
        "name_sv": "Nyckelexistentialister",
        "name_is": "Lykilexistenshugsuðir",
        "learning_objectives": "Explore the ideas of major existentialist philosophers.",
        "when_to_add": ["hobbyInterests.Philosophy.existentialism"]
    },
    {
        "curriculum": "Existentialism",
        "field": "Philosophy",
        "subject": "Existentialism",
        "id": "topic_Existentialism_AThemes",
        "name_en": "Major Themes in Existentialism",
        "name_sv": "Stora teman i existentialism",
        "name_is": "Mikil efni í lýðræðisfræði",
        "learning_objectives": "Understand the central themes of existentialist thought.",
        "when_to_add": ["hobbyInterests.Philosophy.existentialism"]
    },
    {
        "curriculum": "Existentialism",
        "field": "Philosophy",
        "subject": "Existentialism",
        "id": "topic_Existentialism_Application",
        "name_en": "Existentialism in Literature",
        "name_sv": "Existentialism i litteraturen",
        "name_is": "Tilvist í bókmenntum",
        "learning_objectives": "Explore the influence of existentialism in literature and art.",
        "when_to_add": ["hobbyInterests.Philosophy.existentialism"]
    },
        {
            "curriculum": "Eastern Philosophy",
            "field": "Philosophy",
            "subject": "Eastern Philosophy",
            "id": "topic_Eastern_Phil_Vedanta",
            "name_en": "Vedanta Philosophy",
            "name_sv": "Vedanta- filosofin",
            "name_is": "Vedanta-fílózofía",
            "learning_objectives": "Investigate the core concepts of Vedanta philosophy.",
            "when_to_add": ["hobbyInterests.Philosophy.eastern_philosophy"]
        },
        {
            "curriculum": "Eastern Philosophy",
            "field": "Philosophy",
            "subject": "Eastern Philosophy",
            "id": "topic_Eastern_Phil_Buddhism",
            "name_en": "Buddhism and its Philosophy",
            "name_sv": "Buddismen och dess filosofi",
            "name_is": "Búddhismi og hans fílózofía",
            "learning_objectives": "Understand the philosophical teachings of Buddhism.",
            "when_to_add": ["hobbyInterests.Philosophy.eastern_philosophy"]
        },
        {
            "curriculum": "Eastern Philosophy",
            "field": "Philosophy",
            "subject": "Eastern Philosophy",
            "id": "topic_Eastern_Phil_Taoism",
            "name_en": "Taoism and its Principles",
            "name_sv": "Taoismen och dess principer",
            "name_is": "Taoismi og hans meginreglur",
            "learning_objectives": "Explore the ideas and principles of Taoism.",
            "when_to_add": ["hobbyInterests.Philosophy.eastern_philosophy"]
        },
        {
            "curriculum": "Western Philosophy",
            "field": "Philosophy",
            "subject": "Western Philosophy",
            "id": "topic_Western_Phil_Plato",
            "name_en": "Plato and His Ideas",
            "name_sv": "Platon och hans idéer",
            "name_is": "Platon og hans hugmyndir",
            "learning_objectives": "Examine the contributions of Plato to Western philosophy.",
            "when_to_add": ["hobbyInterests.Philosophy.western_philosophy"]
        },
        {
            "curriculum": "Western Philosophy",
            "field": "Philosophy",
            "subject": "Western Philosophy",
            "id": "topic_Western_Phil_Descartes",
            "name_en": "Descartes and Rationalism",
            "name_sv": "Descartes och rationalismen",
            "name_is": "Descartes og rökhyggja",
            "learning_objectives": "Explore the philosophical ideas of René Descartes.",
            "when_to_add": ["hobbyInterests.Philosophy.western_philosophy"]
        },
        {
            "curriculum": "Western Philosophy",
            "field": "Philosophy",
            "subject": "Western Philosophy",
            "id": "topic_Western_Phil_Kant",
            "name_en": "Kant's Philosophy",
            "name_sv": "Kants filosofi",
            "name_is": "Kants fílózofía",
            "learning_objectives": "Understand the key concepts in Immanuel Kant's philosophy.",
            "when_to_add": ["hobbyInterests.Philosophy.western_philosophy"]
        },
        {
            "curriculum": "Logic and Critical Thinking",
            "field": "Philosophy",
            "subject": "Logic and Critical Thinking",
            "id": "topic_Logic_CriticalThinking_Basics",
            "name_en": "Basics of Logic",
            "name_sv": "Grunderna i logik",
            "name_is": "Grunnvallarat í rökfræði",
            "learning_objectives": "Learn the foundational concepts of logic.",
            "when_to_add": ["hobbyInterests.Philosophy.logic_critical_thinking"]
        },
        {
            "curriculum": "Logic and Critical Thinking",
            "field": "Philosophy",
            "subject": "Logic and Critical Thinking",
            "id": "topic_Logic_CriticalThinking_CriticalThinking",
            "name_en": "Principles of Critical Thinking",
            "name_sv": "Principer för kritiskt tänkande",
            "name_is": "Meginreglur kritískrar hugsunar",
            "learning_objectives": "Understand how to think critically and evaluate arguments.",
            "when_to_add": ["hobbyInterests.Philosophy.logic_critical_thinking"]
        },
        {
            "curriculum": "Logic and Critical Thinking",
            "field": "Philosophy",
            "subject": "Logic and Critical Thinking",
            "id": "topic_Logic_CriticalThinking_Fallacies",
            "name_en": "Identifying Logical Fallacies",
            "name_sv": "Identifiera logiska fel",
            "name_is": "Að bera kennsl á rökfræði villur",
            "learning_objectives": "Learn how to identify and understand logical fallacies.",
            "when_to_add": ["hobbyInterests.Philosophy.logic_critical_thinking"]
        },
        {
            "curriculum": "Philosophy of Mind",
            "field": "Philosophy",
            "subject": "Philosophy of Mind",
            "id": "topic_Philosophy_of_Mind_Consciousness",
            "name_en": "The Nature of Consciousness",
            "name_sv": "Medvetandets natur",
            "name_is": "Eðli meðvitundar",
            "learning_objectives": "Explore the philosophical questions surrounding consciousness.",
            "when_to_add": ["hobbyInterests.Philosophy.philosophy_of_mind"]
        },
        {
            "curriculum": "Philosophy of Mind",
            "field": "Philosophy",
            "subject": "Philosophy of Mind",
            "id": "topic_Philosophy_of_Mind_Body",
            "name_en": "Mind-Body Problem",
            "name_sv": "Problemet kropp-själ",
            "name_is": "Vandamál hugur-líkami",
            "learning_objectives": "Understand the relationship between mind and body in philosophy.",
            "when_to_add": ["hobbyInterests.Philosophy.philosophy_of_mind"]
        },
        {
            "curriculum": "Philosophy of Mind",
            "field": "Philosophy",
            "subject": "Philosophy of Mind",
            "id": "topic_Philosophy_of_Mind_Nature",
            "name_en": "Nature of the Self",
            "name_sv": "Självets natur",
            "name_is": "Eðli sjálfsins",
            "learning_objectives": "Examine different perspectives on the concept of self.",
            "when_to_add": ["hobbyInterests.Philosophy.philosophy_of_mind"]
        },
        {
            "curriculum": "Cognitive Psychology",
            "field": "Psychology",
            "subject": "Cognitive Psychology",
            "id": "topic_Cognitive_Psychology_Processes",
            "name_en": "Cognitive Processes",
            "name_sv": "Kognitiva processer",
            "name_is": "Kognitívar ferli",
            "learning_objectives": "Explore the major cognitive processes, including perception and memory.",
            "when_to_add": ["hobbyInterests.Psychology.cognitive_psychology"]
        },
        {
            "curriculum": "Cognitive Psychology",
            "field": "Psychology",
            "subject": "Cognitive Psychology",
            "id": "topic_Cognitive_Psychology_Theories",
            "name_en": "Theories of Cognitive Psychology",
            "name_sv": "Teorier inom kognitiv psykologi",
            "name_is": "Kenningar í kognitívri sálfræði",
            "learning_objectives": "Understand the key theories in cognitive psychology.",
            "when_to_add": ["hobbyInterests.Psychology.cognitive_psychology"]
        },
        {
            "curriculum": "Cognitive Psychology",
            "field": "Psychology",
            "subject": "Cognitive Psychology",
            "id": "topic_Cognitive_Psychology_Applications",
            "name_en": "Applications of Cognitive Psychology",
            "name_sv": "Tillämpningar av kognitiv psykologi",
            "name_is": "Notkun kognitívrar sálfræði",
            "learning_objectives": "Learn how cognitive psychology principles are applied in various fields.",
            "when_to_add": ["hobbyInterests.Psychology.cognitive_psychology"]
        },
        {
            "curriculum": "Behavioral Psychology",
            "field": "Psychology",
            "subject": "Behavioral Psychology",
            "id": "topic_Behavioral_Psychology_Conditioning",
            "name_en": "Principles of Conditioning",
            "name_sv": "Principer för betingning",
            "name_is": "Meginreglur aðlögunar",
            "learning_objectives": "Explore the principles of classical and operant conditioning.",
            "when_to_add": ["hobbyInterests.Psychology.behavioral_psychology"]
        },
        {
            "curriculum": "Behavioral Psychology",
            "field": "Psychology",
            "subject": "Behavioral Psychology",
            "id": "topic_Behavioral_Psychology_Applications",
            "name_en": "Applications of Behavioral Psychology",
            "name_sv": "Tillämpningar av beteendepsykologi",
            "name_is": "Notkun hegðunar sálfræði",
            "learning_objectives": "Learn how behavioral principles are applied in therapy and education.",
            "when_to_add": ["hobbyInterests.Psychology.behavioral_psychology"]
        },
        {
            "curriculum": "Behavioral Psychology",
            "field": "Psychology",
            "subject": "Behavioral Psychology",
            "id": "topic_Behavioral_Psychology_Influences",
            "name_en": "Influences on Behavior",
            "name_sv": "Påverkan på beteende",
            "name_is": "Fyrirkomulag á hegðun",
            "learning_objectives": "Examine external factors that influence behavior.",
            "when_to_add": ["hobbyInterests.Psychology.behavioral_psychology"]
        },
        {
            "curriculum": "Developmental Psychology",
            "field": "Psychology",
            "subject": "Developmental Psychology",
            "id": "topic_Developmental_Psychology_Milestones",
            "name_en": "Developmental Milestones",
            "name_sv": "Utvecklingsmilstenar",
            "name_is": "Þroskastig",
            "learning_objectives": "Understand key developmental milestones across the lifespan.",
            "when_to_add": ["hobbyInterests.Psychology.developmental_psychology"]
        },
        {
            "curriculum": "Developmental Psychology",
            "field": "Psychology",
            "subject": "Developmental Psychology",
            "id": "topic_Developmental_Psychology_Theories",
            "name_en": "Theories of Development",
            "name_sv": "Teorier om utveckling",
            "name_is": "Kenningar um þroska",
            "learning_objectives": "Explore major theories of human development, such as those by Piaget and Erikson.",
            "when_to_add": ["hobbyInterests.Psychology.developmental_psychology"]
        },
        {
            "curriculum": "Developmental Psychology",
            "field": "Psychology",
            "subject": "Developmental Psychology",
            "id": "topic_Developmental_Psychology_Impact",
            "name_en": "Impact of Environment on Development",
            "name_sv": "Miljöpåverkan på utveckling",
            "name_is": "Umhverfi áhrif á þroska",
            "learning_objectives": "Examine how environmental factors influence development.",
            "when_to_add": ["hobbyInterests.Psychology.developmental_psychology"]
        },
        {
            "curriculum": "Social Psychology",
            "field": "Psychology",
            "subject": "Social Psychology",
            "id": "topic_Social_Psychology_Group_Dynamics",
            "name_en": "Group Dynamics",
            "name_sv": "Gruppdynamik",
            "name_is": "Hópdýnamík",
            "learning_objectives": "Understand the principles of group behavior and dynamics.",
            "when_to_add": ["hobbyInterests.Psychology.social_psychology"]
        },
        {
            "curriculum": "Social Psychology",
            "field": "Psychology",
            "subject": "Social Psychology",
            "id": "topic_Social_Psychology_Influences",
            "name_en": "Social Influences on Behavior",
            "name_sv": "Social påverkan på beteende",
            "name_is": "Félagsleg áhrif á hegðun",
            "learning_objectives": "Examine how social contexts influence individual behavior.",
            "when_to_add": ["hobbyInterests.Psychology.social_psychology"]
        },
        {
            "curriculum": "Social Psychology",
            "field": "Psychology",
            "subject": "Social Psychology",
            "id": "topic_Social_Psychology_Prejudice",
            "name_en": "Prejudice and Discrimination",
            "name_sv": "Fördomar och diskriminering",
            "name_is": "Forspá and mismunur",
            "learning_objectives": "Explore the psychological factors contributing to prejudice and discrimination.",
            "when_to_add": ["hobbyInterests.Psychology.social_psychology"]
        },
        {
            "curriculum": "Positive Psychology",
            "field": "Psychology",
            "subject": "Positive Psychology",
            "id": "topic_Positive_Psychology_Happiness",
            "name_en": "Understanding Happiness",
            "name_sv": "Förstå lycka",
            "name_is": "Skilningur á hamingju",
            "learning_objectives": "Investigate the concept of happiness and well-being.",
            "when_to_add": ["hobbyInterests.Psychology.positive_psychology"]
        },
        {
            "curriculum": "Positive Psychology",
            "field": "Psychology",
            "subject": "Positive Psychology",
            "id": "topic_Positive_Psychology_Resilience",
            "name_en": "Building Resilience",
            "name_sv": "Bygga motståndskraft",
            "name_is": "Að byggja viðnám",
            "learning_objectives": "Learn strategies for developing resilience in individuals.",
            "when_to_add": ["hobbyInterests.Psychology.positive_psychology"]
        },
        {
            "curriculum": "Positive Psychology",
            "field": "Psychology",
            "subject": "Positive Psychology",
            "id": "topic_Positive_Psychology_Fulfillment",
            "name_en": "Pursuing Meaning and Fulfillment",
            "name_sv": "Sträva efter mening och uppfyllelse",
            "name_is": "Að sækja merkingu og uppfyllingu",
            "learning_objectives": "Explore the importance of meaning in a fulfilling life.",
            "when_to_add": ["hobbyInterests.Psychology.positive_psychology"]
        },
        {
            "curriculum": "Therapy Techniques",
            "field": "Psychology",
            "subject": "Therapy Techniques",
            "id": "topic_Therapy_Techniques_Types",
            "name_en": "Types of Therapy Techniques",
            "name_sv": "Typer av terapi-tekniker",
            "name_is": "Tegundir meðferðaraðferða",
            "learning_objectives": "Understand various types of therapy techniques used in psychology.",
            "when_to_add": ["hobbyInterests.Psychology.therapy_techniques"]
        },
        {
            "curriculum": "Therapy Techniques",
            "field": "Psychology",
            "subject": "Therapy Techniques",
            "id": "topic_Therapy_Techniques_Effectiveness",
            "name_en": "Effectiveness of Therapy Techniques",
            "name_sv": "Effektiviteten hos terapi-tekniker",
            "name_is": "Skilvirkni meðferðaraðferðar",
            "learning_objectives": "Evaluate the effectiveness of different therapy methods.",
            "when_to_add": ["hobbyInterests.Psychology.therapy_techniques"]
        },
        {
            "curriculum": "Therapy Techniques",
            "field": "Psychology",
            "subject": "Therapy Techniques",
            "id": "topic_Therapy_Techniques_Case_Studies",
            "name_en": "Case Studies in Therapy Techniques",
            "name_sv": "Fallstudier inom terapi-tekniker",
            "name_is": "Fjölskyldustærðir meðferðaraðferðar",
            "learning_objectives": "Analyze case studies to understand the application of therapy techniques.",
            "when_to_add": ["hobbyInterests.Psychology.therapy_techniques"]
        },
        {
            "curriculum": "Neuroscience Basics",
            "field": "Brain Science",
            "subject": "Neuroscience Basics",
            "id": "topic_Neuroscience_Basics_Structure",
            "name_en": "Structure of the Brain",
            "name_sv": "Hjärnans struktur",
            "name_is": "Strúktúr heilans",
            "learning_objectives": "Learn about the different structures and functions of the brain.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroscience_basics"]
        },
        {
            "curriculum": "Neuroscience Basics",
            "field": "Brain Science",
            "subject": "Neuroscience Basics",
            "id": "topic_Neuroscience_Basics_Functions",
            "name_en": "Functions of the Brain",
            "name_sv": "Hjärnans funktioner",
            "name_is": "Funktionir heilans",
            "learning_objectives": "Examine the primary functions of different brain regions.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroscience_basics"]
        },
        {
            "curriculum": "Neuroscience Basics",
            "field": "Brain Science",
            "subject": "Neuroscience Basics",
            "id": "topic_Neuroscience_Basics_Neurons",
            "name_en": "Neurons and Synapses",
            "name_sv": "Neuroner och synapser",
            "name_is": "Taugafrumur og samlag",
            "learning_objectives": "Understand the structure and function of neurons and synapses.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroscience_basics"]
        },
        {
            "curriculum": "Neuroplasticity",
            "field": "Brain Science",
            "subject": "Neuroplasticity",
            "id": "topic_Neuroplasticity_Concepts",
            "name_en": "Understanding Neuroplasticity",
            "name_sv": "Förstå neuroplasticitet",
            "name_is": "Skilning á taugamyndun",
            "learning_objectives": "Explore the concept of neuroplasticity and its significance.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroplasticity"]
        },
        {
            "curriculum": "Neuroplasticity",
            "field": "Brain Science",
            "subject": "Neuroplasticity",
            "id": "topic_Neuroplasticity_Applications",
            "name_en": "Applications of Neuroplasticity",
            "name_sv": "Tillämpningar av neuroplasticitet",
            "name_is": "Notkun taugamyndunar",
            "learning_objectives": "Investigate how neuroplasticity can be harnessed in therapy.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroplasticity"]
        },
        {
            "curriculum": "Neuroplasticity",
            "field": "Brain Science",
            "subject": "Neuroplasticity",
            "id": "topic_Neuroplasticity_Consequences",
            "name_en": "Consequences of Neuroplasticity",
            "name_sv": "Konsekvenser av neuroplasticitet",
            "name_is": "Afleiðingar taugamyndunar",
            "learning_objectives": "Learn about the positive and negative consequences of neuroplasticity.",
            "when_to_add": ["hobbyInterests.Brain_Science.neuroplasticity"]
        },
        {
            "curriculum": "Memory Techniques",
            "field": "Brain Science",
            "subject": "Memory Techniques",
            "id": "topic_Memory_Techniques_Strategies",
            "name_en": "Memory Enhancement Strategies",
            "name_sv": "Strategier för att förbättra minnet",
            "name_is": "Aðferðir til að bæta minni",
            "learning_objectives": "Explore techniques to enhance memory retention and recall.",
            "when_to_add": ["hobbyInterests.Brain_Science.memory_techniques"]
        },
        {
            "curriculum": "Memory Techniques",
            "field": "Brain Science",
            "subject": "Memory Techniques",
            "id": "topic_Memory_Techniques_Types",
            "name_en": "Types of Memory Techniques",
            "name_sv": "Typer av minnesmetoder",
            "name_is": "Tegundir minnisfestinga",
            "learning_objectives": "Understand different types of memory techniques and their uses.",
            "when_to_add": ["hobbyInterests.Brain_Science.memory_techniques"]
        },
        {
            "curriculum": "Memory Techniques",
            "field": "Brain Science",
            "subject": "Memory Techniques",
            "id": "topic_Memory_Techniques_Training",
            "name_en": "Memory Training Programs",
            "name_sv": "Minnesträningsprogram",
            "name_is": "Minnistraining kerfi",
            "learning_objectives": "Learn about various memory training programs available.",
            "when_to_add": ["hobbyInterests.Brain_Science.memory_techniques"]
        },
        {
            "curriculum": "Cognitive Enhancement",
            "field": "Brain Science",
            "subject": "Cognitive Enhancement",
            "id": "topic_Cognitive_Enhancement_Strategies",
            "name_en": "Cognitive Enhancement Strategies",
            "name_sv": "Strategier för kognitiv förbättring",
            "name_is": "Aðferðir til að bæta hugræna virkni",
            "learning_objectives": "Explore strategies to enhance cognitive function.",
            "when_to_add": ["hobbyInterests.Brain_Science.cognitive_enhancement"]
        },
        {
            "curriculum": "Cognitive Enhancement",
            "field": "Brain Science",
            "subject": "Cognitive Enhancement",
            "id": "topic_Cognitive_Enhancement_Education",
            "name_en": "Education and Cognitive Enhancement",
            "name_sv": "Utbildning och kognitiv förbättring",
            "name_is": "Menntun og bætandi hugræna",
            "learning_objectives": "Analyze the role of education in cognitive enhancement.",
            "when_to_add": ["hobbyInterests.Brain_Science.cognitive_enhancement"]
        },
        {
            "curriculum": "Cognitive Enhancement",
            "field": "Brain Science",
            "subject": "Cognitive Enhancement",
            "id": "topic_Cognitive_Enhancement_Tools",
            "name_en": "Tools for Cognitive Enhancement",
            "name_sv": "Verktyg för kognitiv förbättring",
            "name_is": "Verkfæri fyrir kognitíva bætandi",
            "learning_objectives": "Learn about tools and resources for cognitive enhancement.",
            "when_to_add": ["hobbyInterests.Brain_Science.cognitive_enhancement"]
        },
        {
            "curriculum": "Mental Health Disorders",
            "field": "Brain Science",
            "subject": "Mental Health Disorders",
            "id": "topic_Mental_Health_Disorders_Overview",
            "name_en": "Overview of Mental Health Disorders",
            "name_sv": "Översikt över psykiska störningar",
            "name_is": "Yfirlit yfir geðræn vandamál",
            "learning_objectives": "Understand different types of mental health disorders.",
            "when_to_add": ["hobbyInterests.Brain_Science.mental_health_disorders"]
        },
        {
            "curriculum": "Mental Health Disorders",
            "field": "Brain Science",
            "subject": "Mental Health Disorders",
            "id": "topic_Mental_Health_Disorders_Treatment",
            "name_en": "Treatment Approaches for Mental Health Disorders",
            "name_sv": "Behandlingsmetoder för psykiska störningar",
            "name_is": "Meðferðaraðgerðir fyrir geðræn vandamál",
            "learning_objectives": "Explore various treatment options for mental health disorders.",
            "when_to_add": ["hobbyInterests.Brain_Science.mental_health_disorders"]
        },
        {
            "curriculum": "Mental Health Disorders",
            "field": "Brain Science",
            "subject": "Mental Health Disorders",
            "id": "topic_Mental_Health_Disorders_Prevention",
            "name_en": "Preventive Measures for Mental Health",
            "name_sv": "Preventiva åtgärder för psykisk hälsa",
            "name_is": "Forvarnir til andlegrar heilsu",
            "learning_objectives": "Learn about preventive strategies for mental health maintenance.",
            "when_to_add": ["hobbyInterests.Brain_Science.mental_health_disorders"]
        },
        {
            "curriculum": "Psychology of Learning",
            "field": "Brain Science",
            "subject": "Psychology of Learning",
            "id": "topic_Psychology_of_Learning_Basics",
            "name_en": "Basics of Learning Psychology",
            "name_sv": "Grunderna i lärandepsykologi",
            "name_is": "Grunnvallarat í lærandipsykólogíu",
            "learning_objectives": "Understand key concepts in the psychology of learning.",
            "when_to_add": ["hobbyInterests.Brain_Science.psychology_of_learning"]
        },
        {
            "curriculum": "Psychology of Learning",
            "field": "Brain Science",
            "subject": "Psychology of Learning",
            "id": "topic_Psychology_of_Learning_Theories",
            "name_en": "Theories of Learning",
            "name_sv": "Teorier om lärande",
            "name_is": "Kenningar um læringu",
            "learning_objectives": "Explore major theories of learning and their implications.",
            "when_to_add": ["hobbyInterests.Brain_Science.psychology_of_learning"]
        },
        {
            "curriculum": "Psychology of Learning",
            "field": "Brain Science",
            "subject": "Psychology of Learning",
            "id": "topic_Psychology_of_Learning_Applications",
            "name_en": "Applications of Learning Psychology",
            "name_sv": "Tillämpningar av lärandepsykologi",
            "name_is": "Notkun á læringapsykón",
            "learning_objectives": "Investigate the practical applications of learning psychology.",
            "when_to_add": ["hobbyInterests.Brain_Science.psychology_of_learning"]
        },
        {
            "curriculum": "Gardening Basics",
            "field": "Hobbies",
            "subject": "Gardening Basics",
            "id": "topic_Gardening_Basics_Tools",
            "name_en": "Essential Gardening Tools",
            "name_sv": "Viktiga trädgårdsredskap",
            "name_is": "Mikilvæg trjárðvunartæki",
            "learning_objectives": "Learn about essential tools for effective gardening.",
            "when_to_add": ["hobbyInterests.Hobbies.gardening_basics"]
        },
        {
            "curriculum": "Gardening Basics",
            "field": "Hobbies",
            "subject": "Gardening Basics",
            "id": "topic_Gardening_Basics_Techniques",
            "name_en": "Basic Gardening Techniques",
            "name_sv": "Grundläggande trädgårdstekniker",
            "name_is": "Grunnvallarat trjárðvistar",
            "learning_objectives": "Understand fundamental gardening techniques for beginners.",
            "when_to_add": ["hobbyInterests.Hobbies.gardening_basics"]
        },
        {
            "curriculum": "Gardening Basics",
            "field": "Hobbies",
            "subject": "Gardening Basics",
            "id": "topic_Gardening_Basics_Plants",
            "name_en": "Choosing the Right Plants",
            "name_sv": "Välja rätt växter",
            "name_is": "Choose the right plants",
            "learning_objectives": "Learn how to select plants suitable for your garden.",
            "when_to_add": ["hobbyInterests.Hobbies.gardening_basics"]
        },
        {
            "curriculum": "Writing",
            "field": "Hobbies",
            "subject": "Writing",
            "id": "topic_Writing_Fiction",
            "name_en": "Fiction Writing Techniques",
            "name_sv": "Tekniker för att skriva skönlitteratur",
            "name_is": "Skrifta skáldskapartækni",
            "learning_objectives": "Explore techniques for writing engaging fiction.",
            "when_to_add": ["hobbyInterests.Hobbies.writing"]
        },
        {
            "curriculum": "Writing",
            "field": "Hobbies",
            "subject": "Writing",
            "id": "topic_Writing_NonFiction",
            "name_en": "Non-Fiction Writing Techniques",
            "name_sv": "Tekniker för att skriva facklitteratur",
            "name_is": "Fjölmargt skrifa aðferðir",
            "learning_objectives": "Understand the principles of effective non-fiction writing.",
            "when_to_add": ["hobbyInterests.Hobbies.writing"]
        },
        {
            "curriculum": "Writing",
            "field": "Hobbies",
            "subject": "Writing",
            "id": "topic_Writing_Poetry",
            "name_en": "Poetry Writing Craft",
            "name_sv": "Att skriva poesi",
            "name_is": "Lífið",
            "learning_objectives": "Learn the craft of writing poetry and its forms.",
            "when_to_add": ["hobbyInterests.Hobbies.writing"]
        },
        {
            "curriculum": "Blogging and Vlogging",
            "field": "Hobbies",
            "subject": "Blogging and Vlogging",
            "id": "topic_Blogging_Vlogging_Tips",
            "name_en": "Tips for Blogging and Vlogging",
            "name_sv": "Tips för bloggande och vloggande",
            "name_is": "Ábendingar fyrir blogghyggju",
            "learning_objectives": "Explore effective tips for starting a blog or vlog.",
            "when_to_add": ["hobbyInterests.Hobbies.blogging_vlogging"]
        },
        {
            "curriculum": "Blogging and Vlogging",
            "field": "Hobbies",
            "subject": "Blogging and Vlogging",
            "id": "topic_Blogging_Vlogging_Promotion",
            "name_en": "Promoting Your Blog or Vlog",
            "name_sv": "Marknadsföring av din blogg eller vlogg",
            "name_is": "Auglýsingar á blogginu eða vloggin",
            "learning_objectives": "Learn strategies for promoting your blog or vlog.",
            "when_to_add": ["hobbyInterests.Hobbies.blogging_vlogging"]
        },
        {
            "curriculum": "Blogging and Vlogging",
            "field": "Hobbies",
            "subject": "Blogging and Vlogging",
            "id": "topic_Blogging_Vlogging_Content",
            "name_en": "Creating Engaging Content",
            "name_sv": "Skapa engagerande innehåll",
            "name_is": "Skapa athyglisvert efni",
            "learning_objectives": "Understand how to create content that engages your audience.",
            "when_to_add": ["hobbyInterests.Hobbies.blogging_vlogging"]
        }
    ]