import { british } from "./[locale]/topics/BritishCoricculum";
import { american } from "./[locale]/topics/american";
import {swedish} from './[locale]/topics/swedish'
import { engineering, arts, science, business, law, medicine, architecture, education, psychology, computer_science } from "./[locale]/topics/unicourses";
import { generalInterests } from "./[locale]/topics/generalInterests";
export const allTopics = [...engineering, ...american, ...british, ...swedish, ...arts, ...science, ...business, ...law, ...medicine, ...architecture, ...education, ...psychology, ...computer_science, ...generalInterests]
// [
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_NumberOperations",
//       "name_en": "Number Operations for Year 7 Mathematics",
//       "name_sv": "Taloperationer för Årskurs 7 Matematik",
//       "learning_objectives": "Understand and apply basic arithmetic operations including addition, subtraction, multiplication, and division to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
//           "interests.mathematics",
//           "generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_FractionsDecimalsPercentages",
//       "name_en": "Fractions, Decimals, and Percentages for Year 7 Mathematics",
//       "name_sv": "Bråk, Decimaler och Procent för Årskurs 7 Matematik",
//       "learning_objectives": "Convert between fractions, decimals, and percentages, and solve problems involving these concepts to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
//           "interests.mathematics",
//           "generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_AlgebraBasics",
//       "name_en": "Introduction to Algebra for Year 7 Mathematics",
//       "name_sv": "Introduktion till Algebra för Årskurs 7 Matematik",
//       "learning_objectives": "Understand the use of variables to represent numbers, simplify expressions, and solve simple algebraic equations to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
//           "interests.mathematics",
//           "generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_GeometryShapes",
//       "name_en": "Properties of Shapes for Year 7 Mathematics",
//       "name_sv": "Formegenskaper för Årskurs 7 Matematik",
//       "learning_objectives": "Identify and describe properties of 2D and 3D shapes, including types of angles and symmetry, to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics",
//           "interests.mathematics",
//           "generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_Measurement",
//       "name_en": "Measurement for Year 7 Mathematics",
//       "name_sv": "Mätning för Årskurs 7 Matematik",
//       "learning_objectives": "Understand units of measurement for length, mass, and capacity, and calculate perimeter, area, and volume to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_Angles",
//       "name_en": "Understanding Angles for Year 7 Mathematics",
//       "name_sv": "Förstå Vinklar för Årskurs 7 Matematik",
//       "learning_objectives": "Identify different types of angles, measure angles using a protractor, and calculate unknown angles to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_Statistics",
//       "name_en": "Introduction to Statistics for Year 7 Mathematics",
//       "name_sv": "Introduktion till Statistik för Årskurs 7 Matematik",
//       "learning_objectives": "Collect, organize, and interpret data using charts and graphs, and understand mean, median, mode, and range to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_Probability",
//       "name_en": "Basic Probability for Year 7 Mathematics",
//       "name_sv": "Grundläggande Sannolikhet för Årskurs 7 Matematik",
//       "learning_objectives": "Understand basic probability concepts and calculate the likelihood of simple events to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_RatioProportion",
//       "name_en": "Ratio and Proportion for Year 7 Mathematics",
//       "name_sv": "Förhållande och Proportion för Årskurs 7 Matematik",
//       "learning_objectives": "Solve problems involving ratios and proportions to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Math_Time",
//       "name_en": "Understanding Time for Year 7 Mathematics",
//       "name_sv": "Förstå Tid för Årskurs 7 Matematik",
//       "learning_objectives": "Interpret and calculate time intervals, convert between 12-hour and 24-hour clocks, and solve problems involving timetables to meet the requirements for passing Year 7 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_CellBiology",
//       "name_en": "Cell Biology for Year 7 Science",
//       "name_sv": "Cellbiologi för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Understand the structure and function of plant and animal cells to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Ecosystems",
//       "name_en": "Ecosystems for Year 7 Science",
//       "name_sv": "Ekosystem för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Explore food chains, food webs, and the interdependence of organisms to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Particles",
//       "name_en": "Particle Model of Matter for Year 7 Science",
//       "name_sv": "Partikelmodellen för Materia för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Understand how particles are arranged in solids, liquids, and gases to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_ElementsCompoundsMixtures",
//       "name_en": "Elements, Compounds, and Mixtures for Year 7 Science",
//       "name_sv": "Grundämnen, Föreningar och Blandningar för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Differentiate between elements, compounds, and mixtures to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Forces",
//       "name_en": "Forces and Motion for Year 7 Science",
//       "name_sv": "Krafter och Rörelse för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Understand different types of forces, their effects on motion, and how they can be measured to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Space",
//       "name_en": "Earth and Space for Year 7 Science",
//       "name_sv": "Jorden och Rymden för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Learn about the solar system, the moon phases, and Earth's place in the universe to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Electricity",
//       "name_en": "Introduction to Electricity for Year 7 Science",
//       "name_sv": "Introduktion till Elektricitet för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Understand basic electrical concepts, construct simple circuits, and learn about current and voltage to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_HumanReproduction",
//       "name_en": "Human Reproduction for Year 7 Science",
//       "name_sv": "Mänsklig Reproduktion för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Learn about the human reproductive system, puberty, and development to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Sound",
//       "name_en": "Sound for Year 7 Science",
//       "name_sv": "Ljud för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Explore how sound is produced, how it travels, and its properties to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Science",
//       "subject": "Year 7",
//       "id": "topic_British_Y7_Science_Environment",
//       "name_en": "Environment and Ecology for Year 7 Science",
//       "name_sv": "Miljö och Ekologi för Årskurs 7 Naturvetenskap",
//       "learning_objectives": "Understand human impact on the environment, conservation, and sustainability to meet the requirements for passing Year 7 Science.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year7_gcse1.schoolSubjects.science"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_NumberOperations",
//       "name_en": "Advanced Number Operations for Year 8 Mathematics",
//       "name_sv": "Avancerade Taloperationer för Årskurs 8 Matematik",
//       "learning_objectives": "Perform calculations with negative numbers, factors, multiples, and primes to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_AlgebraicExpressions",
//       "name_en": "Algebraic Expressions for Year 8 Mathematics",
//       "name_sv": "Algebraiska Uttryck för Årskurs 8 Matematik",
//       "learning_objectives": "Simplify algebraic expressions, expand brackets, and factorize to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_LinearEquations",
//       "name_en": "Linear Equations for Year 8 Mathematics",
//       "name_sv": "Linjär Ekvationer för Årskurs 8 Matematik",
//       "learning_objectives": "Solve single-variable linear equations and apply them to problem-solving to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_GeometryAngles",
//       "name_en": "Angles in Geometry for Year 8 Mathematics",
//       "name_sv": "Vinklar i Geometri för Årskurs 8 Matematik",
//       "learning_objectives": "Calculate unknown angles in polygons and parallel lines to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_Transformations",
//       "name_en": "Transformations for Year 8 Mathematics",
//       "name_sv": "Transformationer för Årskurs 8 Matematik",
//       "learning_objectives": "Perform translations, rotations, reflections, and enlargements on shapes to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_Probability",
//       "name_en": "Probability for Year 8 Mathematics",
//       "name_sv": "Sannolikhet för Årskurs 8 Matematik",
//       "learning_objectives": "Calculate probabilities of single and combined events to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_Statistics",
//       "name_en": "Data Handling for Year 8 Mathematics",
//       "name_sv": "Datahantering för Årskurs 8 Matematik",
//       "learning_objectives": "Collect, represent, and interpret data using various graphical methods to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_RatioProportion",
//       "name_en": "Ratio and Proportion Problems for Year 8 Mathematics",
//       "name_sv": "Problem med Förhållande och Proportion för Årskurs 8 Matematik",
//       "learning_objectives": "Solve complex problems involving ratios, rates, and direct proportion to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_PerimeterAreaVolume",
//       "name_en": "Perimeter, Area, and Volume for Year 8 Mathematics",
//       "name_sv": "Omkrets, Area och Volym för Årskurs 8 Matematik",
//       "learning_objectives": "Calculate the perimeter, area, and volume of various shapes to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 8",
//       "id": "topic_British_Y8_Math_Pythagoras",
//       "name_en": "Introduction to Pythagoras' Theorem for Year 8 Mathematics",
//       "name_sv": "Introduktion till Pythagoras Sats för Årskurs 8 Matematik",
//       "learning_objectives": "Understand and apply Pythagoras' Theorem in right-angled triangles to meet the requirements for passing Year 8 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_AlgebraicManipulation",
//       "name_en": "Algebraic Manipulation for Year 9 Mathematics",
//       "name_sv": "Algebraisk Manipulation för Årskurs 9 Matematik",
//       "learning_objectives": "Manipulate algebraic expressions, including simplifying, expanding, and factoring properly to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_QuadraticEquations",
//       "name_en": "Quadratic Equations for Year 9 Mathematics",
//       "name_sv": "Kvadratiska Ekvationer för Årskurs 9 Matematik",
//       "learning_objectives": "Solve quadratic equations using various methods including factoring, completing the square, and the quadratic formula to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_SimultaneousEquations",
//       "name_en": "Simultaneous Equations for Year 9 Mathematics",
//       "name_sv": "Samtida Ekvationer för Årskurs 9 Matematik",
//       "learning_objectives": "Solve pairs of simultaneous linear equations using substitution and elimination methods to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_TrigonometryBasics",
//       "name_en": "Trigonometry Basics for Year 9 Mathematics",
//       "name_sv": "Trigonometri Grunder för Årskurs 9 Matematik",
//       "learning_objectives": "Understand basic trigonometric ratios (sine, cosine, tangent) and their application in right triangles to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_AdvancedGeometry",
//       "name_en": "Advanced Geometry for Year 9 Mathematics",
//       "name_sv": "Avancerad Geometri för Årskurs 9 Matematik",
//       "learning_objectives": "Explore properties of circles, including theorems related to angles, arcs, and chords to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_SequencesSeries",
//       "name_en": "Sequences and Series for Year 9 Mathematics",
//       "name_sv": "Folknings- och Arvserier för Årskurs 9 Matematik",
//       "learning_objectives": "Understand and use arithmetic and geometric sequences and their sums to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_ProbabilityDistributions",
//       "name_en": "Probability Distributions for Year 9 Mathematics",
//       "name_sv": "Sannolikhetsfördelningar för Årskurs 9 Matematik",
//       "learning_objectives": "Explore different types of probability distributions including discrete and continuous to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_Statistics",
//       "name_en": "Statistics for Year 9 Mathematics",
//       "name_sv": "Statistik för Årskurs 9 Matematik",
//       "learning_objectives": "Construct and interpret histograms, calculate cumulative frequency, and analyze data sets to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_GraphsOfFunctions",
//       "name_en": "Graphs of Functions for Year 9 Mathematics",
//       "name_sv": "Funktionens Grafer för Årskurs 9 Matematik",
//       "learning_objectives": "Plot and analyze graphs of various functions, understand linear and non-linear relationships to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Math_VectorsTransformations",
//       "name_en": "Vectors and Transformations for Year 9 Mathematics",
//       "name_sv": "Vektor och Transformationer för Årskurs 9 Matematik",
//       "learning_objectives": "Understand vector notation, perform operations with vectors, and apply transformations in the coordinate plane to meet the requirements for passing Year 9 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_AlgebraicManipulation",
//       "name_en": "Advanced Algebraic Manipulation for Year 10 Mathematics",
//       "name_sv": "Avancerad Algebraisk Manipulation för Årskurs 10 Matematik",
//       "learning_objectives": "Further develop skills in manipulating algebraic expressions including working with polynomial long division and rational expressions to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_QuadraticEquations",
//       "name_en": "Advanced Quadratic Equations for Year 10 Mathematics",
//       "name_sv": "Avancerade Kvadratiska Ekvationer för Årskurs 10 Matematik",
//       "learning_objectives": "Analyze and solve quadratic equations including completing the square and applying the quadratic formula in various contexts to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_FunctionsRelations",
//       "name_en": "Functions and Relations for Year 10 Mathematics",
//       "name_sv": "Funktioner och Relationer för Årskurs 10 Matematik",
//       "learning_objectives": "Understand and apply different types of functions and their properties, including linear, quadratic, and exponential functions to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_Trigonometry",
//       "name_en": "Trigonometry Applications for Year 10 Mathematics",
//       "name_sv": "Trigonometri Tillämpningar för Årskurs 10 Matematik",
//       "learning_objectives": "Apply trigonometric ratios to solve real-world problems and understand the unit circle and circular functions to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_AdvancedGeometry",
//       "name_en": "Advanced Geometric Theorems for Year 10 Mathematics",
//       "name_sv": "Avancerade Geometriska Satsar för Årskurs 10 Matematik",
//       "learning_objectives": "Utilize advanced geometric theorems including properties of triangles, circles, and polygons in problem-solving contexts to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_StatisticsProbability",
//       "name_en": "Statistics and Probability for Year 10 Mathematics",
//       "name_sv": "Statistik och Sannolikhet för Årskurs 10 Matematik",
//       "learning_objectives": "Conduct probability experiments, calculate probabilities of events and analyze statistical data using suitable methods including the use of technology to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_SequencesSeries",
//       "name_en": "Advanced Sequences and Series for Year 10 Mathematics",
//       "name_sv": "Avancerade Folknings- och Arvserier för Årskurs 10 Matematik",
//       "learning_objectives": "Analyze arithmetic and geometric sequences and series, including formulas for finding the nth term and the sum of the series to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_GraphTheory",
//       "name_en": "Introduction to Graph Theory for Year 10 Mathematics",
//       "name_sv": "Introduktion till Grafer för Årskurs 10 Matematik",
//       "learning_objectives": "Understand the basic concepts of graph theory, including vertices, edges, and various types of graphs to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Math_VectorsApplications",
//       "name_en": "Applications of Vectors for Year 10 Mathematics",
//       "name_sv": "Tillämpningar av Vektorer för Årskurs 10 Matematik",
//       "learning_objectives": "Apply vector concepts in geometry and physics, including vector addition, subtraction, and scalar multiplication to meet the requirements for passing Year 10 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_ComplexNumbers",
//       "name_en": "Complex Numbers for Year 11 Mathematics",
//       "name_sv": "Komplexa Tal för Årskurs 11 Matematik",
//       "learning_objectives": "Understand the concept of complex numbers, perform operations, and represent them graphically to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_AdvancedStatistics",
//       "name_en": "Advanced Statistics for Year 11 Mathematics",
//       "name_sv": "Avancerad Statistik för Årskurs 11 Matematik",
//       "learning_objectives": "Utilize advanced statistical techniques including hypothesis testing, correlation, regression, and interpreting statistical outputs to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_ProbabilityTheory",
//       "name_en": "Probability Theory for Year 11 Mathematics",
//       "name_sv": "Sannolikhetsteori för Årskurs 11 Matematik",
//       "learning_objectives": "Explore advanced probability concepts and theorems including Bayes' theorem and combinatorics to solve complex probability problems to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_AdvancedFunctions",
//       "name_en": "Advanced Functions for Year 11 Mathematics",
//       "name_sv": "Avancerade Funktioner för Årskurs 11 Matematik",
//       "learning_objectives": "Analyze and manipulate advanced functions such as logarithmic and exponential functions and their applications in different contexts to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_Inequalities",
//       "name_en": "Inequalities and Regions for Year 11 Mathematics",
//       "name_sv": "Olikheter och Regioner för Årskurs 11 Matematik",
//       "learning_objectives": "Understand and solve linear and quadratic inequalities, graph them on a coordinate plane, and analyze solution regions to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Mathematics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Math_Transformations",
//       "name_en": "Transformations in Mathematics for Year 11 Mathematics",
//       "name_sv": "Transformationer i Matte för Årskurs 11 Matematik",
//       "learning_objectives": "Study various transformations of functions including translations, reflections, and dilations, and understand their effects on the graph of the function to meet the requirements for passing Year 11 Mathematics.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.mathematics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Physics_ForcesAndMotion",
//       "name_en": "Forces and Motion for Year 9 Physics",
//       "name_sv": "Krafter och Rörelse för Årskurs 9 Fysik",
//       "learning_objectives": "Understand Newton's laws, calculating speed, velocity, acceleration, and understanding force diagrams.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Physics_EnergyTransfers",
//       "name_en": "Energy Transfers and Efficiency for Year 9 Physics",
//       "name_sv": "Energitransfer och Effektivitet för Årskurs 9 Fysik",
//       "learning_objectives": "Explore conservation of energy, energy transfer diagrams, and calculating efficiency.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 8-10",
//       "id": "topic_British_Y8_10_Physics_WavesLightAndSound",
//       "name_en": "Waves - Light and Sound for Year 8-10 Physics",
//       "name_sv": "Vågor - Ljus och Ljud för Årskurs 8-10 Fysik",
//       "learning_objectives": "Study properties of waves, reflection, refraction, and the electromagnetic spectrum.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year8_gcse1.schoolSubjects.physics",
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.physics",
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Physics_ElectricityAndMagnetism",
//       "name_en": "Electricity and Magnetism for Year 10 Physics",
//       "name_sv": "Elektricitet och Magnetism för Årskurs 10 Fysik",
//       "learning_objectives": "Learn about electrical circuits, current, voltage, resistance, and basics of magnetism.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Physics_NuclearPhysics",
//       "name_en": "Nuclear Physics for Year 11 Physics",
//       "name_sv": "Kärnfysik för Årskurs 11 Fysik",
//       "learning_objectives": "Understand radioactivity, types of radiation, half-life, and nuclear reactions.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Physics_SpacePhysics",
//       "name_en": "Space Physics for Year 11 Physics",
//       "name_sv": "Rymdfysik för Årskurs 11 Fysik",
//       "learning_objectives": "Explore the structure of the solar system, life cycle of stars, and the universe.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 9",
//       "id": "topic_British_Y9_Physics_ThermalPhysics",
//       "name_en": "Thermal Physics for Year 9 Physics",
//       "name_sv": "Termisk Fysik för Årskurs 9 Fysik",
//       "learning_objectives": "Study heat transfer methods, temperature changes, and specific heat capacity.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year9_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 10",
//       "id": "topic_British_Y10_Physics_DynamicsAndMomentum",
//       "name_en": "Dynamics and Momentum for Year 10 Physics",
//       "name_sv": "Dynamik och Rörelsemängd för Årskurs 10 Fysik",
//       "learning_objectives": "Understand momentum calculations and conservation, and collision problems.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year10_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Physics_ElectricityGeneration",
//       "name_en": "Electricity Generation and Transmission for Year 11 Physics",
//       "name_sv": "Elproduktion och Överföring för Årskurs 11 Fysik",
//       "learning_objectives": "Learn about power stations, renewable and non-renewable energy sources, and the national grid.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "British",
//       "field": "Physics",
//       "subject": "Year 11",
//       "id": "topic_British_Y11_Physics_Electromagnetism",
//       "name_en": "Electromagnetism for Year 11 Physics",
//       "name_sv": "Elektromagnetism för Årskurs 11 Fysik",
//       "learning_objectives": "Study magnetic effects of electric current, electromagnets, and their applications.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.british.schoolYear.year11_gcse1.schoolSubjects.physics"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_FunctionsAndGraphs",
//       "name_en": "Functions and Graphs for IB Mathematics HL",
//       "name_sv": "Funktioner och Grafer för IB Matematik HL",
//       "learning_objectives": "Deep exploration of various types of functions and their graphs, transformations, and applications.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_EquationsAndInequalities",
//       "name_en": "Equations and Inequalities for IB Mathematics HL",
//       "name_sv": "Ekvationer och Olikheter för IB Matematik HL",
//       "learning_objectives": "Solving complex equations and inequalities, including polynomial, exponential, and logarithmic forms.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_DifferentialCalculus",
//       "name_en": "Differential Calculus for IB Mathematics HL",
//       "name_sv": "Differentialkalkyl för IB Matematik HL",
//       "learning_objectives": "Advanced differentiation techniques and applications in optimization and motion problems.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_IntegralCalculus",
//       "name_en": "Integral Calculus for IB Mathematics HL",
//       "name_sv": "Integralkalkyl för IB Matematik HL",
//       "learning_objectives": "Advanced integration methods, definite and indefinite integrals, and areas under curves.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_ComplexNumbers",
//       "name_en": "Complex Numbers for IB Mathematics HL",
//       "name_sv": "Komplexa Tal för IB Matematik HL",
//       "learning_objectives": "Operations with complex numbers, polar form, and De Moivre's Theorem.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_VectorsIn3DSpace",
//       "name_en": "Vectors in Three-Dimensional Space for IB Mathematics HL",
//       "name_sv": "Vektorer i Tre-Dimensionellt Rum för IB Matematik HL",
//       "learning_objectives": "Vector algebra, scalar and vector products, and applications in 3D geometry.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_ProbabilityDistributions",
//       "name_en": "Probability Distributions for IB Mathematics HL",
//       "name_sv": "Sannolikhetsfördelningar för IB Matematik HL",
//       "learning_objectives": "Discrete and continuous probability distributions, expectation, and variance.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_StatisticalAnalysis",
//       "name_en": "Statistical Analysis for IB Mathematics HL",
//       "name_sv": "Statistisk Analys för IB Matematik HL",
//       "learning_objectives": "Data analysis, hypothesis testing, and use of statistical tests.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_DifferentialEquations",
//       "name_en": "Differential Equations for IB Mathematics HL",
//       "name_sv": "Differentialekvationer för IB Matematik HL",
//       "learning_objectives": "Formation and solution of first-order differential equations and modeling with differential equations.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_DiscreteMathematics",
//       "name_en": "Discrete Mathematics for IB Mathematics HL",
//       "name_sv": "Diskret Matematik för IB Matematik HL",
//       "learning_objectives": "Logic, sets, combinatorics, graph theory, and algorithms.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_MathematicalInduction",
//       "name_en": "Mathematical Induction for IB Mathematics HL",
//       "name_sv": "Matematisk Induktion för IB Matematik HL",
//       "learning_objectives": "Proving statements using the principle of mathematical induction.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_SeriesAndApproximations",
//       "name_en": "Series and Approximations for IB Mathematics HL",
//       "name_sv": "Serier och Approximati​oner för IB Matematik HL",
//       "learning_objectives": "Convergence of series, Taylor and Maclaurin series expansions.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_CalculusOfParametricAndPolarCurves",
//       "name_en": "Calculus of Parametric and Polar Curves for IB Mathematics HL",
//       "name_sv": "Kalkyl av Parametriska och Polära Kurvor för IB Matematik HL",
//       "learning_objectives": "Differentiation and integration of parametric and polar equations.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_VectorsAndPlanes",
//       "name_en": "Vectors and Planes for IB Mathematics HL",
//       "name_sv": "Vektorer och Planer för IB Matematik HL",
//       "learning_objectives": "Equations of lines and planes in three dimensions, intersections, and angles.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_FurtherIntegrationTechniques",
//       "name_en": "Further Integration Techniques for IB Mathematics HL",
//       "name_sv": "Vidare Integreringstekniker för IB Matematik HL",
//       "learning_objectives": "Techniques such as integration by parts, substitution, and improper integrals.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_ProbabilityAndStatisticsBivariateDistributions",
//       "name_en": "Probability and Statistics - Bivariate Distributions for IB Mathematics HL",
//       "name_sv": "Sannolikhet och Statistik - Bivariat Distribution för IB Matematik HL",
//       "learning_objectives": "Correlation, regression, and analysis of bivariate data.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_ChiSquaredTests",
//       "name_en": "Chi-Squared Tests for IB Mathematics HL",
//       "name_sv": "Chi-Kvadrat Tester för IB Matematik HL",
//       "learning_objectives": "Conducting and interpreting chi-squared tests for independence and goodness of fit.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_CalculusOfInfiniteSeries",
//       "name_en": "Calculus of Infinite Series for IB Mathematics HL",
//       "name_sv": "Kalkyl av Oändliga Serier för IB Matematik HL",
//       "learning_objectives": "Testing series for convergence and divergence; working with power series.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_LimitsAndContinuity",
//       "name_en": "Limits and Continuity for IB Mathematics HL",
//       "name_sv": "Gränser och Kontinuitet för IB Matematik HL",
//       "learning_objectives": "Formal definitions of limits and continuity, and evaluating complex limits.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "IB",
//       "field": "Mathematics",
//       "subject": "HL Years 11 and 12",
//       "id": "topic_IB_Math_HL_OptimizationProblems",
//       "name_en": "Optimization Problems for IB Mathematics HL",
//       "name_sv": "Optimeringsproblem för IB Matematik HL",
//       "learning_objectives": "Using calculus to solve real-world optimization problems involving maximum and minimum values.",
//       "when_to_add": [
//           "userType.school_student.schoolCurriculum.ib.schoolYear.year11_ib1.schoolSubjects.math_hl"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "I Topics",
//       "id": "topic_University_Calculus_I_LimitsAndContinuity",
//       "name_en": "Limits and Continuity for University Calculus I",
//       "name_sv": "Gränser och Kontinuitet för Universitetets Kalkyl I",
//       "learning_objectives": "Fundamental concepts of limits and continuity essential for understanding calculus.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "I Topics",
//       "id": "topic_University_Calculus_I_DerivativesAndApplications",
//       "name_en": "Derivatives and Applications for University Calculus I",
//       "name_sv": "Derivativer och Tillämpningar för Universitetets Kalkyl I",
//       "learning_objectives": "Techniques of differentiation and applying derivatives to motion and rate problems.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus1",
//"generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "I Topics",
//       "id": "topic_University_Calculus_I_IntegralsAndApplications",
//       "name_en": "Integrals and Applications for University Calculus I",
//       "name_sv": "Integraler och Tillämpningar för Universitetets Kalkyl I",
//       "learning_objectives": "Fundamental theorem of calculus, techniques of integration, and calculating areas.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus1",
//"generalInterests.mathematics",
//           "generalInterests.science"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "I Topics",
//       "id": "topic_University_Calculus_I_ApplicationsOfDefiniteIntegral",
//       "name_en": "Applications of the Definite Integral for University Calculus I",
//       "name_sv": "Tillämpningar av Bestämda Integraler för Universitetets Kalkyl I",
//       "learning_objectives": "Using integrals to compute volumes, work, and average values.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "I Topics",
//       "id": "topic_University_Calculus_I_SequencesAndSeries",
//       "name_en": "Sequences and Series for University Calculus I",
//       "name_sv": "Sekvenser och Serier för Universitetets Kalkyl I",
//       "learning_objectives": "Introduction to infinite sequences and series, convergence tests.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Linear Algebra",
//       "subject": "Topics",
//       "id": "topic_University_LinearAlgebra_MatrixOperations",
//       "name_en": "Matrix Operations for University Linear Algebra",
//       "name_sv": "Matrisoperationer för Universitetets Linjär Algebra",
//       "learning_objectives": "Performing operations with matrices, including addition, multiplication, and inversion.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Linear Algebra",
//       "subject": "Topics",
//       "id": "topic_University_LinearAlgebra_Determinants",
//       "name_en": "Determinants and Their Properties for University Linear Algebra",
//       "name_sv": "Determinanter och Deras Egenskaper för Universitetets Linjär Algebra",
//       "learning_objectives": "Calculating determinants and understanding their significance in linear systems.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Linear Algebra",
//       "subject": "Topics",
//       "id": "topic_University_LinearAlgebra_VectorSpaces",
//       "name_en": "Vector Spaces for University Linear Algebra",
//       "name_sv": "Vektorrum för Universitetets Linjär Algebra",
//       "learning_objectives": "Understanding vector spaces, subspaces, bases, and dimensions.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Linear Algebra",
//       "subject": "Topics",
//       "id": "topic_University_LinearAlgebra_EigenvaluesAndEigenvectors",
//       "name_en": "Eigenvalues and Eigenvectors for University Linear Algebra",
//       "name_sv": "Egenvärden och Egenvektorer för Universitetets Linjär Algebra",
//       "learning_objectives": "Finding eigenvalues, eigenvectors, and their applications in engineering problems.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Linear Algebra",
//       "subject": "Topics",
//       "id": "topic_University_LinearAlgebra_SystemsOfLinearEquations",
//       "name_en": "Systems of Linear Equations for University Linear Algebra",
//       "name_sv": "System av Linjära Ekvationer för Universitetets Linjär Algebra",
//       "learning_objectives": "Solving linear systems using various methods including Gaussian elimination.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.linear_algebra"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "I Topics",
//       "id": "topic_University_Physics_I_Kinematics",
//       "name_en": "Kinematics for University Physics I",
//       "name_sv": "Kinematik för Universitetets Fysik I",
//       "learning_objectives": "Describing motion in one and two dimensions, including velocity and acceleration.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "I Topics",
//       "id": "topic_University_Physics_I_NewtonianMechanics",
//       "name_en": "Newtonian Mechanics for University Physics I",
//       "name_sv": "Newtonsk Mekanik för Universitetets Fysik I",
//       "learning_objectives": "Applying Newton's laws to analyze forces and motion in physical systems.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "I Topics",
//       "id": "topic_University_Physics_I_WorkAndEnergy",
//       "name_en": "Work and Energy for University Physics I",
//       "name_sv": "Arbete och Energi för Universitetets Fysik I",
//       "learning_objectives": "Concepts of work, kinetic energy, potential energy, and conservation of energy.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "I Topics",
//       "id": "topic_University_Physics_I_MomentumAndCollisions",
//       "name_en": "Momentum and Collisions for University Physics I",
//       "name_sv": "Rörelsemängd och Kollisioner för Universitetets Fysik I",
//       "learning_objectives": "Linear momentum, impulse, and analyzing elastic and inelastic collisions.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "I Topics",
//       "id": "topic_University_Physics_I_RotationalDynamics",
//       "name_en": "Rotational Dynamics for University Physics I",
//       "name_sv": "Rotationsdynamik för Universitetets Fysik I",
//       "learning_objectives": "Rotation of rigid bodies, torque, angular momentum, and rotational energy.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics1"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Engineering Fundamentals",
//       "subject": "Topics",
//       "id": "topic_University_EngineeringFundamentals_StaticsOfRigidBodies",
//       "name_en": "Statics of Rigid Bodies for Engineering Fundamentals",
//       "name_sv": "Statik av Stela Kroppar för Ingenjörsgrundläggande",
//       "learning_objectives": "Equilibrium of particles and rigid bodies, analyzing forces, and moments.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.engineering_fundamentals"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Engineering Fundamentals",
//       "subject": "Topics",
//       "id": "topic_University_EngineeringFundamentals_MaterialsScience",
//       "name_en": "Materials Science Basics for Engineering Fundamentals",
//       "name_sv": "Materialvetenskap Grunder för Ingenjörsgrundläggande",
//       "learning_objectives": "Structure and properties of engineering materials, including metals, polymers, and ceramics.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.engineering_fundamentals"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Engineering Fundamentals",
//       "subject": "Topics",
//       "id": "topic_University_EngineeringFundamentals_Thermodynamics",
//       "name_en": "Introduction to Thermodynamics for Engineering Fundamentals",
//       "name_sv": "Introduktion till Termodynamik för Ingenjörsgrundläggande",
//       "learning_objectives": "Laws of thermodynamics, energy conversion, and heat transfer.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.engineering_fundamentals"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Engineering Fundamentals",
//       "subject": "Topics",
//       "id": "topic_University_EngineeringFundamentals_CircuitAnalysis",
//       "name_en": "Circuit Analysis for Engineering Fundamentals",
//       "name_sv": "Kretsanalys för Ingenjörsgrundläggande",
//       "learning_objectives": "Fundamentals of electrical circuits, Ohm's law, Kirchhoff's laws, and circuit components.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.engineering_fundamentals"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Engineering Fundamentals",
//       "subject": "Topics",
//       "id": "topic_University_EngineeringFundamentals_EngineeringDesignProcess",
//       "name_en": "Engineering Design Process for Engineering Fundamentals",
//       "name_sv": "Ingenjörsdesignprocessen för Ingenjörsgrundläggande",
//       "learning_objectives": "Steps in the engineering design process, problem-solving, and project management.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.engineering_fundamentals"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "II Topics",
//       "id": "topic_University_Calculus_II_AdvancedIntegration",
//       "name_en": "Advanced Integration Techniques for University Calculus II",
//       "name_sv": "Avancerade Integreringstekniker för Universitetets Kalkyl II",
//       "learning_objectives": "Integration methods including partial fractions, trigonometric integrals, and substitution.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "II Topics",
//       "id": "topic_University_Calculus_II_ParametricEquationsAndPolarCoordinates",
//       "name_en": "Parametric Equations and Polar Coordinates for University Calculus II",
//       "name_sv": "Parametriska Ekvationer och Polära Koordinater för Universitetets Kalkyl II",
//       "learning_objectives": "Analyzing curves represented parametrically or in polar coordinates.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Calculus",
//       "subject": "II Topics",
//       "id": "topic_University_Calculus_II_InfiniteSeriesAndConvergence",
//       "name_en": "Infinite Series and Convergence for University Calculus II",
//       "name_sv": "Oändliga Serier och Konvergens för Universitetets Kalkyl II",
//       "learning_objectives": "Testing for convergence and working with power series and Taylor series.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.calculus2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "II Topics",
//       "id": "topic_University_Physics_II_ElectricFieldsAndForces",
//       "name_en": "Electric Fields and Forces for University Physics II",
//       "name_sv": "Elektriska Fält och Krafter för Universitetets Fysik II",
//       "learning_objectives": "Understanding electric charge, Coulomb's law, and electric field concepts.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "II Topics",
//       "id": "topic_University_Physics_II_MagneticFieldsAndInduction",
//       "name_en": "Magnetic Fields and Induction for University Physics II",
//       "name_sv": "Magnetiska Fält och Induktion för Universitetets Fysik II",
//       "learning_objectives": "Exploring magnetic fields, Ampère's law, and electromagnetic induction.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "II Topics",
//       "id": "topic_University_Physics_II_CircuitsAndElectronics",
//       "name_en": "Circuits and Electronics for University Physics II",
//       "name_sv": "Kretsar och Elektronik för Universitetets Fysik II",
//       "learning_objectives": "Analyzing AC/DC circuits, capacitors, inductors, and basic electronic components.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics2"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Mechanics of Materials",
//       "subject": "Topics",
//       "id": "topic_University_MechanicsOfMaterials_StressAndStrain",
//       "name_en": "Stress and Strain for Mechanics of Materials",
//       "name_sv": "Spänning och Deformation för Materialmekanik",
//       "learning_objectives": "Understanding the relationship between applied forces and deformation in materials.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.mechanics_of_materials"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Mechanics of Materials",
//       "subject": "Topics",
//       "id": "topic_University_MechanicsOfMaterials_AxialLoadAndDeformation",
//       "name_en": "Axial Load and Deformation for Mechanics of Materials",
//       "name_sv": "Axial Belastning och Deformation för Materialmekanik",
//       "learning_objectives": "Calculating elongation and compression of members under axial loads.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.mechanics_of_materials"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Mechanics of Materials",
//       "subject": "Topics",
//       "id": "topic_University_MechanicsOfMaterials_TorsionOfCircularShafts",
//       "name_en": "Torsion of Circular Shafts for Mechanics of Materials",
//       "name_sv": "Torsion av Cirkulära Axel för Materialmekanik",
//       "learning_objectives": "Analyzing twisting of shafts subjected to torque.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.mechanics_of_materials"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Mechanics of Materials",
//       "subject": "Topics",
//       "id": "topic_University_MechanicsOfMaterials_BendingOfBeams",
//       "name_en": "Bending of Beams for Mechanics of Materials",
//       "name_sv": "Böjning av Balkar för Materialmekanik",
//       "learning_objectives": "Determining stress and deflection in beams due to bending moments.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.mechanics_of_materials"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Computer Science",
//       "subject": "Programming Fundamentals Topics",
//       "id": "topic_University_ProgramFund_VariablesAndDataTypes",
//       "name_en": "Variables and Data Types in Programming",
//       "name_sv": "Variabler och Datatyper i Programmering",
//       "learning_objectives": "Understanding various data types, variable declaration, and memory allocation.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.computer_science.universityCourses.programming_foundations"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Computer Science",
//       "subject": "Programming Fundamentals Topics",
//       "id": "topic_University_ProgramFund_ControlStructures",
//       "name_en": "Control Structures in Programming",
//       "name_sv": "Kontrollstrukturer i Programmering",
//       "learning_objectives": "Implementing conditional statements and loops for flow control.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.computer_science.universityCourses.programming_foundations"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Computer Science",
//       "subject": "Programming Fundamentals Topics",
//       "id": "topic_University_ProgramFund_FunctionsAndModularProgramming",
//       "name_en": "Functions and Modular Programming",
//       "name_sv": "Funktioner och Modular Programmering",
//       "learning_objectives": "Creating reusable code blocks with functions and understanding scope.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.computer_science.universityCourses.programming_foundations"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Computer Science",
//       "subject": "Programming Fundamentals Topics",
//       "id": "topic_University_ProgramFund_ObjectOrientedProgramming",
//       "name_en": "Object-Oriented Programming Concepts",
//       "name_sv": "Objektorienterade Programmeringskoncept",
//       "learning_objectives": "Understanding classes, objects, inheritance, and polymorphism.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.computer_science.universityCourses.programming_foundations"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Computer Science",
//       "subject": "Programming Fundamentals Topics",
//       "id": "topic_University_ProgramFund_DataStructures",
//       "name_en": "Data Structures in Programming",
//       "name_sv": "Datatyper i Programmering",
//       "learning_objectives": "Implementing arrays, linked lists, stacks, queues, and trees.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.computer_science.universityCourses.programming_foundations"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Business",
//       "subject": "Business Principles Topics",
//       "id": "topic_University_BusinessPrinciples_Microeconomics",
//       "name_en": "Principles of Microeconomics",
//       "name_sv": "Principer för Mikroekonomi",
//       "learning_objectives": "Supply and demand analysis, market structures, and consumer behavior.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.business.universityCourses.business_principles"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Business",
//       "subject": "Business Principles Topics",
//       "id": "topic_University_BusinessPrinciples_AccountingBasics",
//       "name_en": "Accounting Basics",
//       "name_sv": "Bokföringsgrunder",
//       "learning_objectives": "Understanding financial statements, bookkeeping, and accounting principles.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.business.universityCourses.business_principles"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Business",
//       "subject": "Business Principles Topics",
//       "id": "topic_University_BusinessPrinciples_MarketingPrinciples",
//       "name_en": "Marketing Principles",
//       "name_sv": "Marknadsföringsprinciper",
//       "learning_objectives": "Concepts of market segmentation, targeting, positioning, and marketing mix.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.business.universityCourses.business_principles"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Business",
//       "subject": "Business Principles Topics",
//       "id": "topic_University_BusinessPrinciples_OrganizationalBehavior",
//       "name_en": "Organizational Behavior",
//       "name_sv": "Organisatoriskt Beteende",
//       "learning_objectives": "Studying how individuals and groups act within organizations.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.business.universityCourses.business_principles"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Business",
//       "subject": "Business Principles Topics",
//       "id": "topic_University_BusinessPrinciples_BusinessLaw",
//       "name_en": "Business Law",
//       "name_sv": "Affärsjuridik",
//       "learning_objectives": "Legal environment of business, contracts, and regulatory compliance.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.business.universityCourses.business_principles"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Humanities and Social Sciences",
//       "subject": "Social Sciences Topics",
//       "id": "topic_University_Humanities_154",
//       "name_en": "Cultural Anthropology",
//       "name_sv": "Kulturell Antropologi",
//       "learning_objectives": "Study of cultures, societies, and their development globally.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.humanities.universityCourses.social_sciences"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Humanities and Social Sciences",
//       "subject": "Social Sciences Topics",
//       "id": "topic_University_Humanities_155",
//       "name_en": "Sociological Theories",
//       "name_sv": "Sociologiska Teorier",
//       "learning_objectives": "Examination of major theoretical frameworks in sociology.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.humanities.universityCourses.social_sciences"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Philosophy",
//       "subject": "Philosophy Topics",
//       "id": "topic_University_Humanities_156",
//       "name_en": "Philosophy of Ethics",
//       "name_sv": "Etikens Filosofi",
//       "learning_objectives": "Exploration of ethical theories and their application to moral dilemmas.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.humanities.universityCourses.philosophy"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Humanities and Social Sciences",
//       "subject": "Literature Topics",
//       "id": "topic_University_Humanities_157",
//       "name_en": "Modern Literature",
//       "name_sv": "Modern Litteratur",
//       "learning_objectives": "Analysis of themes, styles, and influences in contemporary literature.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.humanities.universityCourses.literature"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Political Science",
//       "subject": "Political Science Topics",
//       "id": "topic_University_Humanities_158",
//       "name_en": "Political Science Fundamentals",
//       "name_sv": "Grunderna i Statsvetenskap",
//       "learning_objectives": "Introduction to political systems, ideologies, and policy-making processes.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.humanities.universityCourses.political_science"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Biology",
//       "subject": "Molecular Biology",
//       "id": "topic_Biology_159",
//       "name_en": "Molecular Biology",
//       "name_sv": "Molekylär Biologi",
//       "learning_objectives": "Structure and function of biomolecules and their role in cellular processes.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.biology"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Biology",
//       "subject": "Environmental Biology",
//       "id": "topic_Biology_160",
//       "name_en": "Environmental Biology",
//       "name_sv": "Miljöbiologi",
//       "learning_objectives": "Study of ecosystems, biodiversity, and the impact of human activities on the environment.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.biology"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Chemistry",
//       "subject": "Inorganic Chemistry",
//       "id": "topic_Chemistry_161",
//       "name_en": "Inorganic Chemistry",
//       "name_sv": "Oorganisk Kemi",
//       "learning_objectives": "Characteristics and reactions of inorganic compounds, including transition metals.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.chemistry"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Chemistry",
//       "subject": "Analytical Chemistry",
//       "id": "topic_Chemistry_162",
//       "name_en": "Analytical Chemistry",
//       "name_sv": "Analytisk Kemi",
//       "learning_objectives": "Techniques for qualitative and quantitative analysis of chemical substances.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.chemistry"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Physics",
//       "subject": "Modern Physics",
//       "id": "topic_Physics_163",
//       "name_en": "Modern Physics",
//       "name_sv": "Modern Fysik",
//       "learning_objectives": "Advanced topics including quantum mechanics, relativity, and particle physics.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.physics"
//       ]
//   },
//   {
//       "curriculum": "Additional Sciences",
//       "field": "Physics",
//       "subject": "Waves - Light and Sound",
//       "id": "topic_British_Y8_10_Physics_WavesLightAndSound",
//       "name_en": "Waves - Light and Sound for Year 8-10 Physics",
//       "name_sv": "Vågor - Ljus och Ljud för Årskurs 8-10 Fysik",
//       "learning_objectives": "Study properties of waves, reflection, refraction, and the electromagnetic spectrum.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.science.universityCourses.physics"
//       ]
//   },
//   {
//       "curriculum": "University",
//       "field": "Physics",
//       "subject": "Physics II Topics",
//       "id": "topic_University_Physics_I_CircuitsAndElectronics",
//       "name_en": "Circuits and Electronics for University Physics II",
//       "name_sv": "Kretsar och Elektronik för Universitetets Fysik II",
//       "learning_objectives": "Analyzing AC/DC circuits, capacitors, inductors, and basic electronic components.",
//       "when_to_add": [
//           "userType.university_student.universityProgram.engineering.universityCourses.physics2"
//       ]
//   }
// ]

export const getUniqueFields = () => ([...new Set(allTopics.map(item => item.field))]);
export const getSelectedTopics = (responses) => {
  return allTopics
    .filter((topic) => {
      if (topic.when_to_add) {
        return topic.when_to_add.some((condition) => {
          // Handle 'hobbyInterests' conditions specially
          if (condition.startsWith('hobbyInterests.')) {
            const hobbyValue = condition.substring('hobbyInterests.'.length); // e.g., 'Psychology.cognitive_psychology'
            if (responses['hobbyInterests'] && Array.isArray(responses['hobbyInterests'])) {
              // Check if 'hobbyValue' is an exact match in the 'hobbyInterests' array
              return responses['hobbyInterests'].includes(hobbyValue);
            } else {
              return false;
            }
          }

          // Original logic for other conditions
          const parts = condition.split('.');
          const pairs = [];
          for (let i = 0; i < parts.length; i += 2) {
            const key = parts[i];
            const value = parts[i + 1];
            pairs.push({ key, value });
          }
          return pairs.every((pair) => {
            const { key, value } = pair;
            if (responses[key]) {
              if (Array.isArray(responses[key])) {
                return responses[key].includes(value);
              } else {
                return responses[key] === value;
              }
            } else {
              return false;
            }
          });
        });
      }
      return false;
    })
    .map((topic) => topic.id);
};
// export const getSelectedTopics = (responses) => {

//   return allTopics
//     .filter((topic) => {
//       if (topic.when_to_add) {
//         return topic.when_to_add.some((condition) => {
//           const parts = condition.split('.');
//           const pairs = [];
//           for (let i = 0; i < parts.length; i += 2) {
//             const key = parts[i];
//             const value = parts[i + 1];
//             pairs.push({ key, value });
//           }
//           return pairs.every((pair) => {
//             const { key, value } = pair;
//             if (responses[key]) {
//               if (Array.isArray(responses[key])) {
//                 return responses[key].includes(value);
//               } else {
//                 return responses[key] === value;
//               }
//             } else {
//               return false;
//             }
//           });
//         });
//       }
//       return false;
//     })
//     .map((topic) => topic.id);
// };

export const getSelectedTopicObjects = (selectedTopicIds) => {
  if(!selectedTopicIds || !selectedTopicIds.length) return
  return selectedTopicIds.map(selectedId => 
    allTopics.find(topic => topic.id === selectedId)
  ) // Filter out any undefined values
};

export const buildOptionTopicCounts = () => {
  const counts = {};

  allTopics.forEach((topic) => {
    if (topic.when_to_add) {
      topic.when_to_add.forEach((condition) => {
        const parts = condition.split('.');
        for (let i = 0; i < parts.length; i += 2) {
          const key = parts[i];
          const value = parts[i + 1];
          if (key && value) {
            if (!counts[key]) {
              counts[key] = {};
            }
            counts[key][value] = (counts[key][value] || 0) + 1;
          }
        }
      });
    }
  });

  return counts;
};
// export const getSelectedTopics = (responses) => {
//   console.log('responses is', responses)
//     return topics.filter(topic => {
//       if (topic.when_to_add) {
//         return topic.when_to_add.some(condition => {
//           // Check if each condition in `when_to_add` matches the user's responses
//           const parts = condition.split('.');
//           return parts.every(part => {
//             const response = responses[part.split('.').slice(-1)[0]];
//             const isMatchingCondition =
//               part.startsWith('userType') ? response === 'school_student' : response === part.split('.').slice(-1)[0];
//             return isMatchingCondition;
//           });
//         });
//       }
//       return false;
//     }).map(topic => topic.id); // Return only the IDs of the topics that should be selected
//   };