export const swedish = [
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_NumberOperations",
        "name_en": "Number Operations for Year 7 Mathematics",
        "name_sv": "Taloperationer för Årskurs 7 Matematik",
        "name_is": "Talaniðir fyrir 7. bekk Matematik",
        "learning_objectives": "Understand and apply basic arithmetic operations including addition, subtraction, multiplication, and division to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_FractionsDecimals",
        "name_en": "Fractions and Decimals for Year 7 Mathematics",
        "name_sv": "Bråk och Decimaler för Årskurs 7 Matematik",
        "name_is": "Brot og tugt fyrir 7. bekk Matematik",
        "learning_objectives": "Convert between fractions and decimals and solve related problems to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_AlgebraicExpressions",
        "name_en": "Algebraic Expressions for Year 7 Mathematics",
        "name_sv": "Algebraiska Uttryck för Årskurs 7 Matematik",
        "name_is": "Algebruk fyrir 7. bekk Matematik",
        "learning_objectives": "Recognize and manipulate algebraic expressions and solve basic equations to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_GeometricShapes",
        "name_en": "Geometric Shapes for Year 7 Mathematics",
        "name_sv": "Geometriska Former för Årskurs 7 Matematik",
        "name_is": "Geometrískar myndir fyrir 7. bekk Matematik",
        "learning_objectives": "Identify and describe geometric shapes and their properties to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_Measurement",
        "name_en": "Measurement for Year 7 Mathematics",
        "name_sv": "Mätning för Årskurs 7 Matematik",
        "name_is": "Mæling fyrir 7. bekk Matematik",
        "learning_objectives": "Understand and apply different units of measurement in various contexts to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_Statistics",
        "name_en": "Statistical Concepts for Year 7 Mathematics",
        "name_sv": "Statistiska Begrepp för Årskurs 7 Matematik",
        "name_is": "Statísk hugtök fyrir 7. bekk Matematik",
        "learning_objectives": "Collect, analyze and interpret data to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_Probability",
        "name_en": "Probability for Year 7 Mathematics",
        "name_sv": "Sannolikhet för Årskurs 7 Matematik",
        "name_is": "Sannleikur fyrir 7. bekk Matematik",
        "learning_objectives": "Understand and calculate probabilities in different situations to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Math_Ratios",
        "name_en": "Problems with Ratios for Year 7 Mathematics",
        "name_sv": "Problem med Förhållande för Årskurs 7 Matematik",
        "name_is": "Vandamál með hlutföll fyrir 7. bekk Matematik",
        "learning_objectives": "Solve problems involving ratios and proportions to meet the requirements for passing Year 7 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Physics_ForceMotion",
        "name_en": "Force and Motion for Year 7 Physics",
        "name_sv": "Kraft och Rörelse för Årskurs 7 Fysik",
        "name_is": "Kraft og hreyfing fyrir 7. bekk eðlisfræði",
        "learning_objectives": "Understand the concepts of force and motion and apply them to everyday situations to meet the requirements for passing Year 7 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Physics_EnergyWork",
        "name_en": "Energy and Work for Year 7 Physics",
        "name_sv": "Energi och Arbete för Årskurs 7 Fysik",
        "name_is": "Orka og vinna fyrir 7. bekk eðlisfræði",
        "learning_objectives": "Explore the different forms of energy and calculate work done in various contexts to meet the requirements for passing Year 7 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Physics_HeatTemperature",
        "name_en": "Heat and Temperature for Year 7 Physics",
        "name_sv": "Värme och Temperatur för Årskurs 7 Fysik",
        "name_is": "Hiti og hita fyrir 7. bekk eðlisfræði",
        "learning_objectives": "Understand the concepts of heat and temperature and the principles of thermal expansion to meet the requirements for passing Year 7 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Chemistry_MatterChanges",
        "name_en": "Matter and Changes for Year 7 Chemistry",
        "name_sv": "Materia och Förändringar för Årskurs 7 Kemi",
        "name_is": "Efni og breytingar fyrir 7. bekk efnafræði",
        "learning_objectives": "Understand the states of matter and the changes between them to meet the requirements for passing Year 7 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Year 7 Chemistry",
        "name_sv": "Kemiska Reaktioner för Årskurs 7 Kemi",
        "name_is": "Kemískar efnaskipti fyrir 7. bekk efnafræði",
        "learning_objectives": "Identify, describe, and classify chemical reactions and their products to meet the requirements for passing Year 7 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Chemistry_PeriodicTable",
        "name_en": "The Periodic Table for Year 7 Chemistry",
        "name_sv": "Periodiska Systemet för Årskurs 7 Kemi",
        "name_is": "Taflan fyrir 7. bekk efnafræði",
        "learning_objectives": "Understand the organization of the periodic table and the properties of elements to meet the requirements for passing Year 7 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Biology_CellBiology",
        "name_en": "Cell Biology for Year 7 Biology",
        "name_sv": "Cellbiologi för Årskurs 7 Biologi",
        "name_is": "Frumuöðlisfræði fyrir 7. bekk líffræði",
        "learning_objectives": "Understand the structure and function of cells and their roles in living organisms to meet the requirements for passing Year 7 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Biology_EcologyEnvironment",
        "name_en": "Ecology and Environment for Year 7 Biology",
        "name_sv": "Ekologi och Miljö för Årskurs 7 Biologi",
        "name_is": "Skoðum umhverfi og umhverfi fyrir 7. bekk líffræði",
        "learning_objectives": "Explore ecosystems, the relationships between organisms, and the impact of human activities on the environment to meet the requirements for passing Year 7 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Biology_GeneticsEvolution",
        "name_en": "Genetics and Evolution for Year 7 Biology",
        "name_sv": "Genetik och Evolution för Årskurs 7 Biologi",
        "name_is": "Genetík og þróun fyrir 7. bekk líffræði",
        "learning_objectives": "Understand the principles of inheritance, variation, and evolution among species to meet the requirements for passing Year 7 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_English_BasicConversation",
        "name_en": "Basic Conversation for Year 7 English",
        "name_sv": "Grundläggande Konversation för Årskurs 7 Engelska",
        "name_is": "Grunnleggjandi Sýning fyrir 7. bekk Enska",
        "learning_objectives": "Develop basic conversation skills in everyday situations and practice speaking and listening in English to meet the requirements for passing Year 7 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_English_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 7 English",
        "name_sv": "Grammatik och Ordförråd för Årskurs 7 Engelska",
        "name_is": "Grammatík og orðaforði fyrir 7. bekk Enska",
        "learning_objectives": "Understand and apply basic grammar rules and expand vocabulary for effective communication in English to meet the requirements for passing Year 7 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_History_SwedenHistory",
        "name_en": "Sweden's History for Year 7 History",
        "name_sv": "Sverigeförbundets Historia för Årskurs 7 Historia",
        "name_is": "Saga Íslands fyrir 7. bekk sagnfræði",
        "learning_objectives": "Explore the major events in Swedish history and their implications for contemporary society to meet the requirements for passing Year 7 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_History_WorldHistory",
        "name_en": "World History for Year 7 History",
        "name_sv": "Världshistoria för Årskurs 7 Historia",
        "name_is": "Heimsaga fyrir 7. bekk sagnfræði",
        "learning_objectives": "Understand significant global historical events and their impact on the present to meet the requirements for passing Year 7 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Geography_SwedenWorld",
        "name_en": "Sweden and the World for Year 7 Geography",
        "name_sv": "Sverige och Världen för Årskurs 7 Geografi",
        "name_is": "Svíþjóð og heimurinn fyrir 7. bekk landafræði",
        "learning_objectives": "Understand the geographical features of Sweden and their relation to global geography to meet the requirements for passing Year 7 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Geography_MapsScale",
        "name_en": "Maps and Scale for Year 7 Geography",
        "name_sv": "Kartor och Skala för Årskurs 7 Geografi",
        "name_is": "Kort og mælikvarði fyrir 7. bekk landafræði",
        "learning_objectives": "Read and understand maps, scales, and directions to navigate different geographical contexts to meet the requirements for passing Year 7 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Economics_Microeconomics",
        "name_en": "Microeconomics for Year 7 Economics",
        "name_sv": "Mikroekonomi för Årskurs 7 Ekonomi",
        "name_is": "Mikroekonomi fyrir 7. bekk efnahagsfræði",
        "learning_objectives": "Understand the principles of microeconomics and their impact on individual choices and markets to meet the requirements for passing Year 7 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Economics_Macroeconomics",
        "name_en": "Macroeconomics for Year 7 Economics",
        "name_sv": "Makroekonomi för Årskurs 7 Ekonomi",
        "name_is": "Makróekonomi fyrir 7. bekk efnahagsfræði",
        "learning_objectives": "Explore broader economic features such as inflation, unemployment, and economic growth to meet the requirements for passing Year 7 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_CS_ProgrammingBasics",
        "name_en": "Programming Basics for Year 7 Computer Science",
        "name_sv": "Programmeringsgrunder för Årskurs 7 Datorvetenskap",
        "name_is": "Grunnleggjandi forritun fyrir 7. bekk tölvunarfræði",
        "learning_objectives": "Understand the fundamentals of programming, algorithms, and coding to meet the requirements for passing Year 7 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_CS_AlgorithmsDataStructures",
        "name_en": "Algorithms and Data Structures for Year 7 Computer Science",
        "name_sv": "Algoritmer och Datastrukturer för Årskurs 7 Datorvetenskap",
        "name_is": "Algrímur og gögnaskipan fyrir 7. bekk tölvunarfræði",
        "learning_objectives": "Explore basic algorithms and data structures and their applications in problem-solving to meet the requirements for passing Year 7 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Psychology_IntroductionPsychology",
        "name_en": "Introduction to Psychology for Year 7 Psychology",
        "name_sv": "Introduktion till Psykologi för Årskurs 7 Psykologi",
        "name_is": "Inngangur að sálfræði fyrir 7. bekk sálfræði",
        "learning_objectives": "Understand the basics of psychology and human behavior to meet the requirements for passing Year 7 Psychology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Psychology_HumanDevelopment",
        "name_en": "Human Development for Year 7 Psychology",
        "name_sv": "Människans Utveckling för Årskurs 7 Psykologi",
        "name_is": "Mannger þróun fyrir 7. bekk sálfræði",
        "learning_objectives": "Explore the stages of human development and their psychological implications to meet the requirements for passing Year 7 Psychology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Sociology_BasicConcepts",
        "name_en": "Basic Sociological Concepts for Year 7 Sociology",
        "name_sv": "Grundläggande Sociologiska Begrepp för Årskurs 7 Sociologi",
        "name_is": "Grunnleggjandi félagsleg hugtök fyrir 7. bekk félagsfræði",
        "learning_objectives": "Understand basic sociological concepts and their application in society to meet the requirements for passing Year 7 Sociology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Sociology_FamilyConcepts",
        "name_en": "Family Concepts for Year 7 Sociology",
        "name_sv": "Familjebegrepp för Årskurs 7 Sociologi",
        "name_is": "Fjölskylduhugtök fyrir 7. bekk félagsfræði",
        "learning_objectives": "Explore the role of family in society and the various family structures to meet the requirements for passing Year 7 Sociology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_ArtDesign_ArtForms",
        "name_en": "Art Forms for Year 7 Art & Design",
        "name_sv": "Konstformer för Årskurs 7 Konst och Design",
        "name_is": "Listafyrir 7. bekk List og hönnun",
        "learning_objectives": "Explore different forms of art and their cultural significance to meet the requirements for passing Year 7 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_ArtDesign_DesignProcess",
        "name_en": "Design Process for Year 7 Art & Design",
        "name_sv": "Designprocessen för Årskurs 7 Konst och Design",
        "name_is": "Hönnunaraðferð fyrir 7. bekk List og hönnun",
        "learning_objectives": "Understand the design process from concept to creation and develop skills in design thinking to meet the requirements for passing Year 7 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Music_MusicalElements",
        "name_en": "Musical Elements for Year 7 Music",
        "name_sv": "Musikaliska Element för Årskurs 7 Musik",
        "name_is": "Tónlistarleg einkenni fyrir 7. bekk tónlist",
        "learning_objectives": "Explore the foundations of music theory, including rhythm, melody, and harmony to meet the requirements for passing Year 7 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Music_MusicProduction",
        "name_en": "Music Production for Year 7 Music",
        "name_sv": "Musikproduktion för Årskurs 7 Musik",
        "name_is": "Tónlistagerð fyrir 7. bekk tónlist",
        "learning_objectives": "Understand the basics of music production techniques and software applications to create and produce music to meet the requirements for passing Year 7 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_PE_MovementHealth",
        "name_en": "Movement and Health for Year 7 Physical Education",
        "name_sv": "Rörelse och Hälsa för Årskurs 7 Gymnastik",
        "name_is": "Hreyfing og heilsa fyrir 7. bekk íþróttir",
        "learning_objectives": "Understand the importance of physical activity for health and well-being and develop skills in various physical activities to meet the requirements for passing Year 7 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_PE_SportTechnique",
        "name_en": "Sports and Techniques for Year 7 Physical Education",
        "name_sv": "Idrott och Teknik för Årskurs 7 Gymnastik",
        "name_is": "Íþróttir og aðferðir fyrir 7. bekk íþróttir",
        "learning_objectives": "Develop sport-specific skills and techniques to meet the requirements for passing Year 7 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_NumberOperations",
        "name_en": "Number Operations for Year 8 Mathematics",
        "name_sv": "Taloperationer för Årskurs 8 Matematik",
        "name_is": "Talaniðir fyrir 8. bekk Matematik",
        "learning_objectives": "Enhance skills in arithmetic operations including addition, subtraction, multiplication, and division to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_FractionsDecimals",
        "name_en": "Fractions and Decimals for Year 8 Mathematics",
        "name_sv": "Bråk och Decimaler för Årskurs 8 Matematik",
        "name_is": "Brot og tugt fyrir 8. bekk Matematik",
        "learning_objectives": "Master conversions between fractions, decimals, and percentages, and solve related problems to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_AlgebraicExpressions",
        "name_en": "Algebraic Expressions for Year 8 Mathematics",
        "name_sv": "Algebraiska Uttryck för Årskurs 8 Matematik",
        "name_is": "Algebrutrykk fyrir 8. bekk Matematik",
        "learning_objectives": "Manipulate algebraic expressions and solve more complex equations to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_GeometricShapes",
        "name_en": "Geometric Shapes for Year 8 Mathematics",
        "name_sv": "Geometriska Former för Årskurs 8 Matematik",
        "name_is": "Geometrískar myndir fyrir 8. bekk Matematik",
        "learning_objectives": "Study properties of geometric shapes, including area and volume calculations to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_Measurement",
        "name_en": "Measurement for Year 8 Mathematics",
        "name_sv": "Mätning för Årskurs 8 Matematik",
        "name_is": "Mæling fyrir 8. bekk Matematik",
        "learning_objectives": "Conduct measurements in various contexts and apply conversion of measurement units to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_Statistics",
        "name_en": "Statistical Concepts for Year 8 Mathematics",
        "name_sv": "Statistiska Begrepp för Årskurs 8 Matematik",
        "name_is": "Statísk hugtök fyrir 8. bekk Matematik",
        "learning_objectives": "Collect, analyze, and interpret statistical data to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_Probability",
        "name_en": "Probability for Year 8 Mathematics",
        "name_sv": "Sannolikhet för Årskurs 8 Matematik",
        "name_is": "Sannleikur fyrir 8. bekk Matematik",
        "learning_objectives": "Calculate and understand basic probabilities in different contexts to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Math_Ratios",
        "name_en": "Problems with Ratios for Year 8 Mathematics",
        "name_sv": "Problem med Förhållande för Årskurs 8 Matematik",
        "name_is": "Vandamál með hlutföll fyrir 8. bekk Matematik",
        "learning_objectives": "Explore ratios and proportions, solving related problems effectively to meet the requirements for passing Year 8 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Physics_ForceMotion",
        "name_en": "Force and Motion for Year 8 Physics",
        "name_sv": "Kraft och Rörelse för Årskurs 8 Fysik",
        "name_is": "Kraft og hreyfing fyrir 8. bekk eðlisfræði",
        "learning_objectives": "Deepen understanding of force, motion, and Newton's laws to meet the requirements for passing Year 8 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Physics_EnergyWork",
        "name_en": "Energy and Work for Year 8 Physics",
        "name_sv": "Energi och Arbete för Årskurs 8 Fysik",
        "name_is": "Orka og vinna fyrir 8. bekk eðlisfræði",
        "learning_objectives": "Evaluate different forms of energy and their transformations to meet the requirements for passing Year 8 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Physics_HeatTemperature",
        "name_en": "Heat and Temperature for Year 8 Physics",
        "name_sv": "Värme och Temperatur för Årskurs 8 Fysik",
        "name_is": "Hiti og hita fyrir 8. bekk eðlisfræði",
        "learning_objectives": "Investigate heat transfer and thermodynamic principles to meet the requirements for passing Year 8 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Chemistry_MatterChanges",
        "name_en": "Matter and Changes for Year 8 Chemistry",
        "name_sv": "Materia och Förändringar för Årskurs 8 Kemi",
        "name_is": "Efni og breytingar fyrir 8. bekk efnafræði",
        "learning_objectives": "Understand the changes of states of matter and apply them to practical scenarios to meet the requirements for passing Year 8 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Year 8 Chemistry",
        "name_sv": "Kemiska Reaktioner för Årskurs 8 Kemi",
        "name_is": "Kemískar efnaskipti fyrir 8. bekk efnafræði",
        "learning_objectives": "Explore and identify various types of chemical reactions and their implications in real life to meet the requirements for passing Year 8 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Chemistry_PeriodicTable",
        "name_en": "The Periodic Table for Year 8 Chemistry",
        "name_sv": "Periodiska Systemet för Årskurs 8 Kemi",
        "name_is": "Taflan fyrir 8. bekk efnafræði",
        "learning_objectives": "Analyze the periodic table and elemental properties to deepen knowledge in chemistry, fulfilling the requirements for passing Year 8 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Biology_CellBiology",
        "name_en": "Cell Biology for Year 8 Biology",
        "name_sv": "Cellbiologi för Årskurs 8 Biologi",
        "name_is": "Frumuöðlisfræði fyrir 8. bekk líffræði",
        "learning_objectives": "Expand knowledge on cellular structures, their functions, and dynamics within living organisms to meet the requirements for passing Year 8 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Biology_EcologyEnvironment",
        "name_en": "Ecology and Environment for Year 8 Biology",
        "name_sv": "Ekologi och Miljö för Årskurs 8 Biologi",
        "name_is": "Skoðun á umhverfi og umhverfi fyrir 8. bekk líffræði",
        "learning_objectives": "Investigate ecosystems and examine human impact on the environment to fulfill the requirements for passing Year 8 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Biology_GeneticsEvolution",
        "name_en": "Genetics and Evolution for Year 8 Biology",
        "name_sv": "Genetik och Evolution för Årskurs 8 Biologi",
        "name_is": "Genetík og þróun fyrir 8. bekk líffræði",
        "learning_objectives": "Explore principles of heredity, adaptation, and evolution to meet the requirements for passing Year 8 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_English_BasicConversation",
        "name_en": "Basic Conversation for Year 8 English",
        "name_sv": "Grundläggande Konversation för Årskurs 8 Engelska",
        "name_is": "Grunnleggjandi Sýning fyrir 8. bekk Enska",
        "learning_objectives": "Enhance conversational skills for everyday situations and practice listening in English to fulfill Year 8 English requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_English_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 8 English",
        "name_sv": "Grammatik och Ordförråd för Årskurs 8 Engelska",
        "name_is": "Grammatík og orðaforði fyrir 8. bekk Enska",
        "learning_objectives": "Focus on grammatical structures and vocabulary expansion for effective communication in English to meet Year 8 English requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_History_SwedenHistory",
        "name_en": "Sweden's History for Year 8 History",
        "name_sv": "Sverigeförbundets Historia för Årskurs 8 Historia",
        "name_is": "Saga Íslands fyrir 8. bekk sagnfræði",
        "learning_objectives": "Study key events in Sweden's history and their relevance to current society to fulfill Year 8 History requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_History_WorldHistory",
        "name_en": "World History for Year 8 History",
        "name_sv": "Världshistoria för Årskurs 8 Historia",
        "name_is": "Heimsaga fyrir 8. bekk sagnfræði",
        "learning_objectives": "Examine significant historical events globally and their impact on contemporary society to satisfy Year 8 History targets.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Geography_SwedenWorld",
        "name_en": "Sweden and the World for Year 8 Geography",
        "name_sv": "Sverige och Världen för Årskurs 8 Geografi",
        "name_is": "Svíþjóð og heimurinn fyrir 8. bekk landafræði",
        "learning_objectives": "Explore geographical features of Sweden and global connections to meet Year 8 Geography requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Geography_MapsScale",
        "name_en": "Maps and Scale for Year 8 Geography",
        "name_sv": "Kartor och Skala för Årskurs 8 Geografi",
        "name_is": "Kort og mælikvarði fyrir 8. bekk landafræði",
        "learning_objectives": "Read and understand maps, including scales, to navigate and analyze geographical data fulfilling Year 8 Geography requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Economics_Microeconomics",
        "name_en": "Microeconomics for Year 8 Economics",
        "name_sv": "Mikroekonomi för Årskurs 8 Ekonomi",
        "name_is": "Mikroekonomi fyrir 8. bekk efnahagsfræði",
        "learning_objectives": "Understand individual economic decision-making and market interactions to satisfy Year 8 Economics standards.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Economics_Macroeconomics",
        "name_en": "Macroeconomics for Year 8 Economics",
        "name_sv": "Makroekonomi för Årskurs 8 Ekonomi",
        "name_is": "Makróekonomi fyrir 8. bekk efnahagsfræði",
        "learning_objectives": "Examine broader economic features such as GDP, inflation, and unemployment to meet Year 8 Economics fundamentals.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_CS_ProgrammingBasics",
        "name_en": "Programming Basics for Year 8 Computer Science",
        "name_sv": "Programmeringsgrunder för Årskurs 8 Datorvetenskap",
        "name_is": "Grunnleggjandi forritun fyrir 8. bekk tölvunarfræði",
        "learning_objectives": "Deepen understanding in coding, algorithms, and their applications to fulfill Year 8 Computer Science criteria.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_CS_AlgorithmsDataStructures",
        "name_en": "Algorithms and Data Structures for Year 8 Computer Science",
        "name_sv": "Algoritmer och Datastrukturer för Årskurs 8 Datorvetenskap",
        "name_is": "Algrímur og gögnaskipan fyrir 8. bekk tölvunarfræði",
        "learning_objectives": "Investigate fundamental algorithms and data structures for solving problems effectively to accomplish Year 8 Computer Science standards.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Psychology_IntroductionPsychology",
        "name_en": "Introduction to Psychology for Year 8 Psychology",
        "name_sv": "Introduktion till Psykologi för Årskurs 8 Psykologi",
        "name_is": "Inngangur að sálfræði fyrir 8. bekk sálfræði",
        "learning_objectives": "Gain insight into psychological concepts and basic behavioral patterns to secure passing criteria for Year 8 Psychology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Psychology_HumanDevelopment",
        "name_en": "Human Development for Year 8 Psychology",
        "name_sv": "Människans Utveckling för Årskurs 8 Psykologi",
        "name_is": "Mannlegra þróun fyrir 8. bekk sálfræði",
        "learning_objectives": "Examines developmental theories and stages of human growth to fulfill Year 8 Psychology development goals.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Sociology_BasicConcepts",
        "name_en": "Basic Sociological Concepts for Year 8 Sociology",
        "name_sv": "Grundläggande Sociologiska Begrepp för Årskurs 8 Sociologi",
        "name_is": "Grunnleggjandi félagsleg hugtök fyrir 8. bekk félagsfræði",
        "learning_objectives": "Understand key sociological theories and frameworks to meet Year 8 Sociology requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Sociology_FamilyConcepts",
        "name_en": "Family Concepts for Year 8 Sociology",
        "name_sv": "Familjebegrepp för Årskurs 8 Sociologi",
        "name_is": "Fjölskylduhugtök fyrir 8. bekk félagsfræði",
        "learning_objectives": "Explore different family structures and their societal roles to fulfill Year 8 Sociology standards.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_ArtDesign_ArtForms",
        "name_en": "Art Forms for Year 8 Art & Design",
        "name_sv": "Konstformer för Årskurs 8 Konst och Design",
        "name_is": "Listafyrir 8. bekk List og hönnun",
        "learning_objectives": "Study various forms of art and their meanings in different cultures to meet Year 8 Art & Design requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_ArtDesign_DesignProcess",
        "name_en": "Design Process for Year 8 Art & Design",
        "name_sv": "Designprocessen för Årskurs 8 Konst och Design",
        "name_is": "Hönnunaraðferð fyrir 8. bekk List og hönnun",
        "learning_objectives": "Explore the design process from ideation to realization in artistic contexts to accomplish Year 8 Art & Design targets.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Music_MusicalElements",
        "name_en": "Musical Elements for Year 8 Music",
        "name_sv": "Musikaliska Element för Årskurs 8 Musik",
        "name_is": "Tónlistarleg einkenni fyrir 8. bekk tónlist",
        "learning_objectives": "Study the basics of music theory, including rhythm, notation, and harmony to meet Year 8 Music requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Music_MusicProduction",
        "name_en": "Music Production for Year 8 Music",
        "name_sv": "Musikproduktion för Årskurs 8 Musik",
        "name_is": "Tónlistagerð fyrir 8. bekk tónlist",
        "learning_objectives": "Develop skills in music production tools and techniques to create original music, fulfilling Year 8 Music standards.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_PE_MovementHealth",
        "name_en": "Movement and Health for Year 8 Physical Education",
        "name_sv": "Rörelse och Hälsa för Årskurs 8 Gymnastik",
        "name_is": "Hreyfing og heilsa fyrir 8. bekk íþróttir",
        "learning_objectives": "Explore the significance of physical activity for health and well-being and develop skills in various physical activities to fulfill Year 8 Physical Education requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_PE_SportTechnique",
        "name_en": "Sports and Techniques for Year 8 Physical Education",
        "name_sv": "Idrott och Teknik för Årskurs 8 Gymnastik",
        "name_is": "Íþróttir og aðferðir fyrir 8. bekk íþróttir",
        "learning_objectives": "Focus on developing sport-specific skills and techniques to meet Year 8 Physical Education criteria.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_NumberOperations",
        "name_en": "Number Operations for Year 9 Mathematics",
        "name_sv": "Taloperationer för Årskurs 9 Matematik",
        "name_is": "Talaniðir fyrir 9. bekk Matematik",
        "learning_objectives": "Master arithmetic operations including rational numbers to address the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_FractionsDecimalsPercentages",
        "name_en": "Fractions, Decimals, and Percentages for Year 9 Mathematics",
        "name_sv": "Bråk, Decimaler och Procent för Årskurs 9 Matematik",
        "name_is": "Brot, tugt og prósentur fyrir 9. bekk Matematik",
        "learning_objectives": "Convert between and solve problems involving fractions, decimals, and percentages to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_AlgebraicExpressions",
        "name_en": "Algebraic Expressions for Year 9 Mathematics",
        "name_sv": "Algebraiska Uttryck för Årskurs 9 Matematik",
        "name_is": "Algebrutrykk fyrir 9. bekk Matematik",
        "learning_objectives": "Manipulate and solve algebraic expressions and equations to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_GeometricShapes",
        "name_en": "Geometric Shapes for Year 9 Mathematics",
        "name_sv": "Geometriska Former för Årskurs 9 Matematik",
        "name_is": "Geometrískar myndir fyrir 9. bekk Matematik",
        "learning_objectives": "Identify, describe, and analyze properties of geometric shapes and their relationships to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_Measurement",
        "name_en": "Measurement for Year 9 Mathematics",
        "name_sv": "Mätning för Årskurs 9 Matematik",
        "name_is": "Mæling fyrir 9. bekk Matematik",
        "learning_objectives": "Apply concepts of measurement in problem-solving across different contexts to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_Statistics",
        "name_en": "Statistical Concepts for Year 9 Mathematics",
        "name_sv": "Statistiska Begrepp för Årskurs 9 Matematik",
        "name_is": "Statísk hugtök fyrir 9. bekk Matematik",
        "learning_objectives": "Analyze and interpret data to draw conclusions and make predictions, meeting the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_Probability",
        "name_en": "Probability for Year 9 Mathematics",
        "name_sv": "Sannolikhet för Årskurs 9 Matematik",
        "name_is": "Sannleikur fyrir 9. bekk Matematik",
        "learning_objectives": "Understand probability theory and calculate probabilities in various scenarios to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Math_Ratios",
        "name_en": "Problems with Ratios for Year 9 Mathematics",
        "name_sv": "Problem med Förhållande för Årskurs 9 Matematik",
        "name_is": "Vandamál með hlutföll fyrir 9. bekk Matematik",
        "learning_objectives": "Solve problems involving ratios and proportions in applied contexts to meet the requirements for passing Year 9 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Physics_ForceMotion",
        "name_en": "Force and Motion for Year 9 Physics",
        "name_sv": "Kraft och Rörelse för Årskurs 9 Fysik",
        "name_is": "Kraft og hreyfing fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Explore the laws of motion and the effects of forces on objects to meet the requirements for passing Year 9 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Physics_EnergyWork",
        "name_en": "Energy and Work for Year 9 Physics",
        "name_sv": "Energi och Arbete för Årskurs 9 Fysik",
        "name_is": "Orka og vinna fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Understand energy sources and the concept of work done in physical systems to meet the requirements for passing Year 9 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Physics_HeatTemperature",
        "name_en": "Heat and Temperature for Year 9 Physics",
        "name_sv": "Värme och Temperatur för Årskurs 9 Fysik",
        "name_is": "Hiti og hita fyrir 9. bekk eðlisfræði",
        "learning_objectives": "Explore thermal phenomena and the transfer of heat in different materials to meet the requirements for passing Year 9 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Chemistry_MatterChanges",
        "name_en": "Matter and Changes for Year 9 Chemistry",
        "name_sv": "Materia och Förändringar för Årskurs 9 Kemi",
        "name_is": "Efni og breytingar fyrir 9. bekk efnafræði",
        "learning_objectives": "Study matter, its properties, and chemical changes to understand scientific principles in Year 9 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Year 9 Chemistry",
        "name_sv": "Kemiska Reaktioner för Årskurs 9 Kemi",
        "name_is": "Kemískar efnaskipti fyrir 9. bekk efnafræði",
        "learning_objectives": "Identify and describe different types of chemical reactions and their implications to meet the requirements for passing Year 9 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Chemistry_PeriodicTable",
        "name_en": "The Periodic Table for Year 9 Chemistry",
        "name_sv": "Periodiska Systemet för Årskurs 9 Kemi",
        "name_is": "Taflan fyrir 9. bekk efnafræði",
        "learning_objectives": "Understand the organization of the periodic table and the properties of different elements to meet the requirements for passing Year 9 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Biology_CellBiology",
        "name_en": "Cell Biology for Year 9 Biology",
        "name_sv": "Cellbiologi för Årskurs 9 Biologi",
        "name_is": "Frumuöðlisfræði fyrir 9. bekk líffræði",
        "learning_objectives": "Study the structure, function, and processes of cells in living organisms to meet the requirements for passing Year 9 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Biology_EcologyEnvironment",
        "name_en": "Ecology and Environment for Year 9 Biology",
        "name_sv": "Ekologi och Miljö för Årskurs 9 Biologi",
        "name_is": "Skoðun á umhverfi og umhverfi fyrir 9. bekk líffræði",
        "learning_objectives": "Understand ecosystems' interrelationships and environmental challenges to meet the requirements for passing Year 9 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Biology_GeneticsEvolution",
        "name_en": "Genetics and Evolution for Year 9 Biology",
        "name_sv": "Genetik och Evolution för Årskurs 9 Biologi",
        "name_is": "Genetík og þróun fyrir 9. bekk líffræði",
        "learning_objectives": "Examine the principles of inheritance, evolution, and biodiversity to meet the requirements for passing Year 9 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_English_BasicConversation",
        "name_en": "Basic Conversation for Year 9 English",
        "name_sv": "Grundläggande Konversation för Årskurs 9 Engelska",
        "name_is": "Grunnleggjandi Sýning fyrir 9. bekk Enska",
        "learning_objectives": "Develop conversational skills for various scenarios in English to meet the requirements for passing Year 9 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_English_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 9 English",
        "name_sv": "Grammatik och Ordförråd för Årskurs 9 Engelska",
        "name_is": "Grammatík og orðaforði fyrir 9. bekk Enska",
        "learning_objectives": "Expand grammar and vocabulary knowledge for effective communication in English to meet the requirements for passing Year 9 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_History_SwedenHistory",
        "name_en": "Sweden's History for Year 9 History",
        "name_sv": "Sverigeförbundets Historia för Årskurs 9 Historia",
        "name_is": "Saga Íslands fyrir 9. bekk sagnfræði",
        "learning_objectives": "Explore significant historical events in Sweden and their societal impact to meet the requirements for passing Year 9 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_History_WorldHistory",
        "name_en": "World History for Year 9 History",
        "name_sv": "Världshistoria för Årskurs 9 Historia",
        "name_is": "Heimsaga fyrir 9. bekk sagnfræði",
        "learning_objectives": "Understand global historical events and trends and their effects on current societies to meet the requirements for passing Year 9 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Geography_SwedenWorld",
        "name_en": "Sweden and the World for Year 9 Geography",
        "name_sv": "Sverige och Världen för Årskurs 9 Geografi",
        "name_is": "Svíþjóð og heimurinn fyrir 9. bekk landafræði",
        "learning_objectives": "Understand geographical features of Sweden and their relation to global geography to meet the requirements for passing Year 9 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Geography_MapsScale",
        "name_en": "Maps and Scale for Year 9 Geography",
        "name_sv": "Kartor och Skala för Årskurs 9 Geografi",
        "name_is": "Kort og mælikvarði fyrir 9. bekk landafræði",
        "learning_objectives": "Navigate using maps and scales in various contexts to understand geographical concepts for passing Year 9 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Economics_Microeconomics",
        "name_en": "Microeconomics for Year 9 Economics",
        "name_sv": "Mikroekonomi för Årskurs 9 Ekonomi",
        "name_is": "Mikroekonomi fyrir 9. bekk efnahagsfræði",
        "learning_objectives": "Understand microeconomic principles and their application to individual choices and market interactions for passing Year 9 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Economics_Macroeconomics",
        "name_en": "Macroeconomics for Year 9 Economics",
        "name_sv": "Makroekonomi för Årskurs 9 Ekonomi",
        "name_is": "Makróekonomi fyrir 9. bekk efnahagsfræði",
        "learning_objectives": "Explore broader economic concepts like inflation, unemployment, and growth to fulfill the requirements for passing Year 9 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_CS_ProgrammingBasics",
        "name_en": "Programming Basics for Year 9 Computer Science",
        "name_sv": "Programmeringsgrunder för Årskurs 9 Datorvetenskap",
        "name_is": "Grunnleggjandi forritun fyrir 9. bekk tölvunarfræði",
        "learning_objectives": "Develop foundational programming skills, including coding and algorithms, to meet the requirements for passing Year 9 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_CS_AlgorithmsDataStructures",
        "name_en": "Algorithms and Data Structures for Year 9 Computer Science",
        "name_sv": "Algoritmer och Datastrukturer för Årskurs 9 Datorvetenskap",
        "name_is": "Algrímur og gögnaskipan fyrir 9. bekk tölvunarfræði",
        "learning_objectives": "Explore basic algorithms and data structures for effective problem-solving to meet Year 9 Computer Science standards.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Psychology_IntroductionPsychology",
        "name_en": "Introduction to Psychology for Year 9 Psychology",
        "name_sv": "Introduktion till Psykologi för Årskurs 9 Psykologi",
        "name_is": "Inngangur að sálfræði fyrir 9. bekk sálfræði",
        "learning_objectives": "Explore foundational concepts of psychology and human behavior to meet the requirements for passing Year 9 Psychology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Psychology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Psychology_HumanDevelopment",
        "name_en": "Human Development for Year 9 Psychology",
        "name_sv": "Människans Utveckling för Årskurs 9 Psykologi",
        "name_is": "Mannlegra þróun fyrir 9. bekk sálfræði",
        "learning_objectives": "Explore human development stages and their psychological implications to meet the requirements for passing Year 9 Psychology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.psychology",
            "interests.psychology",
            "generalInterests.psychology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Sociology_BasicConcepts",
        "name_en": "Basic Sociological Concepts for Year 9 Sociology",
        "name_sv": "Grundläggande Sociologiska Begrepp för Årskurs 9 Sociologi",
        "name_is": "Grunnleggjandi félagsleg hugtök fyrir 9. bekk félagsfræði",
        "learning_objectives": "Grasp foundational sociological theories to meet Year 9 Sociology requirements.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Sociology",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Sociology_FamilyConcepts",
        "name_en": "Family Concepts for Year 9 Sociology",
        "name_sv": "Familjebegrepp för Årskurs 9 Sociologi",
        "name_is": "Fjölskylduhugtök fyrir 9. bekk félagsfræði",
        "learning_objectives": "Investigate varied family structures in society for Year 9 Sociology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.sociology",
            "interests.sociology",
            "generalInterests.sociology",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Law",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Law_BasicConcepts",
        "name_en": "Basic Concepts of Law for Year 9 Law",
        "name_sv": "Grundläggande Juridiska Begrepp för Årskurs 9 Juridik",
        "name_is": "Grunnleggjandi lögfræðihugtök fyrir 9. bekk lögfræði",
        "learning_objectives": "Familiarize with legal systems and the importance of laws.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.law",
            "interests.law",
            "generalInterests.law",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_French_BasicConversation",
        "name_en": "Basic Conversation for Year 9 French",
        "name_sv": "Grundläggande Konversation för Årskurs 9 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir 9. bekk Franska",
        "learning_objectives": "Enhance conversational skills in French for daily interactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 9 French",
        "name_sv": "Grammatik och Ordförråd för Årskurs 9 Franska",
        "name_is": "Grammatík og orðaforði fyrir 9. bekk Franska",
        "learning_objectives": "Expand French vocabulary and grammar understanding.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Year 9 Spanish",
        "name_sv": "Grundläggande Konversation för Årskurs 9 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir 9. bekk Spænsku",
        "learning_objectives": "Develop conversational skills in Spanish for everyday situations.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 9 Spanish",
        "name_sv": "Grammatik och Ordförråd för Årskurs 9 Spanska",
        "name_is": "Grammatík og orðaforði fyrir 9. bekk Spænsku",
        "learning_objectives": "Expand Spanish vocabulary and grammar for effective communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_German_BasicConversation",
        "name_en": "Basic Conversation for Year 9 German",
        "name_sv": "Grundläggande Konversation för Årskurs 9 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir 9. bekk Þýsku",
        "learning_objectives": "Develop conversational skills in German for everyday interactions.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 9 German",
        "name_sv": "Grammatik och Ordförråd för Årskurs 9 Tyska",
        "name_is": "Grammatík og orðaforði fyrir 9. bekk Þýsku",
        "learning_objectives": "Expand grammar and vocabulary knowledge for effective communication in German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_French_BasicConversation",
        "name_en": "Basic Conversation for Year 7 French",
        "name_sv": "Grundläggande Konversation för Årskurs 7 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir 7. bekk Franska",
        "learning_objectives": "Develop basic conversational skills in French for everyday situations to meet the requirements for passing Year 7 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 7 French",
        "name_sv": "Grammatik och Ordförråd för Årskurs 7 Franska",
        "name_is": "Grammatík og orðaforði fyrir 7. bekk Franska",
        "learning_objectives": "Expand grammar and vocabulary knowledge in French to enhance communication skills for Year 7 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Year 7 Spanish",
        "name_sv": "Grundläggande Konversation för Årskurs 7 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir 7. bekk Spænsku",
        "learning_objectives": "Develop basic conversational skills in Spanish for everyday situations to meet the requirements for passing Year 7 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 7 Spanish",
        "name_sv": "Grammatik och Ordförråd för Årskurs 7 Spanska",
        "name_is": "Grammatík og orðaforði fyrir 7. bekk Spænsku",
        "learning_objectives": "Expand grammar and vocabulary knowledge in Spanish to enhance communication skills for Year 7 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_German_BasicConversation",
        "name_en": "Basic Conversation for Year 7 German",
        "name_sv": "Grundläggande Konversation för Årskurs 7 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir 7. bekk Þýsku",
        "learning_objectives": "Develop basic conversational skills in German for everyday situations to meet the requirements for passing Year 7 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 7",
        "id": "topic_Swedish_Y7_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 7 German",
        "name_sv": "Grammatik och Ordförråd för Årskurs 7 Tyska",
        "name_is": "Grammatík og orðaforði fyrir 7. bekk Þýsku",
        "learning_objectives": "Expand grammar and vocabulary knowledge in German to enhance communication skills for Year 7 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year7.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_French_BasicConversation",
        "name_en": "Basic Conversation for Year 8 French",
        "name_sv": "Grundläggande Konversation för Årskurs 8 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir 8. bekk Franska",
        "learning_objectives": "Enhance conversational skills in French for everyday situations to meet the requirements for passing Year 8 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 8 French",
        "name_sv": "Grammatik och Ordförråd för Årskurs 8 Franska",
        "name_is": "Grammatík og orðaforði fyrir 8. bekk Franska",
        "learning_objectives": "Expand grammar and vocabulary knowledge in French to enhance communication skills for Year 8 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Year 8 Spanish",
        "name_sv": "Grundläggande Konversation för Årskurs 8 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir 8. bekk Spænsku",
        "learning_objectives": "Enhance conversational skills in Spanish for everyday situations to meet the requirements for passing Year 8 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 8 Spanish",
        "name_sv": "Grammatik och Ordförråd för Årskurs 8 Spanska",
        "name_is": "Grammatík og orðaforði fyrir 8. bekk Spænsku",
        "learning_objectives": "Expand grammar and vocabulary knowledge in Spanish to enhance communication skills for Year 8 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_German_BasicConversation",
        "name_en": "Basic Conversation for Year 8 German",
        "name_sv": "Grundläggande Konversation för Årskurs 8 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir 8. bekk Þýsku",
        "learning_objectives": "Enhance conversational skills in German for everyday situations to meet the requirements for passing Year 8 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Year 8",
        "id": "topic_Swedish_Y8_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Year 8 German",
        "name_sv": "Grammatik och Ordförråd för Årskurs 8 Tyska",
        "name_is": "Grammatík og orðaforði fyrir 8. bekk Þýsku",
        "learning_objectives": "Expand grammar and vocabulary knowledge in German to enhance communication skills for Year 8 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year8.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_French_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 1 French",
        "name_sv": "Grundläggande Konversation för Gymnasiet 1 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 1 Franska",
        "learning_objectives": "Develop advanced conversational skills in French for various contexts to meet the requirements for passing Gymnasium 1 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 1 French",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 1 Franska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 1 Franska",
        "learning_objectives": "Enhance grammar and vocabulary knowledge for effective communication in diverse situations in French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 1 Spanish",
        "name_sv": "Grundläggande Konversation för Gymnasiet 1 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 1 Spænsku",
        "learning_objectives": "Develop advanced conversational skills in Spanish for various contexts to meet the requirements for passing Gymnasium 1 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 1 Spanish",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 1 Spanska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 1 Spænsku",
        "learning_objectives": "Enhance grammar and vocabulary knowledge for effective communication in diverse situations in Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_German_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 1 German",
        "name_sv": "Grundläggande Konversation för Gymnasiet 1 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 1 Þýsku",
        "learning_objectives": "Develop advanced conversational skills in German for various contexts to meet the requirements for passing Gymnasium 1 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 1",
        "id": "topic_Swedish_Gymnasium1_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 1 German",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 1 Tyska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 1 Þýsku",
        "learning_objectives": "Enhance grammar and vocabulary knowledge for effective communication in diverse situations in German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs1.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_French_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 2 French",
        "name_sv": "Grundläggande Konversation för Gymnasiet 2 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 2 Franska",
        "learning_objectives": "Further develop conversational skills in French for more complex interactions to meet the requirements for passing Gymnasium 2 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 2 French",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 2 Franska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 2 Franska",
        "learning_objectives": "Enhance grammar and vocabulary skills for advanced French comprehension and communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 2 Spanish",
        "name_sv": "Grundläggande Konversation för Gymnasiet 2 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 2 Spænsku",
        "learning_objectives": "Further develop conversational skills in Spanish for more complex interactions to meet the requirements for passing Gymnasium 2 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 2 Spanish",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 2 Spanska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 2 Spænsku",
        "learning_objectives": "Enhance grammar and vocabulary skills for advanced Spanish comprehension and communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_German_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 2 German",
        "name_sv": "Grundläggande Konversation för Gymnasiet 2 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 2 Þýsku",
        "learning_objectives": "Further develop conversational skills in German for more complex interactions to meet the requirements for passing Gymnasium 2 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 2",
        "id": "topic_Swedish_Gymnasium2_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 2 German",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 2 Tyska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 2 Þýsku",
        "learning_objectives": "Enhance grammar and vocabulary skills for advanced German comprehension and communication.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs2.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_French_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 3 French",
        "name_sv": "Grundläggande Konversation för Gymnasiet 3 Franska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 3 Franska",
        "learning_objectives": "Master conversational skills in French for sophisticated discussions and presentations to meet the requirements for passing Gymnasium 3 French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "French",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_French_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 3 French",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 3 Franska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 3 Franska",
        "learning_objectives": "Achieve proficiency in grammar and vocabulary for nuanced and advanced communication in French.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.french",
            "interests.french",
            "generalInterests.french",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_Spanish_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 3 Spanish",
        "name_sv": "Grundläggande Konversation för Gymnasiet 3 Spanska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 3 Spænsku",
        "learning_objectives": "Master conversational skills in Spanish for sophisticated discussions and presentations to meet the requirements for passing Gymnasium 3 Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Spanish",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_Spanish_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 3 Spanish",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 3 Spanska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 3 Spænsku",
        "learning_objectives": "Achieve proficiency in grammar and vocabulary for nuanced and advanced communication in Spanish.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.spanish",
            "interests.spanish",
            "generalInterests.spanish",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_German_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium 3 German",
        "name_sv": "Grundläggande Konversation för Gymnasiet 3 Tyska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet 3 Þýsku",
        "learning_objectives": "Master conversational skills in German for sophisticated discussions and presentations to meet the requirements for passing Gymnasium 3 German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "German",
        "subject": "Gymnasium 3",
        "id": "topic_Swedish_Gymnasium3_German_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium 3 German",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet 3 Tyska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet 3 Þýsku",
        "learning_objectives": "Achieve proficiency in grammar and vocabulary for nuanced and advanced communication in German.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.gymnasium.kurs3.schoolSubjects.german",
            "interests.german",
            "generalInterests.german",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Music_MusicalElements",
        "name_en": "Musical Elements for Year 9 Music",
        "name_sv": "Musikaliska Element för Årskurs 9 Musik",
        "name_is": "Tónlistarleg einkenni fyrir 9. bekk tónlist",
        "learning_objectives": "Explore different musical elements and their application in performance and composition to meet the requirements for passing Year 9 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_Music_MusicProduction",
        "name_en": "Music Production for Year 9 Music",
        "name_sv": "Musikproduktion för Årskurs 9 Musik",
        "name_is": "Tónlistagerð fyrir 9. bekk tónlist",
        "learning_objectives": "Understand music production techniques and software applications for creating and producing music to meet the requirements for passing Year 9 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_ArtDesign_ArtForms",
        "name_en": "Art Forms for Year 9 Art & Design",
        "name_sv": "Konstformer för Årskurs 9 Konst och Design",
        "name_is": "Listafyrir 9. bekk List og hönnun",
        "learning_objectives": "Explore various art forms and their cultural significance to meet the requirements for passing Year 9 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_ArtDesign_DesignProcess",
        "name_en": "Design Process for Year 9 Art & Design",
        "name_sv": "Designprocessen för Årskurs 9 Konst och Design",
        "name_is": "Hönnunaraðferð fyrir 9. bekk List og hönnun",
        "learning_objectives": "Understand the design process from concept to creation and develop skills in design thinking to meet the requirements for passing Year 9 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_PE_MovementHealth",
        "name_en": "Movement and Health for Year 9 Physical Education",
        "name_sv": "Rörelse och Hälsa för Årskurs 9 Gymnastik",
        "name_is": "Hreyfing og heilsa fyrir 9. bekk íþróttir",
        "learning_objectives": "Understand the significance of physical activity for health and well-being and develop skills in various physical activities to meet the requirements for passing Year 9 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Year 9",
        "id": "topic_Swedish_Y9_PE_SportTechnique",
        "name_en": "Sports and Techniques for Year 9 Physical Education",
        "name_sv": "Idrott och Teknik för Årskurs 9 Gymnastik",
        "name_is": "Íþróttir og aðferðir fyrir 9. bekk íþróttir",
        "learning_objectives": "Develop sport-specific skills and techniques to meet the requirements for passing Year 9 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.year9.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_NumberOperations",
        "name_en": "Number Operations for Gymnasium Year 1 Mathematics",
        "name_sv": "Taloperationer för Gymnasiet år 1 Matematik",
        "name_is": "Talaniðir fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Deepen the understanding of number operations including advanced arithmetic calculations to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_FractionsDecimals",
        "name_en": "Fractions and Decimals for Gymnasium Year 1 Mathematics",
        "name_sv": "Bråk och Decimaler för Gymnasiet år 1 Matematik",
        "name_is": "Brot og tugt fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Master the concepts of fractions, decimals, and their conversions in practical contexts to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_Algebra",
        "name_en": "Algebra for Gymnasium Year 1 Mathematics",
        "name_sv": "Algebra för Gymnasiet år 1 Matematik",
        "name_is": "Algebra fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Develop skills in solving algebraic equations and working with inequalities to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_Geometry",
        "name_en": "Geometry for Gymnasium Year 1 Mathematics",
        "name_sv": "Geometri för Gymnasiet år 1 Matematik",
        "name_is": "Geometri fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Understand geometric figures and their properties, including theorems relevant to shapes to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_Statistics",
        "name_en": "Statistics for Gymnasium Year 1 Mathematics",
        "name_sv": "Statistik för Gymnasiet år 1 Matematik",
        "name_is": "Statistik fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Analyze data, construct and interpret statistical measures for effective decision making to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Math_Probability",
        "name_en": "Probability for Gymnasium Year 1 Mathematics",
        "name_sv": "Sannolikhet för Gymnasiet år 1 Matematik",
        "name_is": "Sannleikur fyrir Gymnasiet í 1. bekk Matematik",
        "learning_objectives": "Understand concepts of probability and apply them in various real-world situations to meet the requirements for passing Gymnasium Year 1 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Physics_Mechanics",
        "name_en": "Mechanics for Gymnasium Year 1 Physics",
        "name_sv": "Kraft och Rörelse för Gymnasiet år 1 Fysik",
        "name_is": "Kraft og hreyfing fyrir Gymnasiet í 1. bekk eðlisfræði",
        "learning_objectives": "Explore the principles of mechanics and apply them to real-life scenarios in physics to meet the requirements for passing Gymnasium Year 1 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Physics_Energy",
        "name_en": "Energy for Gymnasium Year 1 Physics",
        "name_sv": "Energi och Arbete för Gymnasiet år 1 Fysik",
        "name_is": "Orka og vinna fyrir Gymnasiet í 1. bekk eðlisfræði",
        "learning_objectives": "Understand different forms of energy, conservation laws, and their applications to meet the requirements for passing Gymnasium Year 1 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Physics_Thermodynamics",
        "name_en": "Thermodynamics for Gymnasium Year 1 Physics",
        "name_sv": "Termodynamik för Gymnasiet år 1 Fysik",
        "name_is": "Termófræði fyrir Gymnasiet í 1. bekk eðlisfræði",
        "learning_objectives": "Explore the laws of thermodynamics and their applications in physical systems to meet the requirements for passing Gymnasium Year 1 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Chemistry_Matter",
        "name_en": "Matter for Gymnasium Year 1 Chemistry",
        "name_sv": "Materia och Förändringar för Gymnasiet år 1 Kemi",
        "name_is": "Efni og breytingar fyrir Gymnasiet í 1. bekk efnafræði",
        "learning_objectives": "Understand the various states of matter and changes between them to meet the requirements for passing Gymnasium Year 1 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Chemistry_ChemicalReactions",
        "name_en": "Chemical Reactions for Gymnasium Year 1 Chemistry",
        "name_sv": "Kemiska Reaktioner för Gymnasiet år 1 Kemi",
        "name_is": "Kemískar efnaskipti fyrir Gymnasiet í 1. bekk efnafræði",
        "learning_objectives": "Analyze basic chemical reactions and understand their products to meet the requirements for passing Gymnasium Year 1 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Chemistry_PeriodicTable",
        "name_en": "The Periodic Table for Gymnasium Year 1 Chemistry",
        "name_sv": "Periodiska Systemet för Gymnasiet år 1 Kemi",
        "name_is": "Taflan fyrir Gymnasiet í 1. bekk efnafræði",
        "learning_objectives": "Familiarize with the periodic table and the properties of elements to meet the requirements for passing Gymnasium Year 1 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Biology_CellBiology",
        "name_en": "Cell Biology for Gymnasium Year 1 Biology",
        "name_sv": "Cellbiologi för Gymnasiet år 1 Biologi",
        "name_is": "Frumuöðlisfræði fyrir Gymnasiet í 1. bekk líffræði",
        "learning_objectives": "Examine the structure and function of cells and their roles in life processes to meet the requirements for passing Gymnasium Year 1 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Biology_Ecology",
        "name_en": "Ecology for Gymnasium Year 1 Biology",
        "name_sv": "Ekologi för Gymnasiet år 1 Biologi",
        "name_is": "Ekólógía fyrir Gymnasiet í 1. bekk líffræði",
        "learning_objectives": "Explore ecosystems, organisms' interactions, and human impact on the environment to meet the requirements for passing Gymnasium Year 1 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Biology_Genetics",
        "name_en": "Genetics for Gymnasium Year 1 Biology",
        "name_sv": "Genetik för Gymnasiet år 1 Biologi",
        "name_is": "Genetik fyrir Gymnasiet í 1. bekk líffræði",
        "learning_objectives": "Understand basic principles of genetics and inheritance and their implications for living organisms to meet the requirements for passing Gymnasium Year 1 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_English_BasicConversation",
        "name_en": "Basic Conversation for Gymnasium Year 1 English",
        "name_sv": "Grundläggande Konversation för Gymnasiet år 1 Engelska",
        "name_is": "Grunnleggjandi Sýning fyrir Gymnasiet í 1. bekk Enska",
        "learning_objectives": "Practice conversational skills in various contexts to improve fluency and meet the requirements for passing Gymnasium Year 1 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_English_GrammarVocabulary",
        "name_en": "Grammar and Vocabulary for Gymnasium Year 1 English",
        "name_sv": "Grammatik och Ordförråd för Gymnasiet år 1 Engelska",
        "name_is": "Grammatík og orðaforði fyrir Gymnasiet í 1. bekk Enska",
        "learning_objectives": "Expand vocabulary and understand grammar rules to improve written and spoken communication skills in English to meet the requirements for passing Gymnasium Year 1 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_History_SwedenHistory",
        "name_en": "Sweden's History for Gymnasium Year 1 History",
        "name_sv": "Sverigeförbundets Historia för Gymnasiet år 1 Historia",
        "name_is": "Saga Íslands fyrir Gymnasiet í 1. bekk sagnfræði",
        "learning_objectives": "Analyze significant events in Swedish history and their effects on present society to meet the requirements for passing Gymnasium Year 1 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_History_WorldHistory",
        "name_en": "World History for Gymnasium Year 1 History",
        "name_sv": "Världshistoria för Gymnasiet år 1 Historia",
        "name_is": "Heimsaga fyrir Gymnasiet í 1. bekk sagnfræði",
        "learning_objectives": "Study key world historical events and their ongoing implications to meet the requirements for passing Gymnasium Year 1 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Geography_SwedenWorld",
        "name_en": "Sweden and the World for Gymnasium Year 1 Geography",
        "name_sv": "Sverige och Världen för Gymnasiet år 1 Geografi",
        "name_is": "Svíþjóð og heimurinn fyrir Gymnasiet í 1. bekk landafræði",
        "learning_objectives": "Explore Sweden's geographical features in relation to global geography and societal implications to meet the requirements for passing Gymnasium Year 1 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Geography_MapsScale",
        "name_en": "Maps and Scale for Gymnasium Year 1 Geography",
        "name_sv": "Kartor och Skala för Gymnasiet år 1 Geografi",
        "name_is": "Kort og mælikvarði fyrir Gymnasiet í 1. bekk landafræði",
        "learning_objectives": "Develop skills in reading maps and understanding scales for geographical navigation to meet the requirements for passing Gymnasium Year 1 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Economics_Microeconomics",
        "name_en": "Microeconomics for Gymnasium Year 1 Economics",
        "name_sv": "Mikroekonomi för Gymnasiet år 1 Ekonomi",
        "name_is": "Mikroekonomi fyrir Gymnasiet í 1. bekk efnahagsfræði",
        "learning_objectives": "Understand the basic principles of microeconomics and their effects on choice and market dynamics to meet the requirements for passing Gymnasium Year 1 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Economics_Macroeconomics",
        "name_en": "Macroeconomics for Gymnasium Year 1 Economics",
        "name_sv": "Makroekonomi för Gymnasiet år 1 Ekonomi",
        "name_is": "Makróekonomi fyrir Gymnasiet í 1. bekk efnahagsfræði",
        "learning_objectives": "Explore economic indicators, monetary systems, and their implications for society to meet the requirements for passing Gymnasium Year 1 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_CS_ProgrammingBasics",
        "name_en": "Programming Basics for Gymnasium Year 1 Computer Science",
        "name_sv": "Programmeringsgrunder för Gymnasiet år 1 Datorvetenskap",
        "name_is": "Grunnleggjandi forritun fyrir Gymnasiet í 1. bekk tölvunarfræði",
        "learning_objectives": "Learn fundamental programming concepts, including syntax, logic, and data types to meet the requirements for passing Gymnasium Year 1 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_CS_AlgorithmsDataStructures",
        "name_en": "Algorithms and Data Structures for Gymnasium Year 1 Computer Science",
        "name_sv": "Algoritmer och Datastrukturer för Gymnasiet år 1 Datorvetenskap",
        "name_is": "Algrímur og gögnaskipan fyrir Gymnasiet í 1. bekk tölvunarfræði",
        "learning_objectives": "Understand basic algorithms, data structures, and their applications in solving problems to meet the requirements for passing Gymnasium Year 1 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_ArtDesign_ArtForms",
        "name_en": "Art Forms for Gymnasium Year 1 Art & Design",
        "name_sv": "Konstformer för Gymnasiet år 1 Konst och Design",
        "name_is": "Listafyrir Gymnasiet í 1. bekk List og hönnun",
        "learning_objectives": "Explore various art forms and their historical significance to meet the requirements for passing Gymnasium Year 1 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_ArtDesign_DesignProcess",
        "name_en": "Design Process for Gymnasium Year 1 Art & Design",
        "name_sv": "Designprocessen för Gymnasiet år 1 Konst och Design",
        "name_is": "Hönnunaraðferð fyrir Gymnasiet í 1. bekk List og hönnun",
        "learning_objectives": "Develop understanding of the design process and its application in creating artworks to meet the requirements for passing Gymnasium Year 1 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Music_MusicalElements",
        "name_en": "Musical Elements for Gymnasium Year 1 Music",
        "name_sv": "Musikaliska Element för Gymnasiet år 1 Musik",
        "name_is": "Tónlistarleg einkenni fyrir Gymnasiet í 1. bekk tónlist",
        "learning_objectives": "Explore music theory, including rhythm, melody, and harmony to meet the requirements for passing Gymnasium Year 1 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_Music_MusicProduction",
        "name_en": "Music Production for Gymnasium Year 1 Music",
        "name_sv": "Musikproduktion för Gymnasiet år 1 Musik",
        "name_is": "Tónlistagerð fyrir Gymnasiet í 1. bekk tónlist",
        "learning_objectives": "Understand the essentials of music production technology and techniques to create a variety of musical compositions to meet the requirements for passing Gymnasium Year 1 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_PE_MovementHealth",
        "name_en": "Movement and Health for Gymnasium Year 1 Physical Education",
        "name_sv": "Rörelse och Hälsa för Gymnasiet år 1 Gymnastik",
        "name_is": "Hreyfing og heilsa fyrir Gymnasiet í 1. bekk íþróttir",
        "learning_objectives": "Understand the importance of physical activity and health in relation to wellness and personal fitness to meet the requirements for passing Gymnasium Year 1 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Gymnasium Year 1",
        "id": "topic_Swedish_Gymnasium1_PE_SportTechnique",
        "name_en": "Sports Techniques for Gymnasium Year 1 Physical Education",
        "name_sv": "Idrott och Teknik för Gymnasiet år 1 Gymnastik",
        "name_is": "Íþróttir og aðferðir fyrir Gymnasiet í 1. bekk íþróttir",
        "learning_objectives": "Develop sport-specific skills and techniques for various physical activities to meet the requirements for passing Gymnasium Year 1 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium1.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Math_AdvancedAlgebra",
        "name_en": "Advanced Algebra for Gymnasium Year 2 Mathematics",
        "name_sv": "Avancerad Algebra för Gymnasiet år 2 Matematik",
        "name_is": "Avanceruð Algebra fyrir Gymnasiet í 2. bekk Matematik",
        "learning_objectives": "Explore complex algebraic concepts and functions to prepare for higher mathematics and meet the requirements for passing Gymnasium Year 2 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Math_StatisticsProbability",
        "name_en": "Statistics and Probability for Gymnasium Year 2 Mathematics",
        "name_sv": "Statistik och Sannolikhet för Gymnasiet år 2 Matematik",
        "name_is": "Statistik og Sannleikur fyrir Gymnasiet í 2. bekk Matematik",
        "learning_objectives": "Analyze data sets and calculate probabilities to facilitate decision making in various contexts to meet the requirements for passing Gymnasium Year 2 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Math_GeometryTrigonometry",
        "name_en": "Geometry and Trigonometry for Gymnasium Year 2 Mathematics",
        "name_sv": "Geometri och Trigonometri för Gymnasiet år 2 Matematik",
        "name_is": "Geometri og Trigonometri fyrir Gymnasiet í 2. bekk Matematik",
        "learning_objectives": "Study geometric principles and trigonometric functions to solve complex problems and meet the requirements for passing Gymnasium Year 2 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Physics_Electromagnetism",
        "name_en": "Electromagnetism for Gymnasium Year 2 Physics",
        "name_sv": "Elektromagnetism för Gymnasiet år 2 Fysik",
        "name_is": "Rafsegul fyrir Gymnasiet í 2. bekk eðlisfræði",
        "learning_objectives": "Understand electromagnetism, its principles, and applications in technology to meet the requirements for passing Gymnasium Year 2 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Physics_WavesOptics",
        "name_en": "Waves and Optics for Gymnasium Year 2 Physics",
        "name_sv": "Vågor och Optik för Gymnasiet år 2 Fysik",
        "name_is": "Rofur og Optík fyrir Gymnasiet í 2. bekk eðlisfræði",
        "learning_objectives": "Explore properties of waves, sound, and light, and their behavior through different mediums to meet the requirements for passing Gymnasium Year 2 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Chemistry_OrganicChemistry",
        "name_en": "Organic Chemistry for Gymnasium Year 2 Chemistry",
        "name_sv": "Organisk Kemi för Gymnasiet år 2 Kemi",
        "name_is": "Organisk Kemi fyrir Gymnasiet í 2. bekk efnafræði",
        "learning_objectives": "Understand the structure and function of organic compounds and their reactions to meet the requirements for passing Gymnasium Year 2 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Chemistry_InorganicChemistry",
        "name_en": "Inorganic Chemistry for Gymnasium Year 2 Chemistry",
        "name_sv": "Oorganisk Kemi för Gymnasiet år 2 Kemi",
        "name_is": "Óorganisk Kemi fyrir Gymnasiet í 2. bekk efnafræði",
        "learning_objectives": "Examine inorganic substances and their properties to meet the requirements for passing Gymnasium Year 2 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Biology_Ecology",
        "name_en": "Ecology for Gymnasium Year 2 Biology",
        "name_sv": "Ekologi för Gymnasiet år 2 Biologi",
        "name_is": "Ekólógía fyrir Gymnasiet í 2. bekk líffræði",
        "learning_objectives": "Analyze ecosystems and understand ecological relationships to meet the requirements for passing Gymnasium Year 2 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Biology_Genetics",
        "name_en": "Genetics for Gymnasium Year 2 Biology",
        "name_sv": "Genetik för Gymnasiet år 2 Biologi",
        "name_is": "Genetik fyrir Gymnasiet í 2. bekk líffræði",
        "learning_objectives": "Understand the principles of heredity and their implications for living organisms to meet the requirements for passing Gymnasium Year 2 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_English_AdvancedCommunication",
        "name_en": "Advanced Communication for Gymnasium Year 2 English",
        "name_sv": "Avancerad Kommunikation för Gymnasiet år 2 Engelska",
        "name_is": "Avanceruð samskipti fyrir Gymnasiet í 2. bekk Enska",
        "learning_objectives": "Enhance communication skills through discussions, presentations, and writing in English to meet the requirements for passing Gymnasium Year 2 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "English",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_English_LiteratureAnalysis",
        "name_en": "Literature Analysis for Gymnasium Year 2 English",
        "name_sv": "Litteraturanaly för Gymnasiet år 2 Engelska",
        "name_is": "Litteraturanaly fyrir Gymnasiet í 2. bekk Enska",
        "learning_objectives": "Develop critical thinking by analyzing texts and understanding various literary elements to meet the requirements for passing Gymnasium Year 2 English.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.english",
            "interests.english",
            "generalInterests.english",
            "generalInterests.languages"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_History_SwedishHistory",
        "name_en": "Swedish History for Gymnasium Year 2 History",
        "name_sv": "Sverigeförbundets Historia för Gymnasiet år 2 Historia",
        "name_is": "Saga Íslands fyrir Gymnasiet í 2. bekk sagnfræði",
        "learning_objectives": "Explore the key events in Swedish history and their impact on contemporary society to meet the requirements for passing Gymnasium Year 2 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "History",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_History_GlobalHistory",
        "name_en": "Global History for Gymnasium Year 2 History",
        "name_sv": "Världshistoria för Gymnasiet år 2 Historia",
        "name_is": "Heimsaga fyrir Gymnasiet í 2. bekk sagnfræði",
        "learning_objectives": "Analyze significant global historical events and their implications to facilitate understanding of current affairs to meet the requirements for passing Gymnasium Year 2 History.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.history",
            "interests.history",
            "generalInterests.history",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Geography_HumanGeography",
        "name_en": "Human Geography for Gymnasium Year 2 Geography",
        "name_sv": "Mänsklig Geografi för Gymnasiet år 2 Geografi",
        "name_is": "Mannkynslandafræði fyrir Gymnasiet í 2. bekk landafræði",
        "learning_objectives": "Study population distribution, migration patterns, and urban development to understand human-environment interactions to meet the requirements for passing Gymnasium Year 2 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Geography",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Geography_EnvironmentalGeography",
        "name_en": "Environmental Geography for Gymnasium Year 2 Geography",
        "name_sv": "Miljögeografi för Gymnasiet år 2 Geografi",
        "name_is": "Umhverfislandafræði fyrir Gymnasiet í 2. bekk landafræði",
        "learning_objectives": "Understand environmental issues, conservation efforts, and sustainability practices to meet the requirements for passing Gymnasium Year 2 Geography.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.geography",
            "interests.geography",
            "generalInterests.geography",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Economics_EconomicTheory",
        "name_en": "Economic Theory for Gymnasium Year 2 Economics",
        "name_sv": "Ekonomisk Teori för Gymnasiet år 2 Ekonomi",
        "name_is": "Efnahagskenning fyrir Gymnasiet í 2. bekk efnahagsfræði",
        "learning_objectives": "Explore economic theories, market structures, and their implications on society to meet the requirements for passing Gymnasium Year 2 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Economics",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Economics_InternationalEconomics",
        "name_en": "International Economics for Gymnasium Year 2 Economics",
        "name_sv": "Internationell Ekonomi för Gymnasiet år 2 Ekonomi",
        "name_is": "Alþjóðleg efnahagsfræði fyrir Gymnasiet í 2. bekk efnahagsfræði",
        "learning_objectives": "Understand global economic systems, trade theories, and their impacts on nations to meet the requirements for passing Gymnasium Year 2 Economics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.economics",
            "interests.economics",
            "generalInterests.economics",
            "generalInterests.socialStudies"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_CS_AdvancedProgramming",
        "name_en": "Advanced Programming for Gymnasium Year 2 Computer Science",
        "name_sv": "Avancerad Programmering för Gymnasiet år 2 Datorvetenskap",
        "name_is": "Avanceruð forritun fyrir Gymnasiet í 2. bekk tölvunarfræði",
        "learning_objectives": "Develop advanced programming skills and software development techniques to meet the requirements for passing Gymnasium Year 2 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Computer Science",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_CS_WebDevelopment",
        "name_en": "Web Development for Gymnasium Year 2 Computer Science",
        "name_sv": "Webbutveckling för Gymnasiet år 2 Datorvetenskap",
        "name_is": "Vefþróun fyrir Gymnasiet í 2. bekk tölvunarfræði",
        "learning_objectives": "Understand web development principles and design interactive websites to meet the requirements for passing Gymnasium Year 2 Computer Science.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.cs",
            "interests.cs",
            "generalInterests.cs",
            "generalInterests.technology"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_ArtDesign_VisualArts",
        "name_en": "Visual Arts for Gymnasium Year 2 Art & Design",
        "name_sv": "Visuell Konst för Gymnasiet år 2 Konst och Design",
        "name_is": "Sjónlist fyrir Gymnasiet í 2. bekk List og hönnun",
        "learning_objectives": "Create and analyze various visual arts to express ideas and emotions to meet the requirements for passing Gymnasium Year 2 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Art & Design",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_ArtDesign_FashionDesign",
        "name_en": "Fashion Design for Gymnasium Year 2 Art & Design",
        "name_sv": "Modedesign för Gymnasiet år 2 Konst och Design",
        "name_is": "Fatahönnun fyrir Gymnasiet í 2. bekk List og hönnun",
        "learning_objectives": "Explore the process of designing clothing and accessories, focusing on creativity and innovation to meet the requirements for passing Gymnasium Year 2 Art & Design.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.art_design",
            "interests.art_design",
            "generalInterests.art_design",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Music_AdvancedTheory",
        "name_en": "Advanced Music Theory for Gymnasium Year 2 Music",
        "name_sv": "Avancerad Musikteori för Gymnasiet år 2 Musik",
        "name_is": "Avanceruð Tónlistartheory fyrir Gymnasiet í 2. bekk tónlist",
        "learning_objectives": "Analyze musical structures and progressions to enhance compositional skills to meet the requirements for passing Gymnasium Year 2 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Music",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_Music_MusicProduction",
        "name_en": "Music Production for Gymnasium Year 2 Music",
        "name_sv": "Musikproduktion för Gymnasiet år 2 Musik",
        "name_is": "Tónlistagerð fyrir Gymnasiet í 2. bekk tónlist",
        "learning_objectives": "Understand music production including techniques and technology to meet the requirements for passing Gymnasium Year 2 Music.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.music",
            "interests.music",
            "generalInterests.music",
            "generalInterests.creative"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_PE_Fitness",
        "name_en": "Fitness for Gymnasium Year 2 Physical Education",
        "name_sv": "Fitness för Gymnasiet år 2 Gymnastik",
        "name_is": "Fitness fyrir Gymnasiet í 2. bekk íþróttir",
        "learning_objectives": "Enhance physical fitness and understand its importance for health and well-being to meet the requirements for passing Gymnasium Year 2 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physical Education",
        "subject": "Gymnasium Year 2",
        "id": "topic_Swedish_Gymnasium2_PE_SportsSkills",
        "name_en": "Sports Skills for Gymnasium Year 2 Physical Education",
        "name_sv": "Idrott och Teknik för Gymnasiet år 2 Gymnastik",
        "name_is": "Íþróttir og aðferðir fyrir Gymnasiet í 2. bekk íþróttir",
        "learning_objectives": "Develop advanced skills in various sports and physical activities to meet the requirements for passing Gymnasium Year 2 Physical Education.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium2.schoolSubjects.pe",
            "interests.pe",
            "generalInterests.pe",
            "generalInterests.health"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Math_AdvancedAlgebra",
        "name_en": "Advanced Algebra for Gymnasium Year 3 Mathematics",
        "name_sv": "Avancerad Algebra för Gymnasiet år 3 Matematik",
        "name_is": "Avanceruð Algebra fyrir Gymnasiet í 3. bekk Matematik",
        "learning_objectives": "Understand and apply advanced algebraic techniques including functions, sequences, and series to fulfill the requirements for passing Gymnasium Year 3 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Math_StatisticsProbability",
        "name_en": "Statistics and Probability for Gymnasium Year 3 Mathematics",
        "name_sv": "Statistik och Sannolikhet för Gymnasiet år 3 Matematik",
        "name_is": "Statistik og Sannleikur fyrir Gymnasiet í 3. bekk Matematik",
        "learning_objectives": "Analyze data and understand concepts of statistical inference and probability to meet the requirements for passing Gymnasium Year 3 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Mathematics",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Math_Geometry",
        "name_en": "Geometry for Gymnasium Year 3 Mathematics",
        "name_sv": "Geometri för Gymnasiet år 3 Matematik",
        "name_is": "Geometri fyrir Gymnasiet í 3. bekk Matematik",
        "learning_objectives": "Understand the properties of shapes and theorems related to geometry to fulfill the requirements for passing Gymnasium Year 3 Mathematics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.mathematics",
            "interests.mathematics",
            "generalInterests.mathematics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Physics_Electromagnetism",
        "name_en": "Electromagnetism for Gymnasium Year 3 Physics",
        "name_sv": "Elektromagnetism för Gymnasiet år 3 Fysik",
        "name_is": "Rafsegul fyrir Gymnasiet í 3. bekk eðlisfræði",
        "learning_objectives": "Explore the principles of electromagnetism and their applications in technology to meet the requirements for passing Gymnasium Year 3 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Physics",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Physics_WavesAndOptics",
        "name_en": "Waves and Optics for Gymnasium Year 3 Physics",
        "name_sv": "Vågor och Optik för Gymnasiet år 3 Fysik",
        "name_is": "Rofur og Optik fyrir Gymnasiet í 3. bekk eðlisfræði",
        "learning_objectives": "Understand wave phenomena such as sound and light, including optical systems, to meet the requirements for passing Gymnasium Year 3 Physics.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.physics",
            "interests.physics",
            "generalInterests.physics",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Chemistry_OrganicChemistry",
        "name_en": "Organic Chemistry for Gymnasium Year 3 Chemistry",
        "name_sv": "Organisk Kemi för Gymnasiet år 3 Kemi",
        "name_is": "Organisk Kemi fyrir Gymnasiet í 3. bekk efnafræði",
        "learning_objectives": "Study the structure, properties, and reactions of organic compounds to meet the requirements for passing Gymnasium Year 3 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Chemistry",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Chemistry_PhysicalChemistry",
        "name_en": "Physical Chemistry for Gymnasium Year 3 Chemistry",
        "name_sv": "Fysikalisk Kemi för Gymnasiet år 3 Kemi",
        "name_is": "Fysikalisk Kemi fyrir Gymnasiet í 3. bekk efnafræði",
        "learning_objectives": "Explore the principles of thermodynamics and kinetics in chemical processes to meet the requirements for passing Gymnasium Year 3 Chemistry.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.chemistry",
            "interests.chemistry",
            "generalInterests.chemistry",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Biology_Ecology",
        "name_en": "Ecology for Gymnasium Year 3 Biology",
        "name_sv": "Ekologi för Gymnasiet år 3 Biologi",
        "name_is": "Ekólógía fyrir Gymnasiet í 3. bekk líffræði",
        "learning_objectives": "Understand ecosystems, biodiversity, and human impact on the environment to meet the requirements for passing Gymnasium Year 3 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    },
    {
        "curriculum": "Swedish",
        "field": "Biology",
        "subject": "Gymnasium Year 3",
        "id": "topic_Swedish_Gymnasium3_Biology_GeneticsEvolution",
        "name_en": "Genetics and Evolution for Gymnasium Year 3 Biology",
        "name_sv": "Genetik och Evolution för Gymnasiet år 3 Biologi",
        "name_is": "Genetík og þróun fyrir Gymnasiet í 3. bekk líffræði",
        "learning_objectives": "Explore genetic principles, molecular biology, and evolutionary theory to meet the requirements for passing Gymnasium Year 3 Biology.",
        "when_to_add": [
            "userType.school_student.schoolCurriculum.swedish.schoolYear.gymnasium3.schoolSubjects.biology",
            "interests.biology",
            "generalInterests.biology",
            "generalInterests.science"
        ]
    }
];