import React, { useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
} from '@mui/material';
import TopicSelectorModal from '../new-learning-path/content';
import { fetchUserSelectedTopics } from '@studygenius/shared/Requests';
import { MUIStyles } from '@studygenius/shared/Variables';
import { OutlinedPrimaryOpacityButton } from '../../Components';
import colors from '@studygenius/shared/Colors';
import { getSelectedTopicObjects } from '../../getSelectedTopics';
import {useTranslations} from 'next-intl';

const SelectedTopics = ({
  selectedTopics,
  isLoadingTopics,
  setSelectedTopics,
  onTopicsChanged,
  userId,
  locale = 'en',
  modalOpen,
  setModalOpen,
  handleModalOpen, // Passed from Page component
}) => {
  const t = useTranslations('HomePage');


  const handleReselect = () => {
    setModalOpen(true);
    if (handleModalOpen) {
      handleModalOpen();
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  console.log('selectedTopics', selectedTopics)

  const selectedTopicObjects = getSelectedTopicObjects(selectedTopics);
  console.log('selectedTopicObjects', selectedTopicObjects)


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '280px',
        minWidth: '240px',
        padding: '24px',
        marginTop: '20px'
      }}
    >
      <Typography sx={{ ...MUIStyles.latoBoldMediumParagraph }}>
        {t('YOUR_TOPICS')}
      </Typography>
      {isLoadingTopics ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            width: '100%',
            margin: '0px 0',
          }}
        >
          {selectedTopics.length > 0 ? (
            <List sx={{height: '300px', overflowY: 'scroll'}}>
              {selectedTopicObjects.map((topic, index) => (
                <ListItem key={index} sx={{padding: 0}}>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: { ...MUIStyles.paragraph, flexGrow: 1, opacity: 0.7 },
                    }}
                    primary={locale === 'en' ? `${topic?.name_en}` :  `${topic?.name_sv}`}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <p>{t('NO_TOPICS')}</p>
          )}
        </div>
      )}
      <OutlinedPrimaryOpacityButton onClick={handleReselect} sx={{width: 'fit-content'}}>
        
        {t('RESELECT')}
      </OutlinedPrimaryOpacityButton>
      {modalOpen && (
        <TopicSelectorModal
          open={modalOpen}
          onClose={handleModalClose}
          onTopicsChanged={onTopicsChanged}
          userId={userId}
          selectedTopics={selectedTopics}
          
        />
      )}
    </div>
  );
};

export default SelectedTopics;