'use client';

import React, { useState, useEffect, lazy } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
  Box,
} from '@mui/material';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { MUIStyles } from '@studygenius/shared/Variables';
import { PrimaryButton, FailureButton } from './Components';
import Lottie from 'lottie-react';
import successAnimation from './happy.json';
import failureAnimation from './sad.json';
import loadingAnimation from './loading.json';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import ReactMarkdown from 'react-markdown';
import { escapeDollarSignsOutsideMath } from './Components';
import { useTranslations } from 'next-intl';

const LazyLottieComponent = lazy(() => import('lottie-react'));

const InFeedQuiz = ({
  selectedTopics,
  searchQuery,
  initialQuizData,
  fetchNewQuizData,
  quizzesCompleted,
  updateQuizzesCompleted,
}) => {
  const [quizData, setQuizData] = useState(initialQuizData);
  const [loading, setLoading] = useState(!initialQuizData);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [isQuizPassed, setIsQuizPassed] = useState(null);

  const userId = useSelector(state => state.websocket.userProfile?.id);

  const t = useTranslations('Quiz')

  useEffect(() => {
    setQuizData(initialQuizData);
    if (initialQuizData) {
      setLoading(false);
    }
  }, [initialQuizData]);

  const handleOptionChange = (selectedOption) => {
    const currentQuestion = quizData?.questions[currentQuestionIndex];
    const isCorrect = selectedOption === currentQuestion.correctOption;

    setUserAnswers((prev) => ({
      ...prev,
      [currentQuestionIndex]: {
        selectedOption,
        isCorrect,
      },
    }));
  };

  const handleNextQuestion = () => {
    if (userAnswers[currentQuestionIndex]?.isCorrect) {
      if (currentQuestionIndex + 1 < quizData?.questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } 
     else {
        setIsQuizCompleted(true);
        setIsQuizPassed(true);

        // Update quizzesCompleted via the prop function
        const topic = quizData.topic;
        console.log('quizData is', quizData)
        updateQuizzesCompleted(quizData.topic?.id);
      }
    } else {
      setIsQuizCompleted(true);
      setIsQuizPassed(false);
    }
  };

  const handleRetry = async () => {
    setLoading(true);
    const newQuizData = await fetchNewQuizData();
    if (newQuizData) {
      setQuizData(newQuizData);
      setCurrentQuestionIndex(0);
      setUserAnswers({});
      setIsQuizCompleted(false);
      setIsQuizPassed(null);
    }
     setLoading(false);
  };

  const handleNextQuiz = async () => {
    setLoading(true);
    const newQuizData = await fetchNewQuizData();
    if (newQuizData) {
      setQuizData(newQuizData);
      setCurrentQuestionIndex(0);
      setUserAnswers({});
      setIsQuizCompleted(false);
      setIsQuizPassed(null);
    }
    setLoading(false);
  };

  const cleanQuestionText = (text) => {
    return text.replace(/^:\s*\d*[\.\)]?\s*/, '').replace(/[\n-]+$/, '');
  };
  
  const cleanOptionText = (text) => {
    return text.replace(/^[a-zA-Z]\)\s*/, '').replace(/[\n-]+$/, '');
  };

  const isLastQuestion = currentQuestionIndex + 1 === quizData?.questions.length;

  const RenderBox = ({
    animationData,
    headline,
    textColor,
    subtext,
    ButtonComponent,
    ButtonText,
  }) => {
    return (
      <>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LazyLottieComponent
            animationData={animationData}
            loop
            autoplay
            style={{
              height: '86px',
              width: '86px',
              padding: 0,
              marginBottom: '16px',
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            padding: '16px',
            borderRadius: '8px',
            textAlign: 'center',
          }}
        >
          <Typography sx={{ ...MUIStyles.nuitoBold, color: textColor }}>
            {headline}
          </Typography>
          <Typography sx={{ ...MUIStyles.mediumParagraph, marginTop: '8px' }}>
            {subtext}
          </Typography>
          <ButtonComponent
            onClick={
              isQuizPassed ? handleNextQuiz : handleRetry
            } // Adjust the button action
            sx={{ marginTop: '16px', padding: '10px', minHeight: '32px' }}
          >
            <Typography sx={{ ...MUIStyles.smallBold, color: '#fff' }}>
              {ButtonText}
            </Typography>
          </ButtonComponent>
        </Box>
      </>
    );
  };
  

  const RenderQuizCompleted = () => {
    
    const topic = quizData.topic?.id;
    console.log('topic is', topic)
    console.log('quizzesCompleted is', quizzesCompleted)
    const quizzesCompletedCount = quizzesCompleted[topic] || 0;

    if (isQuizPassed) {
      return (
        <RenderBox
          animationData={successAnimation}
          headline={t('CONGRATULATIONS')}
          textColor={'#25DF03'}
          subtext={`${t('YOU_COMPLETED')} ${quizzesCompletedCount} ${quizData?.topic.name_en} ${t('QUIZZES_CORRECTLY')}`}
          ButtonComponent={PrimaryButton}
          ButtonText={t('NEXT_QUIZ')}
        />
      );
    } else {
      return (
        <RenderBox
          animationData={failureAnimation}
          headline={t("BETTER_LUCK")}
          textColor={'#F3736C'}
          subtext={t('YOU_FAILED')}
          ButtonComponent={FailureButton}
          ButtonText={t('RETRY_QUIZ')}
        />
      );
    }
  };

  const RenderQuestion = () => {
    const currentQuestion = quizData?.questions[currentQuestionIndex];
    console.log('currentQuestion ', currentQuestion)
    const mathOptions = {
      singleDollarTextMath: false,
    };
    const answer = userAnswers[currentQuestionIndex];
    if (!currentQuestion) return null;

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', minHeight: '72px', alignItems: 'center' }}>
          <Typography sx={{ ...MUIStyles.latoBoldParagraph, color: '#fff' }} mb={1}>
          <ReactMarkdown   remarkPlugins={[[remarkMath]]}
  rehypePlugins={[rehypeKatex]}>
            {cleanQuestionText(currentQuestion.question)}
            </ReactMarkdown>
          </Typography>
          <Typography sx={{ ...MUIStyles.latoBoldParagraph, color: '#fff' }} mb={1}>
            {currentQuestionIndex + 1}/{quizData?.questions.length}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '8px',
          }}
        >
          {currentQuestion.options.map((option, index) => {
            const optionLetter = option.charAt(0);
            const isSelected = answer?.selectedOption === optionLetter;
            const isCorrect = currentQuestion.correctOption === optionLetter;
            const userAnswered = Boolean(answer);
            const userAnsweredIncorrectly = userAnswered && !answer.isCorrect;

            // Determine styles based on the current state
            let backgroundColor = '#fff';
            let color = '#000';
            let border = '1px solid #ccc';

            if (userAnswered) {
              if (isSelected) {
                if (isCorrect) {
                  backgroundColor = '#4caf50'; // Correct answer selected (green)
                  color = '#fff';
                } else {
                  backgroundColor = '#f44336'; // Incorrect answer selected (red)
                  color = '#fff';
                }
                
              } else if (userAnsweredIncorrectly && isCorrect) {
                backgroundColor = '#4caf50';
                color = '#fff';
              }
            }

            return (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleOptionChange(optionLetter)}
                disabled={userAnswered}
                sx={{
                  '&&': {
                    backgroundColor: backgroundColor,
                    color: color,
                    border: border,
                  },
                  '&:hover': {
                    backgroundColor: userAnswered ? backgroundColor : '#f0f0f0',
                  },
                  textTransform: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                  justifyContent: 'flex-start',
                  minHeight: '64px'
                }}
              >
                <Typography sx={{ ...MUIStyles.paragraph, textAlign: 'left' }}>
                <ReactMarkdown   remarkPlugins={[remarkMath]}
  rehypePlugins={[rehypeKatex]}>
                  {cleanOptionText(option)}
                  </ReactMarkdown>
                </Typography>
              </Button>
            );
          })}
        </Box>
        {answer ? (
          <PrimaryButton
            variant="contained"
            onClick={handleNextQuestion}
            disabled={!answer}
            sx={{
              marginTop: 1,
              minHeight: '32px',
              padding: '10px',
              paddingY: '8px',
              backgroundColor: '#ff9800',
              '&:hover': {
                backgroundColor: '#f57c00',
              },
              display: 'block',
              marginLeft: 'auto',
            }}
          >
            <Typography sx={{ ...MUIStyles.smallBold, color: '#fff' }}>
              {isLastQuestion ? t("VIEW_RESULTS") : t('NEXT_QUESTION')}
            </Typography>
          </PrimaryButton>
        ) : (
          <Box sx={{ height: '48px' }} />
        )}
      </>
    );
  };

  
  return (
    <Box
      sx={{
        backgroundImage: 'url(/quiz-background.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '8px',
        padding: '16px',
        minHeight: '340px',
        marginBottom: '16px'
      }}
    >
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '340px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LazyLottieComponent
            animationData={loadingAnimation}
            loop
            autoplay
            style={{ height: '100px', width: '100px' }}
          />
        </Box>
      ) : isQuizCompleted && quizData ? (
        <RenderQuizCompleted />
      ) : (
        <RenderQuestion />
      )}
    </Box>
  );
};

export default InFeedQuiz;