'use client';

import React, { useState } from 'react';
import AccountPopovers from './AccountPopovers';
import MyBackdrop from './MyBackdrop';
import CookieConsent from 'react-cookie-consent';
import colors from '@studygenius/shared/Colors';
import { Typography } from '@mui/material';
import { MUIStyles } from '@studygenius/shared/Variables';
import POPOVER_CONTENT from './[locale]/FullMenu'; // Ensure this is adjusted appropriately

export default function ClientProviders({ children }) {
  const [PopoverContent, setPopoverContent] = useState(POPOVER_CONTENT().FALSE);

  return (
    <>
      <AccountPopovers
        setPopoverContent={setPopoverContent}
        PopoverContent={PopoverContent}
      />
      <MyBackdrop open={!!PopoverContent} />
      <CookieConsent
        enableDeclineButton
        cookieName={'myCookie'}
        style={{ background: colors.lightGreen }}
        buttonStyle={{
          background: colors.main,
          ...MUIStyles.mediumParagraph,
          color: colors.white,
        }}
        declineButtonStyle={{
          background: colors.deleteRed,
          ...MUIStyles.mediumParagraph,
          color: colors.white,
        }}
        buttonText={'Accept cookies'}
        declineButtonText={'Decline'}
      >
        <Typography sx={{ ...MUIStyles.paragraph }}>
          This website uses cookies to enhance the user experience.{' '}
        </Typography>
      </CookieConsent>
      {/* Render children here to pass down setPopoverContent if needed */}
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, { setPopoverContent })
        )}
    </>
  );
}