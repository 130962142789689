'use client';

import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Container,
  Button
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { MUIStyles } from '@studygenius/shared/Variables';
import colors from '@studygenius/shared/Colors';
import InFeedQuiz from './InFeedQuiz';
import {
  fetchQuizzesCompleted,
  saveQuizzesCompletedToDatabase,
  searchQuizzes,
  fetchQuizQuestions,
  initialSearchQuizzes,
  fetchImages,
  generateNewImages
} from '@studygenius/shared/Requests';
import { getSelectedTopicObjects } from './getSelectedTopics';
import Link from 'next/link';
import remarkMath from 'remark-math';
import Head from 'next/head';
// import { VariableSizeList as List } from 'react-window';
import rehypeKatex from 'rehype-katex';
import { Virtuoso } from 'react-virtuoso';
import { useLocale } from 'next-intl';
import ArticleComponent from './ArticleComponent';

function shuffleArray(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

function isValidQuestion(question) {
  if (!question) return false;

  // Check that question has 'question' property, which is a non-empty string
  if (typeof question.question !== 'string' || question.question.trim() === '') {
    return false;
  }

  // Check that 'options' is an array of 4 non-empty strings
  if (!Array.isArray(question.options) || question.options.length !== 4) {
    return false;
  }
  for (let option of question.options) {
    if (typeof option !== 'string' || option.trim() === '') {
      return false;
    }
  }

  // Check that 'correctOption' is one of 'A', 'B', 'C', 'D'
  const validOptionLetters = ['A', 'B', 'C', 'D'];
  if (!question.correctOption || !validOptionLetters.includes(question.correctOption.toUpperCase())) {
    return false;
  }

  return true;
}

const getRandomQuestions = (questions, numQuestions) => {
    if (questions.length <= numQuestions) {
      return [...questions];
    }
    const shuffledQuestions = questions.sort(() => Math.random() - 0.5);
    return shuffledQuestions.slice(0, numQuestions);
  };

  const QuizPlaceholder = () => {
    return (
      <Box sx={{ height: '340px', backgroundColor: 'transparent', marginBottom: '16px' }}>
        {/* Optionally, add a loading indicator or skeleton */}
      </Box>
    );
  };

const fetchQuizzesForInFeed = async (numberOfQuizzesNeeded, searchQuery, languagePreference) => {
    try {
      // First, perform a search to get nbHits
      const initialResponse = await initialSearchQuizzes(searchQuery, 0, 1, languagePreference);
      console.log('initialResponse is', initialResponse)
      const nbHits = initialResponse[0].nbHits;
  
      console.log('nbHits', nbHits)
      if (nbHits > 0) {
        const maxAllowedHits = 1000; // Algolia's limit
        const cappedNbHits = Math.min(nbHits, maxAllowedHits);
  
        // Calculate total number of pages
        const hitsPerPage = 1;
        const totalPages = Math.ceil(cappedNbHits / hitsPerPage);
  
        const quizzes = [];
  
        // Generate unique random page numbers to avoid duplicates
        const randomPages = generateUniqueRandomNumbers(
          numberOfQuizzesNeeded,
          0,
          totalPages - 1
        );
  
        for (const page of randomPages) {
          const response = await searchQuizzes(searchQuery, page, hitsPerPage);
          if (response.quizzes && response.quizzes.length > 0) {
            const formattedQuiz = response.quizzes[0];
     
            // Validate questions
            const validQuestions = formattedQuiz.questions.filter(isValidQuestion);
            if (validQuestions.length === 0) {
              console.warn('No valid questions found in the selected quiz');
              continue; // Skip this quiz if no valid questions
            }
     
            // Adjust the number of questions if there are fewer valid questions than needed
            const numberOfQuestionsNeeded = 3; // You can adjust this as needed
            const questionsToSelect = Math.min(validQuestions.length, numberOfQuestionsNeeded);
     
            // Shuffle valid questions and select the required number
            const selectedQuestions = shuffleArray(validQuestions).slice(0, questionsToSelect);
            formattedQuiz.questions = selectedQuestions;
     
            // Map topic data
            formattedQuiz.topic = {
              id: formattedQuiz.topicId,
              curriculum: formattedQuiz.curriculum,
              field: formattedQuiz.field,
              subject: formattedQuiz.subject,
              name_en: formattedQuiz.topicName,
              name_sv: formattedQuiz.topicName, // Assuming the topic name is the same in both languages
            };
     
            // If needed, map aspect and language
            formattedQuiz.aspect = formattedQuiz.aspect;
            formattedQuiz.language = formattedQuiz.language;
     
            quizzes.push(formattedQuiz);
          }
        }
  
        return quizzes;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch in-feed quizzes:', error);
      return [];
    }
  };
  
  const generateUniqueRandomNumbers = (count, min, max) => {
    const numbers = new Set();
    while (numbers.size < count) {
      const num = Math.floor(Math.random() * (max - min + 1)) + min;
      numbers.add(num);
    }
    return Array.from(numbers);
  };

  const fetchQuizzesForInFeedTopics = async (numberOfQuizzesNeeded, selectedTopics, languagePreference = 'en') => {
    const quizzes = [];
    console.log('selected topics is', selectedTopics)
    const topicsLength = selectedTopics.length;
  

    for (let i = 0; i < numberOfQuizzesNeeded; i++) {
      const randomIndex = Math.floor(Math.random() * topicsLength);
      const topic = selectedTopics[randomIndex];
      console.log('inside fetchQuizzesForInFeedTopics')
      console.log('topic is ', topic)
  
      try {
        const quiz = await fetchQuizQuestions(topic, 3, languagePreference);
        quizzes.push({
          ...quiz,
          topic,
        });
      } catch (error) {
        console.error('Error fetching quiz for topic:', error);
      }
    }
  
    return quizzes;
  };
  

  const ArticleListContent = ({ articles, noLink = false, selectedTopics, searchQuery, forcedLanguage }) => {
    const [expandedIds, setExpandedIds] = useState([]);
    const dispatch = useDispatch()
    const [inFeedQuizzes, setInFeedQuizzes] = useState([]);
    // const [quizzesCompleted, setQuizzesCompleted] = useState({});
    const quizzesCompleted = useSelector((state) => state.websocket.quizzesCompleted);
    const [fieldImages, setFieldImages] = useState({});
    const [articlesWithImages, setArticlesWithImages] = useState([]);
    console.log('articles', articles)

    const selectedTopicsObjects = useMemo(() => {
      if (!selectedTopics || selectedTopics.length === 0) return [];
      if (typeof selectedTopics[0] === 'object') {
        // Already an array of topic objects
        return selectedTopics;
      } else {
        // Array of IDs; convert them to topic objects
        return getSelectedTopicObjects(selectedTopics);
      }
    }, [selectedTopics]);
  
    // Determine the language preference
    const locale = useLocale();
    const languagePreference = forcedLanguage ? forcedLanguage : locale;
  
    const userId = useSelector((state) => state.websocket.userProfile?.id);
  
    const toggleExpand = (id) => {
      setExpandedIds((prevState) =>
        prevState.includes(id)
          ? prevState.filter((eid) => eid !== id)
          : [...prevState, id]
      );
    };
  
    const updateQuizzesCompleted = (topic) => {
      console.log('topic to save', topic)
      const currentCount = quizzesCompleted[topic] || 0;
      console.log('currentCount', currentCount)
      const newCounts = {
        ...quizzesCompleted,
        [topic]: currentCount + 1,
      };
      console.log('newCounts', newCounts)
  
      // Save to Firestore
      saveQuizzesCompletedToDatabase(newCounts, userId);
  
      // Dispatch action to update Redux store
      dispatch({ type: 'SET_QUIZZES_COMPLETED', payload: newCounts });
    };
  
    // useEffect(() => {
    //   const getCompletedQuizzes = async () => {
    //     if (userId) {
    //       const completedQuizzes = await fetchQuizzesCompleted(userId);
    //       setQuizzesCompleted(completedQuizzes);
    //     }
    //   };
  
    //   getCompletedQuizzes();
    // }, [userId]);

    useEffect(() => {
      const getCompletedQuizzes = async () => {
        if (userId) {
          const completedQuizzes = await fetchQuizzesCompleted(userId);
          dispatch({ type: 'SET_QUIZZES_COMPLETED', payload: completedQuizzes });
        }
      };
  
      getCompletedQuizzes();
    }, [userId]);
  
    const fetchFieldImages = async () => {
      const images = await fetchImages(selectedTopicsObjects);
      console.log('images we got', images);
      setFieldImages(images);
    };

    const getShouldRefetch = (articles, articlesWithImages) => {

      console.log('articles[0]?.id', articles[0]?.id)
      console.log('articlesWithImages[0]?.id', articlesWithImages[0]?.id)
    
    return articlesWithImages.length && articles[0]?.id !== articlesWithImages[0]?.id
    }
  
    useEffect(() => {
      console.log('Processing articles with images');
      // Proceed only if both articles and fieldImages are available
      if (
        articles &&
        articles.length > 0 &&
        (fieldImages &&
        Object.keys(fieldImages).length > 0 || (!selectedTopics?.length))
      ) {
        console.log('Articles:', articles);
        console.log('Articles with images before processing:', articlesWithImages);
    
        let existingArticlesLength = 0;
        const isShouldRefetch = getShouldRefetch(articles, articlesWithImages);
    
        if (!isShouldRefetch) {
          existingArticlesLength = articlesWithImages.length;
        }
    
        const newArticles = articles.slice(existingArticlesLength);
        const processedNewArticles = newArticles.map((article) => {
          // Randomly decide if this article will have an image
          const hasImage = Math.random() < 0.33; // Approximately 1 in 3 articles
          let image = null;
          if (
            fieldImages &&
            Object.keys(fieldImages).length > 0 &&
            hasImage &&
            fieldImages[article.field] &&
            fieldImages[article.field].length > 0
          ) {
            // Pick a random image from fieldImages[article.field]
            const imagesForField = fieldImages[article.field];
            const randomIndex = Math.floor(Math.random() * imagesForField.length);
            image = imagesForField[randomIndex];
          }
          return { ...article, hasImage, image };
        });
    
        let updatedArticlesWithImages;
        if (!isShouldRefetch) {
          updatedArticlesWithImages = [
            ...(articlesWithImages || []),
            ...processedNewArticles,
          ];
        } else {
          updatedArticlesWithImages = [...processedNewArticles];
        }
    
        console.log('Updated articles with images:', updatedArticlesWithImages);
        setArticlesWithImages(updatedArticlesWithImages);
      }
    }, [articles, fieldImages]); 
  
    useEffect(() => {
      fetchFieldImages();
    }, [selectedTopicsObjects]);
  
    const fetchNewQuizDataForIndex = async (inFeedQuizIndex) => {
      console.log('inside fetchNewQuizDataForIndex');
      let newQuizData = null;
      if (searchQuery && searchQuery.trim() !== '') {
        const quizzes = await fetchQuizzesForInFeed(1, searchQuery, languagePreference);
        newQuizData = quizzes[0];
      } else if (selectedTopicsObjects && selectedTopicsObjects.length > 0) {
        const quizzes = await fetchQuizzesForInFeedTopics(
          1,
          selectedTopicsObjects,
          languagePreference
        );
        newQuizData = quizzes[0];
      }
  
      if (newQuizData && newQuizData.questions) {
        // Randomly select 3 questions for the new quiz
        newQuizData.questions = getRandomQuestions(newQuizData.questions, 3);
      }
  
      // Update the inFeedQuizzes state at the specific index
      setInFeedQuizzes((prevQuizzes) => {
        const newQuizzes = [...prevQuizzes];
        newQuizzes[inFeedQuizIndex] = newQuizData;
        return newQuizzes;
      });
  
      return newQuizData;
    };
  
    useEffect(() => {
      const fetchInFeedQuizzes = async () => {
        const isShouldRefetch = getShouldRefetch(articles, articlesWithImages)
        console.log('in fetchInFeedQuizzes')
        console.log('isShouldRefetch', isShouldRefetch)
        const numberOfQuizzesNeeded = Math.floor(articles.length / 3);
        const existingQuizzesLength = inFeedQuizzes.length || 0;
        let numberOfQuizzesToFetch = numberOfQuizzesNeeded
        
        if(!isShouldRefetch) {
          numberOfQuizzesToFetch = numberOfQuizzesNeeded - existingQuizzesLength
        }
        console.log('numberOfQuizzesToFetch', numberOfQuizzesToFetch)
        // numberOfQuizzesNeeded - (isShouldRefetch ? 0 : existingQuizzesLength);
  
        if (numberOfQuizzesToFetch > 0) {
          let newQuizzes = [];
          if (searchQuery && searchQuery.trim() !== '') {
            // Fetch quizzes based on search query
            const quizzes = await fetchQuizzesForInFeed(
              numberOfQuizzesToFetch,
              searchQuery,
              languagePreference
            );
            newQuizzes = quizzes;
          } else if (selectedTopics && selectedTopics.length > 0) {
            // Fetch quizzes based on selected topics
            const quizzes = await fetchQuizzesForInFeedTopics(
              numberOfQuizzesToFetch,
              selectedTopicsObjects,
              languagePreference
            );
            newQuizzes = quizzes;
          }

          let updatedInFeedQuizzes = [...newQuizzes]
          console.log('inFeedQuizzes', inFeedQuizzes)
          if(!isShouldRefetch) {
            updatedInFeedQuizzes = [...inFeedQuizzes, ...newQuizzes]
          }
          console.log('updatedInFeedQuizzes', updatedInFeedQuizzes)


          // const updatedInFeedQuizzes = isShouldRefetch ? [...newQuizzes] : [...inFeedQuizzes, ...newQuizzes];
          setInFeedQuizzes(updatedInFeedQuizzes);
        }
      };
  
      fetchInFeedQuizzes();
    }, [articles]);
  
    const markdownStyle = {
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '1.5',
      color: '#000',
    };
  
    const lineClampStyle = {
      display: '-webkit-box',
      WebkitLineClamp: 5, // Number of lines to display
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    };
  
    // const selectedTopicsObjects = getSelectedTopicObjects(selectedTopics);
 

    const combinedList = useMemo(() => {
      const list = [];
      let quizIndex = 0;
  
      articlesWithImages.forEach((article, index) => {
        list.push({ type: 'article', data: article });
  
        // Insert a quiz every 3 articles
        if ((index + 1) % 3 === 0 && inFeedQuizzes[quizIndex]) {
          list.push({ type: 'quiz', data: inFeedQuizzes[quizIndex], quizIndex });
          quizIndex++;
        }
      });
  
      return list;
    }, [articlesWithImages, inFeedQuizzes]);
  
    // Reference to the list for resetting sizes
    const listRef = useRef();

    return (
      <Box sx={{ width: { xs: '100%', md: '600px' }, backgroundColor: colors.newBackground }}>
        <Container sx={{ marginTop: '2rem', padding: 0 }}>
          <Virtuoso
            useWindowScroll
            data={combinedList}
            overscan={200} // Adjust as needed
            itemContent={(index, item) => {
              if (item.type === 'article') {
                return (
                  <ArticleComponent
                    article={item.data}
                    expandedIds={expandedIds}
                    toggleExpand={toggleExpand}
                    fieldImages={fieldImages}
                    languagePreference={languagePreference}
                    markdownStyle={markdownStyle}
                  />
                );
              } else if (item.type === 'quiz') {
                return (
                  <InFeedQuiz
                    fetchNewQuizData={() => fetchNewQuizDataForIndex(item.quizIndex)}
                    selectedTopics={selectedTopics}
                    initialQuizData={item.data}
                    searchQuery={searchQuery}
                    quizzesCompleted={quizzesCompleted}
                    updateQuizzesCompleted={updateQuizzesCompleted}
                  />
                );
              } else {
                // Render placeholder with fixed height
                return (
                  <QuizPlaceholder key={`quiz-placeholder-${item.quizIndex}`} />
                );
              }
              return null;
            }}
          />
        </Container>
      </Box>
    );
  };
  
  export default ArticleListContent;