import _defineProperty from "/vercel/path0/packages/webb-page/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import colors from './Colors';
export var isReactNative = true;
var isNodeEnvironment;
isNodeEnvironment = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
var isNextEnv = true && (window === null || window === void 0 ? void 0 : window.next);
var browser = true;
var window = true;
if (!isNodeEnvironment && !browser) {
  isReactNative = true;
} else {
  isReactNative = false;
}
export var WEB_LINKS = {
  CATEGORY_LIST: 'categorylist',
  CATEGORY_DETAIL: 'categorydetail',
  FREE_RESOURCES: 'freeresources',
  READ_FACT: 'readfact',
  USER_SCREEN: 'user-screen'
};
import { SharedReact as RNReact, dispatch as RNUseDispatch, selector as RNSelector } from '../expo-RN-app/LernaExports';
import { SharedReact as WebReact, dispatch as WebUseDispatch, selector as WebbSelector } from '../webb-page/LernaExports';
import { getStorage } from "firebase/storage";
import RNAuth from '../expo-RN-app/RNFirebase';
import { auth } from './firebaseSetup';
export var useDispatch = !isReactNative ? WebUseDispatch : RNUseDispatch;
export var useSelector = !isReactNative ? WebbSelector : RNSelector;
export var React = !isReactNative ? WebReact : RNReact;
export var authentication = function authentication() {
  return !isReactNative ? auth() : RNAuth;
};
export var environment = function environment() {
  return !isReactNative ? "production" : 'production';
};
var isProd = environment() === 'production';
export var socketBaseUrl = environment() === 'production' ? 'wss://studygenius-wss-backend.fly.dev/' : 'ws://192.168.0.29:4000/';
export var socketRequestUrl = environment() === 'production' ? 'studygenius-wss-backend.fly.dev/' : 'https://192.168.0.29:4000/';
export var ALGOLIA_APP_ID = environment() === 'production' ? '0S2C0OA33X' : '2WC0WPQ6UP';
export var ALGOLIA_SEARCH_KEY = environment() === 'production' ? 'fe2b424935ec7854a59aca2583a0746f' : 'df21780db6dd3d02310ce18ca3cbd193';
export var cloudFunctionBaseUrl = isProd ? 'https://us-central1-memory-athlete-fd645.cloudfunctions.net/' : 'https://us-central1-educ8r-dev.cloudfunctions.net/';
var STRIPE_KEYS = {
  LIVE: 'pk_live_Cla9tkjaxQsqlQPTeODEgUQJ',
  TEST: 'pk_test_AuhRbUAUrenQvbR33CNGJpYx'
};
var STRIPE_SUBSCRIPTIONS = {
  DEV: {
    STANDARD: {
      PRICE_ID: 'price_1OlvJELfq6ZROjZviHKy3Amz',
      PRODUCT_ID: 'prod_Pb7TrCrlRys1Ks',
      COINS: 40000
    },
    PREMIUM: {
      PRICE_ID: 'price_1OmHVpLfq6ZROjZvB9VoLcEo',
      PRODUCT_ID: 'prod_PbUU2WAwSDlunN',
      COINS: 1200
    },
    FREE: {
      //Note not the same as coins above, hence small letters

      coins: 2
    }
  },
  PROD: {
    STANDARD: {
      PRICE_ID: 'price_1Olv6YLfq6ZROjZvgtfhQ5vW',
      PRODUCT_ID: 'prod_Pb7L0YxIPh9GsD',
      COINS: 400
    },
    PREMIUM: {
      PRICE_ID: 'price_1Olv7BLfq6ZROjZv2hIKVLvU',
      PRODUCT_ID: 'prod_Pb7LUcKXuCzoPd',
      COINS: 1200
    },
    FREE: {
      //Note not the same as coins above, hence small letters
      coins: 2
    }
  }
};
export var subscriptionOptions = environment() === 'production' ? STRIPE_SUBSCRIPTIONS.PROD : STRIPE_SUBSCRIPTIONS.DEV;
var cc = function cc(src, text, id) {
  return {
    src: src,
    text: text,
    id: id
  };
};
var envScience = cc('envScience.png', 'Environmental Science', 'Sew1ykGhIpTgHeHUZ6UhxyDNs6o2');
var physics = cc('physics.png', 'Physics', 'Y0ExVbeqjmgSLd1vqcLkkYAJLz53');
var business = cc('business.png', 'Business Administration OR Management', 'kJfZurtDA7VSa7F7Iz4Sie8O45k1');
var chemistry = cc('chemistry.png', 'Chemistry', 'ZuJQiNB9l0hgaKzL3L6UI4L1YO12');
var computerScience = cc('cs.png', 'Computer Science OR Information Technology', 'inf65RBsFBdESUjqgkVPVtDVbbC3');
var economics = cc('economics.png', 'Economics', 'tW9RyDPJb9cJAOwlnsFwUKgIawk2');
var education = cc('education.png', 'Education and Teaching', 'gmeCZzQFvwQeWkfs6p4o6eG2fOu2');
var engineering = cc('engineering.png', 'Engineering', 'KdwPmwBy76N9DVU7RSOh3Mke39h2');
var english = cc('english.png', 'English Literature', '0cdKYFSQNBPI9WTHVWx08ADJvz72');
var finance = cc('finance.png', 'Finance OR Accounting', 'bTN9xFwCqoXqW3lYNPrcWxpxVK93');
var health = cc('health.png', 'Health', 'CxhFRMyZ6yeHGicbgKvWuiM1Nhm1');
var history = cc('history.png', 'History', 'xj1V8lOFLkd5vVAPvUMNRcKVtYA2');
var law = cc('law.png', 'Law OR Legal studies', 'QdSgBhelp1bc9ocY8fmhaHwL3qa2');
var mathematics = cc('math.png', 'Mathematics', 'Sqa0vuUMSOMexW3BE5qC9VfNh5Z2');
var medical = cc('medical.png', 'Medical studies', 'KR0b5AdwyBa9wgbept0JpSt8zQ12');
var political = cc('political.png', 'Political science OR International relations', 'WOWvD0RZOWUOvocT388UGUNEwfp1');
var psychology = cc('psychology.png', 'Psychology', '1hjqUIvl4sWEn8Inn5uaF2VpXcr2');
var sociology = cc('sociology.png', 'Sociology', 'MtnI6CKqUmbwkbOo2gG5Ifo8Hqh1');
var visual = cc('visual.png', 'Visual arts', 'xJQ9JkMjbGOeNIofBrpb0ZH78AB3');
var communication = cc('communication.png', 'Communication OR Media arts', '5izCdf4SD5ZG7eZUvOz4XR2wkRD3');
var biology = cc('biology.png', 'Biology', 'MGpCOm3xiwVYyfggYdIfhHv7Kya2');
var group = function group(id, title, courses, src) {
  return {
    id: id,
    title: title,
    courses: courses,
    src: src
  };
};
var prodGroups = [group(1, 'Natural Science', [envScience, physics, chemistry, biology], '/Natural-Science.jpg'), group(2, 'Engineering, Computer Science, Maths', [computerScience, engineering, mathematics], '/Engineering.jpg'), group(3, 'Business & Finance', [business, economics, finance], '/Business.jpg'), group(4, 'Communication, Education, Visual Arts', [communication, visual, education, english], '/Communication.jpg'), group(5, 'Health, Medicine, Psychology', [health, medical, psychology], '/Health.jpg'), group(6, 'Social Science, Law, History', [sociology, history, law, political], '/Social-Science.jpg')];
var devGroups = [group(1, 'Natural Science', [envScience, physics, chemistry, biology], '/Natural-Science.jpg'), group(2, 'Engineering, Computer Science, Maths', [computerScience, engineering, mathematics], '/Engineering.jpg'), group(3, 'Business & Finance', [business, economics, finance], '/Business.jpg'), group(4, 'Communication, Education, Visual Arts', [cc('lightbulb.png', 'Computer Science/Information Technology', '4oW8miC7RjQvBJcSiTAP7xQVY5Y2'), visual, education, english], '/Communication.jpg'), group(5, 'Health, Medicine, Psychology', [health, medical, psychology], '/Health.jpg'), group(6, 'Social Science, Law, History', [sociology, history, law, political], '/Social-Science.jpg')];
export var UniCourses = environment() === 'production' ? prodGroups : devGroups;
var prodCourses = prodGroups.reduce(function (acc, group) {
  return acc.concat(group.courses);
}, []); //[business, biology, engineering, medical, education, law, finance, economics, sociology, psychology, communication, chemistry,  health, mathematics, political, envScience, computerScience, history, visual, english, physics];
var devCourses = devGroups.reduce(function (acc, group) {
  return acc.concat(group.courses);
}, []); //[cc('lightbulb.png', 'Computer Science/Information Technology', '4oW8miC7RjQvBJcSiTAP7xQVY5Y2')];

var GA_DEV_ID = 'G-4BR1GGRMBT';
var GA_LIVE_ID = 'G-ZHQ6KH3HD9';
export var CourseLists = environment() === 'production' ? prodCourses : devCourses;
export var stripeKey = environment() === 'production' ? STRIPE_KEYS.LIVE : STRIPE_KEYS.TEST;
export var GA_MEASUREMENT_ID = environment() === 'production' ? GA_LIVE_ID : GA_DEV_ID;
export var partner4Id = environment() === 'production' ? 'yGsCgWyK73VcIQxr4GdjvxixkbC3' : 'DzeS4X2jKOXUd1TlGfKrkXR985w1';
export var pageview = function pageview(url) {
  window.gtag('config', GA_MEASUREMENT_ID, {
    page_path: url
  });
};
export var getActiveSubscriptionOption = function getActiveSubscriptionOption(id) {
  return Object.values(subscriptionOptions).find(function (option) {
    return option.PRICE_ID === id;
  });
};
// function getEnvVars(env = process.env.NODE_ENV) {
//     if (env === 'development') return devConfig;
//     if (env === 'production') return liveConfig;
//     return devConfig;
// }

export var fonts = {
  nunitoBold: 'Nunito_700Bold',
  nunitoRegular: 'Nunito_400Regular',
  nuitoSemiBold: 'Nunito_600SemiBold',
  nuitoMedium: 'Nunito_500Medium',
  latoRegular: 'Lato_400Regular',
  latoBold: 'Lato_700Bold',
  latoSemiBold: 'Lato_600SemiBold'
};
var nuitoSemiBold = {
  fontFamily: 'Nuito, sans-serif',
  fontWeight: '600'
};
var latoSemiBold = {
  fontFamily: 'Lato, sans-serif',
  fontWeight: '600'
};
export var latoRegular = {
  fontFamily: 'Lato, sans-serif',
  fontWeight: '400'
};
var latoBold = {
  fontFamily: 'Lato, sans-serif',
  fontWeight: '700'
};
var nuitoBold = {
  fontFamily: 'Nuito, sans-serif',
  fontWeight: '700'
};
var nuitoRegular = {
  fontFamily: 'Nuito, sans-serif',
  fontWeight: '400'
};
export var TextStyles = {
  XLHeader: _objectSpread({
    fontSize: 56,
    lineHeight: 67,
    letterSpacing: 1,
    color: colors.text
  }, nuitoBold),
  largeHeader: _objectSpread({
    fontSize: 40,
    lineHeight: 48,
    letterSpacing: 1,
    color: colors.text
  }, nuitoBold),
  header: _objectSpread({
    fontSize: 32,
    lineHeight: 42,
    color: colors.text
  }, nuitoBold),
  nuitoRegularHeader: _objectSpread({
    fontSize: 28,
    lineHeight: 38,
    color: colors.text
  }, nuitoRegular),
  smallHeader: _objectSpread({
    fontSize: 22,
    lineHeight: 32,
    color: colors.text
  }, nuitoBold),
  miniHeader: _objectSpread({
    fontSize: 16,
    lineHeight: 22,
    color: colors.text
  }, nuitoBold),
  largeParagraph: _objectSpread(_objectSpread({
    fontSize: 22,
    lineHeight: 35,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: -0.1
  }),
  mediumParagraph: _objectSpread(_objectSpread({
    fontSize: 18,
    lineHeight: 28,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: 0
  }),
  paragraph: _objectSpread(_objectSpread({
    fontSize: 16,
    lineHeight: 24,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: 0
  }),
  hoverEffect: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    borderRadius: '16px',
    zIndex: '10',
    cursor: 'pointer',
    backgroundColor: 'rgba(0,0,0,0)',
    // Start transparent
    transition: 'background-color 0.5s ease',
    // Smooth transition
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.5)' // Darken on hover
    }
  },

  XSParagraph: _objectSpread(_objectSpread({
    fontSize: 12,
    lineHeight: 18,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: 0
  }),
  XXSParagraph: _objectSpread(_objectSpread({
    fontSize: 10,
    lineHeight: 15,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: 0
  }),
  smallParagraph: _objectSpread(_objectSpread({
    fontSize: 14,
    lineHeight: 22,
    color: colors.text
  }, latoRegular), {}, {
    letterSpacing: 0
  }),
  smallBoldParagraph: _objectSpread(_objectSpread({
    fontSize: 14,
    lineHeight: 22,
    color: colors.text
  }, latoBold), {}, {
    letterSpacing: 0
  }),
  latoBoldLargeParagraph: _objectSpread(_objectSpread({
    fontSize: 22,
    lineHeight: 34,
    color: colors.text,
    fontWeight: '600'
  }, latoSemiBold), {}, {
    letterSpacing: 0
  }),
  latoBoldMediumParagraph: _objectSpread(_objectSpread({
    fontSize: 18,
    lineHeight: 28,
    color: colors.text,
    fontWeight: '600'
  }, latoSemiBold), {}, {
    letterSpacing: 0.2
  }),
  latoBoldParagraph: _objectSpread(_objectSpread({
    fontSize: 16,
    lineHeight: 24,
    color: colors.text,
    fontWeight: '600'
  }, latoSemiBold), {}, {
    letterSpacing: 0.2
  }),
  latoBoldSmallParagraph: _objectSpread(_objectSpread({
    fontSize: 14,
    lineHeight: 20,
    color: colors.text,
    fontWeight: '600'
  }, latoSemiBold), {}, {
    letterSpacing: 0.2
  }),
  nuitoMediumHeader: _objectSpread({
    fontSize: 28,
    lineHeight: 38,
    letterSpacing: 1,
    color: colors.text
  }, nuitoBold),
  nuitoHeader: _objectSpread({
    fontSize: 24,
    lineHeight: 34,
    letterSpacing: 1,
    color: colors.text
  }, nuitoBold),
  nuitoBold: _objectSpread(_objectSpread({
    fontSize: 20,
    lineHeight: 28
  }, nuitoBold), {}, {
    color: colors.text
  }),
  smallBold: _objectSpread({
    fontSize: 16,
    lineHeight: 22,
    color: colors.text
  }, nuitoSemiBold),
  miniBold: _objectSpread({
    fontSize: 12,
    lineHeight: 18,
    color: colors.text,
    fontWeight: '700'
  }, latoBold),
  latoLarge: _objectSpread({
    fontSize: 24,
    lineHeight: 34,
    color: colors.text
  }, latoBold),
  buttonText: _objectSpread(_objectSpread({
    fontSize: 18,
    lineHeight: 24,
    color: colors.white
  }, nuitoBold), {}, {
    letterSpacing: 1
  }),
  smallButtonText: _objectSpread(_objectSpread({
    fontSize: 18,
    lineHeight: 24,
    color: colors.white
  }, nuitoRegular), {}, {
    letterSpacing: 1
  })
};

// const convertStylesForMaterialUI = (styles) => {
//     let convertedStyles = {};
//     for (let key in styles) {
//         convertedStyles[key] = {};
//         for(let prop in styles[key]) {
//             if (typeof styles[key][prop] === 'number' ) {
//                 convertedStyles[key][prop] = styles[key][prop] + 'px';
//             } else {
//                 convertedStyles[key][prop] = styles[key][prop];
//             }
//         }
//     }
//     return convertedStyles;
// }

function adjustFontSize(originalSize, originalLineHeight) {
  var decrease = originalSize >= 20 ? 0.2 : 0.2;
  var newSize = originalSize - originalSize * decrease;
  var decreaseLineHeight = originalLineHeight >= 30 ? 0.2 : 0.2;
  var newLineHeight = originalLineHeight - originalLineHeight * decreaseLineHeight;
  // Use Math.round to avoid half-pixel line heights
  var roundedLineHeight = originalLineHeight === 35 ? 24 : originalLineHeight >= 30 ? Math.floor(newLineHeight / 2) * 2 : Math.ceil(newLineHeight / 2) * 2;
  var roundedSize = originalSize >= 20 ? Math.floor(newSize / 2) * 2 : Math.ceil(newSize / 2) * 2;
  // Round down to the nearest even number if originalSize >= 20, else round up
  return {
    fontSize: "".concat(roundedSize, "px"),
    lineHeight: "".concat(roundedLineHeight, "px")
  };
}
var convertStylesForMaterialUI = function convertStylesForMaterialUI(styles) {
  var convertedStyles = {};
  for (var key in styles) {
    convertedStyles[key] = {};
    for (var prop in styles[key]) {
      if (typeof styles[key][prop] === 'number') {
        convertedStyles[key][prop] = "".concat(styles[key][prop], "px");
      } else {
        convertedStyles[key][prop] = styles[key][prop];
      }
    }

    // Extract the original font size
    var originalFontSizePx = convertedStyles[key].fontSize;
    var originalFontSize = parseInt(originalFontSizePx, 10);
    var originalLineHeightPx = convertedStyles[key].lineHeight;
    var originalLineHeight = parseInt(originalLineHeightPx, 10);
    var _adjustFontSize = adjustFontSize(originalFontSize, originalLineHeight),
      fontSize = _adjustFontSize.fontSize,
      lineHeight = _adjustFontSize.lineHeight;

    // Apply the new logic for smaller screens
    convertedStyles[key]['@media (max-width:750px)'] = {
      // ...convertedStyles[key],
      fontSize: fontSize,
      lineHeight: lineHeight
    };

    // You could continue to add other breakpoints with different rules if required.
  }

  return convertedStyles;
};
export var MUIStyles = convertStylesForMaterialUI(TextStyles);
export var userElements = {
  AVAILABLE_IMAGES: 'availableImages',
  AVAILABLE_SUMMARIES: 'availableSummaries',
  FROM_TOPICS: 'fromTopics',
  LAST_UPDATED: 'lastUpdated',
  AVAILABLE_QUESTIONS: 'availableQuestions'
};