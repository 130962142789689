const translations = {
    userType: {
      question: 'Studerar du för närvarande?',
      options: {
        school_student: 'Skolstudent',
        university_student: 'Universitetsstudent',
        not_student: 'Inte en student',
      },
    },
    schoolCurriculum: {
      question: 'Vilken läroplan studerar du?',
      options: {
        ib: 'Internationellt Baccalaureate (IB)',
        british: 'Brittiska läroplanen (GCSE/A-Levels)',
        american: 'Amerikanska läroplanen',
        swedish: 'Svenska nationella läroplanen',
        australian: 'Australiska läroplanen',
        other: 'Annan',
      },
    },
    schoolYear: {
      question: 'Vilken årskurs går du i?',
      optionsByCurriculum: {
        ib: [
          { value: 'year11_ib1', label: 'År 11 (IB År 1)' },
          { value: 'year12_ib2', label: 'År 12 (IB År 2)' },
        ],
        british: [
          { value: 'year10_gcse1', label: 'År 10 (GCSE År 1)' },
          { value: 'year11_gcse2', label: 'År 11 (GCSE År 2)' },
          { value: 'year12_as', label: 'År 12 (AS-År)' },
          { value: 'year13_a', label: 'År 13 (A-År)' },
        ],
        american: [
          { value: 'grade9', label: 'Årskurs 9 (Freshman)' },
          { value: 'grade10', label: 'Årskurs 10 (Sophomore)' },
          { value: 'grade11', label: 'Årskurs 11 (Junior)' },
          { value: 'grade12', label: 'Årskurs 12 (Senior)' },
        ],
        australian: [
          { value: 'year9', label: 'Årskurs 9' },
          { value: 'year10', label: 'Årskurs 10' },
          { value: 'year11', label: 'Årskurs 11' },
          { value: 'year12', label: 'Årskurs 12' },
        ],
        swedish: [
          { value: 'year7', label: 'Årskurs 7' },
          { value: 'year8', label: 'Årskurs 8' },
          { value: 'year9', label: 'Årskurs 9' },
          { value: 'gymnasium1', label: 'Gymnasiet år 1' },
          { value: 'gymnasium2', label: 'Gymnasiet år 2' },
          { value: 'gymnasium3', label: 'Gymnasiet år 3' },
        ],
        other: [
          { value: 'year7', label: 'Årskurs 7' },
          { value: 'year8', label: 'Årskurs 8' },
          { value: 'year9', label: 'Årskurs 9' },
          { value: 'year10', label: 'Årskurs 10' },
          { value: 'year11', label: 'Årskurs 11' },
          { value: 'year12', label: 'Årskurs 12' },
        ],
      
      },
    },
    schoolSubjects: {
      question: 'Välj de ämnen du för närvarande studerar:',
      optionsByCurriculum: {
        ib: [
          { value: 'math_hl', label: 'Matematik HL' },
          { value: 'math_sl', label: 'Matematik SL' },
          { value: 'physics_hl', label: 'Fysik HL' },
          { value: 'physics_sl', label: 'Fysik SL' },
          { value: 'chemistry_hl', label: 'Kemi HL' },
          { value: 'chemistry_sl', label: 'Kemi SL' },
          { value: 'biology_hl', label: 'Biologi HL' },
          { value: 'biology_sl', label: 'Biologi SL' },
          { value: 'english_lit_hl', label: 'Engelska A Litteratur HL' },
          { value: 'english_lit_sl', label: 'Engelska A Litteratur SL' },
          { value: 'history_hl', label: 'Historia HL' },
          { value: 'history_sl', label: 'Historia SL' },
          { value: 'economics_hl', label: 'Ekonomi HL' },
          { value: 'economics_sl', label: 'Ekonomi SL' },
          { value: 'cs_hl', label: 'Datorvetenskap HL' },
          { value: 'cs_sl', label: 'Datorvetenskap SL' },
          { value: 'visual_arts_hl', label: 'Bildkonst HL' },
          { value: 'visual_arts_sl', label: 'Bildkonst SL' },
          { value: 'spanish_b_hl', label: 'Spanska B HL' },
          { value: 'spanish_b_sl', label: 'Spanska B SL' },
        ],
        british: [
          { value: 'mathematics', label: 'Matematik' },
          { value: 'further_math', label: 'Vidare Matematik' },
          { value: 'physics', label: 'Fysik' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'english_lit', label: 'Engelsk Litteratur' },
          { value: 'english_lang', label: 'Engelska Språk' },
          { value: 'history', label: 'Historia' },
          { value: 'geography', label: 'Geografi' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'cs', label: 'Datorvetenskap' },
          { value: 'art_design', label: 'Konst och Design' },
          { value: 'business_studies', label: 'Företagsstudier' },
          { value: 'psychology', label: 'Psykologi' },
          { value: 'sociology', label: 'Sociologi' },
          { value: 'law', label: 'Juridik' },
          { value: 'french', label: 'Franska' },
          { value: 'spanish', label: 'Spanska' },
          { value: 'german', label: 'Tyska' },
        ],
        american: [
          { value: 'algebra1', label: 'Algebra I' },
          { value: 'geometry', label: 'Geometri' },
          { value: 'algebra2', label: 'Algebra II' },
          { value: 'pre_calculus', label: 'Förberäkningskurs' },
          { value: 'calculus', label: 'Kalkyl' },
          { value: 'ap_calc_ab', label: 'AP Kalkyl AB' },
          { value: 'ap_calc_bc', label: 'AP Kalkyl BC' },
          { value: 'physics', label: 'Fysik' },
          { value: 'ap_physics1', label: 'AP Fysik 1' },
          { value: 'ap_physics2', label: 'AP Fysik 2' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'ap_chemistry', label: 'AP Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'ap_biology', label: 'AP Biologi' },
          { value: 'english_lit', label: 'Engelsk Litteratur' },
          { value: 'ap_english_lit', label: 'AP Engelsk Litteratur' },
          { value: 'us_history', label: 'US Historia' },
          { value: 'ap_us_history', label: 'AP US Historia' },
          { value: 'world_history', label: 'Världshistoria' },
          { value: 'ap_world_history', label: 'AP Världshistoria' },
          { value: 'government', label: 'Regering' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'spanish', label: 'Spanska' },
          { value: 'ap_spanish', label: 'AP Spanska Språk' },
        ],
        australian: [
          { value: 'math_methods', label: 'Matematisk Metod' },
          { value: 'specialist_math', label: 'Specialistmatematik' },
          { value: 'general_math', label: 'Allmän Matematik' },
          { value: 'physics', label: 'Fysik' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'english', label: 'Engelska' },
          { value: 'literature', label: 'Litteratur' },
          { value: 'modern_history', label: 'Modern Historia' },
          { value: 'ancient_history', label: 'Antikens Historia' },
          { value: 'geography', label: 'Geografi' },
          { value: 'legal_studies', label: 'Juridikstudier' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'business_management', label: 'Företagsledning' },
          { value: 'visual_arts', label: 'Bildkonst' },
          { value: 'design_tech', label: 'Design och Teknik' },
          { value: 'it', label: 'Informationsteknik' },
          { value: 'languages', label: 'Språk' },
        ],
        other: [
          { value: 'mathematics', label: 'Matematik' },
          { value: 'adv_math', label: 'Avancerad Matematik' },
          { value: 'physics', label: 'Fysik' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'english', label: 'Engelska' },
          { value: 'history', label: 'Historia' },
          { value: 'geography', label: 'Geografi' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'cs', label: 'Datorvetenskap' },
          { value: 'art_design', label: 'Konst och Design' },
          { value: 'business_studies', label: 'Företagsstudier' },
          { value: 'psychology', label: 'Psykologi' },
          { value: 'sociology', label: 'Sociologi' },
          { value: 'law', label: 'Juridik' },
          { value: 'french', label: 'Franska' },
          { value: 'spanish', label: 'Spanska' },
          { value: 'german', label: 'Tyska' },
          { value: 'music', label: 'Musik' },
          { value: 'pe', label: 'Gymnastik' },
        ],
        swedish: [
          { value: 'mathematics', label: 'Matematik' },
          { value: 'physics', label: 'Fysik' },
          { value: 'chemistry', label: 'Kemi' },
          { value: 'biology', label: 'Biologi' },
          { value: 'english', label: 'Engelska' },
          { value: 'history', label: 'Historia' },
          { value: 'geography', label: 'Geografi' },
          { value: 'economics', label: 'Ekonomi' },
          { value: 'cs', label: 'Datorvetenskap' },
          { value: 'art_design', label: 'Konst och Design' },
          { value: 'business_studies', label: 'Företagsstudier' },
          { value: 'psychology', label: 'Psykologi' },
          { value: 'sociology', label: 'Sociologi' },
          { value: 'law', label: 'Juridik' },
          { value: 'french', label: 'Franska' },
          { value: 'spanish', label: 'Spanska' },
          { value: 'german', label: 'Tyska' },
          { value: 'music', label: 'Musik' },
          { value: 'pe', label: 'Gymnastik' },
        ],
      },
      multiple: true,
      dependsOn: { id: 'schoolCurriculum' },
    },
    universityProgram: {
      question: 'Vilket program är du inskriven i?',
      options: {
        engineering: 'Ingenjör',
        arts: 'Konst',
        science: 'Vetenskap',
        business: 'Företagsekonomi',
        law: 'Juridik',
        medicine: 'Medicin',
        computer_science: 'Datorvetenskap',
        psychology: 'Psykologi',
        education: 'Utbildning',
        architecture: 'Arkitektur',
      },
    },
    universityCourses: {
      question: 'Välj de kurser du för närvarande studerar:',
      optionsByProgram: {
        engineering: [
          { value: 'calculus1', label: 'Kalkyl I' },
          { value: 'calculus2', label: 'Kalkyl II' },
          { value: 'linear_algebra', label: 'Linjär Algebra' },
          { value: 'physics1', label: 'Fysik I' },
          { value: 'physics2', label: 'Fysik II' },
          { value: 'chem_for_eng', label: 'Kemi för Ingenjörer' },
          { value: 'statics', label: 'Statik' },
          { value: 'dynamics', label: 'Dynamik' },
          { value: 'thermodynamics', label: 'Termodynamik' },
          { value: 'materials_science', label: 'Materialvetenskap' },
          { value: 'fluid_mechanics', label: 'Strömningsmekanik' },
          { value: 'electrical_circuits', label: 'Elektriska Kretsar' },
          { value: 'eng_design', label: 'Ingenjörsdesign' },
          { value: 'mech_materials', label: 'Materialmekanik' },
        ],
        arts: [
          { value: 'intro_literature', label: 'Introduktion till Litteratur' },
          { value: 'art_history', label: 'Konsthistoria' },
          { value: 'philosophy_101', label: 'Filosofi 101' },
          { value: 'psychology_101', label: 'Psykologi 101' },
          { value: 'sociology_101', label: 'Sociologi 101' },
          { value: 'creative_writing', label: 'Kreativt Skrivande' },
          { value: 'performing_arts', label: 'Scenkonst' },
          { value: 'history_west_civ', label: 'Västerlandets Historik' },
          { value: 'cultural_studies', label: 'Kulturstudier' },
        ],
        science: [
          { value: 'bio1', label: 'Biologi I' },
          { value: 'bio2', label: 'Biologi II' },
          { value: 'chem1', label: 'Kemi I' },
          { value: 'chem2', label: 'Kemi II' },
          { value: 'physics1', label: 'Fysik I' },
          { value: 'physics2', label: 'Fysik II' },
          { value: 'earth_science', label: 'Geovetenskap' },
          { value: 'env_science', label: 'Miljövetenskap' },
          { value: 'statistics', label: 'Statistik' },
          { value: 'genetics', label: 'Genetik' },
          { value: 'organic_chem', label: 'Organisk Kemi' },
          { value: 'ecology', label: 'Ekologi' },
        ],
        business: [
          { value: 'intro_business', label: 'Introduktion till Företagsekonomi' },
          { value: 'accounting1', label: 'Bokföring I' },
          { value: 'accounting2', label: 'Bokföring II' },
          { value: 'microeconomics', label: 'Mikroekonomi' },
          { value: 'macroeconomics', label: 'Makroekonomi' },
          { value: 'marketing_principles', label: 'Marknadsprinciper' },
          { value: 'business_law', label: 'Affärsjuridik' },
          { value: 'org_behavior', label: 'Organisationsbeteende' },
          { value: 'finance', label: 'Finansiering' },
          { value: 'operations_mgmt', label: 'Produktionsledning' },
          { value: 'intl_business', label: 'Internationell Företagsekonomi' },
        ],
        law: [
          { value: 'intro_law', label: 'Introduktion till Juridik' },
          { value: 'contract_law', label: 'Avtalsrätt' },
          { value: 'criminal_law', label: 'Straffrätt' },
          { value: 'property_law', label: 'Fastighetsrätt' },
          { value: 'tort_law', label: 'Skadeståndsrätt' },
          { value: 'intl_law', label: 'Internationell Rätt' },
          { value: 'constitutional_law', label: 'Konstitutionell Rätt' },
          { value: 'legal_writing', label: 'Juridiskt Skrivande' },
          { value: 'admin_law', label: 'Administrativ Rätt' },
        ],
        medicine: [
          { value: 'human_anatomy', label: 'Mänsklig Anatomi' },
          { value: 'human_physiology', label: 'Mänsklig Fysiologi' },
          { value: 'biochemistry', label: 'Biokemi' },
          { value: 'medical_ethics', label: 'Medicinsk Etik' },
          { value: 'microbiology', label: 'Mikrobiologi' },
          { value: 'pathology', label: 'Patologi' },
          { value: 'pharmacology', label: 'Farmakologi' },
          { value: 'neuroscience', label: 'Neurovetenskap' },
          { value: 'clinical_skills', label: 'Kliniska Färdigheter' },
        ],
        computer_science: [
          { value: 'intro_programming', label: 'Introduktion till Programmering' },
          { value: 'oop', label: 'Objektorienterad Programmering' },
          { value: 'data_structures', label: 'Datastrukturer' },
          { value: 'algorithms', label: 'Algoritmer' },
          { value: 'comp_systems', label: 'Datorsystem' },
          { value: 'operating_systems', label: 'Operativsystem' },
          { value: 'database_systems', label: 'Databassystem' },
          { value: 'software_eng', label: 'Programvaruteknik' },
          { value: 'ai', label: 'Artificiell Intelligens' },
          { value: 'machine_learning', label: 'Maskininlärning' },
          { value: 'web_dev', label: 'Webbutveckling' },
          { value: 'networks_security', label: 'Nätverk och Säkerhet' },
        ],
        psychology: [
          { value: 'intro_psychology', label: 'Introduktion till Psykologi' },
          { value: 'dev_psychology', label: 'Utvecklingspsykologi' },
          { value: 'cognitive_psychology', label: 'Kognitiv Psykologi' },
          { value: 'abnormal_psychology', label: 'Abnorm Psykologi' },
          { value: 'social_psychology', label: 'Socialpsykologi' },
          { value: 'neuroscience', label: 'Neurovetenskap' },
          { value: 'research_methods', label: 'Forskningsmetoder' },
          { value: 'personality_psych', label: 'Personlighetspsykologi' },
          { value: 'psych_assessment', label: 'Psykologiska Bedömningar' },
        ],
        education: [
          { value: 'foundations_edu', label: 'Utbildningens Grunder' },
          { value: 'edu_psychology', label: 'Utbildningspsykologi' },
          { value: 'curriculum_dev', label: 'Kursplanutveckling' },
          { value: 'class_mgmt', label: 'Klassrumsledning' },
          { value: 'assessment_eval', label: 'Bedömning och Utvärdering' },
          { value: 'special_education', label: 'Särskild Utbildning' },
          { value: 'teaching_practicum', label: 'Undervisningspraktik' },
          { value: 'edu_technology', label: 'Utbildningsteknik' },
        ],
        architecture: [
          { value: 'design_studio1', label: 'Designstudio I' },
          { value: 'design_studio2', label: 'Designstudio II' },
          { value: 'arch_history', label: 'Arkitekturens Historia' },
          { value: 'building_tech', label: 'Byggteknik' },
          { value: 'structures', label: 'Strukturer' },
          { value: 'env_systems', label: 'Miljösystem' },
          { value: 'digital_design', label: 'Digital Design' },
          { value: 'urban_planning', label: 'Stadsplanering' },
          { value: 'sustainable_design', label: 'Hållbar Design' },
        ],
        // other: [
        //   { value: 'electives', label: 'Valfria Kurser' },
        //   { value: 'gen_ed_courses', label: 'Allmänna Utbildningskurser' },
        //   { value: 'interdisciplinary_studies', label: 'Tvärvetenskapliga Studier' },
        // ],
      },
      multiple: true,
      dependsOn: { id: 'universityProgram' },
    },
 
    // ... other translations ...
    hobbyInterests: {
      question: 'Välj dina personliga intresseområden:',
      options: [
        {
          category: 'Psychology',
          translatedCategory: 'Psykologi',
          subOptions: [
            { value: 'cognitive_psychology', label: 'Kognitiv psykologi' },
            { value: 'behavioral_psychology', label: 'Beteendepsykologi' },
            { value: 'developmental_psychology', label: 'Utvecklingspsykologi' },
            { value: 'social_psychology', label: 'Socialpsykologi' },
            { value: 'positive_psychology', label: 'Positiv psykologi' },
            { value: 'therapy_techniques', label: 'Terapi tekniker' },
          ],
        },
        {
          category: 'Playing Music',
          translatedCategory: 'Spela Musik',
          subOptions: [
            { value: 'guitar_beginner', label: 'Gitarr Nybörjare' },
            { value: 'guitar_intermediate_advanced', label: 'Gitarr Mellan/Avancerad' },
            { value: 'piano_beginner', label: 'Piano Nybörjare' },
            { value: 'piano_intermediate_advanced', label: 'Piano Mellan/Avancerad' },
            { value: 'piano_advanced', label: 'Piano Avancerad' },
            { value: 'violin', label: 'Violin' },
            { value: 'drums_beginner', label: 'Trummor Nybörjare' },
            { value: 'drums_intermediate_advanced', label: 'Trummor Mellan/Avancerad' },
            { value: 'brass_instruments', label: 'Bleckblåsinstrument (Trumpet, Trombone)' },
            { value: 'woodwind_instruments', label: 'Träblåsinstrument (Flöjt, Klarinett)' },
            { value: 'music_theory', label: 'Musikteori' },
            { value: 'music_production', label: 'Musikproduktion' },
            { value: 'songwriting_lyrics', label: 'Låtskrivande: Låttexter' },
            { value: 'songwriting_melody', label: 'Låtskrivande: Melodi Skapande' },
            { value: 'songwriting_structure', label: 'Låtskrivande: Låtstruktur' },
            { value: 'songwriting_genres', label: 'Låtskrivande: Genrer (Pop, Rock, Hip-Hop, etc.)' },
            { value: 'classical_composition', label: 'Klassisk Komposition' },
            { value: 'contemporary_composition', label: 'Samtida Komposition' },
            { value: 'arranging_music', label: 'Arrangera Musik' },
            { value: 'electronic_composition', label: 'Elektronisk Komposition' },
          ],
        },
        {
          category: 'Languages',
          translatedCategory: 'Språk',
          subOptions: [
            { value: 'spanish_beginner', label: 'Spanska Nybörjare' },
            { value: 'spanish_intermediate', label: 'Spanska Mellan' },
            { value: 'spanish_advanced', label: 'Spanska Avancerad' },
            { value: 'french_beginner', label: 'Franska Nybörjare' },
            { value: 'french_intermediate', label: 'Franska Mellan' },
            { value: 'french_advanced', label: 'Franska Avancerad' },
            { value: 'mandarin_beginner', label: 'Mandarin Kinesiska Nybörjare' },
            { value: 'mandarin_intermediate', label: 'Mandarin Kinesiska Mellan' },
            { value: 'mandarin_advanced', label: 'Mandarin Kinesiska Avancerad' },
            { value: 'german_beginner', label: 'Tyska Nybörjare' },
            { value: 'german_intermediate', label: 'Tyska Mellan' },
            { value: 'german_advanced', label: 'Tyska Avancerad' },
            { value: 'italian_beginner', label: 'Italienska Nybörjare' },
            { value: 'italian_intermediate', label: 'Italienska Mellan' },
            { value: 'italian_advanced', label: 'Italienska Avancerad' },
            { value: 'japanese_beginner', label: 'Japanska Nybörjare' },
            { value: 'japanese_intermediate', label: 'Japanska Mellan' },
            { value: 'japanese_advanced', label: 'Japanska Avancerad' },
            { value: 'russian_beginner', label: 'Ryska Nybörjare' },
            { value: 'russian_intermediate', label: 'Ryska Mellan' },
            { value: 'russian_advanced', label: 'Ryska Avancerad' },
          ],
        },
        {
          category: 'Technology_and_Coding',
          translatedCategory: 'Teknik och Kodning',
          subOptions: [
            { value: 'frontend_dev', label: 'Frontend Utveckling (HTML, CSS, JavaScript, React)' },
            { value: 'backend_dev', label: 'Backend Utveckling (Node.js, Python, Ruby)' },
            { value: 'fullstack_dev', label: 'Fullstack Utveckling' },
            { value: 'ux_design', label: 'UX Design' },
            { value: 'ui_design', label: 'UI Design' },
            { value: 'game_design', label: 'Speldesign' },
            { value: 'cybersecurity', label: 'Cybersecurity' },
            { value: 'data_science', label: 'Data Science' },
            { value: 'machine_learning', label: 'Maskininlärning' },
            { value: 'mobile_app_dev', label: 'Mobilapp Utveckling' },
            { value: 'cloud_computing', label: 'Molntjänster' },
          ],
        },
        {
          category: 'Entertainment',
          translatedCategory: 'Underhållning',
          subOptions: [
            { value: 'movie_analysis', label: 'Filmanalys' },
            { value: 'high_grossing_movies', label: 'Högavkastande Filmer' },
            { value: 'celebrity_news', label: 'Kändisnyheter' },
            { value: 'tv_shows', label: 'TV-program och Serier' },
            { value: 'popular_video_games', label: 'Populära Videospel' },
            { value: 'comic_books', label: 'Serietidningar och Grafiska Romaner' },
          ],
        },
        {
          category: 'Self_Growth_Personal_Development',
          translatedCategory: 'Självutveckling/Personlig Utveckling',
          subOptions: [
            { value: 'mindfulness', label: 'Mindfulness och Meditation' },
            { value: 'time_management', label: 'Tidsledningstekniker' },
            { value: 'productivity_hacks', label: 'Produktivitetsknep' },
            { value: 'emotional_intelligence', label: 'Emotionell Intelligens' },
            { value: 'goal_setting', label: 'Målsättning och Vanebildning' },
            { value: 'public_speaking', label: 'Offentligt Talande' },
            { value: 'networking_skills', label: 'Nätverksfärdigheter' },
            { value: 'negotiation_techniques', label: 'Förhandlingstekniker' },
            { value: 'leadership_skills', label: 'Ledarskapsförmåga' },
            { value: 'critical_thinking', label: 'Kritiskt Tänkande' },
            { value: 'creativity_enhancement', label: 'Kreativitetsförbättring' },
          ],
        },
        {
          category: 'Politics_Geography',
          translatedCategory: 'Politik/Geografi',
          subOptions: [
            { value: 'political_theories', label: 'Politiska Teorier' },
            { value: 'global_current_events', label: 'Globala Aktuella Händelser' },
            { value: 'environmental_studies', label: 'Miljöstudier' },
            { value: 'cultural_geography', label: 'Kulturell Geografi' },
            { value: 'international_relations', label: 'Internationella Relationer' },
            { value: 'geopolitical_analysis', label: 'Geopolitisk Analys' },
          ],
        },
        {
          category: 'Sports',
          translatedCategory: 'Sport',
          subOptions: [
            { value: 'gym_techniques', label: 'Gymtekniker' },
            { value: 'sport_psychology', label: 'Psykologi i Sport' },
            { value: 'sport_history', label: 'Sportens Historia/Kultur' },
            { value: 'coaching_techniques', label: 'Tränartekniker' },
            { value: 'performance_analysis', label: 'Prestandaanalys' },
          ],
        },
        {
          category: 'Creative_Arts',
          translatedCategory: 'Kreativa Konstformer',
          subOptions: [
            { value: 'painting_drawing', label: 'Målnings- och Rittekniker' },
            { value: 'photography', label: 'Fotografi för Nybörjare' },
            { value: 'digital_art', label: 'Digital Konst och Illustration' },
            { value: 'crafting_diy', label: 'Hantverk och DIY-projekt' },
            { value: 'graphic_design', label: 'Grafisk Design' },
            { value: 'creative_writing', label: 'Kreativt skrivande' },
          ],
        },
        {
          category: 'Cooking_Baking',
          translatedCategory: 'Matlagning/Bakning',
          subOptions: [
            { value: 'basic_cooking_skills', label: 'Grundläggande Matlagning' },
            { value: 'specialty_cuisines', label: 'Särskilda Kök (Italienskt, Mexikanskt, Asiatisk)' },
            { value: 'baking_techniques', label: 'Bakningstekniker' },
            { value: 'meal_prep_ideas', label: 'Måltidsplaneringstips' },
            { value: 'healthy_eating', label: 'Hälsosam Kost och Näring' },
          ],
        },
        {
          category: 'Travel',
          translatedCategory: 'Resor',
          subOptions: [
            { value: 'travel_planning', label: 'Reseplaneringstips' },
            { value: 'cultural_etiquette', label: 'Kulturell Etikett i Världen' },
            { value: 'adventures_abroad', label: 'Äventyr och Upplevelser Utomlands' },
            { value: 'destination_highlights', label: 'Resmålshöjdpunkter' },
            { value: 'travel_photography', label: 'Resefotografi' },
          ],
        },
        {
          category: 'Health_and_Fitness',
          translatedCategory: 'Hälsa och Fitness',
          subOptions: [
            { value: 'nutrition_dieting', label: 'Näring och Diet' },
            { value: 'mental_health', label: 'Medvetenhet om Mental Hälsa' },
            { value: 'yoga_pilates', label: 'Yoga och Pilates' },
            { value: 'running_marathon', label: 'Löpning och Maratonträning' },
            { value: 'exercise_science', label: 'Träningsvetenskap' },
          ],
        },
        {
          category: 'Science_and_Nature',
          translatedCategory: 'Vetenskap och Natur',
          subOptions: [
            { value: 'astronomy_space', label: 'Astronomi och Rymdforskning' },
            { value: 'biology_wildlife', label: 'Biologi och Viltliv' },
            { value: 'environmental_conservation', label: 'Miljöbevarande' },
            { value: 'physics_concepts', label: 'Fysikaliska Begrepp' },
            { value: 'technology_innovations', label: 'Teknologiska Innovationer' },
          ],
        },
        {
          category: 'History',
          translatedCategory: 'Historia',
          subOptions: [
            { value: 'ancient_civilizations', label: 'Antika Civilisationer' },
            { value: 'world_wars', label: 'Världskrigens Studier' },
            { value: 'historical_figures', label: 'Historiska Gestalter och Deras Påverkan' },
            { value: 'cultural_history', label: 'Kulturell Historia' },
            { value: 'social_movements', label: 'Sociala Rörelser' },
            { value: 'history_of_science', label: 'Vetenskapens Historia och Teknik' },
          ],
        },
        {
          category: 'Philosophy',
          translatedCategory: 'Filosofi',
          subOptions: [
            { value: 'ethics_morality', label: 'Etik och Moral' },
            { value: 'existentialism', label: 'Existentialism' },
            { value: 'eastern_philosophy', label: 'Österländsk Filosofi' },
            { value: 'western_philosophy', label: 'Västerländsk Filosofi' },
            { value: 'logic_critical_thinking', label: 'Logik och Kritiskt Tänkande' },
            { value: 'philosophy_of_mind', label: 'Sinnets Filosofi' },
          ],
        },
        {
          category: 'Brain_Science',
          translatedCategory: 'Hjärnforskning',
          subOptions: [
            { value: 'neuroscience_basics', label: 'Grundläggande Neurovetenskap' },
            { value: 'neuroplasticity', label: 'Neuroplastiska Begrepp' },
            { value: 'memory_techniques', label: 'Minnestekniker' },
            { value: 'cognitive_enhancement', label: 'Kognitiva Förbättringsstrategier' },
            { value: 'mental_health_disorders', label: 'Mental Hälsosjukdomar och Hantering' },
            { value: 'psychology_of_learning', label: 'Lärandets Psykologi' },
          ],
        },
        {
          category: 'Hobbies',
          translatedCategory: 'Hobbyer',
          subOptions: [
            { value: 'gardening_basics', label: 'Trädgårdsodling för Nybörjare' },
            { value: 'writing', label: 'Skrivande (Fiktion, Facklitteratur, Poesi)' },
            { value: 'blogging_vlogging', label: 'Bloggande och Vlogging' },
          ],
        },
      ],
      multiple: true,
    }
    // ... other translations ...
  };

  export default translations