'use client';

import React, { useEffect, useState, useMemo } from 'react';
import { Container, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { fetchArticles } from '@studygenius/shared/Requests'; // Adjust the import path
import ArticleListContent from './ArticleListContent'; // Adjust the import path
import colors from '@studygenius/shared/Colors';
import { useLocale } from 'next-intl';

const ArticleList = ({ selectedTopics, isLoadingTopics = false, searchQuery = '' }) => {
  const [articles, setArticles] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [seenArticleIds, setSeenArticleIds] = useState(new Set());
  const [lastFetchedCursors, setLastFetchedCursors] = useState({});
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const locale = useLocale();
  const languagePreference = locale;
  console.log('languagePreference in articleList', languagePreference);

  const pageSize = 10;

  const memoizedSelectedTopics = useMemo(() => selectedTopics || [], [selectedTopics]);

  useEffect(() => {
    console.log('in use effect')
    console.log('memoizedSelectedTopics', memoizedSelectedTopics)
    setArticles([]);
    setSeenArticleIds(new Set());
    setHasMore(true);
    setLastFetchedCursors({});
    setPage(0); // Reset page to 0 when search query or topics change
    loadMoreArticles(true);
  }, [memoizedSelectedTopics, searchQuery]);

  const loadMoreArticles = async (loadAlways = false) => {
    console.log('in load more');
    if (!loadAlways && (!hasMore || isLoadingTopics || isLoading)) return;
    try {
      setIsLoading(true);
      const result = await fetchArticles(
        memoizedSelectedTopics,
        pageSize,
        seenArticleIds,
        lastFetchedCursors,
        searchQuery,
        page,
        languagePreference
      );

      setIsLoading(false);
      console.log('result.articles', result.articles)
      if (result.articles && result.articles.length > 0) {
        console.log('result.articles', result.articles)
        setSeenArticleIds((prevIds) =>
          new Set([...prevIds, ...result.articles.map((a) => a.id)])
        );
        console.log('articles', articles)
        if(loadAlways) {
          setArticles(result.articles)
          setLastFetchedCursors((prevCursors) => ({
            ...result.lastFetchedCursors,
          }));
        } else {
          setArticles((prevArticles) => [...prevArticles, ...result.articles]);
          setLastFetchedCursors((prevCursors) => ({
            ...prevCursors,
            ...result.lastFetchedCursors,
          }));
        }

        // Update last fetched cursors per topic
       

        // Determine if there's more data to load
        if (result.allTopicsExhausted) {
          setHasMore(false);
        }

        // Increment page number if we're using search
        if (searchQuery && searchQuery.trim() !== '') {
          setPage((prevPage) => prevPage + 1);
        }
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error loading articles:', error);
      setHasMore(false);
    }
  };

  return (
    <div style={{ width: '100%', backgroundColor: colors.newBackground }}>
      {isLoadingTopics ? (
        <Container sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <CircularProgress />
        </Container>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => articles.length > 0 ? loadMoreArticles() : null}
          hasMore={hasMore}
          initialLoad={false} // Prevent automatic loading on mount
          loader={
            <Container key={0} sx={{ textAlign: 'center', marginTop: '2rem' }}>
              <CircularProgress />
            </Container>
          }
        >
          <ArticleListContent selectedTopics={memoizedSelectedTopics} articles={articles} searchQuery={searchQuery} />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ArticleList;